import React from "react"
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";
import CloseImg from "../../../Media/Img/close.png"
import Report from "../../../Models/Analysis/Report";
import { GetValueByPeriod, Period } from "../../../Models/Collections/PeriodDictionary";

import "./ReportBlock.css"

interface IReportBlock{
    report : Report
    changeReport(reportId : string) : void
    textProvider : ILanguagePhrase
    deleteReport(reportId : string) : void
}

export default function ReportBlock(props : IReportBlock){
    const onDelete = () => {
        props.deleteReport(props.report.reportId);
    }

    const dateToString = (date : string) => new Date(date).toLocaleDateString();

    let interval = GetValueByPeriod(props.report.dateType,
        props.textProvider.byDay,
        props.textProvider.byMonth,
        props.textProvider.byWeek,
        props.textProvider.byYear);

    let content = [
        <div onClick = {onDelete} className="PeriodBlockDelete"><img src={CloseImg}></img></div>,
        <p className="ReportBlockMobileFont">{props.textProvider.period + " : " + dateToString(props.report.firstDate) + "-" + dateToString(props.report.secondDate)}</p>,
        <p className="ReportBlockMobileFont">{props.textProvider.dateReport + " : " + dateToString(props.report.postTime)}</p>,
    ];

    if(props.report.isReady || props.report.almostReady)
        return <div onClick = {() => props.changeReport(props.report.reportId)} className="ReportBlock">
            {content}      
            {props.report.almostReady && <p className="ReportBlockMobileFont">{props.textProvider.almostReadyOrNotInsufficientData}</p>}
            <p className="ReportBlockMobileFont">{interval}</p>
        </div>     
                    
    return <div className="ReportBlockNotLoad">
        {content}
        <div className="ReportBlockMobileFont">{props.textProvider.reportNotLoad}</div>
    </div>
}