import BaseUser from "./BaseUser";

export default class UserInfo {
    constructor(firstName : string = '', lastName : string = '', email : string = '', phone : string = '') {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
    }

    public static fromUser(user : BaseUser) : UserInfo {
        return new UserInfo(user.firstName, user.lastName, user.email, user.phone);
    }

    public firstName : string;

    public lastName : string;

    public email : string;

    public phone : string;
}