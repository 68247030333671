import React from 'react'

import BaseContainer from '../../Containers/BaseContainer'

import './Switcher.css'

interface ISwitcher {
    textFirst : string
    textSecond : string

    onFirstSelected() : void
    onSecondSelected() : void
}

interface ISwitcherState {
    current : 1 | 2
}

export default class Switcher extends BaseContainer<ISwitcher, ISwitcherState> {
    constructor(props : ISwitcher) {
        super(props);
        this.state = {
            current : 1
        }
    }

    onFirstSelectedInternal() {
        if (this.state.current === 1) return;

        this.setState(state => { return { ...state, current: 1 } } );
        this.props.onFirstSelected();
    }

    onSecondSelectedInternal() {
        if (this.state.current === 2) return;

        this.setState(state => { return { ...state, current: 2 } } );
        this.props.onSecondSelected();
    }

    private readonly _activeClass = 'PrimaryButton';
    private readonly _nonActiveClass = 'SecondaryButton';

    render() {
        const firstClass = this.state.current === 1 ? this._activeClass : this._nonActiveClass;
        const secondClass = this.state.current === 2 ? this._activeClass : this._nonActiveClass;

        return <div className='SwitcherContainer'>
            <button className={firstClass} onClick={() => this.onFirstSelectedInternal()}>{this.props.textFirst}</button>
            <button className={secondClass} onClick={() => this.onSecondSelectedInternal()}>{this.props.textSecond}</button>
        </div>
    }
}