import React from 'react'

import BalanceOutcomeRecord from '../../../Models/Balance/BalanceOutcomeRecord'
import BaseContainer from '../../BaseContainer'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Pair from '../../../Models/Pair'
import PaginatedList from '../../../Components/PaginatedList'

interface IOutcomesTable {
    textProvider : ILanguagePhrase
    records : BalanceOutcomeRecord[]
    dateToString(d : Date) : string
}

interface IOutcomesTableState {
    itemsToShow : BalanceOutcomeRecord[]
    panelIndex : number
    moreButtonNeeded : boolean
}

export default class OutcomesTable extends BaseContainer<IOutcomesTable, IOutcomesTableState> {
    private readonly _paginatedListPageSize = 15;
    private readonly _paginatedList : PaginatedList<BalanceOutcomeRecord>;

    constructor(props : IOutcomesTable) {
        super(props);

        this._paginatedList = new PaginatedList<BalanceOutcomeRecord>(props.records, this._paginatedListPageSize)

        let items = this._paginatedList.nextPage();
        this.state = {
            itemsToShow : items,
            panelIndex : -1,
            moreButtonNeeded: items.length === this._paginatedListPageSize,
        };
    }

    getCost(count : number, amount : number) : string {
        let value = (amount / count);

        return Number.isFinite(value) ? value.toFixed(2) : '?';
    }

    getInfoPanel(item : BalanceOutcomeRecord, text : string, isInBeginning : boolean) {
        let style : React.CSSProperties = {};
        if (isInBeginning) style = { top: '0vh' };
        else style = { bottom: '1vh' }
        return <div className="BalanceUIPaymentInfoPanelContainer" style={style}>
            <div className="BalanceUIPaymentInfoPanel Shadow">
                <p className="SubHeader BalanceUIBold">{text}</p>
                <table className="BalanceUIPaymentInfoTable">
                    <tr>
                        <td><p className="Prompt">{this.props.textProvider.balanceBillItem}</p></td>
                        <td><p className="Prompt">{this.props.textProvider.balanceBillQuantity}</p></td>
                        <td><p className="Prompt">{this.props.textProvider.balanceBillPrice}</p></td>
                        <td><p className="Prompt">{this.props.textProvider.balanceBillTotal}</p></td>
                    </tr>
                    <tr className="GrayText">
                        <td><p className="Prompt">{this.props.textProvider.employees}</p></td>
                        <td><p className="Prompt">x{item.employeeCount}</p></td>
                        <td><p className="Prompt">{this.getCost(item.employeeCount, item.amount)}{item.currency.symbol}</p></td>
                        <td><p className="Prompt">{item.amount.toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                    <tr>
                        <td colSpan={3}><p className="Prompt">{this.props.textProvider.balanceBillTotal}:</p></td>
                        <td><p className="Prompt">{item.amount.toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                </table>
            </div>
        </div>
    }

    getItemTemplate(item : BalanceOutcomeRecord, index : number) {
        let text = `${this.props.textProvider.balanceTariff} ${item.tariffName}. ${this.props.textProvider.balanceInvoice}, ${this.props.dateToString(item.postTime)}`;
        let panel = null;
        if (index == this.state.panelIndex)
            panel = this.getInfoPanel(item, text, index < 10);
        return <tr  style={{backgroundColor: this.state.panelIndex === index ? '#F3F3F3' : 'white'}}
                    onClick={() => this.changeValueState(new Pair('panelIndex', this.state.panelIndex === index ? -1 : index))}>
            <td>{text}{panel}</td>
            <td>{item.amount.toFixed(2)}{item.currency.symbol}</td>
        </tr>
    }

    moreClicked() {
        let itemsToAdd = this._paginatedList.nextPage();
        this.changeValueState(
            new Pair('itemsToShow', this.state.itemsToShow.concat(itemsToAdd)), 
            new Pair('moreButtonNeeded', itemsToAdd.length === this._paginatedListPageSize)
        );
    }

    render() {
        return <div className="BalanceUITableContainer">
            <table className="BalanceUITable">
                <thead>
                    <td>{this.props.textProvider.balanceInvoice}</td>
                    <td>{this.props.textProvider.balanceAmount}</td>
                </thead>
                {this.state.itemsToShow.map((item,index) => this.getItemTemplate(item, index))}
                {this.state.moreButtonNeeded && <tr>
                    <td colSpan={2}><button className="WhiteButton" style={{paddingLeft: '0.5vw', paddingRight: '0.5vw'}} onClick={() => this.moreClicked()}>˅</button></td>
                </tr>}
            </table>
        </div>
    }
}