import React, { useState } from "react"

import LinkArea from "../../Components/LinkArea/LinkArea"
import LanguageChangeComponent from "../../Components/LanguageChangeComponent/LanguageChangeComponent"
import { InputV2, InputV2Props, InputV2Imaged } from "../../Components/InputV2/InputsV2"
import PopUpMessage from "../../Components/PopupMessage/PopupMessage";
import SimpleHeader from '../../Components/SimpleHeader/SimpleHeader'

import Validator from "../../Helpers/Validator/Validator"

import Eye from "../../Media/Img/eye.png"
import ClosedEye from "../../Media/Img/closedEye.png"

import "./SignUpPage.css"
import RoutesUrl from "../../Helpers/Routes/RoutesUrl"
import { IRoutesAgent } from "../../Helpers/Routes/RoutesAgent"
import ButtonInteractive, { ErrorImg, OkImg } from "../../Components/Buttons/ButtonInteractive/ButtonInteractive"
import { ITextProvider } from "../../Helpers/TextProvider/TextProvider";

const firstNameInputId = 'SignUpFirstNameInput', 
      lastNameInputId = 'SignUpLastNameInput', 
      emailInputId = 'SignUpEmailInput', 
      passwordInputId = 'SignUpPasswordInput',
      changeLangSelectId = 'SignUpChangeLangSelect',
      privacyPolicyRadioId = 'SignUpPrivacyPolicyRadio',
      privacyDataRadioId = 'SignUpPrivacyDataRadio',
      registrationButtonId = 'SignUpRegistrationButton',
      signInButtonId = 'SignUpSignInButton',
      messagePopupButtonId = 'SignUpMessagePopupButtonId';

export { 
    firstNameInputId,
    lastNameInputId,
    emailInputId,
    passwordInputId,
    changeLangSelectId,
    privacyPolicyRadioId,
    privacyDataRadioId,
    registrationButtonId,
    signInButtonId,
    messagePopupButtonId
}

interface ISignUpUI{
    textProvider : ITextProvider
    routesAgent : IRoutesAgent
    isSuccess : boolean
    errorMessage : string
    changeStateSucReg() : void

    registerUser(firstName : string, lastName : string, email : string, password : string):void
}

function SignUpUI(props:ISignUpUI){
    const [ forceError, forceErrorChange ] = useState(false);
    const [ isPolicyAgree, isPolicyAgreeChange ] = useState(false);
    const [ isDataAgree, isDataAgreeChange ] = useState(false);

    const [ language, languageChange ] = useState(props.textProvider.GetLang());
    const [ firstName, firstNameChange ] = useState('');
    const [ lastName, lastNameChange ] = useState('');
    const [ email, emailChange ] = useState('');
    const [ password, passwordChange ] = useState('');
    const [ showPassword, showPasswordChange ] = useState(false);

    const isFirstNameValid = !Validator.isNullOrWhitespace(firstName);
    const isLastNameValid = !Validator.isNullOrWhitespace(lastName);
    const isEmailValid = Validator.IsEmailTemplate(email);
    const isPasswordValid = Validator.IsPassword(password);
    const isValid = isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid && isPolicyAgree && isDataAgree;

    let popUp = null;
    if(props.isSuccess || (props.errorMessage && props.errorMessage.length > 0))
        popUp = <PopUpMessage 
                    zIndex={100} 
                    closePopup={() => props.changeStateSucReg()} 
                    header='' 
                    text={props.isSuccess ? language.sucsessRegistrate : props.errorMessage}
                    buttonId={messagePopupButtonId}/>

    const setLang = (iso : string) => {
        props.textProvider.SetLangByISO(iso);
        languageChange(props.textProvider.GetLang());
    }

    const onAction = () => {
        forceErrorChange(true);
        if (!isValid) return false;

        props.registerUser(firstName, lastName, email, password);
        return undefined;
    }

    return <SimpleHeader>
            {popUp}
            <div className="CommonPage SignUpPage">
                <div className="CommonPageInner">
                    <div className="CommonPageHeader">{language.registration}</div>
                    <LanguageChangeComponent 
                        vertical="fit" 
                        currentLanguage={language} 
                        textProvider={props.textProvider}
                        changeLanguage={iso => {setLang(iso); props.routesAgent.reload()}} 
                        id={changeLangSelectId}/>
                    {InputV2(new InputV2Props(
                        language.firstName,
                        forceError,
                        language.uncorrectFirstName,
                        firstName,
                        isFirstNameValid,
                        v => firstNameChange(v),
                        'text', false, 'fit', false, firstNameInputId))}
                    {InputV2(new InputV2Props(
                        language.lastName,
                        forceError,
                        language.uncorrectLastName,
                        lastName,
                        isLastNameValid,
                        v => lastNameChange(v),
                        'text', false, 'fit', false, lastNameInputId))}
                    {InputV2(new InputV2Props(
                        language.email,
                        forceError,
                        language.uncorrectEmail,
                        email,
                        isEmailValid,
                        v => emailChange(v),
                        'text', false, 'fit', false, emailInputId))}
                    <InputV2Imaged
                        inputProps={new InputV2Props(
                            language.password,
                            forceError,
                            language.uncorrectPassword,
                            password,
                            isPasswordValid,
                            v => passwordChange(v),
                            showPassword ? 'text' : 'password', false, 'fit', false, passwordInputId)}
                        imgProps={{
                            imgPosition : 'post',
                            imgSrc : showPassword ? Eye : ClosedEye,
                            imgClicked : () => showPasswordChange(!showPassword),
                        }}/>
                    <LinkArea linkParts={language.privacyPolicyAgree} isChecked={isPolicyAgree} checked={v => isPolicyAgreeChange(v)} id={privacyPolicyRadioId}/>
                    <LinkArea linkParts={language.privacyDataAgree} isChecked={isDataAgree} checked={v => isDataAgreeChange(v)} id={privacyDataRadioId}/>
                    <ButtonInteractive 
                        id={registrationButtonId}
                        successImgSrc={OkImg}
                        errorImgSrc={ErrorImg}
                        buttonContainerClass='WideButton'
                        buttonStyle='PrimaryButton WideButton'
                        buttonText={language.signUpBut}
                        action={async () => await onAction()}/>
                    <button id={signInButtonId} className="SecondaryButton" onClick={() => props.routesAgent.navigateTo(RoutesUrl.SignIn)}>{language.signInBut}</button>
                </div>
            </div>
        </SimpleHeader>;
}

export default SignUpUI;