import React from "react";

import Pair from '../Models/Pair'

export default class BaseContainer<IProps, IState> extends React.Component<IProps,IState>{
    constructor(prop:any){
        super(prop);
    }

    changeValueState(...items: Pair<string, any>[]) {
        this.setState(state => {
            let newState : any = state;
            items.forEach(item => newState[item.key] = item.value);
            return newState;
        });
    }
}
