import Pair from "../Pair";

class Dictionary<K,V>{
    public dictionary : Array<Pair<K,V>> = new Array<Pair<K, V>>();

    constructor(...couples:Array<Pair<K,V>>){
        couples.forEach(item => this.AddItem(item));
    }

    public GetLenghtDictionary(){
        return this.dictionary.length;
    }

    public GetValueByKey(key:K): V | null{
        return this.dictionary.find((item:Pair<K,V>) => item.key==key)?.value ?? null;
    }

    public GetKeyByValue(value:V):K | null{
        return this.dictionary.find((item:Pair<K,V>) => item.value==value)?.key ?? null;
    }

    public GetAllValues():Array<V>{
        return this.dictionary.map((item) => {return item.value})
    }

    public AddPair(key : K, value : V) {
        this.AddItem(new Pair(key, value));
    }

    public AddItem(item: Pair<K, V>) {
        this.dictionary.forEach(couple => {
            if (couple.key == item.key)
                throw new Error("Same key already exists in dictionary")
        })

        this.dictionary.push(item);
    }
}

export default Dictionary;