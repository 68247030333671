import React from 'react'

import EmployeeActions from '../../../Actions/EmployeeActions/EmployeeActions'
import IEmployeeActions from '../../../Actions/EmployeeActions/IEmployeeActions'
import EmployeeLocal from '../../../Models/Employees/EmployeeLocal';

import BaseContainer from '../../BaseContainer'
import EmployeeSettingsUI from './EmployeeSettingsUI'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl'
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent';
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';

interface IEmployeeSettings {
    localStorageAgent : ILocalStorageAgent | null
    textProvider : ITextProvider | null
}

interface IEmployeeSettingsState {
    isLoaded : boolean
    isInformationCorrect : boolean
    isCurrentUser : boolean
    employee : EmployeeLocal | undefined
    errorMessage : string
}

export default class EmployeeSettings extends BaseContainer<IEmployeeSettings, IEmployeeSettingsState> {
    private readonly _employeeActions : IEmployeeActions = new EmployeeActions();
    
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _textProvider : ITextProvider;

    constructor(props : IEmployeeSettings) {
        super(props);
        this.state = {
            isLoaded : false,
            isInformationCorrect : true,
            isCurrentUser : true,
            employee : undefined,
            errorMessage : ''
        };

        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
        this._textProvider = props.textProvider ?? new TextProvider();
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get(RoutesUrl.idKey);
        if (id === null)
            window.location.href = RoutesUrl.Users;

        this._employeeActions.getEmployeeInfoAsync(id as string).then(r => {
            if (typeof r === 'string') this.setState(state => { return { ...state, errorMessage: r } })
            else {
                const currentUser = this._localStorageAgent.user;
                this.setState(state => { 
                    return { ...state, isCurrentUser: !currentUser ? false : currentUser.email === r.email, employee: r } 
                });
            }

            this.setState(state => { return { ...state, isLoaded: true }})
        });
    }

    deleteEmployee() {
        if (this.state.employee)
            this._employeeActions.deleteAsync(this.state.employee.employeeId).then(_ => window.location.href = RoutesUrl.Users);
    }

    employeeUpdated(isCorrect : Boolean, employee : EmployeeLocal) {
        this.setState(state => {
            return {
                ...state,
                isInformationCorrect: !!isCorrect,
                employee: employee,
            };
        });
    }

    clearEmployee() {
        this.setState(state => {
            return {
                ...state,
                employee: new EmployeeLocal(),
            }
        })
    }

    updateEmployee() {
        return this._employeeActions.updateAsync(this.state.employee as EmployeeLocal).then(errorMessage => {
            if (!errorMessage) return true;
            
            this.setState(state => { return { ...state, errorMessage: errorMessage }});
            return false;
        });
    }

    sendInvite() {
        this._employeeActions.sendInvitationAsync((this.state.employee as EmployeeLocal).employeeId).then(_ => window.location.reload());
    }

    onCloseErrorMessage() {
        if (!this.state.employee) window.location.href = RoutesUrl.Users;
        this.setState(state => { return { ...state, errorMessage: ''} })
    }

    render() {
        return !this.state.isLoaded ? <PreloaderCompactDefault /> 
                                    : <EmployeeSettingsUI
                                        employee={this.state.employee}
                                        isCorrect={this.state.isInformationCorrect}
                                        isCurrentUser={this.state.isCurrentUser}
                                        deleteEmployee={() => this.deleteEmployee()}
                                        employeeUpdated={(isCorrect, employee) => this.employeeUpdated(isCorrect, employee)}
                                        clear={() => this.clearEmployee()}
                                        update={() => this.updateEmployee()}
                                        sendInvite={() => this.sendInvite()}
                                        errorMessage={this.state.errorMessage}
                                        errorMessageClose={() => this.onCloseErrorMessage()}
                                    />
    }
}