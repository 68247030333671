import IColorRecognizer from "../../Helpers/Strategys/ColorStrategy/IColorRecognizer";
import ChartData from "../Entintys/ChartData";

class ChartOption{

    public data : ChartData;

    public color : string;

    public coloringStrategy : IColorRecognizer

    public isFill : boolean

    public chartType : string;

    constructor(data : ChartData, color : string, coloringStrategy : IColorRecognizer, chartType : string = "line" ,isFill : boolean = false){
        this.data = data;
        this.color = color;
        this.coloringStrategy = coloringStrategy;
        this.isFill = isFill;
        this.chartType = chartType;
    }

}

export default ChartOption;