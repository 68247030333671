import EmployeeOrganizationInfo from './EmployeeOrganizationInfo'
import EmployeeType from '../../Enums/EmployeeType';
import Gender from "../../Enums/Gender"
import EmployeeBasic from "./EmployeeBasic";
import Language from '../Users/Language';
import EmployeeAccess from '../../Enums/EmployeeAccess'

export default class EmployeeBaseLocal extends EmployeeBasic {
    constructor(
        employeeId : string = '',
        firstName : string = '', 
        lastName : string = '', 
        phone : string = '', 
        email : string = '', 
        birthDate : string = new Date(1970,0,1).toISOString(), 
        gender : Gender = -1, 
        profession : string = '', 
        position : EmployeeType = EmployeeType.Contributor, 
        workExperienceMonths : number = 0, 
        employementDate : string = new Date(1970,0,1).toISOString(),
        departmentsInfo : EmployeeOrganizationInfo[] = [],
        languageType : Language = Language.En,
        accessTypeEmployee : EmployeeAccess = EmployeeAccess.NotManager) {

        super(employeeId, firstName, lastName, phone, email, birthDate, gender, profession, position, workExperienceMonths, employementDate, languageType, accessTypeEmployee);
        
        this.departmentsInfo = departmentsInfo;
    }

    public departmentsInfo : EmployeeOrganizationInfo[];
}