export const
            adminDataHintKey = 'adminDataHint',
            employeesIntroHintKey = 'employeesIntroHint',
            employeesHintKey = 'employeesHint',
            employeeEditHintKey = 'employeeEditHint',
            featuresHintKey = 'featuresHint',
            introHintKey = 'introHintKey',
            managersAddHintKey = 'addManagersHint',
            managersEditHintKey = 'editManagersHint',
            managersIntroHintKey = 'managersIntroHint',
            managersRightHintKey = 'rightManagersHint',
            paymentDataHintKey = 'paymentDataHint',
            structuresHintKey = 'structuresHint',
            supportHintKey = 'supportHint';