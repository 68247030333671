import BaseUser from '../Models/Users/BaseUser'
import Department from '../Models/Organizations/Department/Department';
import ILocalStorageAgent from './ILocalStorageAgent'
import InteractiveHintState from '../Models/InteractiveHints/InteractiveHintsState';
import Pair from '../Models/Pair';

export default class LocalStorageAgent implements ILocalStorageAgent {

    private static readonly _user = 'user';
    public get user() : BaseUser | null { return this.getFromStorage<BaseUser>(LocalStorageAgent._user); }
    public set user(v : BaseUser | null) { this.setToStorage(LocalStorageAgent._user, v); }

    private static readonly _langISO = 'language';
    public get langISO() : string | null { return this.getFromStorageRaw(LocalStorageAgent._langISO); }
    public set langISO(v : string | null) { this.setToStorageRaw(LocalStorageAgent._langISO, v); }

    private static readonly _token = 'token';
    public get token() : string | null { return this.getFromStorageRaw(LocalStorageAgent._token); }
    public set token(v : string | null) { this.setToStorageRaw(LocalStorageAgent._token, v); }

    //Pair<hierarchyId : string, reportId : string>[]
    private static readonly _defaultReportIdsByHierarchyIds = 'defaultReportIdsByHierarchyIds'
    public getDefaultReportIdByHierarchyId(hierarchyId : string) {
        const defaults = this.getFromStorage<Pair<string,string>[]>(LocalStorageAgent._defaultReportIdsByHierarchyIds) ?? [];
        return defaults.find(pair => pair.key === hierarchyId)?.value ?? '';
    }
    public appendDefaultReportIdByHierarchyId(hierarchyId : string, reportId : string) {
        const defaults = this.getFromStorage<Pair<string,string>[]>(LocalStorageAgent._defaultReportIdsByHierarchyIds) ?? [];
        const pair = defaults.find(pair => pair.key === hierarchyId);
        if (pair) pair.value = reportId;
        else defaults.push(new Pair(hierarchyId, reportId));

        this.setToStorage(LocalStorageAgent._defaultReportIdsByHierarchyIds, defaults);
    }

    private static readonly _lastHierarchyId = 'lastActiveHierarchy'
    public get lastHierarchyId() : string | null { return this.getFromStorageRaw(LocalStorageAgent._lastHierarchyId); }
    public set lastHierarchyId(v : string | null) { this.setToStorageRaw(LocalStorageAgent._lastHierarchyId, v); }

    private static readonly _favoriteDepartments = 'FavoritesDepartments';
    public getFavoriteDepartments(hierarchyId : string) : Department[] { 
        return this.getFromStorage<Department[]>(hierarchyId + LocalStorageAgent._favoriteDepartments) ?? [] 
    }
    public setFavoriteDepartments(hierarchyId : string, v : Department[]) {
        this.setToStorage(hierarchyId + LocalStorageAgent._favoriteDepartments, v);
    }

    private static readonly _interactiveHintsStateId = 'interactiveHintsState'
    public get interactiveHintsStates() : InteractiveHintState[] { return this.getFromStorage<InteractiveHintState[]>(LocalStorageAgent._interactiveHintsStateId) ?? []; }
    public set interactiveHintsStates(v : InteractiveHintState[]) { this.setToStorage(LocalStorageAgent._interactiveHintsStateId, v ?? []); }

    private getFromStorage<T>(key : string) : T | null {
        let raw = this.getFromStorageRaw(key);
        return this.isDataValid(raw) ? JSON.parse(raw as string) as T : null;
    }

    private setToStorage(key: string, obj : any) {
        this.setToStorageRaw(key, JSON.stringify(obj));
    }

    private getFromStorageRaw(key : string) {
        return localStorage.getItem(key);
    }

    private setToStorageRaw(key : string, data : string | null) {
        localStorage.setItem(key, this.isDataValid(data) ? data as string : '');
    }

    private isDataValid(data : string | null) {
        return data !== null && data !== undefined && data !== 'null' && data !== 'undefined' && (data.length > 0 ?? false)
    }
}