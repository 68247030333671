import TariffStatus from "../../Enums/TariffStatus";

export default class TariffAPI {
    public id : string = '';

    public innerName : string = '';
    
    public maxManagers : number = 0;
    
    public maxEmployees : number = 0;

    public maxHierarchies : number = 0;

    public dailyPayment : number = 0;

    public monthlyPayment : number = 0;

    /**
     * IT IS CODE NOT SYMBOL
     */
    public currencySymbol : string = '';

    public status : TariffStatus = TariffStatus.Archived;
}