class EmployeeDepartmentInfo {
    constructor(departmentId : string, departmentName : string) {
        this.departmentId = departmentId;
        this.departmentName = departmentName;
    }

    public departmentId : string;

    public departmentName : string;
}

export default EmployeeDepartmentInfo;