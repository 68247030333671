import React from 'react'

import BaseContainer from '../../BaseContainer';
import BalanceUI from './BalanceUI';

import { IBalanceActions, BalanceActions } from '../../../Actions/BalanceActions'

import BalanceUserInfo from '../../../Models/Balance/BalanceUserInfo';
import BalanceUser from '../../../Models/Balance/BalanceUser';
import { Euro } from '../../../Helpers/Currencies';
import Pair from '../../../Models/Pair';
import TariffUser from '../../../Models/Tariffs/TariffUser';
import Tariff from '../../../Models/Tariffs/Tariff';
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';

interface IBalance {
    textProvider : ITextProvider | null
}

interface IBalanceState {
    isLoaded : boolean
    balanceInfo : BalanceUserInfo
    currentTariff : TariffUser
    defaultTariffs : Tariff[]
}

export default class Balance extends BaseContainer<IBalance, IBalanceState> {
    private readonly _balanceActions : IBalanceActions = new BalanceActions();
    private readonly _textProvider : ITextProvider;

    private readonly _balanceUserInfoPromise : Promise<BalanceUserInfo>;
    private readonly _tariffsInfoPromise : Promise<Pair<TariffUser, Tariff[]>>;

    constructor(props : IBalance) {
        super(props);
        this._textProvider = props.textProvider ?? new TextProvider();
        this.state = {
            isLoaded: false,
            balanceInfo: new BalanceUserInfo(new BalanceUser(undefined, Euro), new BalanceUser(undefined, Euro), [], []),
            currentTariff: new TariffUser(undefined, Euro),
            defaultTariffs: [],
        };

        this._balanceUserInfoPromise = this._balanceActions.getUserBalanceInfo();
        this._tariffsInfoPromise = this._balanceActions.getTariffsInfo();
    }

    componentDidMount() {
        Promise.all([
            this._balanceUserInfoPromise,
            this._tariffsInfoPromise
        ]).then(result => {
            this.changeValueState(
                new Pair('isLoaded', true),
                new Pair('balanceInfo', result[0]),
                new Pair('currentTariff', result[1].key),
                new Pair('defaultTariffs', result[1].value));
        })
    }

    render() {
        return <BalanceUI 
                    textProvider={this._textProvider}
                    isLoaded={this.state.isLoaded} 
                    balanceInfo={this.state.balanceInfo} 
                    currentTariff={this.state.currentTariff}
                    defaultTariffs={this.state.defaultTariffs}/>
    }
}