import Language, { getValueByLang } from "../../Models/Users/Language";

class RoutesUrl {

    public static getPolicyLink(lang : Language) : string {
        const en = 'en';
        const langCode = getValueByLang<string>(lang, en, 'fr', en);
        return `https://whyeva.com/${langCode}/docs.html`;
    }

    public static getFAQLink(lang : Language) : string {
        const en = 'en';
        const langCode = getValueByLang<string>(lang, en, 'fr', en);
        return `https://www.whyeva.com/${langCode}/manual.html`;
    }

    public static idKey = "id";

    public static Root = "/";

    public static SignIn = "/SignIn";

    public static SignUp = "/SignUp";
    
    public static ResetPassword = "/ResetPassword";

    public static NoHierarchy = "/NoHierarchy";



    public static EvaChatBot = "https://app.whyeva.com/SignIn";

    public static UserInterface = '/User'

    public static Reports = RoutesUrl.UserInterface + "/Reports";

    public static DailyReport = RoutesUrl.UserInterface + '/Daily'

    public static Users = RoutesUrl.UserInterface + '/Users';
    
    public static ManagerSettings = RoutesUrl.UserInterface + "/Manager"

    public static EmployeeSettings = RoutesUrl.UserInterface + "/Employee";

    public static Structures = RoutesUrl.UserInterface + '/Structures';

    public static Balance = RoutesUrl.UserInterface + '/Balance';

    public static AdminProfile = RoutesUrl.UserInterface + '/Profile';
    
    public static CRMInterface = '/CRM';

    public static CRMOrganizations = RoutesUrl.CRMInterface + '/Organizations';

    public static CRMOrganization = RoutesUrl.CRMInterface + '/Organization'

}

export default RoutesUrl;