import PaymentType from "../../Enums/PaymentType";

export default class BalanceRecordAPI {
    public postTime : string = '';

    public balance : number = 0;

    /**
     * IT IS CODE NOT SYMBOL
     */
    public currencySymbol : string = '';

    public typePayment : PaymentType = PaymentType.Unconfirmed;
}