import React from 'react'

import './SimpleHeader.css'

const SimpleHeader : React.FunctionComponent<{}> = props => {
    return <div className="SimpleHeaderContainer">
        <div className="CommonPageHeader SimpleHeaderHeader" onClick={() => window.location.href = "https://whyeva.com/"} style={{cursor: 'pointer'}}>WhyEva</div>
        <hr />
        {props.children}
    </div>
}

export default SimpleHeader;
