import BaseUser from "../Users/BaseUser";
import Entity from "../Organizations/Entity";

export default class CRMOrganizationInfo {
    constructor(
        adminInfo : BaseUser = new BaseUser(),
        companyInfo : Entity = new Entity(),
        managersCount : number = 0,
        employeeCount : number = 0,
        hierarchiesCount : number = 0) {

        this.adminInfo = adminInfo;
        this.companyInfo = companyInfo;
        this.managersCount = managersCount;
        this.employeeCount = employeeCount;
        this.hierarchiesCount = hierarchiesCount;
    }

    public adminInfo : BaseUser;
    
    public companyInfo : Entity;

    public managersCount : number;

    public employeeCount : number;

    public hierarchiesCount : number;
}