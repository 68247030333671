import RequestSignature from "../RequestProvider/RequestSignature";

class HierarchyServiceConstants {
    
    public static readonly AddHierarchySignature = new RequestSignature("POST", "/api/v1.0/Hierarchy/AddHierarchy");

    public static readonly UpdateHierarchySignature = new RequestSignature("PUT", "/api/v1.0/Hierarchy/UpdateHierarchy");

    public static readonly DeleteHierarchySignature = new RequestSignature("DELETE", "/api/v1.0/Hierarchy/DeleteHierarchy");

    public static readonly GetHierarchySignature = new RequestSignature("GET", "/api/v1.0/Hierarchy/GetHierarchy");
}

export default HierarchyServiceConstants;