import React from 'react'

import BaseContainer from '../../BaseContainer'
import DailyReportUI from './DailyReportUI'

import Pair from '../../../Models/Pair'
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider'
import Report from '../../../Models/Analysis/Report'

import IReportsActions from '../../../Actions/ReportsActions/IReportsActions'
import ReportsActions from '../../../Actions/ReportsActions/ReportsActions'
import Hierarchy from '../../../Models/Organizations/Hierarchy'
import IHierarchyActions from '../../../Actions/HerarchyActions/IHierarchyActions'
import HierarchyActions from '../../../Actions/HerarchyActions/HierarchyActions'
import Preloader from '../../../Components/Preloader/Preloader'

interface IDailyReportState {
    isLoaded : boolean
    reportsByHierarchy : Pair<Report, Hierarchy>[]
}

export default class DailyReport extends BaseContainer<{}, IDailyReportState> {
    private readonly _hierarchiesActions : IHierarchyActions = new HierarchyActions();    
    private readonly _reportsActions : IReportsActions = new ReportsActions();
    
    private readonly _promise : Promise<Pair<Report, Hierarchy>[]>;

    private readonly _textProvider : ITextProvider = new TextProvider();

    constructor() {
        super(0);
        this.state = {
            isLoaded : false,
            reportsByHierarchy: [],
        };

        this._promise = this.getPromise();
    }

    getPromise() : Promise<Pair<Report, Hierarchy>[]> {
        return this._reportsActions.GetDailyReports().then(reports => {
            if (!reports || !Array.isArray(reports)) return [];

            let reportsByHierarchy = reports.map(r => new Pair(r.hierarchyId, r));
            
            return Promise.all(reportsByHierarchy.map(async pair => 
                new Pair(pair.value, await this._hierarchiesActions.GetHierarchyAsyncResponce(pair.key))))
        });
    }

    group<TKey, TElement>(array : TElement[], selector : (item : TElement) => TKey) {
        let result : Pair<TKey, TElement[]>[] = [];
        array.forEach(item => {
            let key = selector(item);
            let added = false;
            result.forEach(group => {
                if (group.key === key) {
                    group.value.push(item)
                    added = true;
                    return;
                }
            })
            
            if (!added)
                result.push(new Pair(key, [ item ]));
        })

        return result;
    }

    componentDidMount(): void {
        this._promise.then(result => this.changeValueState(new Pair('reportsByHierarchy', result), new Pair('isLoaded', true)))
    }

    render() {
        if (!this.state.isLoaded)
            return <Preloader />
        return <DailyReportUI textProvider={this._textProvider.GetLang()} reportsByHierarchy={this.state.reportsByHierarchy}/>
    }
}