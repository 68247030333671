import React from 'react'
import ReactDOM from 'react-dom'

import IRequestProvider from "./IRequestProvider"
import RequestSettings from "./RequestSettings"
import Dictionary from "../../Models/Collections/Dictionary";
import Action from "../../Models/Delegate/Action";
import Pair from "../../Models/Pair";
import RequestSignature from "./RequestSignature";
import RoutesUrl from "../../Helpers/Routes/RoutesUrl";
import PopUpMessage, { IPopUpMessage } from "../../Components/PopupMessage/PopupMessage";
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';
import { ITextProvider, TextProvider } from '../../Helpers/TextProvider/TextProvider';
import ILocalStorageAgent from '../../LocalStorageWorker/ILocalStorageAgent';

import { FindMessage } from '../ObjectsReflector'

class RequestProvider implements IRequestProvider{
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _textProvider : ITextProvider;
    private readonly _language : ILanguagePhrase;

    constructor(localStorageAgent : ILocalStorageAgent, textProvider : ITextProvider) {
        this._localStorageAgent = localStorageAgent;
        this._language = (this._textProvider = textProvider).GetLang();
    }

    //"https://whyeva.com:5221";
    //"http://whyeva.com:5200";
    private readonly uriApi = "https://whyeva.com:5221";

    public async Request(requestSettings:RequestSettings, actions:Dictionary<Number,Action<any>>):Promise<void>{
        this.Add4XXChecks(actions);
        let res = await this.DoRequest(requestSettings.RequestSignature, requestSettings.Queries, requestSettings.Data, requestSettings.Parametrs)
        await this.ProcessResult(requestSettings, actions, res);
    }

    private Add4XXChecks(actions : Dictionary<Number, Action<any>>) {
        if (!this.IsCodeExpected(actions, 401)) {
            actions.AddItem(new Pair<Number, Action<unknown>>(401, _ => { this._localStorageAgent.token = ''; window.location.href = RoutesUrl.SignIn; }))
        }
        if (!this.IsCodeExpected(actions, 402)) {
            actions.AddItem(new Pair(402, () => {
                const id = '_402Popup';
                let props = {
                    zIndex : 1000, 
                    closePopup : () => {
                        const popup = document.getElementById('_402Popup');
                        if (!popup) return;

                        popup.parentElement?.removeChild(popup);
                    },
                    header : this._language.error,
                    text : this._language.balanceOutOfMoney,
                    buttonId : 'RequestProvider402MessagePopupButtonId'
                };
                this.ShowMessagePopup(id, props);
            }));
        }
        if (!this.IsCodeExpected(actions, 403)) {
            actions.AddItem(new Pair(403, () => {
                const id = '_403Popup'
                const props = {
                    zIndex : 1000,
                    closePopup : () => window.location.href = '/',
                    header : this._language.error,
                    text : this._language.noPermissions,
                    buttonId : 'RequestProvider403MessagePopupButtonId'
                };
                this.ShowMessagePopup(id, props);
            }))
        }
    }

    private async ProcessResult(requestSettings : RequestSettings, actions : Dictionary<Number,Action<any>>, result : Response) {
        let handler = actions.GetValueByKey(result.status);

        if (handler == null) {
            let processResult = false;
            if (result.status === 400)
                processResult = this.ProcessBadRequest(await this.Deserialize(result));
            
            if (processResult) return;
            throw new Error(this.GetErrorMessage(requestSettings, result));
        }
        
        handler(await this.Deserialize(result));
    }

    private async Deserialize(r : Response) : Promise<unknown> {
        const text = await r.text();
        try {
            return JSON.parse(text);
        } catch {
            return text;
        }
    }

    private ProcessBadRequest(data : unknown) : boolean {
        let result = FindMessage(data);
        if (result.key)
        {
            const id = 'badRequestPopup';
            let props = {
                zIndex : 1000, 
                closePopup : () => document.body.removeChild(document.getElementById(id) as HTMLElement),
                header : this._language.error,
                text : result.value,
                buttonId : 'RequestProvider400MessagePopupButtonId'
            };
            this.ShowMessagePopup(id, props);
            return true;
        }

        return false;
    }

    private ShowMessagePopup(id : string, props : IPopUpMessage) {
        if (document.getElementById(id))
            return;
            
        let container = document.createElement('div');
        container.id = id;
        document.body.appendChild(container);
        ReactDOM.render(React.createElement(PopUpMessage, props), container);
    }

    private IsCodeExpected(actions : Dictionary<Number, Action<object>>, code : Number) : boolean {
        return actions.GetValueByKey(code) != null;
    }

    private GetErrorMessage(requestSettings : RequestSettings, res : Response) {
        return requestSettings.RequestSignature.HttpMethod + " " +
         requestSettings.RequestSignature.Uri + " returned " + res.status + " : " + res.statusText + " no handler found";
    }

    private DoRequest(signature : RequestSignature, queries : Dictionary<string, string>, data : any | null, parametrs : string):Promise<Response>{
        let uriWithQueries = this.GetUriWithQueries(this.uriApi + signature.Uri, queries);
        let completeUri = this.GetParamerts(uriWithQueries,parametrs);
        let token = this._localStorageAgent.token ?? "";
        
        return this.Fetch(completeUri, signature.HttpMethod,data, signature.ContentType, token);
    }

    private GetUriWithQueries(uri : string, queries : Dictionary<string, string>) : string {
        if (queries.GetLenghtDictionary() === 0) return uri;
        
        let result = uri + "?" + queries.dictionary.map(p => `${p.key}=${p.value}\&`).reduce((prev, acc) => acc + prev, '');    
        return result.substring(0, result.length - 1);
    }

    private GetParamerts(uri : string, parametrs : string) : string{
        if (!parametrs) return uri;
        return uri + "/" + parametrs;
    }

    private async Fetch(uri : string, typeRequest : string, parametr : any | null, contentType : string,token : string): Promise<Response>{
        const settings : RequestInit = {
            method: typeRequest,
            headers: new Headers({
              "Content-Type": contentType,
              "Authorization": "Bearer "+ token
            })
        };
        if (parametr && typeRequest.toLowerCase() !== 'get')
            settings.body = parametr;

        try {
            return await fetch(uri, settings);
        } catch (e) {
            window.location.href = RoutesUrl.SignIn;
            throw e;
        }
    }
}

export default RequestProvider;