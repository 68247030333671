import DepartmentBase from "./DepartmentBase";

class DepartmentGet extends DepartmentBase {

    constructor(id:string='',name:string='', numberDepartment : number, superiorDepartmentId:string|null=null) {
        super(id,name,numberDepartment);

        this.superiorDepartmentId = superiorDepartmentId;
    }

    public superiorDepartmentId : string | null;

}

export default DepartmentGet;