import React, { useState } from 'react'

import InfiniteCalendar from 'react-infinite-calendar';

import DatesHelper from '../../../Helpers/DatesHelper';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'

import CloseImg from "../../../Media/Img/closeWhite.png";

import 'react-calendar/dist/Calendar.css';
import 'react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet
import './PeriodBlock.css'

interface IDateSelectBlock {
    date : Date
    dateChanged(date:Date):void
    textProvider : ILanguagePhrase
    minDate : Date
    maxDate : Date
    disabledDates : Array<Date>
    disabledDays : Array<number>
}

export default function DateSelectBlock(props : IDateSelectBlock) {
    const [calendarNeeded, calendarNeededChange] = useState(false);

    const scrollChange = (isEnabled : boolean) => {
        document.body.style.overflowY = isEnabled ? 'auto' : 'hidden';
    }

    const dateClicked = () => {
        if (calendarNeeded) return;

        scrollChange(false)
        calendarNeededChange(true);
    }

    const onDateChanged = (e : Date | Date[]) => {
        props.dateChanged(e as Date);
        calendarNeededChange(false);
        scrollChange(true);
    }

    let calendar = null;
    if(calendarNeeded)
    {
        let locale = {
            blank : "",
            headerFormat: 'MMM',
            todayLabel : {
                long : "",
            },
            weekdays : DatesHelper.getLocaleWeekDaysNames(props.textProvider.bcp),
        }

        let width : number;
        let height : number | 'auto';
        if (document.body.clientWidth > 1024) {
            width = 400;
            height = 550;
        }
        else {
            width = document.body.clientWidth * 0.95;
            height = 'auto';
        }
            

        calendar = <div className = "Calendar">
            <div className="CalendarPart Prompt">
                <InfiniteCalendar   
                    width={width}
                    height={height}
                    onSelect={onDateChanged}
                    disabledDates = {props.disabledDates}
                    disabledDays = {props.disabledDays.length != 0 ? [0,2,3,4,5,6] : []}
                    selected ={props.date}
                    min = {props.minDate}
                    max = {props.maxDate}
                    locale = {locale}
                    minDate = {props.minDate}
                    maxDate = {props.maxDate}
                />
            </div>
        </div>
    }

    React.useEffect(() => {
        let bar = document.querySelectorAll("div[class='Cal__Container__root']");
        if (!bar || bar.length === 0)
            return;

        let element = bar[0];
        let close = element.querySelectorAll("div[class='CalendarClose']");

        if (close && close.length > 0)
            return;

        let node = document.createElement('div');
        node.classList.add('CalendarClose');

        let img = document.createElement('img');
        img.onclick = () => { scrollChange(true); calendarNeededChange(false); };
        img.src = CloseImg;

        node.appendChild(img);
        element.appendChild(node);
    });

    return <p className="DatePeriod Prompt" onClick = {() => dateClicked()}>{props.date.toLocaleDateString()}{calendar}</p>;
}