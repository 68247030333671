import React from "react";
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";
import { IRoutesAgent, RoutesAgent } from "../../Helpers/Routes/RoutesAgent";

import RoutesUrl from '../../Helpers/Routes/RoutesUrl';
import { ITextProvider, TextProvider } from "../../Helpers/TextProvider/TextProvider";
import { ITokenParser, TokenParser } from '../../Helpers/TokenParser'
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";
import Role from "../../Models/Role";
import BaseContainer from "../BaseContainer";

import './NoHierarchyPage.css'

const structuresButtonId = 'NoHierarchyPageStructuresButtonId',
      signInButtonId = 'NoHierarchyPageSignInButtonId';

export { structuresButtonId, signInButtonId }

interface INoHierarchyPage {
    textProvider : ITextProvider | null
    routesAgent : IRoutesAgent | null
    tokenParser : ITokenParser | null
    localStorageAgent : ILocalStorageAgent | null
}

export default class NoHierarchyPage extends BaseContainer<INoHierarchyPage,{}>{
    private readonly _lang : ILanguagePhrase;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _tokenParser : ITokenParser;
    private readonly _localStorageAgent : ILocalStorageAgent;
    constructor(props : INoHierarchyPage) {
        super(props);
        this._lang = (props.textProvider ?? new TextProvider).GetLang();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._tokenParser = props.tokenParser ?? new TokenParser();
        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
    }

    render() {
        let structuresButton = null;
        if (this._tokenParser.GetRoles(this._localStorageAgent.token ?? '').some(r => r === Role.CompanyAdministrator))
            structuresButton = <button id={structuresButtonId} className="PrimaryButton" onClick={() => this._routesAgent.navigateTo(RoutesUrl.Structures)}>{this._lang.structureAdd}</button>

        return <div className="NoHierarchyContainer">
            <div className="NoHierarchyContainerInner">
                {this._lang.noHierarchy}
                <div className="NoHierarchyButtonsContainer">
                    <button id={signInButtonId} className="SecondaryButton" onClick={() => this._routesAgent.navigateTo(RoutesUrl.SignIn)}>{this._lang.signInBut}</button>
                    {structuresButton}
                </div>
            </div>
        </div>
    }
}