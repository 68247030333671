class ReportInterval {
    firstDate : string
    secondDate : string

    constructor(firstDate : string = '', secondDate : string = ''){
        this.firstDate = firstDate;
        this.secondDate = secondDate;
    }
}

export default ReportInterval;