import ILanguagePhrase from "../LanguagePhrase/ILanguagePhrase"
import EnglishPhrase from "../LanguagePhrase/EnglishPhrase";
import FranchPhrase from "../LanguagePhrase/FranchPhrase";
import Language from "../../Models/Users/Language";
import Pair from "../../Models/Pair";
import BaseUser from "../../Models/Users/BaseUser";
import LocalStorageAgent from '../../LocalStorageWorker/LocalStorageAgent'
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";

export interface ITextProvider {
    SetLang(lang : ILanguagePhrase) : void
    GetLang() : ILanguagePhrase
    GetNamesPairs() : Pair<Language, string>[]
    SetLangByISO(iso : string) : void
    GetLangISO() : string
}

const english = new EnglishPhrase();

const Languages = [
    english,
    new FranchPhrase()
]

export { Languages }

export class TextProvider implements ITextProvider {
    private readonly _localStorageAgent : ILocalStorageAgent;
    constructor(localStorageAgent : ILocalStorageAgent | null = null) {
        this._localStorageAgent = localStorageAgent ?? new LocalStorageAgent();
    }

    public SetLang(lang : ILanguagePhrase) : void {
        this.SetLangByISO(lang.iso);
    }

    public GetLang() : ILanguagePhrase {
        let iso = this.GetLangISO();
        return Languages.find(l => l.iso === iso) ?? english;
    }

    public GetNamesPairs() : Pair<Language, string>[] {
        return Languages.map(l => new Pair(l.lang, l.languageName));
    }
    
    public SetLangByISO(iso : string) : void {
        this._localStorageAgent.langISO = iso;

        let userJSON = this._localStorageAgent.user;
        if (!userJSON)
            return;

        let user = userJSON as BaseUser;
        user.languageType = Languages.find(l => l.iso === iso)?.lang ?? Language.En;
        this._localStorageAgent.user = user;
    }

    public GetLangISO() : string {
        const user = this._localStorageAgent.user;
        if (!user) {
            const iso = this._localStorageAgent.langISO;
            if (iso) return iso;
            else return this.getBrowserLang();
        }

        let lang = (user as BaseUser).languageType;
        return Languages.find(l => l.lang === lang)?.iso ?? this.getBrowserLang();
    }

    private getBrowserLang() {
        return navigator.language.substring(0,2).toLowerCase();
    }
}