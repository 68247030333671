import Department from "./Department";

export default class DepartmentView extends Department {
    constructor(item : Department, isExtended : boolean = true) {
        super(item.id, item.name, item.numberDepartment);
        this.superiorDepartment = item.superiorDepartment;
        this.subDepartments = item.subDepartments;
        this.isExtended = isExtended;
    }

    public isExtended : boolean;
}