import ITensenessAnalysisService from "./ITensenessAnalysisService";
import BaseService from "../BaseService";

import TensenessAnalysisConstants from "./TensenessAnalysisConstants"

import ReportTensityAnalysis from "../../Models/Analysis/Report/ReportTensityAnalysis";
import ReportWellbeingAnalysis from "../../Models/Analysis/Report/ReportWellbeingAnalysis";
import RequestSettings from "../RequestProvider/RequestSettings";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";

import IRequestProvider from "../RequestProvider/IRequestProvider";

class TensenessAnalysisService extends BaseService implements ITensenessAnalysisService{
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async GetReportsTensenessByReportId(reportId : string) : Promise<Array<ReportTensityAnalysis>>{
        const settings = new RequestSettings(TensenessAnalysisConstants.GetReportsTensenessByReportId, new Dictionary(new Pair("reportId",reportId)), null);
        let result : ReportTensityAnalysis[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as ReportTensityAnalysis[]),
            new Pair(204, _ => { })
        ))

        return result;
    }

    public async GetReportsWellbeingByReportId(reportId : string) : Promise<Array<ReportWellbeingAnalysis>>{
        const settings = new RequestSettings(TensenessAnalysisConstants.GetReportsWellbeingByReportId, new Dictionary(new Pair("reportId",reportId)), null);
        let result : ReportWellbeingAnalysis[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as ReportWellbeingAnalysis[]),
            new Pair(204, _ => { })
        ))
        
        return result;
    }
}

export default TensenessAnalysisService;