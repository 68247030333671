import React from 'react'

import IUserAction from '../../../Actions/UserActions/IUserAction';
import UserActions from '../../../Actions/UserActions/UserActions';

import BaseContainer from "../../BaseContainer"
import ManagerSettingsUI from './ManagerSettingsUI';

import BaseUser from "../../../Models/Users/BaseUser"
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';
import Language from '../../../Models/Users/Language';
import { ITextProvider, TextProvider } from "../../../Helpers/TextProvider/TextProvider"
import UserInfo from '../../../Models/Users/UserInfo'
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';
import Role from '../../../Models/Role';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';

interface IManagerSettings {
    textProvider : ITextProvider | null
}

interface IManagerSettingsState {
    info : BaseUser
    isLoaded : Boolean
    errorMessage : string
}

class ManagerSettings extends BaseContainer<IManagerSettings, IManagerSettingsState> {
    private readonly _localStorageAgent = new LocalStorageAgent();
    private readonly _userActions : IUserAction = new UserActions();
    
    private readonly _textProvider : ITextProvider;
    private readonly _lang : ILanguagePhrase;

    constructor(props : IManagerSettings) {
        super(props);
        this._lang = (this._textProvider = props.textProvider ?? new TextProvider()).GetLang();
        this.state = {
            info: new BaseUser("", "", "", "", "", "", "", "", Language.En, false),
            isLoaded: false,
            errorMessage : '',
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let id = params.get(RoutesUrl.idKey);
        if (id === null)
            window.location.href = RoutesUrl.Users;

        this._userActions.GetUserProfileById(id as string).then(r => {
            if (typeof r === 'string') 
                window.location.href = RoutesUrl.Users;
            else
                this.setState(state => { return { ...state, info : r }})

            this.setState(state => { return { ...state, isLoaded: true } })
        });
    }

    updateManager(result : UserInfo) {
        let user = this.state.info;
        BaseUser.edit(user, result.firstName, result.lastName, result.email, result.phone);
        return this._userActions.UpdateUserInfo(user).then(errorMessage => {
            if (!errorMessage) return true;

            this.setState(state => { return { ...state, errorMessage: errorMessage} })
            return false;
        })
    }

    deleteManager() {
        if (!window.confirm(this._lang.areYouSure))
            return;

        this._userActions.DeleteUser(this.state.info.id).then(errorMessage => {
            if (!errorMessage) window.location.href = RoutesUrl.Users

            this.setState(state => { return { ...state, errorMessage: errorMessage } })
        });
    }

    blockManagerChange() {
        const isBlocked = this.state.info.positions.some(p => p === Role.Banned);
        this._userActions.BlockManagerChangeAsync(this.state.info.id, !isBlocked).then(isSuccess => {
            if (isSuccess) window.location.reload();

            window.location.href = RoutesUrl.Users;
        });
    }

    onErrorMessageClose() {
        if (!this.state.info) window.location.href = RoutesUrl.Users;

        this.setState(state => { return { ...state, errorMessage: '' } })
    }

    render() {
        return <ManagerSettingsUI 
                    info={this.state.info}
                    isLoaded={this.state.isLoaded}
                    isBlocked={this.state.info.positions.some(p => p === Role.Banned)}
                    isCurrentUser={this.state.info.id === (this._localStorageAgent.user as BaseUser).id}
                    updateManager={r => this.updateManager(r)}
                    deleteManager={() => this.deleteManager()}
                    blockManagerChange={() => this.blockManagerChange()}
                    errorMessage={this.state.errorMessage}
                    errorMessageClose={() => this.onErrorMessageClose()}/>
    }
}

export default ManagerSettings;