import React, { useState } from 'react'

import LinkArea from '../../../Components/LinkArea/LinkArea'
import OrganizationCharts from '../OrganizationCharts/OrganizationCharts'
import OrganizationUIReportDepartmentsInfo from '../ReportDepartmentsInfo/ReportDepartmentsInfo'
import { SelectV2, SelectV2Props } from '../../../Components/SelectV2/SelectV2'

import BarColors from "../../../Components/Chart/ChartComponent/BarColors";
import BaseReportIndexAnalysis from "../../../Models/Analysis/Report/BaseReportIndexAnalysis";
import ChartData from '../../../Models/Entintys/ChartData'
import ChartOption from '../../../Models/ChartModels/ChartOption'
import Department from '../../../Models/Organizations/Department/Department'
import graphicScaler from '../../../Helpers/ValueScalers/GraphicValueScaler'
import Hierarchy from '../../../Models/Organizations/Hierarchy'
import LinkText from '../../../Models/Link/LinkText'
import NameIndexValues from '../../../Models/UI/NameIndexValues'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import OrganizationDataConverterToUI from '../OrganizationDataConverterToUI'
import Report from '../../../Models/Analysis/Report'
import ReportsGroup from "../../../Models/Analysis/Report/ReportsGroup"
import ReportType from '../../../Models/Reports/ReportType'
import Pair from '../../../Models/Pair'

import AnxietyColorRecognizer from "../../../Helpers/Strategys/ColorStrategy/AnxietyColorRecognizer";
import TensityColorRecognizer from "../../../Helpers/Strategys/ColorStrategy/TensityColorRecognizer";
import VolumeColorRecognizer from "../../../Helpers/Strategys/ColorStrategy/VolumeColorRecognizer";

import '../Report.css'
import DatesHelper from '../../../Helpers/DatesHelper'

interface IReportInfo {
    textProvider : ILanguagePhrase
    reportsGroup : ReportsGroup
    report : Report
    hierarchy : Hierarchy
}

export default function ReportInfo(props : IReportInfo) {
    const getArrayAllChartData = (dep : Department, outArr : ChartData[], indexes : BaseReportIndexAnalysis[], period : Array<Date>) => {
        let nowChartData =  OrganizationDataConverterToUI.GetSubDepartmentsDataUi(dep, indexes, period);
  
        nowChartData.forEach(item => outArr.push(new ChartData(dep.name, item._values, item._avgValue)));
        if(dep.subDepartments) {
           dep.subDepartments.forEach(item => getArrayAllChartData(item, outArr, indexes, period));
        }
     }
  
    const getChartData = (report : BaseReportIndexAnalysis[], period : Date[]) : [ChartData[], ChartData] => {
        let allChartData : Array<ChartData> = [];
        getArrayAllChartData(props.hierarchy.mainDepartment as Department, allChartData, report, period);
        let chartDataNowMainDep = OrganizationDataConverterToUI.GetDepartmentAnalysisValues(department, report, period);
  
        return [allChartData, chartDataNowMainDep];
    }

    const [ isShowSuDepTensityValues, useisShowSuDepTensityValues ] = useState(false);
    const [ department, departmentChange ] = useState(props.hierarchy.mainDepartment as Department);

    let dates = DatesHelper.getReportDates(new Date(props.report.firstDate), new Date(props.report.secondDate), props.report.dateType)
    
    const [ allAnxietyChartData, chartDataAnxietyNowMainDep ] = getChartData(props.reportsGroup.anxietyReport, dates);
    let anxietyRangeValue = graphicScaler.GetRangeValue(allAnxietyChartData);

        //tensity chart data
    const [ allTensityChartData, chartDataTensityNowMainDep ] = getChartData(props.reportsGroup.tensityReport, dates);

    let chartDataTensity = OrganizationDataConverterToUI.GetSubDepartmentsDataUi(department, props.reportsGroup.tensityReport, dates);

    let subDepTensityChart : ChartOption[] = [];
    if(isShowSuDepTensityValues){
            subDepTensityChart = chartDataTensity.map((item, index) => new ChartOption(item, BarColors[index].value, new TensityColorRecognizer(), "bar", false));
    }

      //volume chart data
    const [ allVolumeChartData, chartDataVolumeNowMainDep ] = getChartData(props.reportsGroup.volumeReport, dates);
    let departments = Hierarchy.getDepartments(props.hierarchy);

    return <React.Fragment>
        <OrganizationUIReportDepartmentsInfo 
            textProvider={props.textProvider}
            nowReport={props.report}
            hierarchy={props.hierarchy}
            department={department}
            listIndexes={[new NameIndexValues(allTensityChartData, props.textProvider.tensity, ReportType.Tensity, new TensityColorRecognizer()),
                          new NameIndexValues(allAnxietyChartData, props.textProvider.anxiety, ReportType.Anxiety, new AnxietyColorRecognizer()),
                          new NameIndexValues(allVolumeChartData, props.textProvider.volume, ReportType.Volume, new VolumeColorRecognizer())]}
            checkOtherDepartment={d => departmentChange(d)}/>
        <div className = "blockReportPage">
        <div className = "coupleBlockReportPage">
            <p className="Header ReportInfoDetailedReportHeader">{props.textProvider.detailedReportByDepartments}</p>
            <div></div>
        </div>
        <div className = "coupleChartsInputs coupleChartsInputsHeader">
            <div>
                {SelectV2(new SelectV2Props(
                    props.textProvider.department,
                    false,
                    '',
                    department.id,
                    departments.map(d => new Pair(d.id, d.name)),
                    true,
                    id => departmentChange(Hierarchy.findDepartment(props.hierarchy, id) as Department),
                    false, 'fit'))}
            </div>
            <div>
                <LinkArea 
                    id='ReportInfoDetailedReportLinkArea'
                    linkParts={[new LinkText(props.textProvider.detailedReport)]}
                    isChecked={isShowSuDepTensityValues}
                    checked={() => useisShowSuDepTensityValues(!isShowSuDepTensityValues)}/>
            </div>
        </div>
            <OrganizationCharts 
                textProvider={props.textProvider}
                period={dates}
                sortType={props.report.dateType}
                anxietyRangeValue={anxietyRangeValue}
                anxietyChartData={chartDataAnxietyNowMainDep}
                tensityChartData={chartDataTensityNowMainDep}
                subDepTensityChart={subDepTensityChart}
                volumeChartData={chartDataVolumeNowMainDep}/>
             </div>
    </React.Fragment>
}