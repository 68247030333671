import IVolumeActions from "./IVolumeActions";
import IVolumeServices from "../../Services/Volume/IVolumeServices";
import BaseReportIndexAnalysis from "../../Models/Analysis/Report/BaseReportIndexAnalysis";
import VolumeServices from "../../Services/Volume/VolumeServices";

class VolumeActions implements IVolumeActions {
    private readonly _volumeServices : IVolumeServices = new VolumeServices();
    constructor(services : IVolumeServices | null = null) {
        this._volumeServices = services ?? new VolumeServices();
    }

    public async GetReportVolumeByReportId(reportId : string) : Promise<Array<BaseReportIndexAnalysis>>{
        let reports = await this._volumeServices.GetReportVolumeByReportId(reportId);
        return reports.map(item => new BaseReportIndexAnalysis(item, item.activityAnalysisCollection));
    }

    public async GetReportActivityByReportId(reportId : string) : Promise<Array<BaseReportIndexAnalysis>>{
        let reports = await  this._volumeServices.GetReportsActivityByReportId(reportId);
        return reports.map(item => new BaseReportIndexAnalysis(item, item.activityAnalysisCollection));
    }
}

export default VolumeActions;