import IVolumeServices from "./IVolumeServices";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";

import VolumeServicesConstansts from "./VolumeServicesConstants";
import ReportVolume from "../../Models/Volume/ReportVolume";

import IRequestProvider from "../RequestProvider/IRequestProvider";

class VolumeServices extends BaseService implements IVolumeServices{
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async GetReportVolumeByReportId(reportId : string) : Promise<Array<ReportVolume>>{
        const settings = new RequestSettings(VolumeServicesConstansts.GetReportsVolume, new Dictionary(new Pair("reportId",reportId)), null);
        let result : ReportVolume[] = [];
        
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as ReportVolume[]),
            new Pair(204, _ => { })
        ));

        return result;
    }
    
    public async GetReportsActivityByReportId(reportId : string) : Promise<Array<ReportVolume>>{
        const settings = new RequestSettings(VolumeServicesConstansts.GetReportsActivity, new Dictionary(new Pair("reportId",reportId)), null);
        let result : ReportVolume[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as ReportVolume[]),
            new Pair(204, _ => { })
        ))

        return result;
    }
}

export default VolumeServices;