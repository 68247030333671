import React, { useState } from "react";
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";

import HintIcon from "../../../Media/Img/HintIcon.png"

import "./ReportHintBlock.css";

interface IReportHintBlock{
    textProvider : ILanguagePhrase
}

function ReportHintBlock(props : IReportHintBlock){
    const [ hintNeeded, hintNeededChange ] = useState(false)

    let hint : JSX.Element | null = null;
    if (hintNeeded)
        hint = <div id="reportHint" onClick={() => hintNeededChange(false)}>
            <p className="SubHeader">{props.textProvider.reportHintTitle}</p>
            <p className="Prompt"><b>{props.textProvider.tensity}</b>: {props.textProvider.tensityHint}</p>
            <p className="Prompt"><b>{props.textProvider.anxiety}</b>: {props.textProvider.anxietyHint}</p>
            <p className="Prompt"><b>{props.textProvider.volume}</b>: {props.textProvider.volumeHint}</p>
            <p className="SubHeader">{props.textProvider.reportHintSubTitle}</p>
            {props.textProvider.reportHintItems.map(item => <p className="Prompt">{item}</p>)}
        </div>

    return <div className = "ReportImageBlock">
        <div className="ReportHintImageBlock">
            <img src={HintIcon} onClick={() => hintNeededChange(!hintNeeded)}/>
            {hint}
        </div>
    </div>
}

export default ReportHintBlock;