import React, { useEffect } from "react"
import Chart, { ChartData, ChartDataSets } from 'chart.js';
import ChartOptions from "./ChartOptions"

import "./Chart.css"
import ArrayChartOption from "../../../Models/ChartModels/ArrayChartOption";
import GuidGenerator from "../../../Helpers/GuidGenetator";

export interface IChart{
    chartsOption : ArrayChartOption
    periods : Array<string>
    yAxis : JSX.Element | null
    heading : string
    isShowYLabels : boolean
    prompts : Array<JSX.Element>
    minYValue : number
    maxYValue : number
}

function ChartComponent(props:IChart){

    let unicalCode = GuidGenerator.GenerateGuid();


    useEffect(() => {
        let chartCanvas:HTMLCanvasElement=document.getElementById("chartCanvas" + unicalCode) as HTMLCanvasElement;
        let chartOption:ChartOptions=new ChartOptions();
        let option=chartOption.GetOption(props.chartsOption, props.periods, 0, props.isShowYLabels , props.maxYValue, props.minYValue);
        let chart = new Chart(chartCanvas,option);
        return () => {
            chart.destroy();
        }
    });

    let promptBlock = (props.prompts.length != 0) ? <div className = "promptBlock">{props.prompts}</div>
                                                  : null;

    let YAxios = (props.yAxis) ? <div className = "YAxiosContainer">{props.yAxis}</div> : null;

    return(<div className="Chart">
            <p className="SubHeader">{props.heading}</p>
            {YAxios}
            {promptBlock}
            <canvas className = "chartForChartJs" id={"chartCanvas" + unicalCode}/>
          </div>)
}

export default ChartComponent;