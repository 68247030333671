import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";

enum DateType //TODO: reorder
    {
        ByDays = 1,
        ByMonth = 2,
        ByWeek = 3,
        OnYears = 4
    }

export function GetValueByDateType<T>(type : DateType, byDays : T, byMonth : T, byWeek : T, byYears : T) {
    switch (type) {
        case DateType.ByDays: return byDays;
        case DateType.ByMonth: return byMonth;
        case DateType.ByWeek: return byWeek;
        case DateType.OnYears: return byYears;

        default: throw new Error('Such dateType not implemented');
    }
} 

export function getLocalizedDateType(type : DateType, textProvider : ILanguagePhrase) {
    return GetValueByDateType(type,
        textProvider.byDay,
        textProvider.byMonth,
        textProvider.byWeek,
        textProvider.byYear);
}

export default DateType;