import React from 'react'

import AdminSideMenuItem from './AdminSideMenuItem'

import Animate from '../../../Helpers/Animator'
import BaseUser from '../../../Models/Users/BaseUser'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl'

import BurgerBlack from "../../../Media/Img/burgerBlack.png"
import ChatbotBlack from '../../../Media/Img/chatbotBlack.png'
import ManagersBlack from "../../../Media/Img/managersBlack.png"
import ManagersWhite from "../../../Media/Img/managersWhite.png"
import StructuresBlack from "../../../Media/Img/StructuresBlack.png"
import StructuresWhite from "../../../Media/Img/StructuresWhite.png"
import BalanceBlack from "../../../Media/Img/balanceBlack.png"
import BalanceWhite from "../../../Media/Img/balanceWhite.png"
import ProfileBlack from "../../../Media/Img/profileBlack.png"
import ProfileWhite from "../../../Media/Img/profileWhite.png"
import ReportingBlack from "../../../Media/Img/ReportingBlack.png"
import ExitBlack from "../../../Media/Img/exitBlack.png"
import PersonImg from "../../../Media/Img/person.png";
import Logo from "../../../Media/Img/logo_actual_main.png";

import './AdminFrameInner.css'
import Role from '../../../Models/Role'
import { TokenParser } from '../../../Helpers/TokenParser'
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent'

const adminSideMenuId = 'adminSideMenu';

export { adminSideMenuId }

interface IAdminFrameInner {
    user : BaseUser
    textProvider : ILanguagePhrase
    sideMenuWidthChanged(widthVW : number) : void
    adminHeaderHeightChanged(height : number) : void
}

interface IAdminFrameInnerState {
    extended : boolean
    width : number
    headerHeight: number
    backgroundContentColor : number
    backgroundColor : number
}

export default class AdminFrameInner extends React.Component<IAdminFrameInner, IAdminFrameInnerState> {
    static readonly backgroundContentDefault : number = 0;
    static readonly backgroundContentExtended : number = 0.5;
    static readonly backhroundContentDiff : number = AdminFrameInner.backgroundContentExtended - AdminFrameInner.backgroundContentDefault

    static readonly backgroundDefault : number = 0xF3;
    static readonly backgroundExtended : number = 0xFF;
    static readonly backgroundDiff : number = AdminFrameInner.backgroundExtended - AdminFrameInner.backgroundDefault;

    static readonly widthDefault : number = 8;
    static readonly widthExtended : number = 28;
    static readonly widthDiff : number = AdminFrameInner.widthExtended - AdminFrameInner.widthDefault;

    animationStopper : (() => void) | null = null;

    private readonly _isAdmin : boolean;
    constructor(props : IAdminFrameInner) {
        super(props);
        this.state = {
            extended : false,
            width : AdminFrameInner.widthDefault,
            headerHeight: 60,
            backgroundContentColor: AdminFrameInner.backgroundContentDefault,
            backgroundColor : AdminFrameInner.backgroundDefault,
        };

        props.sideMenuWidthChanged(AdminFrameInner.widthDefault)

        this._isAdmin = new TokenParser().GetRoles(new LocalStorageAgent().token ?? '').some(pos => pos === Role.CompanyAdministrator) ?? false;

        window.addEventListener('resize', () => this.headerHeightUpdate());
    }

    componentDidMount() {
        this.headerHeightUpdate();
    }

    headerHeightUpdate() {
        const height = (document.getElementById('adminHeader') as HTMLElement).clientHeight;
        this.setState(state => { return { ...state, headerHeight: height } })
        this.props.adminHeaderHeightChanged(height);
    };

    extendHandler() {
        let current = !this.state.extended;
        this.setState(state => {
            return {
                ...state,
                extended: current,
            };
        });
        if (this.animationStopper)
            this.animationStopper();

        this.animationStopper = Animate({
            millisecondsDuration: 250,
            animationHandler: rate => this.extendAnimationHandler(rate, current),
        });
    }

    extendAnimationHandler(animationRate : number, extended : boolean) {
        //if this.state.extended then element extending and rate goes from 0 to 1 otherwise from 1 to 0
        let rate = extended ? animationRate : 1 - animationRate;
        let width = (AdminFrameInner.widthDiff * rate) + AdminFrameInner.widthDefault;
        this.setState(state => {
            return {
                ...state,
                width: width,
                backgroundContentColor: (AdminFrameInner.backhroundContentDiff * rate) + AdminFrameInner.backgroundContentDefault,
                backgroundColor: (AdminFrameInner.backgroundDiff * rate) + AdminFrameInner.backgroundDefault,
            }
        })
        this.props.sideMenuWidthChanged(width);
    }

    logOut() {
        localStorage.setItem('user', '');
        localStorage.setItem('token', '');
        window.location.href = RoutesUrl.Root;
    }

    render() {
        const colorElement = this.state.backgroundColor.toString();
        const containerStyle : React.CSSProperties = {
            width: this.state.width + 'vw',
            backgroundColor: `rgb(${colorElement}, ${colorElement}, ${colorElement})`,
            zIndex: this.state.width > AdminFrameInner.widthDefault ? 20 : 2,
            alignItems: this.state.width == AdminFrameInner.widthDefault || (this.state.width != AdminFrameInner.widthDefault || !this.state.extended) ? 'flex-end' : 'flex-start',
        };

        let frameBackground : JSX.Element | null = null;
        if (this.state.width != AdminFrameInner.widthDefault) {
            const adminFrameBackgroundStyle : React.CSSProperties = {
                zIndex: 10,
                backgroundColor: 'black',
                top: `${this.state.headerHeight}px`,
                left: `${this.state.width}vw`,
                opacity: this.state.backgroundContentColor,
            }
            frameBackground = <div className="AdminFrameBackground" style={adminFrameBackgroundStyle} onClick={() => this.extendHandler()}></div>
        }

        
        return <div>
            <div id='adminHeader' className="AdminHeaderContainer">
                <div className="AdminHeaderContainerInner">
                    <div className='AdminHeaderLogoContainer'>
                        <img src={BurgerBlack} onClick={() => this.extendHandler()} className="AdminHeaderLogo"></img>
                    </div>
                    <div className='AdminHeaderTitle' onClick={() => window.location.href = "https://www.whyeva.com/"} style={{cursor: 'pointer'}}><img className="AdminImgHeader" src={Logo} /></div>
                    <div className="AdminHeaderUser" onClick={() => window.location.href=RoutesUrl.AdminProfile}>
                        <p>{`${this.props.user.firstName} ${this.props.user.lastName}`}</p>
                        <div>
                            <img src={PersonImg} />
                        </div>
                    </div>
                </div>
                <hr className="AdminHeaderHr" />
            </div>
            <div className="AdminFrameInner" style={{marginTop: `${this.state.headerHeight}px`}}>
                <div id={adminSideMenuId} className="AdminSideMenuContainer" style={containerStyle}>
                    {this._isAdmin && <AdminSideMenuItem
                        extended={this.state.extended}
                        selected={window.location.pathname.startsWith(RoutesUrl.Users)}
                        iconSrcDefault={ManagersBlack}
                        iconSrcSelected={ManagersWhite}
                        text={this.props.textProvider.employees}
                        selectHandler ={() => window.location.href = RoutesUrl.Users}
                    />}
                    <AdminSideMenuItem 
                        extended={this.state.extended}
                        selected={false}
                        iconSrcDefault={ChatbotBlack}
                        iconSrcSelected=''
                        text='Eva ChatBot'
                        selectHandler={() => window.location.href = RoutesUrl.EvaChatBot}/>
                    <AdminSideMenuItem
                        extended={this.state.extended}
                        selected={window.location.pathname.startsWith(RoutesUrl.Reports)}
                        iconSrcDefault={ReportingBlack}
                        iconSrcSelected={ReportingBlack}
                        text={this.props.textProvider.reports}
                        selectHandler ={() => window.location.href = RoutesUrl.Reports}
                    />
                    {this._isAdmin && <React.Fragment>
                        <AdminSideMenuItem
                            extended={this.state.extended}
                            selected={window.location.pathname.startsWith(RoutesUrl.Structures)}
                            iconSrcDefault={StructuresBlack}
                            iconSrcSelected={StructuresWhite}
                            text={this.props.textProvider.structures}
                            selectHandler ={() => window.location.href = RoutesUrl.Structures}/>
                        <AdminSideMenuItem
                            extended={this.state.extended}
                            selected={window.location.pathname.startsWith(RoutesUrl.Balance)}
                            iconSrcDefault={BalanceBlack}
                            iconSrcSelected={BalanceWhite}
                            text={this.props.textProvider.balance}
                            selectHandler ={() => window.location.href = RoutesUrl.Balance}/>
                    </React.Fragment>}
                    <AdminSideMenuItem
                        extended={this.state.extended}
                        selected={window.location.pathname.startsWith(RoutesUrl.AdminProfile)}
                        iconSrcDefault={ProfileBlack}
                        iconSrcSelected={ProfileWhite}
                        text={this.props.textProvider.settings}
                        selectHandler ={() => window.location.href = RoutesUrl.AdminProfile}
                    />
                    <AdminSideMenuItem
                        extended={this.state.extended}
                        selected={false}
                        iconSrcDefault={ExitBlack}
                        iconSrcSelected=''
                        text={this.props.textProvider.logOut}
                        selectHandler ={this.logOut}
                    />
                </div>
                {frameBackground}
                {this.props.children}
            </div>
        </div>
    }
}
