import React from 'react'

import BalanceOutcomeRecordAPI from './BalanceOutcomeRecordAPI';
import BalanceRecord from './BalanceRecord'
import Currency from '../Currencies/Currency';
import { Euro } from '../../Helpers/Currencies';

export default class BalanceOutcomeRecord extends BalanceRecord {
    constructor(proto : BalanceOutcomeRecordAPI = new BalanceOutcomeRecordAPI(), currency : Currency = Euro) {
        super(proto, currency);
        this.employeeCount = proto.employeeCount;
        this.tariffName = proto.innerName;
    }

    public employeeCount : number;

    public tariffName : string = '';
}