import IDisabledDates from "./IDisabledDates";

class YearDisabledDates implements IDisabledDates{

   

    public GetDisabledDays() : Array<number> {
        return [];
    }

    public GetDisabledDates(startDate : Date, endDate : Date) : Array<Date> {
        let disabledDates : Array<Date> = []
        let nowDate = startDate;
        while(nowDate <= endDate) {
            if(nowDate.getDate() != 1 || nowDate.getMonth() != 0 )
                disabledDates.push(nowDate);
            nowDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1);
        }

        return disabledDates;
    }

}

export default YearDisabledDates;