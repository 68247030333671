import React from 'react'

import { IMyPopupBase, MyPopupBase } from '../../../Components/MyPopupBase/MyPopupBase'
import DepartmentInfo from '../../../Components/Admin/DepartmentInfo/DepartmentInfo'

import DepartmentView from '../../../Models/Organizations/Department/DepartmentView'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'

import './DepartmentEditPopup.css'

interface IDepartmentEditPopup extends IMyPopupBase {
    textProvider : ILanguagePhrase
    structure : DepartmentView[]
    superior : DepartmentView
    current : DepartmentView
    actionText : string
    onAdd(name : string, superiorId : string) : void
}

export default function DepartmentEditPopup(props :IDepartmentEditPopup) {
    function onAddRequested(name : string, superiorId : string) {
        props.closePopup();
        props.onAdd(name, superiorId);
    }

    return <MyPopupBase zIndex={props.zIndex} closePopup={props.closePopup} width={'default'}>
        <div className="DepartmentEditPopupDepartmentInfoContainer">
            <DepartmentInfo
                textProvider={props.textProvider} 
                structure={props.structure}
                superiorView={props.superior}
                currentView={props.current}
                cancelAction={() => props.closePopup()} 
                actionText={props.actionText}
                action={(currentName, superiorId) => onAddRequested(currentName, superiorId)}/>
        </div>
    </MyPopupBase>
}