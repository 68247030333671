export default class InteractiveHintState {
    constructor(userId : string = '', isShowAllowed : boolean = true, showedHintsKeys : string[] = []) {
        this.userId = userId;
        this.isShowAllowed = isShowAllowed;
        this.showedHintsKeys = showedHintsKeys;
    }

    public userId : string;

    public isShowAllowed : boolean;

    public showedHintsKeys : string[];
}