class Colors {

    public static readonly DarkGreen : string = "#32CD32"
    public static readonly MiddleGreen : string = "#7CFC00"
    public static readonly LighGreen : string = "#29B55D"
    public static readonly Yellow : string = "#FFFF00"
    public static readonly YellowOrange : string = "#FFD700"
    public static readonly DarkOrange : string = "#FF8C00"
    public static readonly OrangeRed : string = "#FF4500"
    public static readonly Red : string = "#FF0000"
    public static readonly DarkRed : string = "#8B0000";

    public static readonly LighBlue : string = "#4084DD";

    public static readonly Silver : string = "#C4C4C4";

    public static readonly lightGreen : string = "#92E3DA";

    public static readonly lightYellow : string = "#FFD900";

    public static readonly lightRed : string = "#C36261";

}

export default Colors;              