import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import BaseContainer from '../../../Containers/BaseContainer'
import { DecoratorContainer, HeaderedComponent, ImagedComponent, UnderlinedComponent } from '../../Decorators/Decorators'
import Department from '../../../Models/Organizations/Department/Department'
import DepartmentView from '../../../Models/Organizations/Department/DepartmentView';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import Validator from '../../../Helpers/Validator/Validator';

import { InputV2Props, InputV2} from '../../InputV2/InputsV2'

import ExpandDown from '../../../Media/Img/ExpandDown.png'

import './DepartmentInfo.css'
import { IStructureComponentReadonly, StructureComponentReadonly } from '../StructureComponent/StructureComponent';
import Pair from '../../../Models/Pair';

interface IDepartmentInfo {
    textProvider : ILanguagePhrase
    structure : DepartmentView[]
    superiorView : DepartmentView
    currentView : DepartmentView

    cancelAction() : void;
    actionText : string;
    action(currentName : string, superiorId : string) : void;
}

interface IDepartmentInfoState {
    name : string
    structure : DepartmentView[]
    superiorView : DepartmentView

    forceError : boolean
    structureViewRequired : boolean
}

export default class DepartmentInfo extends BaseContainer<IDepartmentInfo, IDepartmentInfoState> {
    private readonly structurePopupId = 'StructureViewPopup'; 
    private readonly structurePopupContainerId = 'StructureViewPopupContainer'

    constructor(props : IDepartmentInfo) {
        super(props);
        this.state = {
            name : props.currentView.name,
            structure : this.correctViewSubs(props.structure, props.currentView),
            superiorView : props.superiorView,

            forceError : false,
            structureViewRequired : false,
        }
    }

    getPopup() {
        return document.getElementById(this.structurePopupId);
    }

    removePopup() {
        let popup = this.getPopup();
        if (popup)
            document.body.removeChild(popup);
    }

    componentWillUnmount() {
        this.removePopup();
    }

    componentDidUpdate() {
        let popup = this.getPopup();
        if (!popup) return;

        let container = document.getElementById(this.structurePopupContainerId);
        if (!container) return;

        let width = window.innerWidth;
        let height = window.innerHeight;
        let rect = container.getClientRects()[0];
        popup.style.top = `${rect.top + (2*height/100)}px`;
        popup.style.left = `${rect.left + (2*width/100)}px`;
    }

    correctViewSubs(subs : Department[], currentView : DepartmentView) : DepartmentView[] {
        let views = [];
        for (let key in subs) {
            let sub = subs[key];
            
            if (sub.id !== currentView.id) {
                let itemToAdd : Department = new Department(sub.id, sub.name, sub.numberDepartment);
                itemToAdd.superiorDepartment = sub.superiorDepartment;
                itemToAdd.subDepartments = this.correctViewSubs(sub.subDepartments, currentView);
                views.push(itemToAdd);
            }
        }

        return views.map(view => {
            let result = new DepartmentView(view);
            result.subDepartments = view.subDepartments;
            return result;
        });
    }

    checkName(v : string) : boolean {
        return !Validator.isNullOrWhitespace(v);        
    }

    changeName(v : string) {
        this.setState(state => {
            return {
                ...state,
                name : v,
            };
        });
    }

    onAction() {
        this.setState(state => { return { ...state, forceError: true } })
        if (!this.checkName(this.state.name))
            return;

        this.props.action(this.state.name, this.state.superiorView?.id ?? '');
    }

    render() {
        this.removePopup();

        if (this.state.structureViewRequired) {
            let popupContainer = document.createElement('div');
            popupContainer.id = this.structurePopupId;
            popupContainer.className = "DepartmentInfoStructureViewPopup";
            document.body.appendChild(popupContainer);
            let popupProps : IStructureComponentReadonly = {
                textProvider : this.props.textProvider,
                structure : this.state.structure,
                extendChanged : (_, structure) => this.changeValueState(new Pair('structure', structure)),
                itemSelected : (item, _) => this.changeValueState(new Pair('superiorView', item), new Pair('structureViewRequired', false))
            }
            ReactDOM.render(React.createElement(StructureComponentReadonly, popupProps), popupContainer)
        }

        return <div className="DepartmentInfoContainer">
            <DecoratorContainer>
                <HeaderedComponent text={this.props.textProvider.structureDepartmentSuperior} verticalAlign={'fit'} />
                <div className="DepartmentInfoSuperiorNameContainer">
                    <div id={this.structurePopupContainerId} onClick={() => this.changeValueState(new Pair('structureViewRequired', !this.state.structureViewRequired))}>
                        <ImagedComponent 
                            imgPosition='post'
                            imgSrc={ExpandDown}
                            imgClicked={() => { }}>
                            <span className="DepartmentInfoSuperiorName">{this.state.superiorView ? this.state.superiorView.name : ''}</span>
                        </ImagedComponent>
                    </div>
                </div>
                <UnderlinedComponent/>
            </DecoratorContainer>
            {InputV2(new InputV2Props(
                this.props.textProvider.departmentName,
                this.state.forceError,
                this.props.textProvider.uncorrectDepartmentName,
                this.state.name,
                this.checkName(this.state.name),
                v => this.changeName(v),
                'text', false, 'fit'))}
            <div className="ButtonsContainer">
                <button className="SecondaryButton" onClick={() => this.props.cancelAction()}>{this.props.textProvider.cancel}</button>
                <button className="PrimaryButton" onClick={() => this.onAction()}>{this.props.actionText}</button>
            </div>
        </div>
    }
}