export enum Period{
    day = 1,
    month = 2,
    week = 3,
    year = 4,
}

export function GetValueByPeriod<T>(period : Period, onDay : T, onMonth : T, onWeek : T, onYear : T) {
    switch (period) {
        case Period.day: return onDay;
        case Period.month: return onMonth;
        case Period.week: return onWeek;
        case Period.year: return onYear;

        default: throw new Error('Not implemented')
    }
}