import React, { useEffect, useState } from "react";

import ColorBlock from "../ColorBlock/ColorBlock";
import CircleValue from "../CircleValue/CircleValue";
import HierarchyComponent from "../Hierarchy/HierarchyComponent";

import Department from "../../Models/Organizations/Department/Department";
import Hierarchy from "../../Models/Organizations/Hierarchy";
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";
import NameIndexValues from "../../Models/UI/NameIndexValues";
import valueScaler from "../../Helpers/ValueScalers/AvgValueScaler";

import DeleteImg from "../../Media/Img/deleteDepartment.png";

import "./HeadHierarchy.css";


interface IPropsHeadHierarchy{
    hierarchy : Hierarchy
    listIndexes : NameIndexValues[]
    department : Department
    checkOtherDepartment(dep : Department) : void 
    textProvider : ILanguagePhrase
}

function HeadHierarchy(props : IPropsHeadHierarchy) {
    const localStorageAgent : ILocalStorageAgent = new LocalStorageAgent();

    const addDepartmentInFavorites = (department : Department) => {
        let dep = favoritesDepartments.find(item => item.id == department.id);
        if (dep) //if already added
            return;

        let favorites = favoritesDepartments.concat([department]);
        favoritesDepartmentsChange(favorites);
        localStorageAgent.setFavoriteDepartments(props.hierarchy.id, favorites);
    }

    const deleteDepartmentFromFavorites = (department : Department) => {
        let index = favoritesDepartments.findIndex(item => item.id == department.id);
        if(index != -1){
            let favorites = favoritesDepartments.filter((_, insideIndex) => {
                return (index != insideIndex);
            });
            favoritesDepartmentsChange(favorites);
            localStorageAgent.setFavoriteDepartments(props.hierarchy.id, favorites);
        }
    }

    const [ favoritesDepartments, favoritesDepartmentsChange ] = useState(localStorageAgent.getFavoriteDepartments(props.hierarchy.id));
    const [ hierarchyNeeded, hierarchyNeededChange ] = useState(false);

    useEffect(() => {
        document.body.style.overflowY = hierarchyNeeded ? 'hidden' : 'auto';
    }, [ hierarchyNeeded ])

    //find max lenght report
    let indexes = props.listIndexes.map(item => item.findMaxLenghtReport())
    let maxLenghtReports = indexes[0];
    for(let i = 1; i < indexes.length; i++){
        if(indexes[i] > maxLenghtReports)
            maxLenghtReports = indexes[i];
    }

    //mainDepRow
    let rowArrMainDep : (JSX.Element | null)[] = [<td className="Prompt" style={{textAlign:'end'}}>{props.department.name}</td>];
    rowArrMainDep = rowArrMainDep.concat(props.listIndexes.map(item => {
        let report = item.findChartDataByDep(props.department);
        if (!report)
            return null;

        let nowValue = valueScaler.ScaleAvgValue(report._avgValue, item.type);
        nowValue = Number.isNaN(nowValue) ? 1 : nowValue;
        
        return <td className="circleValuesMainDep">
            <CircleValue backgroundColor = {item.colorStrategy.GetColorCode(nowValue)} value = {Math.round(nowValue)} reportType={item.type}/>
        </td>;
    }));

    //subDepRows
    let rows = favoritesDepartments.map(item => {
        if (item.id === props.department.id) return undefined;

        let rowArr = [<td onClick = {() => props.checkOtherDepartment(item)} className="Prompt" style={{textAlign:'end'}}>{item.name}</td>];
        props.listIndexes.forEach(insideItem => {
            let nowDepReport = insideItem.findChartDataByDep(item);

            let avgValue = nowDepReport?._avgValue;
            let nowValue = 0;

            if (avgValue) {
                nowValue = valueScaler.ScaleAvgValue(avgValue, insideItem.type);
                nowValue = Number.isNaN(nowValue) ? 1 : nowValue;
            } else 
                nowValue = NaN

            rowArr.push(<td><ColorBlock onClick={() => props.checkOtherDepartment(item)} background={insideItem.colorStrategy.GetColorCode(nowValue)} value={Math.round(nowValue)}/></td>);
        });

        rowArr.push(<td><img className="deleteFromFavoritiesImg" onClick={() => deleteDepartmentFromFavorites(item)} src={DeleteImg}></img></td>)

        return rowArr;
    });

    return <React.Fragment>
        {hierarchyNeeded && <HierarchyComponent 
                                zIndex={100}
                                closePopup={() => hierarchyNeededChange(false)}
                                width={'default'}
                                hierarchy={props.hierarchy}
                                nowDepartment={props.department}
                                listIndexes={props.listIndexes}
                                addDepartmentInFavoritesDepartments={d => addDepartmentInFavorites(d)}/>}
        <table className="HeadHierarchy">
            <tr>
                <td></td>
                {props.listIndexes.map(item => <td className="Prompt HeadHierarchyPrompt">{item.nameIndex}</td>)}
                <td></td>
            </tr>
            <tr>
                {rowArrMainDep}
                <td></td>
            </tr>
            {rows.map(r => <tr>{r}</tr>)}
            <tr>
                <div onClick={() => hierarchyNeededChange(true)} className="addDepartmentInHeadHierarchy">
                    <div className = "openFullHierarchy SubHeader">{"+" + props.textProvider.addDepartment}</div>
                </div>
            </tr>
        </table>
    </React.Fragment>
}

export default HeadHierarchy;