import React from 'react'

import DepartmentView from '../../../Models/Organizations/Department/DepartmentView'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'

import ExpandDown from '../../../Media/Img/ExpandDown.png'
import ExpandRight from '../../../Media/Img/ExpandRight.png'

import './StructureComponent.css'
import Hierarchy from '../../../Models/Organizations/Hierarchy'

export interface IStructureComponentReadonly {
    textProvider : ILanguagePhrase
    structure : DepartmentView[]
    extendChanged(hierarchyId : string, structure : DepartmentView[]) : void
    itemSelected(item : DepartmentView, superior : DepartmentView) : void
}

interface IStructureComponent extends IStructureComponentReadonly {
    hierarchy : Hierarchy
    isReadonly : boolean
    itemAddRequested(current: DepartmentView) : void
    itemDeleteRequested(current : DepartmentView, superior : DepartmentView) : void
    structureDeleteRequested(hierarchyId : string) : void
    editHierarchy(hierarchy : Hierarchy) : void
}

export function StructureComponentReadonly(props : IStructureComponentReadonly) {
    return StructureComponent({
        ...props,
        hierarchy: new Hierarchy(''),
        isReadonly: true,
        itemAddRequested : c => { },
        itemDeleteRequested : (c,s) => { },
        structureDeleteRequested : (id) => { },
        editHierarchy : h => { }
    });
}

export default function StructureComponent(props : IStructureComponent) {
    const getActionsSpan = (view : DepartmentView, superior: DepartmentView | undefined) => {
        if (props.isReadonly)
            return [];

        let items = [];

        if (superior && view.subDepartments.length === 0) {
            items.push(<span className="StructureComponentDeleteSpan" onClick={() => props.itemDeleteRequested(view, superior)}>{props.textProvider.delete}</span>)
            items.push(<span className="StructureComponentDividerSpan">|</span>)
        }

        items.push(<span className="StructureComponentEditSpan" onClick={() => props.itemSelected(view, superior as DepartmentView)}>{props.textProvider.edit}</span>)

        return items;
    }

    const getExtendItem = (view : DepartmentView) => {
        const className = 'StructureComponentExtend';

        if (view.subDepartments.length === 0)
            return <div className={className} />;
        
        let extendChanged = () => {
            view.isExtended = !view.isExtended;
            props.extendChanged(props.hierarchy.id, props.structure);
        }
        return <img src={view.isExtended ? ExpandDown : ExpandRight} 
                    className="StructureComponentExtend" 
                    onClick={() => extendChanged()}></img>;
    }

    const getTemplate = (view : DepartmentView, superior : DepartmentView | undefined) => {
        let extendSpan : JSX.Element = getExtendItem(view);
        let children : JSX.Element[] = [];
        if (view.subDepartments.length > 0) {

            if (view.isExtended)
                children = view.subDepartments.map(dep => getTemplate(dep as DepartmentView, view));
        }

        let textSpan = <span onClick={() => props.itemSelected(view, superior as DepartmentView)}>{view.name}</span>
        return <div className="StructureComponentItem">
            <div className="StructureComponentNameBox">{extendSpan}{textSpan}{getActionsSpan(view, superior)}</div>
            {children}
        </div>
    }

    let header = null;
    let buttons = null;
    if (!props.isReadonly) {
        let headerEditSpan = <span className="StructureComponentEditSpan" onClick={() => props.editHierarchy(props.hierarchy)}>{props.textProvider.edit}</span>;
        header = <p className="StructureComponentHeader">{props.hierarchy.name} {headerEditSpan}</p>;
        buttons = <div className="StructureComponentButtonsContainer">
            <button className="StructureComponentDeleteButton" onClick={() => props.structureDeleteRequested(props.hierarchy.id)}>{props.textProvider.delete}</button>
            <button className="StructureComponentAddButton" onClick={() => props.itemAddRequested(props.structure[0])}>{props.textProvider.addDepartment}</button>
        </div>
    }

    return <div className="StructureComponentContainer">
        {header}
        {props.structure.map(item => getTemplate(item, undefined))}
        {buttons}
    </div>
}