import DateType from "./DateType"

class Report {

    public reportId : string

    public postTime : string

    public firstDate : string

    public secondDate : string

    public dateType : number

    public isReady : boolean;

    public almostReady : boolean;

    public hierarchyId : string

    constructor(
        reportId : string = '', 
        postTime : string = '',
        firstDate : string = '',
        secondDate : string = '',
        dateType : number = 0,
        isReady : boolean = false,
        isAlmostReady = false,
        hierarchyId : string = ""){

        this.reportId =reportId;
        this.postTime = postTime;
        this.firstDate = firstDate;
        this.secondDate = secondDate;
        this.dateType = dateType;
        this.isReady = isReady;
        this.hierarchyId = hierarchyId;
        this.almostReady = isAlmostReady;
    }

    public static getReport(){
        return new Report("", "", "", "", DateType.ByDays, false, false);
    }

}

export default Report;