import React, {MouseEvent} from "react"

import Pair from '../../Models/Pair'

import './IconWithMenu.css'

interface IIconWithMenu {
    img : string
    imgClicked() : void
    menuItems : Pair<string, () => void>[]
    isMenuVisible : boolean
}

export default function IconWithMenu(props : IIconWithMenu) {
    const getItem = (pair : Pair<string, () => void>) => {
        return <div className="IconWithMenuItemContainer">
            <div className="IconWithMenuItem" onClick={() => pair.value()}>{pair.key}</div>
        </div>
    }

    return <div className="IconWithMenuContainer">
        <img className="IconWithMenuImg" src={props.img} onClick={() => props.imgClicked()}/>
        <div className="IconWithMenuMenu" style={{display: props.isMenuVisible ? '' : 'none'}}>
            {props.menuItems.map(pair => getItem(pair))}
        </div>
    </div>
}