import React from 'react'

import BaseContainer from '../BaseContainer'
import ILocalStorageAgent from '../../LocalStorageWorker/ILocalStorageAgent'
import { ITextProvider, TextProvider } from '../../Helpers/TextProvider/TextProvider'
import ResetPasswordUI from './ResetPasswordUI'
import IUserAction from '../../Actions/UserActions/IUserAction'
import UserActions from '../../Actions/UserActions/UserActions'
import RoutesUrl from '../../Helpers/Routes/RoutesUrl'
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import { IRoutesAgent, RoutesAgent } from '../../Helpers/Routes/RoutesAgent'
import LocalStorageAgent from '../../LocalStorageWorker/LocalStorageAgent'

interface IResetPassword {
    localStorageAgent : ILocalStorageAgent | null
    textProvider : ITextProvider | null
    routesAgent : IRoutesAgent | null
    userActions : IUserAction | null
}

interface IResetPasswordState {
    email : string
    popupMessage : string
    redirectNeeded : boolean
}

export default class ResetPassword extends BaseContainer<IResetPassword, IResetPasswordState> {
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _language : ILanguagePhrase;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _userActions : IUserAction;

    constructor(props : IResetPassword) {
        super(props);
        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
        this._language = (props.textProvider ?? new TextProvider()).GetLang();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._userActions = props.userActions ?? new UserActions();
        this.state = {
            email : (this._localStorageAgent.user?.email) ?? '',
            popupMessage : '',
            redirectNeeded : false,
        };
    }

    onEmailChange(email : string) {
        this.setState(state => { return { ...state, email : email } });

        this._userActions.ResetPassword(email).then(result => {
            this.setState(state => { 
                return { 
                    ...state, 
                    popupMessage : result ? this._language.passwordResetSuccess : this._language.passwordResetError, 
                    redirectNeeded : result
                };
            });
        })
    }

    onPopupClosed() {
        this.setState(state => { return { ...state, popupMessage : '' }})

        if (!this.state.redirectNeeded) return;
        this._routesAgent.navigateTo(RoutesUrl.SignIn);
    }

    render() {
        return <ResetPasswordUI 
                    textProvider={this._language} 
                    routesAgent={this._routesAgent}
                    email={this.state.email} 
                    popupMessage={this.state.popupMessage}
                    onChange={s => this.onEmailChange(s)}
                    onPopupClosed={() => this.onPopupClosed()}/>
    }
}