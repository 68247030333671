import IColorRecognizer from "../../Helpers/Strategys/ColorStrategy/IColorRecognizer";
import Department from "../Organizations/Department/Department";
import ChartData from "../Entintys/ChartData";
import ReportType from "../../Models/Reports/ReportType"

class NameIndexValues {
    constructor(chartData : Array<ChartData>, nameIndex : string, type : ReportType, colorStrategy : IColorRecognizer){
        this.chartData  = chartData;
        this.nameIndex = nameIndex;
        this.type = type;
        this.colorStrategy = colorStrategy;
    }

    public findMaxLenghtReport() : number {
        return this.chartData.reduce((prev, curr) => curr._values.length > prev ? curr._values.length : prev, 0);
    }

    public findChartDataByDep(department : Department) : ChartData {
        let chartData = this.chartData.find(item => item._name == department.name) as ChartData;
        return chartData;
    }
    
    public chartData : Array<ChartData>;

    public nameIndex : string;

    public type : ReportType;

    public colorStrategy : IColorRecognizer
}

export default NameIndexValues;