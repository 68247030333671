import React, { SyntheticEvent } from 'react'

import Pair from '../../Models/Pair';

import './SelectV2.css'

export class SelectV2BaseProps {
    constructor(value : string, options : Pair<string, string>[], isReadonly : Boolean, onChange : ((v:string) => void), id : string = '') {
        this.value = value;
        this.options = options;
        this.isReadonly = isReadonly;
        this.onChange = onChange;
        this.id = id;
    }

    public value : string;
    public options : Pair<string, string>[];
    public isReadonly : Boolean;
    public onChange : (v : string) => void;
    public id : string;
}

export function SelectV2Base(props : SelectV2BaseProps) {
    const onSelect = (handler : SyntheticEvent<HTMLSelectElement, Event>) => {
        const select = (handler.target as HTMLSelectElement);
        const index = select.selectedIndex;
        props.onChange(props.options[index].key);
    };

    if (props.isReadonly)
        return <div id={props.id}>{props.value}</div>
 
    return <select id={props.id} className="SelectV2Select Prompt" onChange={handler => onSelect(handler)}>{props.options.map(o => <option selected={o.key == props.value} key={o.key} value={o.key}>{o.value}</option>)}</select>
}