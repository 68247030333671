import React from 'react'

import BaseContainer from "../../BaseContainer";
import CRMOrganizationsUI from './CRMOrganizationsUI';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';

import CRMOrganization from '../../../Models/CRM/CRMOrganization';

import { ICRMActions, CRMActions } from "../../../Actions/CRMActions";
import { IRoutesAgent, RoutesAgent } from '../../../Helpers/Routes/RoutesAgent';
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';
import { ITokenParser, TokenParser } from '../../../Helpers/TokenParser';
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent';
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';

interface ICRMOrganizations {
    crmActions : ICRMActions | null
    tokenParser : ITokenParser | null
    localStorageAgent : ILocalStorageAgent | null
    routesAgent : IRoutesAgent | null
    textProvider : ITextProvider | null
}

interface ICRMOrganizationsState {
    isLoaded : boolean
    errorMessage : string
    items : CRMOrganization[]
}

export default class CRMOrganizations extends BaseContainer<ICRMOrganizations, ICRMOrganizationsState> {
    private _scrollHandler : any;

    private readonly _crmActions : ICRMActions;
    private readonly _tokenParser : ITokenParser;
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _language : ILanguagePhrase;
    private readonly _companiesPromise : Promise<CRMOrganization[] | string>

    private _pageNumber : number = 0;
    private _requestInProgress : boolean = true;
    private _isEnd : boolean = false;

    constructor(props : ICRMOrganizations) {
        super(props);
        
        this._crmActions = props.crmActions ?? new CRMActions();
        this._tokenParser = props.tokenParser ?? new TokenParser();
        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._companiesPromise = this._crmActions.getCompaniesAsync(this._pageNumber++);
        this._language = (props.textProvider ?? new TextProvider()).GetLang();
        this.state = {
            isLoaded: false,
            errorMessage: '',
            items: [],
        }
    }

    componentDidMount() {
        this._companiesPromise.then(result => {
            this.requestHandler(result);
            this.setState(state => { return { ...state, isLoaded: true } })
        })
        this._scrollHandler = this._routesAgent.onScrollEventAdd(s => this.scrollHandler(s));
    }
    componentWillUnmount() { this._routesAgent.onScrollEventRemove(this._scrollHandler); }

    scrollHandler(relativeScroll : number) {
        if (relativeScroll < 0.8 || this._requestInProgress || this._isEnd) return;

        this._requestInProgress = true;
        this._crmActions.getCompaniesAsync(this._pageNumber++).then(r => this.requestHandler(r));
    }

    requestHandler(result : CRMOrganization[] | string) {
        if (typeof result === 'string') this.setState(state => { return { ...state, errorMessage: result }});
        else {
            const items = this.state.items.concat(result);
            this.setState(state => { return { ...state, items: items} })
            if (result.length === 0) this._isEnd = true;
        } 

        this._requestInProgress = false;
    }

    onItemClicked(item : CRMOrganization) {
        this._routesAgent.navigateTo(`${RoutesUrl.CRMOrganization}?${RoutesUrl.idKey}=${item.organizationId}`);
    }

    onErrorClosed() {
        this.setState(state => { return { ...state, errorMessage: '' } })
    }

    render() {
        return <CRMOrganizationsUI 
                    tokenParser={this._tokenParser}
                    localStorageAgent={this._localStorageAgent}
                    routesAgent={this._routesAgent}
                    language={this._language}
                    isLoaded={this.state.isLoaded} 
                    errorMessage={this.state.errorMessage} 
                    items={this.state.items}
                    onItemClicked={item => this.onItemClicked(item)}
                    onErrorClosed={() => this.onErrorClosed()}/>
    }
}