import React from 'react'

import { IPaymentService, PaymentService } from '../Services/Payment/PaymentService'

import PaymentInfo from '../Models/Payment/PaymentInfo';

export interface IPaymentActions {
    getPublicKey() : Promise<string>;

    getPrivateKey(info : PaymentInfo) : Promise<string>;
}

export class PaymentActions implements IPaymentActions {
    private readonly _paymentService : IPaymentService;
    constructor(service : IPaymentService | null = null) {
        this._paymentService = service ?? new PaymentService();
    }

    public async getPublicKey() {
        return await this._paymentService.getPublicKey();
    }

    public async getPrivateKey(info: PaymentInfo): Promise<string> {
        return await this._paymentService.getPrivateKey(info);
    }
}