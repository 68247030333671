import React from "react";

import { ITokenParser, TokenParser } from "../Helpers/TokenParser";
import Role from "../Models/Role"
import RoutesUrl from "../Helpers/Routes/RoutesUrl";
import ILocalStorageAgent from "../LocalStorageWorker/ILocalStorageAgent";
import { IRoutesAgent, RoutesAgent } from "../Helpers/Routes/RoutesAgent";
import LocalStorageAgent from "../LocalStorageWorker/LocalStorageAgent";

interface IRedirectOnLoad {
    localStorageAgent : ILocalStorageAgent | null
    tokenParser : ITokenParser | null
    routesAgent : IRoutesAgent | null
}

function RedirectOnLoad(props : IRedirectOnLoad){
    const localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
    const tokenParser = props.tokenParser ?? new TokenParser();
    const routesAgent = props.routesAgent ?? new RoutesAgent();

    const token = localStorageAgent.token;
    if (!token)
        routesAgent.navigateTo(RoutesUrl.SignIn);
    else {
        const roles = tokenParser.GetRoles(token);
        routesAgent.navigateTo(roles.some(r => r === Role.Administrator) 
                                    ? RoutesUrl.CRMOrganizations
                                    : roles.some(r => r === Role.CompanyAdministrator)
                                        ? RoutesUrl.Users
                                        : RoutesUrl.Reports)
    }
    
    return <div></div>
}

export default RedirectOnLoad;