import PaymentInfoBase from './PaymentInfoBase'

export default class PaymentInfo extends PaymentInfoBase {
    constructor(
        proto : PaymentInfoBase,
        amount : number,
        currencyCode : string) {
        
        super(proto.companyName, proto.country, proto.address1, proto.address2, proto.city, proto.zipCode, proto.nationalTaxId);

        this.amount = amount;
        this.currencyCode = currencyCode;
    }

    public amount : number;
    
    public currencyCode : string;
}