import React, { useState } from "react"

import { InputV2, InputV2Props, InputV2Imaged } from "../../Components/InputV2/InputsV2"

import { ISignInPageState } from "./SignInPage";
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";

import Eye from "../../Media/Img/eye.png"
import ClosedEye from "../../Media/Img/closedEye.png"
import RoutesUrl from "../../Helpers/Routes/RoutesUrl"

import "./SignInUI.css"
import { IRoutesAgent } from "../../Helpers/Routes/RoutesAgent";

import Logo from "../../Media/Img/logo_actual_signin.png";

const emailInputId = 'SignInEmailInput',
      passwordInputId = 'SignInPasswordInput',
      authButtonId = 'SignInAuthButton',
      errorPromptId = 'SignInError',
      signUpButtonId = 'SignInSignUpButton',
      resetPasswordButtonId = 'SignInResetPasswordButton'

export { emailInputId, passwordInputId, authButtonId, errorPromptId, signUpButtonId, resetPasswordButtonId }

interface ISignInUI{
    routesAgent : IRoutesAgent
    state:ISignInPageState
    changeEmailAction(name:string,value:string):void
    changePasswordAction(name:string,value:string):void
    authUser():void
    textProvider:ILanguagePhrase
    isCorrectDate:boolean
}

function SignInUi(props:ISignInUI){
    const [ forceError, forceErrorChange ] = useState(false);
    const [ passwordImg, passwordImgChange ] = useState(false);

    let errorPrompt="";
    if(!props.isCorrectDate)
        errorPrompt=props.textProvider.unsuccsesAuth
    if(!props.state.isConfirmedEmail)
        errorPrompt = props.textProvider.confirmEmail;

    function onClick() {
        forceErrorChange(true);
        if (!isValid) return;

        props.authUser()
    }

    const isEmailValid = !props.state.emailErrorPrompt || props.state.emailErrorPrompt.length === 0;
    const isPasswordValid = !props.state.passwordErrorPrompt || props.state.passwordErrorPrompt.length === 0;
    const isValid = isEmailValid && isPasswordValid;

    return (<div className="CommonPage">
                <div className="CommonPageInner">
                    <div className="CommonPageHeader"><img className="MainLogoImg" src={Logo} /></div>
                    {InputV2(new InputV2Props(
                        props.textProvider.email,
                        forceError,
                        props.state.emailErrorPrompt,
                        props.state.email,
                        isEmailValid,
                        v => props.changeEmailAction(v, ''),
                        'text', false, 'fit', false, emailInputId))}
                    <InputV2Imaged
                        inputProps={new InputV2Props(
                            props.textProvider.password,
                            forceError,
                            props.state.passwordErrorPrompt,
                            props.state.password,
                            isPasswordValid,
                            v => props.changePasswordAction(v, ''),
                            passwordImg ? 'text' : 'password', false, 'fit', true, passwordInputId)}
                        imgProps={{
                            imgPosition : 'post',
                            imgSrc : passwordImg ? Eye : ClosedEye,
                            imgClicked : () => passwordImgChange(!passwordImg),
                        }}/>
                    <div id={errorPromptId} className="ErrorPromptAuth SubHeader">{errorPrompt}</div>

                    <button id={authButtonId} className="PrimaryButton WideButton" onClick={() => onClick()}>{props.textProvider.signInBut}</button>
                    <button id={signUpButtonId} className="SecondaryButton" onClick={() => props.routesAgent.navigateTo(RoutesUrl.SignUp)}>{props.textProvider.signUpBut}</button>
                    <button id={resetPasswordButtonId} className="SecondaryButton" onClick={() => props.routesAgent.navigateTo(RoutesUrl.ResetPassword)}>{props.textProvider.passwordReset}</button>
                </div>
            </div>)
}

export default SignInUi;
