import React from 'react'

import BalanceRecordAPI from './BalanceRecordAPI'

export default class BalanceOutcomeRecordAPI  extends BalanceRecordAPI {
    constructor() {
        super();
    }

    public employeeCount : number = 0;

    public innerName : string = '';
}