import React from "react"
import SignUpUI from "./SignUpUI"
import RegistrationException from "../../Models/Exceptions/RegistrationException";
import BadRequestAnswer from "../../Models/Exceptions/BadRequestAnswer";
import IUserActions from "../../Actions/UserActions/IUserAction";
import { ITextProvider, TextProvider } from "../../Helpers/TextProvider/TextProvider";
import RoutesUrl from "../../Helpers/Routes/RoutesUrl";
import UserNameExceptions from "../../Models/Exceptions/UserNameException";
import Preloader from "../../Components/Preloader/Preloader";
import UserActions from "../../Actions/UserActions/UserActions";
import { IRoutesAgent, RoutesAgent } from "../../Helpers/Routes/RoutesAgent";

interface ISignUpPage {
    routesAgent : IRoutesAgent | null
    userActions : IUserActions | null
    textProvider : ITextProvider | null
}

interface ISignUpPageState{
    isDemoVersion: boolean
    errorMessage : string
    isSucsessRegisrtrate : boolean
    isLoad : boolean
}

class SignUpPage extends React.Component<ISignUpPage,ISignUpPageState>{
    private readonly _routesAgent : IRoutesAgent;
    private readonly _userActions : IUserActions;
    private readonly _textProvider : ITextProvider;
    constructor(props : ISignUpPage){
        super(props);
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._userActions = props.userActions ?? new UserActions();
        this._textProvider = props.textProvider ?? new TextProvider();
        this.state={
            isDemoVersion : false,
            errorMessage : '',
            isLoad : true,
            isSucsessRegisrtrate : false
        }
    }

    registerUser = (firstName : string, lastName : string, email : string, password : string) => {
        this._userActions.RegistrationAdmin(
            password,
            firstName.trim(),
            lastName.trim(),
            email,
            this._textProvider.GetLang().lang,
            this.state.isDemoVersion).then(errorMessage => {
                if (!errorMessage) {
                    this.setState({isSucsessRegisrtrate : true});
                    return;
                }

                this.setState(state => { return { ...state, errorMessage: errorMessage } } );
            });
    }

    changeStateSucRegitrate = () => {
        this.setState({errorMessage:''});
        if (this.state.isSucsessRegisrtrate)
            this._routesAgent.navigateTo(RoutesUrl.SignIn);
    }

    render(){
        let content = (this.state.isLoad) ? (<SignUpUI
                                                textProvider={this._textProvider}
                                                routesAgent={this._routesAgent}
                                                isSuccess={this.state.isSucsessRegisrtrate}
                                                errorMessage={this.state.errorMessage}
                                                registerUser={(f,l,e,p) => this.registerUser(f,l,e,p)}
                                                changeStateSucReg = {this.changeStateSucRegitrate}
                                            />)
                                           : <Preloader/>
        return content;
    }
}

export default SignUpPage;