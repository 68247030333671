import React, { ChangeEvent } from 'react'

import './InputV2Base.css'

interface IInputV2Base {
    id : string;
    prompt : string;
    value : string;
    type : string;
    isReadonly : Boolean;
    autoComplete : boolean;
    onChange : (value: string) => void;
}

export default function InputV2Base(props : IInputV2Base) {
    const onChange = (s : string) => {
        if (!props.isReadonly)
            props.onChange(s);
    }

    let inputClass = !props.value || props.value.length === 0 || props.isReadonly ? "InputV2BaseReadonly" : "InputV2Base";

    return <input id={props.id}
                disabled={!!props.isReadonly}
                className={inputClass} 
                placeholder={props.prompt} 
                value={props.value} 
                type={props.type}
                autoComplete={props.autoComplete ? "on" : "new-password"}
                onChange={e => onChange(e.currentTarget.value)}>
    </input>
}