import BaseService from "../BaseService"
import BaseUser from "../../Models/Users/BaseUser";
import AuthUser from "../../Models/Users/AuthUser";
import IUSerService from "./IUserService";
import UserServiceConstants from "./UserServiceConstants";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";
import Action from "../../Models/Delegate/Action";
import RequestSettings from "../RequestProvider/RequestSettings";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";
import Token from "../../Models/Token";

import IRequestProvider from "../RequestProvider/IRequestProvider";
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import BadRequestAnswer from "../../Models/Exceptions/BadRequestAnswer";

import { GetMessage } from '../ObjectsReflector'
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";
import { ITextProvider, TextProvider } from "../../Helpers/TextProvider/TextProvider";

class UserService extends BaseService implements IUSerService {
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _lang : ILanguagePhrase;

    constructor(
        requestProvider : IRequestProvider | null = null, 
        localStorageAgent : ILocalStorageAgent | null = null,
        textProvider : ITextProvider | null = null) {
        super(requestProvider);
        this._localStorageAgent = localStorageAgent ?? new LocalStorageAgent();
        this._lang = (textProvider ?? new TextProvider()).GetLang();
    }

    public async AuthorizeAsync(
        user:AuthUser,
        successCallback:(t : Token) => void,
        errorCallback:(s : string) => void,
        unconfirmedEmailCallback:() => void) : Promise<void> {
        const settings = new RequestSettings(UserServiceConstants._authorizationRequestSignature, new Dictionary(), JSON.stringify(user));
        
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => successCallback(r as Token)),
            new Pair(400, r => errorCallback(GetMessage(r))),
            new Pair(403, _ => unconfirmedEmailCallback()),
        ));
    }

    public async RegistrationInOrganization(user : BaseUser) : Promise<string> {
        const settings = new RequestSettings(UserServiceConstants._registrationInOrganization, new Dictionary(), JSON.stringify(user));

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
            new Pair(409, _ => result = this._lang.specialTariffMaxManagers)
        ));

        return result;
    }

    public async RegistrationAdmin(user : BaseUser): Promise<string> {
        const settings = new RequestSettings(UserServiceConstants._adminRegistration, new Dictionary(), JSON.stringify(user));

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => result = ''),
            new Pair(204, _ => result = ''),
            new Pair(400, r => result = GetMessage(r))
        ));

        return result;
    }

    public async UpdateUserInformation(user : BaseUser) : Promise<string>{
        let selfId = (this._localStorageAgent.user as BaseUser).id;
        let isCurrent = user.id === selfId;
        const settings = new RequestSettings(UserServiceConstants.updateUserInformation(!isCurrent ? user.id : ''), new Dictionary(), JSON.stringify(user));

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ))

        //if current and updated successfull
        if (isCurrent && !result) this._localStorageAgent.user = Object.assign(this._localStorageAgent.user as BaseUser, user);

        return result;
    }

    public async GetUserProfileById(userId : string) : Promise<BaseUser | string>{
        const settings = new RequestSettings(UserServiceConstants._getUserProfileById, new Dictionary(), null, userId);
        let result : BaseUser | string = '';

        await this.RequestProvider.Request(settings, new Dictionary<number, Action<BaseUser|string>>(
            new Pair(200, r => result = r as BaseUser),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async GetUserProfile() : Promise<BaseUser> {
        const settings = new RequestSettings(UserServiceConstants._getUserProfile, new Dictionary(), null);
        let result : BaseUser = new BaseUser();

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as BaseUser)
        ));

        return result;
    }

    public async DeleteUser(userId : string) : Promise<string> {
        const settings = new RequestSettings(UserServiceConstants._deleteUser, new Dictionary(), null, userId);

        let result = ''
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async BlockManagerChangeAsync(userId : string, needToBlock : boolean) : Promise<boolean> {
        const signature = needToBlock ? UserServiceConstants._blockManager : UserServiceConstants._unblockManager;
        const settings = new RequestSettings(signature, new Dictionary(new Pair('userId', userId)), {});

        let result = false;
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => result = true),
            new Pair(204, _ => result = true),
            new Pair(400, _ => result = false),
        ));

        return result;
    }

    public async ResendConfirmationEmail(userId : string) : Promise<string> {
        const settings = new RequestSettings(UserServiceConstants._resendConfirmationEmail, new Dictionary(new Pair('userId', userId)), null)

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async ResetPassword(email : string) : Promise<boolean> {
        const settings = new RequestSettings(UserServiceConstants._resetPassword, new Dictionary(new Pair("email",email)), null);
        let result = false;

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => result = true),
            new Pair(204, _ => result = true),
            new Pair(400, _ => result = false),
        ));

        return result;
    } 

}

export default UserService;