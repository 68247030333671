import NumberOfEmployees from "./NumberOfEmployees";
import ProfessionalSectorType from "./ProfessionalSectorType";

 class Organization {
    constructor(
        orgId : string,
        phone : string,
        officialWebsite : string,
        numberOfEmployees : NumberOfEmployees,
        professionalSectorType : ProfessionalSectorType,
        hierarchyIds : Array<string>) {
        
        this.organizationId = orgId;
        this.phone = phone;
        this.officialWebsite = officialWebsite;
        this.numberOfEmployees = numberOfEmployees;
        this.professionalSectorType = professionalSectorType;
        this.hierarchyIds = hierarchyIds
    }

    public organizationId : string;

    public phone : string;

    public officialWebsite : string;

    public numberOfEmployees : NumberOfEmployees;

    public professionalSectorType : ProfessionalSectorType;

    public hierarchyIds : Array<string>

}

export default Organization;