import ILanguagePhrase from "./ILanguagePhrase"
import LinkText from "../../Models/Link/LinkText";
import LinkPart from "../../Models/Link/LinkPart";
import RoutesUrl from "../Routes/RoutesUrl";
import Language from "../../Models/Users/Language";
import NumberOfEmployees, { getValueByNumberOfEmployees } from "../../Models/Organizations/NumberOfEmployees";
import Pair from '../../Models/Pair'
import EmployeeType from '../../Enums/EmployeeType'
import InteractiveHint from "../../Models/InteractiveHints/InteractiveHint";

export default class FranchPhrase implements ILanguagePhrase{

    languageName = 'Français';

    lang = Language.Fr;

    iso = 'fr';

    bcp = 'fr-FR';

    emptyArea="Le champ ne doit pas être vide";

    shortPassword='Le mot de passe doit contenir au moins 8 caractéres'

    uncorrectPassword='Votre mot de passe doit contenir au moins une majuscule, un chiffre et un caractère spécial'

    uncorrectEmail='Indiquer une adresse mail'

    uncorrectPhoneNumber="Indiquer un numéro de téléphone"

    firstName="Prénom"

    lastName="Nom"

    email="E-mail"

    phoneNumber="Numéro de téléphone"

    login="Nom d'utilisateur"

    password="Mot de passe"

    passwordReset="Réinitialiser le mot de passe"

    passwordResetError="Aucun utilisateur avec un tel e-mail n'a été trouvé";

    passwordResetSuccess="L'e-mail de changement de mot de passe a été envoyé à la boîte e-mail spécifiée";

    unsuccsesAuth="Vous avez entré un e-mail ou un mot de passe invalide"

    duplicateEmail="Cette adresse mail est déjà utilisée"

    signUpBut="S'inscrire"

    signInBut="Se connecter"

    authorization="Connexion "

    registration="Inscription "

    registrationDate="Date d’inscription";

    tensity="Bien-être émotionnel"

    anxiety = "Adaptation émotionnelle";

    period = "Période"

    settings = "Paramètres"

    logOut = "Se déconnecter"

    byMonth= "Rapport mensuel"

    byDay = "Rapport quotidien"

    byYear = "Rapport annuel"

    byWeek  = "Rapport hebdomadaire"

    dateReport = "Date de création du rapport"

    reportNotLoad = "En cours de traitement"

    dateActivity = "Dernière connexion"

    cancel = "Abandonner"

    update = "Mettre à jour";

    connected = "Fichier connecté";

    inProcessing  = "Traitement en cours";

    err = "Erreur";

    processed = "Traitement réussi";

    notConnected = "Fichier non-connecté";

    sucsessRegistrate = "Vous vous êtes inscrit avec succès, vérifiez votre e-mail";

    yes = "Oui";

    no = "Non";

    confirmEmail = "Un lien vous sera envoyé pour confirmer votre adresse mail";

    reports  = "Rapports";

    almostReadyOrNotInsufficientData = "Le rapport est partiellement prêt. Les données ne sont pas disponibles pour l'ensemble de départements ";

    volume = "Activité de communication";

    generalReport = "Rapport général";

    good = "Bon";

    risky = "Risqué";

    bad = "Mauvais";

    createReport = "Créer un nouveau rapport";

    createReportPrompt = "Créer votre premier rapport";

    reportBack = "Retour aux rapports";

    reportDaily = "Rapport quotidien";

    reportLibrary = "Bibliothèque des rapports";

    reportType = "Type de rapport";

    addDepartment = "Ajouter un département";

    detailedReportByDepartments = "Rapport détaillé par département";

    tensionDynamics = "Évolution du bien-être émotionnel";

    evolutionOfTensionDynamics = "Évolution de la adaptation émotionnelle";

    volumeDynamics = "Évolution de l'activité de communication";

    personalInformation = "Votre profil";

    save = "Enregistrer";

    interfaceLanguage = "Langue d'interface";

    resourcesInformation = "Ressources et information";

    footerText = "(c) Propriété intellectuelle de WhyEva. Tous droits réservés";

    termsOfSale = "Conditions generales de vente";

    termsOfUse = "Conditions générales d'utilisation de l'application";

    dataProtection = "Règles de la protection des données personnelles";

    addNewReport = "Ajouter un nouveau rapport";

    hereYouCanAdd = "Ici, vous pouvez ajouter des statistiques supplémentaires à l'avenir.";

    programVersion = "Version du programme";

    tensityHint = "l’indicateur qualitatif définissant le niveau de positivité émotionnell au sein du service"

    anxietyHint = "l’évolution de la réponse émotionnelle des employés au sein du service"

    volumeHint = "l’intensité d’échanges au sein du service"

    reportHintTitle = "Cette illustration indique l'état psycho-émotionnel actuel dans l'entreprise."

    reportHintSubTitle = "L'échelle compend 5 niveaux :";

    reportHintItems = [
        "5 - Degré très élevé",
        "4 - Degré élevé",
        "3 - Degré moyen",
        "2 - Faible degré",
        "1- Degré très faible",
    ]

    interfaceSettings = "Paramètres d'interface";

    detailedReport = "Rapport détaillé"

    managers = "Chef";

    managerAdd = "Ajouter un nouveau chef";

    managerAdded = "Un nouveau chef ajouté au système";

    managerBlock = "Bloquer cet administrateur";

    managerUnblock = "Débloquer cet administrateur";

    managerDeleteConfirmation = "Etes-vous sûr de vouloir supprimer cet administrateur?";

    success = "Succès !";

    uncorrectFirstName = "Indiquez un prénom";

    uncorrectLastName = "Indiquez un nom";

    areYouSure = "Êtes-vous sûr(e) ?";

    delete = "Effacer"

    create = "Créer";

    back = "Précédent";

    editProfile = "Actualiser profil";

    edit = "Modifier";

    clear = "Supprimer";

    error = "Erreur";

    confirm = "Je confirme";

    confirmation = "Confirmer";

    noPermissions = "Désolé, vous n'êtes pas autorisé à accéder à cette page";

    actions = "Actions"
    adminInterface = "Administrateur";

    accountSettings = "Paramètres du compte";

    attention = "Attention";

    balance = "Solde du compte";

    balanceAmount = "Montant du paiement";

    balanceAmountPrompt = "Entrez le montant";

    balanceBillingHistory = "Historique des facturations";

    balanceBillItem = "Produit";

    balanceBillPrice = "Prix";

    balanceBillTotal = "Total";

    balanceBillQuantity = "Quantité";

    balanceCurrentBalance = "Solde actuel";

    balanceCurrentTariff = "Tarif actuel";

    balanceCurrentTariffInclude = "Le tarif actuel comprend :";

    balanceCurrentTrial = "Le solde de compte de la période d'essai";

    balanceExpirationDate = "Le solde actuel est suffisant jusqu’au";

    balanceExpirationDateTrial = (daysCount : number) => `${daysCount} jours jusqu'à la fin de la période d'essai`;

    balanceInformation = "Détails de l'abonnement";

    balanceInvoice = "Facture";

    balanceInMonth = "/mois";

    balanceOutOfMoney = "Cette action n'entre pas dans votre tarif ou vous êtes à court de fonds sur votre compte";

    balancePay = "Payer";

    balancePayment = "Réapprovisionnement";

    balancePaymentAmount = "Montant du paiement";

    balancePaymentDate = "Date du réapprovisionnement";

    balancePaymentDetails = "Détails du réapprovisionnement";

    balancePaymentProcessing = "Le paiement est en cours de traitement";

    balancePayments = "Réapprovisionnements";

    balancePerEmployee = "Par employé";

    balancePersonalRemain = "solde de compte";

    balancePlanManage = "Gestion du forfait";

    balancePricePerMonth = "Prix par mois";

    balancePrompt = "Suivez l'état de votre abonnement";

    balanceTariff = "Tarifs";
    balanceTitle = "Paiements";

    balanceRemaining = "Solde de compte";

    balanceRemainigTrial = "Solde de compte de la période d'essai";

    balanceRecharge = "Réapprovisionner le solde ";

    balanceResult = "Solde";

    balanceUsageEmployees = (curr : number, total : number) => `${curr} employés sur ${total}`;

    balanceUsageManagers = (curr : number, total : number) => `${curr} administrateurs sur ${total}`;

    balanceUsageStructures = (curr : number, total : number) => `${curr} structures sur ${total}`;

    buisnessIdentifier = "Tax ID";

    connectInvitation = "Invitation de connexion";

    connectInvitationSure = "Êtes-vous sûr de vouloir envoyer une invitation pour se connecter ?";

    connectSendDate = "Date d'envoi de l'invitation";

    connectSendInvite = "Envoyer une invitation pour se connecter";

    connectSubmittedDate = "Date de lier du compte";

    connectRevokedDate = "Date de rupture du lien";

    companyInformation = "Informations de l'entreprise";

    companyAddress = "Adresse du siège de l'entreprise";

    companyName = "Nom de l'entreprise";

    companyPhone = "Numéro de téléphone de l'entreprise";

    companyWebsite = "Site de l'entreprise";

    companyNumberOfEmployees = "Nombre d'employés dans l'entreprise";

    companyEmployeesNumber = (v : NumberOfEmployees) => getValueByNumberOfEmployees(v, 'Moins de 20', 'Entre 20 et 49', 'Entre 50 et 249', 'Plus de 250');

    currencyEuro = "Euro";

    crm ="CRM"

    crmOrganizationDeleteConfirmation = 'Êtes-vous sûr(e) de vouloir supprimer cette organisation?';

    department = "Département";

    departmentDeleteConfirmation = (name : string, superName : string) => `Êtes-vous sûr(e) de vouloir supprimer le département ${name}, qui est un sous-département ${superName} ?`;

    departmentName = "Nom du département";

    uncorrectDepartmentName = "Indiquez le nom du département";

    employees = "Employés";

    employeeAdd = "Ajouter un nouvel employé";

    employeeAddDepartment  = "Ajouter au sein du service supplémentaire";

    employeeDeleteConfirm = "Êtes-vous sûr de vouloir supprimer cet employé ?";

    employeeInviteQuestion = "L'employé a été ajouté. Envoyer une invitation pour se connecter ?";

    employeeLinkDate = "Date d'accès";

    employeeMustHaveDepartment = "Lier l'employé du département";

    faq = "Le processus de mise en œuvre et d'utilisation d'WhyEva";

    genderMale = "Homme";

    genderFemale = "Femme";

    interactiveAgain = "Afficher à nouveau les indices";

    interactiveDontShow = "Ne pas afficher les indices";

    interactiveGotIt = "Compris";

    interactiveHintAdminData = new InteractiveHint(this.adminInterface, "Configurez les données de l'administrateur et de l'entreprise.\nLes données de la société sont utilisées dans l'historique de paiement pour remplir automatiquement le formulaire de paiement et préparer des factures de paiement pour vous.");

    interactiveHintEmployeesIntro = new InteractiveHint(this.employees, "Les employés sont des utilisateurs qui ont accès au chatbot Eva.\nUn employé ne peut pas être un gestionnaire. Seul l’employé peut accéder aux paramètres de son profil, à ses réponses ou à son activité.\nUn employé peut toujours se déconnecter du système ou supprimer son compte.\nL'employé peut réinitialiser son mot de passe si nécessaire, en recevant un lien à l'adresse e-mail enregistrée dans son profil.\nVous recevrez un e-mail pour vous connecter au système.");

    interactiveHintEmployeeEdit = new InteractiveHint(this.employees, "Envoyez une invitation à l'employé pour qu’il rejoigne le chatbot.\nSaisissez les données du profil de l'employé./nEnlevez le profil de l’employé du système si nécessaire./n/b/Attention/b/ : Le nombre d'employés a des conséquences sur le plan tarifaire !");

    interactiveHintEmployees = new InteractiveHint(this.employees, "Ajoutez des employés en spécifiant leurs divisions ou leurs équipes de projet.\n/b/Attention/b/ : Le choix concernant le rôle de l'employé dans la hiérarchie est très important, étant donné que celui-ci influe considérablement sur l’algorithme du système !");

    interactiveHintHelp = new InteractiveHint("Services d’assistance", "Nous sommes toujours disposés à répondre à vos questions : support@whyeva.com.");

    interactiveHintIntro = new InteractiveHint("", "Bienvenue dans la section Administrateur de la plateforme WhyEva.\nCes conseils vous aideront à vous familiariser avec le système");

    interactiveHintManagersAdd = new InteractiveHint("Ajoutez des gestionnaires au système", "Les gestionnaires ont accès aux rapports.\n/b/Attention/b/ : Le nombre de gestionnaires a des conséquences sur le plan tarifaire !");

    interactiveHintManagersEdit = new InteractiveHint(this.managers, "Une invitation est envoyée à l’utilisateur ayant le rôle de Gestionnaire pour qu’il rejoigne le système de reporting.\nModifiez les données de son profil, le supprimer du système ou le bloquer temporairement.");

    interactiveHintManagersIntro = new InteractiveHint(this.managers, "Les gestionnaires sont des utilisateurs qui ont accès aux comptes de l'entreprise.\nUn gestionnaire peut réinitialiser son mot de passe en recevant un lien vers l'e-mail enregistré dans son profil.\nVotre inscription sera confirmée par le biais d’un e-mail.\nAprès l’avoir confirmé, vous pouvez vous connecter pour la configuration de votre profil.\n/b/Attention/b/ : Utilisez le mot de passe original de l'e-mail. Vous pouvez le modifier après la première connexion.");

    interactiveHintManagersRight = new InteractiveHint(this.managers, "Les utilisateurs ayant le statut de Gestionnaire ont accès :\n- Aux rapports actuels ;\n- Aux paramètres du profil.\n/b/Attention/b/ : Le gestionnaire n'a pas accès au chatbot, s'il est nécessaire que le gestionnaire participe au système, ajoutez-le à la liste des employés.");

    interactiveHintFeatures = new InteractiveHint("L'espace de travail de l'administrateur vous permet de :", "- Créer les comptes des gestionnaires ayant accès aux rapports et aux informations analytiques (section Gestionnaires) ;\n- Créer les comptes des employés ayant accès au chatbot (section Employés) ;\n- Créer des structures organisationnelles et des groupes de projet (section Structures) ;\n- Gérer les paiements et mettre en place des paiements (section Solde) ;\n- Gérer les données du profil (section Paramètres) ;\n- Passer en mode Gestionnaire et accéder aux rapports (section Rapports) ;\n- Se déconnecter du système (section Déconnexion).");

    interactiveHintPaymentData = new InteractiveHint("Configurez les données de paiement", "Vous ne payez que pour les ressources que vous utilisez.\nChaque jour, le système tient compte des dépenses et vous rappelle de faire l’appoint si nécessaire.\n/b/Attention/b/ : Vous bénéficiez d’une période d’essai de 15 jours ainsi que de 200 euros pour tester le système. Ce laps de temps vous permet de choisir les paramètres appropriés du système et de découvrir toutes ses fonctionnalités.\nAprès la période d'essai, vous pourrez choisir le mode de paiement.\nNous utilisons les méthodes de paiement les plus sécurisés. Vos données de paiement ne seront ni récupérées, ni traitées, ni stockées.");

    interactiveHintStructures = new InteractiveHint("Structures", "Créez des structures organisationnelles hiérarchiques qui permettent d’associer les employés des services ou les grouper en équipes de projet.\nLes structures organisationnelles vous offrent la possibilité de gérer les rapports par division.\nGérez les unités de votre entreprise en créant de nouveaux services et divisions.\n/b/Attention/b/ : Le nombre de structures utilisées a des conséquences sur le plan tarifaire !");

    interactiveHints = "Aide en ligne";

    interactiveNext = "La suite";

    noHierarchy = "Absence de hiérarchie pour générer des rapports";

    specialTariffMaxManagers = "La limite fixée pour le plan individuel a été atteinte, il est impossible d'ajouter un Chef ! Pour modifier votre tarif, contactez support@whyeva.com";

    specialTariffMaxEmployees = "La limite fixée pour le plan individuel a été atteinte, il est impossible d'ajouter un Employé ! Pour modifier votre tarif, contactez support@whyeva.com";

    specialTariffMaxStructures = "La limite fixée pour le plan individuel a été atteinte, il est impossible d'ajouter une Structure ! Pour modifier votre tarif, contactez support@whyeva.com";

    specialTariffPrompt = [ 'Spécial', 'Contactez-nous' ];

    structure = "Structure";

    structureAdd = "Ajouter une structure";

    structureDelete = "Supprimer la structure";

    structureDeleteConfirmation = "Etes-vous sûr de vouloir supprimer cette structure?";

    structureDepartmentMain = "Département principal";

    structureDepartmentNumber = "Code du département";

    structureDepartmentSubs = "Sous-départements";

    structureDepartmentSuperior = "Division supérieure"

    structureName = "Nom de la structure";

    structureNameEnter = "Indiquez le nom de la structure";

    structures = "Structures";

    structuresPrompt = 'Vous pouvez créer la structure organisationnelle de votre entreprise ici. Commencez par ajouter une nouvelle structure, puis ajoutez des départements. Vous pouvez connecter vos employés aux structures existantes sur la page Employés pour améliorer votre expérience.';
    support = "Aide et Soutien"
    paymentCountry = "Pays";

    paymentEnterCountry = "Entrez le pays";

    paymentAddress1 = "Adresse 1";

    paymentAddress2 = "Adresse 2";

    paymentEnterAddress = "Entrez l'adresse";

    paymentCity = "Ville";

    paymentEnterCity = "Ville";

    paymentZipCode = "Code postal";

    paymentEnterZipCode = "Entrez le code postal ";

    paymentTaxIdNumber = "SIRET";

    paymentEnterBillingAddress = "Entrez l'adresse de facturation";

    paymentEnterPaymentAmount = "Entrez la somme du paiement";

    paymentEnterTaxIdNumber = "Entrez Le numéro SIRET";

    permissionBot = "Accès au chatbot";

    permissionReporting = "Chef (accès aux rapports)";

    permissions = "Droit d'accès";

    permissionSelectAny = "Indiquer les droit d'accès de l'employé";

    professionalExperience = "Expérience professionnelle";

    uncorrectProfessionalExperience = "Indiquer une epérience professionnelle";

    uncorrectCompanyName = "Entrez le nom de l'entreprise";

    uncorrectCompanyPhone = "Entrez le numéro de téléphone de l'entreprise";

    uncorrectCompanyWebsite = "Entrez le site de l'entreprise";

    uncorrectCompanyAddress = "Entrez l'adresse du siège de l'entreprise";

    uncorrectNumberOfEmployees = "Entrez le nombre d'employés dans l'entreprise";

    months = "mois";

    years = "années";

    dateBirth = "Date de naissance";

    uncorrectDateBirth = "Indiquez une date de naissance";

    gender = "Vous êtes";

    uncorrectGender = "Choisissez qui vous êtes";

    profession = "Fonction";

    uncorrectProfession = "Indiquer une fonction";

    position = "Le rôle"

    positions = [
        new Pair(EmployeeType.Founder, 'Fondateur'),
        new Pair(EmployeeType.TopManagement, 'Direction générale'),
        new Pair(EmployeeType.FunctionalManagement, 'Gestion régionale et fonctionnelle'),
        new Pair(EmployeeType.MiddleManagement, 'Gestion intermédiaire'),
        new Pair(EmployeeType.LineManagement, 'Gestion hiérarchique'),
        new Pair(EmployeeType.Contributor, 'Contributeurs individuels'),
    ];

    uncorrectPosition = "Indiquer le rôle";

    hiredDate = "Date d'embauche ";

    uncorrectHiredDate = "Indiquer une date d'intégration de l'entreprise ";

    organization = "Structure";

    hierarchy = "Hiérarchie";

    privacyPolicyAgree=[
        new LinkText("J’accepte "),
        new LinkPart("les CGU ", RoutesUrl.getPolicyLink(this.lang)),
        new LinkText("et "),
        new LinkPart("la Politique de protection des données", RoutesUrl.getPolicyLink(this.lang)),
        new LinkText(", et certifie être majeur")
    ]

    privacyDataAgree = [new LinkText("Je consens expressément à ce que mes données puissent être utilisées dans les datasets de WhyEva")];
}
