import React, { useState } from 'react'

import DailyReportReportManager from './DailyReportReportManager'
import { SelectV2, SelectV2Props } from '../../../Components/SelectV2/SelectV2'

import Hierarchy from '../../../Models/Organizations/Hierarchy'
import DateType, { getLocalizedDateType } from '../../../Models/Analysis/DateType'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Pair from '../../../Models/Pair'
import Report from '../../../Models/Analysis/Report'
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl'

import Back from '../../../Media/Img/backWhite.png'

import './DailyReportUI.css'

interface IDailyReportUI {
    textProvider : ILanguagePhrase
    reportsByHierarchy : Pair<Report, Hierarchy>[]
}

export default function DailyReportUI(props : IDailyReportUI) {
    const [ currentItem, currentItemChange ] = useState(props.reportsByHierarchy.length > 0 ? props.reportsByHierarchy[0] : undefined);
    
    const localizedDateTypes = [
        new Pair(DateType.ByWeek.toString(), props.textProvider.byWeek),
        new Pair(DateType.ByMonth.toString(), props.textProvider.byMonth)
    ];
    const [ dateType, dateTypeChange ] = useState(Number(localizedDateTypes[0].key));

    const back = () => {
        window.location.href = RoutesUrl.Reports;
    }

    let content;
    if (currentItem)    
        content = <DailyReportReportManager textProvider={props.textProvider} hierarchy={currentItem.value} report={currentItem.key} dateType={dateType}/>

    return <div className="DailyReportUI">
        <div className="DailyReportUIContentContainer">
            <div className="DailyReportUIHeadRow">
                <button className='DailyReportUIBack WhiteButton' onClick={() => back()}><img className="DailyReportUIBackImg" src={Back}/>{props.textProvider.reportBack}</button>
                <div className="DailyReportUIHeadSelectors">
                    <div>
                        <p className='Header'>{props.textProvider.structures + ":"} </p>
                        {SelectV2(new SelectV2Props(
                            '',
                            false,
                            '',
                            currentItem?.value.id ?? '',
                            props.reportsByHierarchy.map(pair => new Pair(pair.value.id, pair.value.name)),
                            true,
                            v => currentItemChange(props.reportsByHierarchy.find(pair => pair.value.id === v) as Pair<Report, Hierarchy>),
                            false, 'fit'))}
                    </div>
                    <div>
                        <p className='Header'>{props.textProvider.reportType + ":"}</p>
                        {SelectV2(new SelectV2Props(
                            '',
                            false,
                            '',
                            dateType.toString(),
                            localizedDateTypes,
                            true,
                            v => dateTypeChange(Number(v)),
                            false, 'fit'))}
                    </div>
                </div>
            </div>
            {content}
        </div>
    </div>
}