import React, { useState } from 'react'

import InjectableComponent from '../../InjectableComponent/InjectableComponent'
import PeriodBlock from '../PeriodBlock/PeriodBlock'

import DateType from '../../../Models/Analysis/DateType'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'

import CloseImg from "../../../Media/Img/close.png";

import './ReportGenerator.css'
import '../Report.css'

interface IReportGenerator  {
    id : string
    textProvider : ILanguagePhrase
    createReport(startDate : Date, endDate : Date, dateType : DateType):void
    minFirstDateGlobal : Date
    maxSecondDateGlobal : Date
}

export default function ReportGenerator(props : IReportGenerator) {
    const [ reportGeneratorNeeded, reportGeneratorNeededChange ] = useState(false);

    return <InjectableComponent popupContainerId={props.id} popupContainerClass='' isPopupNeeded={reportGeneratorNeeded}
        template={<button className="PrimaryButton DisabledButton" disabled onClick={() => reportGeneratorNeededChange(!reportGeneratorNeeded)}>{props.textProvider.createReport}</button>}
        getPopup={() => <div className = "periodBlockInContainer">
            <div className = "periodBlockInContainerHeader">
                <p className="SubHeader">{props.textProvider.createReport}</p>
                <img onClick = {() => reportGeneratorNeededChange(false)} src = {CloseImg}></img>
            </div>
            <PeriodBlock
                reportHierarchy={(s,e,d) => props.createReport(s,e,d)}
                textProvider={props.textProvider}
                minFirstDateGlobal={props.minFirstDateGlobal}
                maxSecondDateGlobal={props.maxSecondDateGlobal}/>
        </div>}/>
}