import BalanceUser from "../../Models/Balance/BalanceUser";
import BalanceUserAPI from "../../Models/Balance/BalanceUserAPI";
import Currencies from '../../Helpers/Currencies'
import Dictionary from "../../Models/Collections/Dictionary";
import BaseService from '../BaseService'
import RequestSettings from "../RequestProvider/RequestSettings";
import BalanceServiceConstants from "./BalanceServiceConstants";
import BalanceIncomeRecord from '../../Models/Balance/BalanceIncomeRecord'
import BalanceIncomeRecordAPI from '../../Models/Balance/BalanceIncomeRecordAPI'
import BalanceOutcomeRecord from '../../Models/Balance/BalanceOutcomeRecord'
import BalanceOutcomeRecordAPI from '../../Models/Balance/BalanceOutcomeRecordAPI'
import Pair from "../../Models/Pair";
import Tariff from '../../Models/Tariffs/Tariff'
import TariffAPI from '../../Models/Tariffs/TariffAPI'
import TariffUser from '../../Models/Tariffs/TariffUser'
import TariffUserAPI from '../../Models/Tariffs/TariffUserAPI'
import IRequestProvider from "../RequestProvider/IRequestProvider";

export interface IBalanceService {
    getUserBalance() : Promise<BalanceUser>;

    getTrialBalance() : Promise<BalanceUser>;

    getIncomeHistory() : Promise<BalanceIncomeRecord[]>;

    getOutcomeHistory() : Promise<BalanceOutcomeRecord[]>;

    getCurrentTariff() : Promise<TariffUser>;

    getDefaultTariffs() : Promise<Tariff[]>;
}

export class BalanceService extends BaseService implements IBalanceService {
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async getUserBalance() : Promise<BalanceUser> {
        const settings = new RequestSettings(BalanceServiceConstants._getUserBalance, new Dictionary(), {});
        let result : BalanceUserAPI | null = null;

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as BalanceUserAPI),
            new Pair(204, _ => { })
        ))

        return result ? new BalanceUser(result, Currencies.getCurrency((result as BalanceUserAPI).currencySymbol)) : new BalanceUser();
    }

    public async getTrialBalance() : Promise<BalanceUser> {
        const settings = new RequestSettings(BalanceServiceConstants._getTrialBalance, new Dictionary(), {});
        let result : BalanceUserAPI | null = null;

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as BalanceUserAPI),
            new Pair(204, _ => { })
        ));

        return result ? new BalanceUser(result, Currencies.getCurrency((result as BalanceUserAPI).currencySymbol)) : new BalanceUser();
    }

    public async getIncomeHistory() : Promise<BalanceIncomeRecord[]> {
        const settings = new RequestSettings(BalanceServiceConstants._getIncomeHistory, new Dictionary(), {});
        let result : BalanceIncomeRecordAPI[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
                new Pair(200, r => result = r as BalanceIncomeRecordAPI[]),
                new Pair(204, _ => { }),
            )
        );

        return result.map(item => new BalanceIncomeRecord(item, Currencies.getCurrency(item.currencySymbol)));
    }

    public async getOutcomeHistory() : Promise<BalanceOutcomeRecord[]> {
        const settings = new RequestSettings(BalanceServiceConstants._getOutcomeHistory, new Dictionary(), {});
        let result : BalanceOutcomeRecordAPI[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
                new Pair(200, r => result = r as BalanceOutcomeRecordAPI[]),
                new Pair(204, _ => [ ]),
            )
        );

        return result.map(item => new BalanceOutcomeRecord(item, Currencies.getCurrency(item.currencySymbol)));
    }

    public async getCurrentTariff() : Promise<TariffUser> {
        const settings = new RequestSettings(BalanceServiceConstants._getTariff, new Dictionary(), {});
        let result : TariffUserAPI | null = null;
        
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as TariffUserAPI),
            new Pair(204, _ => { })
        ))

        return result ? new TariffUser(result, Currencies.getCurrency((result as TariffUserAPI).currencySymbol)) : new TariffUser();
    }

    public async getDefaultTariffs() : Promise<Tariff[]> {
        const settings = new RequestSettings(BalanceServiceConstants._getDefaultTariffs, new Dictionary(), {});
        let result : TariffAPI[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as TariffAPI[]),
            new Pair(204, _ => { })
        ));

        return result.map(item => new Tariff(item, Currencies.getCurrency(item.currencySymbol)));
    }
}