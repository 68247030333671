import React from 'react'

import ChartPrompt from '../../../Components/Chart/ChartPrompt/ChartPrompt';
import HeadHierarchy from '../../../Components/HeadHierarchy/HeadHierarchy'
import ReportHintBlock from '../../../Components/Report/ReportHintBlock/ReportHintBlock'

import Department from '../../../Models/Organizations/Department/Department';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Hierarchy from '../../../Models/Organizations/Hierarchy'
import NameIndexValues from '../../../Models/UI/NameIndexValues'
import Report from "../../../Models/Analysis/Report";

import '../Report.css'

interface IReportDepartmentsInfo {
    textProvider : ILanguagePhrase
    nowReport : Report
    hierarchy : Hierarchy
    department : Department
    listIndexes : NameIndexValues[]
    checkOtherDepartment(dep : Department) : void
}

export default function ReportDepartmentsInfo(props : IReportDepartmentsInfo) {
    const dateString = (date : string) => new Date(date).toLocaleDateString();

    return <div className = "blockReportPage headerBlockReportPage" style={{overflow: 'auto'}}>
        <div className = "fixedBlockOnReportPage">
            <div className = "coupleBlockReportPage">
                <p className="SubHeader">{props.textProvider.generalReport}</p>
                <p className="Prompt PeriodPrompt">
                    {`${props.textProvider.period}:`}
                    <br/>
                    {`${dateString(props.nowReport.firstDate)} - ${dateString(props.nowReport.secondDate)}`}</p>
            </div>
            <div className="ReportPromptBlock">
                <div>
                    <ChartPrompt text={props.textProvider.good + " "} color={"#92E3DA"} />
                    <ChartPrompt text={props.textProvider.risky + " "} color={"#FFD900"} />
                    <ChartPrompt text={props.textProvider.bad} color={"#C36261"} />
                </div>
                {ReportHintBlock({textProvider: props.textProvider})}
            </div>
        </div>
        <HeadHierarchy
            listIndexes = {props.listIndexes}
            hierarchy={props.hierarchy}
            textProvider = {props.textProvider}
            department={props.department}
            checkOtherDepartment = {props.checkOtherDepartment}/>
    </div>
}