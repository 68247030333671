import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import HierarchyServiceConstants from "./HierarchyServiceConstants";

import IHierarchyService from "./IHierarchyService";

import Dictionary from "../../Models/Collections/Dictionary";
import Hierarchy from "../../Models/Organizations/Hierarchy";
import Pair from "../../Models/Pair";
import MainHierarchy from "../../Models/Organizations/MainHierarchy";

import IRequestProvider from "../RequestProvider/IRequestProvider";
import { ITextProvider, TextProvider } from "../../Helpers/TextProvider/TextProvider";
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";

class HierarchyService extends BaseService implements IHierarchyService {
    private readonly _lang : ILanguagePhrase;

    constructor(requestProvider : IRequestProvider | null = null, textProvider : ITextProvider | null = null){
        super(requestProvider);
        this._lang = (textProvider ?? new TextProvider()).GetLang();
    }

    public async AddHierarchyAsync(item : MainHierarchy) : Promise<[boolean, string]> {
        const settings = new RequestSettings(HierarchyServiceConstants.AddHierarchySignature, new Dictionary<string, string>(), JSON.stringify(item));
        let isSuccess : boolean = false;
        let message = '';

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => { isSuccess = true; message = r as string }),
            new Pair(409, _ => { isSuccess = false; message = this._lang.specialTariffMaxStructures })
        ));

        return [isSuccess, message];
    }

    public async UpdateHierarchyAsync(item : Hierarchy) : Promise<void> {
        let copy = Object.assign({}, item);
        copy.mainDepartment = null;
        const settings = new RequestSettings(HierarchyServiceConstants.UpdateHierarchySignature, new Dictionary<string, string>(), JSON.stringify(copy));

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { })
        ))
    }

    public async DeleteHierarchyAsync(id : string) : Promise<void> {
        const settings = new RequestSettings(HierarchyServiceConstants.DeleteHierarchySignature, new Dictionary(new Pair("id", id)), null);

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { })
        ))
    }

    public async GetHierarchyAsync(id : string) : Promise<Hierarchy> {
        const settings = new RequestSettings(HierarchyServiceConstants.GetHierarchySignature, new Dictionary(new Pair("id", id)), null);
        let result : Hierarchy = new Hierarchy('');
        
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as Hierarchy),
        ))

        return result;
    }
}

export default HierarchyService;