import React, { useState } from 'react'

import IconWithMenu from '../../../Components/IconWithMenu/IconWithMenu'
import InteractiveHintsManager from '../../../Components/InteractiveHints/InteractiveHintsManager'
import { PopUpConfirmDefault } from '../../../Components/PopupConfirm/PopupConfirm'

import EmployeeLocal from '../../../Models/Employees/EmployeeLocal'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import { IInteractiveHintActions } from '../../../Actions/InteractiveHintActions'
import * as InteractiveHintsKeys from '../../../Helpers/InteractiveHintsKeys'
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent'
import Pair from '../../../Models/Pair'
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl'

import EditWhite from "../../../Media/Img/editWhite.png"

import './EmployeesTable.css'

export const tableId = 'EmployeesTableTable',
             inviteConfirmId = 'EmployeesTableInviteConfirm',
             inviteCancelId = 'EmployeesTableInviteCancel',
             deleteConfirmId = 'EmployeesTableDeleteConfirm',
             deleteCancelId = 'EmployeesTableDeleteCancel';

interface IEmployeesTable {
    interactiveHintActions : IInteractiveHintActions
    language : ILanguagePhrase
    routesAgent : IRoutesAgent
    employees : EmployeeLocal[]

    inviteEmployee(employee : EmployeeLocal) : void
    deleteEmployee(employee : EmployeeLocal) : void
}

export default function EmployeesTable(props : IEmployeesTable) {
    const [ requiredIndex, requiredIndexChange ] = useState(-1);
    const employeeToAct = requiredIndex < 0 ? new EmployeeLocal() : props.employees[requiredIndex];
    const [ sendInviteRequested, sendInviteRequestedChange ] = useState(false);
    const [ deleteRequested, deleteRequestedChange ] = useState(false);

    function employeeToTableRow(employee : EmployeeLocal, index: number) : JSX.Element {
        const menuItems = [
            new Pair<string, () => void>(props.language.connectSendInvite, () => sendInviteRequestedChange(true)),
            new Pair<string, () => void>(props.language.editProfile, () => 
                props.routesAgent.navigateTo(`${RoutesUrl.EmployeeSettings}?${RoutesUrl.idKey}=${employeeToAct.employeeId}`)),
            new Pair<string, () => void>(props.language.delete, () => deleteRequestedChange(true)),
        ];
    
        return <tr>
            <td style={{paddingLeft: '0.8vw'}}>{employee.firstName}</td>
            <td style={{paddingLeft: '0.8vw'}}>{employee.lastName}</td>
            <td align="center">{employee.email.toLowerCase()}</td>
            <td align="center">{getDepartments(employee)}</td>
            <td align="center">{correctDate(employee.activationDate)}</td>
            <td align="center">
                <IconWithMenu img={EditWhite} 
                              imgClicked={() => requiredIndexChange(requiredIndex === index ? -1 : index)} 
                              menuItems={menuItems} 
                              isMenuVisible={requiredIndex === index}/>
            </td>
        </tr>
    }

    let sendInvitePopup;
    if (sendInviteRequested)
     sendInvitePopup = <PopUpConfirmDefault 
                            zIndex={100} closePopup={() => sendInviteRequestedChange(false)}
                            confirmButtonId={inviteConfirmId} cancelButtonId={inviteCancelId}
                            textProvider={props.language}
                            text={props.language.connectInvitationSure}
                            onConfirm={() => props.inviteEmployee(employeeToAct)}/>

    let deletePopup;
    if (deleteRequested)
        deletePopup = <PopUpConfirmDefault
                        zIndex={100} closePopup={() => deleteRequestedChange(false)}
                        confirmButtonId={deleteConfirmId} cancelButtonId={deleteCancelId}
                        textProvider={props.language}
                        text={props.language.employeeDeleteConfirm}
                        onConfirm={() => props.deleteEmployee(employeeToAct)}/>

    return <React.Fragment>
        <table id={tableId} className='EmployeesTableTable'>
            <thead>
                <td style={{textAlign: 'start'}}>{props.language.firstName}</td>
                <td style={{textAlign: 'start'}}>{props.language.lastName}</td>
                <td>{props.language.email}</td>
                <td>{props.language.department}</td>
                <td>{props.language.employeeLinkDate}</td>
                <td>{props.language.actions}</td>
            </thead>
            {props.employees.map((e, i) => employeeToTableRow(e, i))}
        </table>
        {sendInvitePopup}
        {deletePopup}
        <InteractiveHintsManager 
            interativehintActions={props.interactiveHintActions}
            lang={props.language}
            infos={[
                {
                    hintKey : InteractiveHintsKeys.introHintKey,
                    hint : props.language.interactiveHintIntro,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.featuresHintKey,
                    hint : props.language.interactiveHintFeatures,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.employeesIntroHintKey,
                    hint : props.language.interactiveHintEmployeesIntro,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.employeesHintKey,
                    hint : props.language.interactiveHintEmployees,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                },
                {
                    hintKey : InteractiveHintsKeys.employeeEditHintKey,
                    hint : props.language.interactiveHintEmployeeEdit,
                    position : { anchorId : tableId, shiftH : 5, shiftV : 5 }
                }
            ]}/>
    </React.Fragment>
}

function getDepartments(employee: EmployeeLocal) {
    const line = employee.departmentsInfo.reduce((prev, curr) => prev += `${curr.departmentName}, `, '');
    return line.substring(0, line.length - 2);
}

function correctDate(d : string) {
    if (d.length < 10 || d.startsWith('0001-01-01'))
        return '-';

    return `${d.substring(8,10)}/${d.substring(5,7)}/${d.substring(0,4)}`;
}