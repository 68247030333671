import React from 'react'
import { IInteractiveHintActions } from '../../Actions/InteractiveHintActions';

import BaseContainer from '../../Containers/BaseContainer'
import InteractiveHintComponent from './InteractiveHintComponent';

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';
import { IInteractiveHint } from './IInteractiveHint';

interface IInteractiveHintsManager {
    lang : ILanguagePhrase
    interativehintActions : IInteractiveHintActions
    infos : IInteractiveHint[]
}

interface IInteractiveHintsManagerState {
    hintsToShow : IInteractiveHint[]
}

export default class InteractiveHintsManager extends BaseContainer<IInteractiveHintsManager, IInteractiveHintsManagerState> {
    private readonly _hintPromises : Promise<[boolean, IInteractiveHint]>[]

    constructor(props : IInteractiveHintsManager) {
        super(props);
        this.state = {
            hintsToShow: []
        }

        this._hintPromises = props.infos.map<Promise<[boolean, IInteractiveHint]>>(async info => 
            [ await props.interativehintActions.IsHintShowNeeded(info.hintKey), info ]);
    }

    componentDidMount() {
        Promise.all(this._hintPromises).then(results => {
            const hintsToShow = results.filter(([show, _]) => show).map(([_, hint]) => hint);
            this.setState(state => { return { ...state, hintsToShow: hintsToShow }});
        })
    }

    removeFirstHint() {
        const hints = this.state.hintsToShow.slice(1);
        this.setState(state => {
            return { ...state, hintsToShow: hints }
        })
    }

    async dontShowHints() {
        this.setState(state => { return { ...state, hintsToShow : [] }})
        await this.props.interativehintActions.HintsShowAllowChange(false);
    }

    render() {
        if (this.state.hintsToShow.length === 0) return <React.Fragment />

        const hintKey = this.state.hintsToShow[0].hintKey;
        return <InteractiveHintComponent 
                    hint={this.state.hintsToShow[0]}
                    lang={this.props.lang}
                    isLast={this.state.hintsToShow.length === 1}
                    onClosed={() => { this.removeFirstHint(); this.props.interativehintActions.MarkHintShowed(hintKey); }}
                    onDontShowHints={async () => await this.dontShowHints()}/>
    }
}