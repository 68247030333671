import React, { useState } from 'react'

import {IMyPopupBase, MyPopupBase} from '../../../Components/MyPopupBase/MyPopupBase'
import {InputV2, InputV2Props} from '../../../Components/InputV2/InputsV2'

import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Validator from '../../../Helpers/Validator/Validator'

import './HierarchyAddPopup.css'
import ButtonV2 from '../../../Components/ButtonV2'

interface IHierarchyAddPopup extends IMyPopupBase {
    textProvider : ILanguagePhrase
    addStructure(name : string, mainDepartmentName : string) : void;
}

export default function HierarchyAddPopup(props : IHierarchyAddPopup) {
    const [forceError, forceErrorChange ] = useState(false);
    const [name, nameChange] = useState('');
    const [mainDepartmentName, mainDepartmentNameChange] = useState('');

    const onClick = () => {
        forceErrorChange(true);
        if (!allValid) return;

        props.addStructure(name, mainDepartmentName)
    }

    const nameValid = !Validator.isNullOrWhitespace(name);
    const mainDepartmentNameValid = !Validator.isNullOrWhitespace(mainDepartmentName);
    const allValid = nameValid && mainDepartmentNameValid;
    return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={'default'}>
        <p className="Header">{props.textProvider.structureAdd}</p>
        <div style={{width: '60%'}}>
            {InputV2(new InputV2Props(
                props.textProvider.structureName,
                forceError,
                props.textProvider.structureNameEnter,
                name,
                nameValid,
                v => nameChange(v),
                'text', false, 'fit'))}
            {InputV2(new InputV2Props(
                props.textProvider.structureDepartmentMain,
                forceError,
                props.textProvider.structureNameEnter,
                mainDepartmentName,
                mainDepartmentNameValid,
                v => mainDepartmentNameChange(v),
                'text', false, 'fit'))}
            <div className="ButtonsContainer">
                <button className="SecondaryButton" onClick={() => props.closePopup()}>{props.textProvider.cancel}</button>
                <ButtonV2 className="PrimaryButton" text={props.textProvider.structureAdd} action={() => onClick()}/>
            </div>
        </div>
    </MyPopupBase>
}