import IOrganizationActions from "./IOrganizationAction";
import IOrganizationService from "../../Services/Organizations/IOrganizationService";

import Entity from "../../Models/Organizations/Entity";
import Individual from "../../Models/Organizations/Individual";
import Organization from "../../Models/Organizations/Organization";
import BaseUser from "../../Models/Users/BaseUser";
import OrganizationService from "../../Services/Organizations/OrganizationService";

class OrganizationActions implements IOrganizationActions {    
    private readonly _organizationService : IOrganizationService;
    constructor(service : IOrganizationService | null = null) {
        this._organizationService = service ?? new OrganizationService();
    }

    public async UpdateEntityAsync(item : Entity) : Promise<void> {
        await this._organizationService.UpdateEntityAsync(item);
    }

    public async DeleteEntityAsync(id: string): Promise<string> {
        return await this._organizationService.DeleteEntityAsync(id);
    }

    public async GetEntitiesAsyncResponce() : Promise<Array<Entity>> {
        return await this._organizationService.GetEntitiesAsync();
    }
    
    public async GetIndividualsAsync() : Promise<Array<Individual>> {
        return await this._organizationService.GetIndividualsAsync();
        
    }

    public async GetCurrentOrganization() : Promise<Organization> {
        const [ entities, individuals ] = await Promise.all([this.GetEntitiesAsyncResponce(), this.GetIndividualsAsync()]);
        if (entities.length > 0) return entities[0];
        else if (individuals.length > 0) return individuals[0];
        else throw new Error('Not Implemented');
    }

    public async GetUsersOrganizationByToken() : Promise<Array<BaseUser>>{
        return await this._organizationService.GetUsersOrganizationsByToken();
    }
}

export default OrganizationActions;