export default class TariffUserAPI {
    public priceId : string = '';

    public innerName : string = '';

    public totalPrice : number = 0;

    /**
     * IT IS CODE NOT SYMBOL
     */
    public currencySymbol : string = '';

    public postTime : string = '';

    public managerCount : number = 0;

    public employeeCount : number = 0;

    public hierarchiesCount : number = 0;

    public maxEmployees : number = 0;
    
    public maxHierarchies : number = 0;

    public maxManagers : number = 0;
}