import IDisabledDates from "./IDisabledDates";

class MonthDisabledDates implements IDisabledDates {

    public GetDisabledDates(startDate : Date, endDate : Date) : Array<Date> {
        let disabledDates : Array<Date> = []
        let nowDate = startDate;
        while(nowDate <= endDate) {
            if(nowDate.getDate() != 1)
                disabledDates.push(nowDate);
            nowDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() + 1);
        }

        return disabledDates;
    }

    public GetDisabledDays() : Array<number> {
        return [];
    }

}

export default MonthDisabledDates;