import React from 'react'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';

import BalanceUserInfo from '../../../Models/Balance/BalanceUserInfo';
import Currency from '../../../Models/Currencies/Currency';
import Tariff from '../../../Models/Tariffs/Tariff';
import TariffUser from '../../../Models/Tariffs/TariffUser';

import './BalanceUI.css'

interface IBalanceInformationBlock {
    textProvider : ILanguagePhrase

    defaultTariffs : Tariff[]
    currentTariff : TariffUser

    balanceInfo : BalanceUserInfo

    getMoney(count : number, currency : Currency) : string
    getMoneyInMonth(count : number, currency : Currency) : string

    defaultTariffsPopupChange(v : boolean) : void;
    rechargeBalancePopupChange(v : boolean) : void;
}

export default function BalanceInformationBlock(props : IBalanceInformationBlock) {
    /**
     * @returns [header, primary, secondary]
     */
     const getCurrentBalancePrompts = () : [string, string, string] => {
        if (props.balanceInfo.balance && props.balanceInfo.trialBalance) {
            let balance = props.balanceInfo.balance;
            let trial = props.balanceInfo.trialBalance;
            if (trial.balance > 0) {
                let secondaryText = ` (${props.getMoney(balance.balance, balance.currency)} ${props.textProvider.balancePersonalRemain})`;
                return [props.textProvider.balanceCurrentTrial, props.getMoney(trial.balance, trial.currency), secondaryText];
            }

            return [props.textProvider.balanceCurrentBalance, props.getMoney(balance.balance, balance.currency), '']
        }
        return [props.textProvider.balanceCurrentBalance, '?', ''];
    }

    const getTariffInfoItem = (prompt : string, text : string, uppercase : boolean, secondaryText : string) => {
        let style : React.CSSProperties = { };
        if (uppercase)
            style.textTransform = 'uppercase';
        return <div className="BalanceUIContainer">
            <p className="Prompt">{prompt}</p>
            <p className="BalanceUIImportantInfo" style={style}>{text} <span className="BalanceUITariffItem Prompt">{secondaryText}</span></p>
        </div>;
    }

    const getExpirationDate = (expiration : Date, trialExpiration : Date) => {
        const lastest = expiration.getTime() > trialExpiration.getTime() ? expiration : trialExpiration;
        if (lastest.getTime() < new Date().getTime())
            return '???';

        return lastest.toLocaleDateString(props.textProvider.bcp, { month: 'short', day: '2-digit', year: 'numeric' });
    }

    const getTrialExpirationText = (expirationDate : Date) => {
        let days = Math.floor((expirationDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + 1;
        if (days < 0)
            return ''
        return props.textProvider.balanceExpirationDateTrial(days)
    }

    let pricePerEmployee : number | undefined = props.currentTariff.pricePerEmployee;
    if (Number.isNaN(pricePerEmployee))
        pricePerEmployee = undefined;

    let pricePerEmployeeText = pricePerEmployee ? props.getMoneyInMonth(pricePerEmployee, props.currentTariff.currency) : '?';

    const [currentBalanceHeader, currentBalancePrimaryText, currentBalanceSecondaryText] = getCurrentBalancePrompts();

    return <div className="BalanceUIInformationContainer Shadow">
        <p className="SubHeader">{props.textProvider.balanceInformation}</p>
        <div className="BalanceUITariffItemsContainer">
            {getTariffInfoItem(props.textProvider.balanceCurrentTariff,
                props.currentTariff.innerName, true, '')}
            {getTariffInfoItem(currentBalanceHeader, currentBalancePrimaryText, false, currentBalanceSecondaryText)}
            {getTariffInfoItem(props.textProvider.balancePricePerMonth,
                props.getMoneyInMonth(props.currentTariff.totalPrice, props.currentTariff.currency), false,
                    ` (${pricePerEmployeeText} ${props.textProvider.balancePerEmployee})`)}
        </div>
        <div className="BalanceUIExpirationDateContainer">
            <p className="Prompt Warning">{getTrialExpirationText(props.balanceInfo.trialBalance.expirationDate)}</p>
            <p className="Prompt">
                {props.textProvider.balanceExpirationDate}
                <span className="BalanceUIDateSpan"> {getExpirationDate(props.balanceInfo.balance.expirationDate, props.balanceInfo.trialBalance.expirationDate)}</span>
            </p>
        </div>
        <p className="Prompt">{props.textProvider.balanceCurrentTariffInclude}</p>
        <ul className="BalanceUIBalanceItemsList">
            <li><span className="Prompt">{props.textProvider.balanceUsageManagers(
                props.currentTariff.managerCount, props.currentTariff.maxManagers)}</span></li>
            <li><span className="Prompt">{props.textProvider.balanceUsageEmployees(
                props.currentTariff.employeeCount, props.currentTariff.maxEmployees)}</span></li>
            <li><span className="Prompt">{props.textProvider.balanceUsageStructures(
                props.currentTariff.hierarchiesCount, props.currentTariff.maxHierarchies)}</span></li>
        </ul>
        <div className="ButtonsContainer">
            <button className="WhiteButton"
                    onClick={() => props.defaultTariffsPopupChange(true)}>{props.textProvider.balancePlanManage}</button>
            <button className="WhiteButton DisabledButton" disabled
                    onClick={() => props.rechargeBalancePopupChange(true)}>{props.textProvider.balanceRecharge}</button>
        </div>
    </div>;
}
