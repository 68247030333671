import DateType from "../DateType";

class BaseReport{

    constructor(queryId : string,
                userId : string,
                departmentId : string,
                firstDate : string,
                secondDate : string,
                dateType : DateType,
                avgValue : number){
        this.queryId = queryId;
        this.userId = userId;
        this.departmentId = departmentId;
        this.firstDate = firstDate;
        this.secondDate = secondDate;
        this.dateType = dateType;
        this.avgValue = avgValue;
    }

    public queryId : string;

    public userId : string;

    public departmentId : string;

    public firstDate : string

    public secondDate : string

    public dateType : DateType

    public avgValue : number
}

export default BaseReport;