import IDisabledDates from "./IDisabledDates";

class DayDisabledDates implements IDisabledDates {

    public GetDisabledDays() : Array<number> {
        return [];
    }

    public GetDisabledDates(startDate : Date, endDate : Date) : Array<Date> {
        return [];
    }


}

export default DayDisabledDates;