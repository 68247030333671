import Department from "../Organizations/Department/Department";
import DepartmentView from "./Department/DepartmentView";

class Hierarchy {
    constructor(name : string) {
        this.id = "";
        this.name = name;
        this.mainDepartment = null;
    }

    public id : string;

    public name : string;

    public mainDepartment : Department | null;

    public static ConvertToDepartmentView(item : Hierarchy) : DepartmentView[] {
        if (!item)
            return [];
    
        return Hierarchy.ConvertDepartments([item.mainDepartment as Department]);
    }

    private static ConvertDepartments(departments : Department[], extended : boolean = true) : DepartmentView[] {
        return departments.map(d => {
            let view = new DepartmentView(d,extended);
            if (view.subDepartments && view.subDepartments.length > 0)
                view.subDepartments = Hierarchy.ConvertDepartments(d.subDepartments, false);
            return view;
        });
    }

    public static getDepartments(item : Hierarchy) {
        if (!item.mainDepartment)
            return [];

        return Department.getDepartmentsList(item.mainDepartment);
    }

    public static findDepartment(hierarchy : Hierarchy, id : string) : Department | undefined {
        if (!hierarchy.mainDepartment)
            return undefined;

        return Department.findDepartment(hierarchy.mainDepartment, id);
    }
}

export default Hierarchy;