import React, { useEffect } from 'react'

import BaseContainer from '../../Containers/BaseContainer';
import LoaderImg from "../../Media/Img/load.png"
import "./PreloaderCompact.css"

interface IPreloaderCompact {
    height : string;
}

interface IPreloaderCompactState {
    rotation: number
}

function PreloaderCompactDefault() {
    return <PreloaderCompact height="10vw" />
}

export class PreloaderCompact extends BaseContainer<IPreloaderCompact, IPreloaderCompactState> {
    private readonly RotationDiff = 0.0075;

    private _timerId : any = undefined;

    constructor(props : IPreloaderCompact) {
        super(props);
        this.state = {
            rotation : 0,
        };
    }

    componentDidMount() {
        this._timerId = setInterval(() => this.setState(state => {
            return {
                ...state,
                rotation: (this.state.rotation + this.RotationDiff) % 1,
            };
        }), 1);
    }

    componentWillUnmount() {
        if (this._timerId)
            clearInterval(this._timerId);
    }

    render() {
        return <div className="preloader" style={{height: this.props.height}}>
            <div className='spinerLogo'><img className='spinerLogo' style={{transform: `rotate(${this.state.rotation}turn)`}} src={LoaderImg} alt=""/></div>
        </div>
    }
}

export default PreloaderCompactDefault;