import BalanceUser from "./BalanceUser";
import BalanceIncomeRecord from "./BalanceIncomeRecord";
import BalanceOutcomeRecord from "./BalanceOutcomeRecord";

export default class BalanceUserInfo {
    constructor(balance : BalanceUser, trialBalance : BalanceUser, incomes : BalanceIncomeRecord[], outcomes : BalanceOutcomeRecord[]) {
        this.balance = balance;
        this.trialBalance = trialBalance;

        function order<T extends { postTime : Date }>(arr : T[] )  : T[] { return arr.sort((f,s) => s.postTime.getTime() - f.postTime.getTime()); }

        this.incomes = order(Array.from(incomes));
        this.outcomes = order(Array.from(outcomes));
    }

    public balance : BalanceUser;

    public trialBalance : BalanceUser;

    public incomes : BalanceIncomeRecord[];

    public outcomes : BalanceOutcomeRecord[];
}