import EmployeeService from "../../Services/Employee/EmployeeService";
import IEmployeeService from "../../Services/Employee/IEmployeeService";
import IEmployeeActions from "./IEmployeeActions";

import EmployeeLocal from '../../Models/Employees/EmployeeLocal'
import EmployeeBaseLocal from '../../Models/Employees/EmployeeBaseLocal'

import OrganizationInfo from '../../Models/OrganizationReport/OrganizationInfo'

class EmployeeActions implements IEmployeeActions {
    private readonly _employeeService : IEmployeeService;
    constructor(employeeService : IEmployeeService | null = null) {
        this._employeeService = employeeService ?? new EmployeeService();
    }

    public async getEmployeesInfoAsync() : Promise<EmployeeLocal[]> {
        return await this._employeeService.getEmployeesInfoAsync()
    }

    public async getEmployeeInfoAsync(id : string) : Promise<EmployeeLocal | string> {
        const [employee, organizations ] = await Promise.all([
            this._employeeService.getEmployeeInfoAsync(id), 
            this.getOrganizationsInfoByUserAsync()
        ]);

        if (typeof employee === 'string') return employee;
        return EmployeeLocal.FromAPI(employee, organizations);
    }

    public async sendInvitationAsync(employeeId : string) : Promise<string> {
        return await this._employeeService.sendInvitationAsync(employeeId);
    }

    public async updateAsync(employee : EmployeeBaseLocal) : Promise<string> {
        return await this._employeeService.updateAsync(employee);
    }

    public async deleteAsync(employeeId : string) : Promise<string> {
        return await this._employeeService.deleteAsync(employeeId);
    }

    public getOrganizationsInfoByUserAsync() : Promise<OrganizationInfo[]> {
        return this._employeeService.getOrganizationsInfoByUserAsync();
    }

    public async addAsync(employee : EmployeeBaseLocal) : Promise<[ boolean, string]> {
        return await this._employeeService.addEmployeeAsync(employee);
    }
}

export default EmployeeActions;