import Pair from "../../../Models/Pair";

let BarColors = [
        new Pair("Yellow","#4084dd"),
        new Pair("Blue","#DD40D3"),
        new Pair("DarkOrange","#DD9940"),  
        new Pair("LighGray","#40dd9a"),
        new Pair("Purple","#D2DD40"),
        new Pair("Maroon","#DD9A00"),
        new Pair("Green","#DD4B40"),
        new Pair("8","#9A40DD"),
        new Pair("9","#DD4084"),
        new Pair("10","#0FFFFF"),
        new Pair("11","#a9a9a9")
];

export default BarColors;