import React from 'react'

import Footer from '../../Components/Admin/Footer/Footer'
import SimpleHeaderWithLinks from '../../Components/SimpleHeaderWithLinks/SimpleHeaderWithLinks'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import ILocalStorageAgent from '../../LocalStorageWorker/ILocalStorageAgent'
import { IRoutesAgent, RoutesAgent } from '../../Helpers/Routes/RoutesAgent'
import { ITokenParser, TokenParser } from '../../Helpers/TokenParser'

import './CRMFrame.css'
import LocalStorageAgent from '../../LocalStorageWorker/LocalStorageAgent'
import { TextProvider } from '../../Helpers/TextProvider/TextProvider'

interface ICRMFrame {
    children?: React.ReactNode

    tokenParser?: ITokenParser
    localStorageAgent?: ILocalStorageAgent
    routesAgent?: IRoutesAgent
    language?: ILanguagePhrase
}

export default function CRMFrame(props: ICRMFrame) {
    const language = props.language ?? new TextProvider().GetLang();
    return <div>
        <SimpleHeaderWithLinks 
            tokenParser={props.tokenParser ?? new TokenParser()} 
            localStorageAgent={props.localStorageAgent ?? new LocalStorageAgent()}
            routesAgent={props.routesAgent ?? new RoutesAgent()} 
            language={language} />
        {props.children}
        <Footer text={language.footerText}/>
    </div>
}