import React, {useState} from 'react'

import { DecoratorContainer, HeaderedComponent, UnderlinedComponent, ErrorComponent } from '../Decorators/Decorators'
import { SelectV2Base, SelectV2BaseProps } from './SelectV2Base'

import Pair from "../../Models/Pair"

import './SelectV2.css'
import BaseContainer from '../../Containers/BaseContainer'

export class SelectV2Props extends SelectV2BaseProps {
    constructor(
        prompt : string,
        forceError : boolean,
        errorPrompt : string,
        value : string,
        options : Pair<string, string>[],
        isValid : boolean,
        onChange : (v : string) => void,
        isReadonly = false,
        vertical : 'fit' | 'fill' = 'fit',
        id : string = '') {
            super(value, options, isReadonly, onChange, id)
            this.prompt = prompt;
            this.forceError = forceError;
            this.errorPrompt = errorPrompt;
            this.vertical = vertical;
            this.isValid = isValid;
        }

    public prompt : string;
    public forceError : boolean;
    public errorPrompt : string;
    public vertical : 'fit' | 'fill';
    public isValid : boolean;
}

interface ISelectV2State {
    hadChanged : boolean
}

export function SelectV2(props : SelectV2Props) {
    return React.createElement(SelectV2View, props);
}

class SelectV2View extends BaseContainer<SelectV2Props, ISelectV2State> {
    constructor(props : SelectV2Props) {
        super(props);
        this.state = {
            hadChanged: props.forceError
        }
    }

    valueChanged(v : string) {
        this.setState(state => { return { ...state, hadChanged: true } })
        this.props.onChange(v);
    }

    render() {
        let error = (!this.state.hadChanged && !this.props.forceError) ? '' : (!this.props.isReadonly && !this.props.isValid ? this.props.errorPrompt : " ");
        return <DecoratorContainer>
            <HeaderedComponent text={this.props.prompt} verticalAlign={this.props.vertical}/>
            <SelectV2Base 
                value={this.props.value}
                options={this.props.options}
                isReadonly={this.props.isReadonly}
                onChange={v => this.valueChanged(v)}
                id={this.props.id}/>
            <UnderlinedComponent />
            <ErrorComponent text={error}/>
        </DecoratorContainer>
    }
}