import Dictionary from '../../Models/Collections/Dictionary';
import Pair from '../../Models/Pair';
import PaymentInfo from '../../Models/Payment/PaymentInfo'
import PaymentInfoAPI from '../../Models/Payment/PaymentInfoAPI'

import BaseService from '../BaseService'
import RequestSettings from '../RequestProvider/RequestSettings';
import PaymentServiceConstants from './PaymentServiceConstants';

import IRequestProvider from "../RequestProvider/IRequestProvider";

export interface IPaymentService {
    getPublicKey() : Promise<string>;

    getPrivateKey(info : PaymentInfo) : Promise<string>;
}

export class PaymentService extends BaseService implements IPaymentService {
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async getPublicKey() : Promise<string> {
        const settings = new RequestSettings(PaymentServiceConstants._getStripeConfig, new Dictionary(), null);
        let result : string = '';

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = (r as { publishableKey : string }).publishableKey),
        ));

        return result;
    }

    public async getPrivateKey(info: PaymentInfo): Promise<string> {
        let data = new PaymentInfoAPI(info);
        const settings = new RequestSettings(PaymentServiceConstants._createPaymentIntent, new Dictionary(), JSON.stringify(data));
        
        let result : string = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = (r as {clientSecret : string}).clientSecret),
        ));

        return result;
    }
}