/**
 * Employees's permisson
 */
enum EmployeeAccess {
    NotManager = 0,
    Manager = 1,
    BannedManager = 2,
    RemovedManager = 4
}

export default EmployeeAccess;