import React from 'react'

import BaseContainer from '../../Containers/BaseContainer';

import { DecoratorContainer, HeaderedComponent, UnderlinedComponent, ErrorComponent } from '../Decorators/Decorators'
import InputV2Base from './InputV2Base'

export class InputV2Props {
    constructor(
        prompt : string, 
        forceError : boolean,
        errorPrompt : string, 
        value : string, 
        isValid : boolean, 
        onChange : (v : string) => void,
        type = 'text',
        isReadonly = false,
        vertical : 'fit' | 'fill' = 'fit',
        autocomplete = false,
        id : string = '') {
            
        this.prompt = prompt;
        this.forceError = forceError;
        this.errorPrompt = errorPrompt;
        this.value = value;
        this.type = type;
        this.isReadonly = isReadonly;
        this.vertical = vertical;
        this.isValid = isValid;
        this.onChange = onChange;
        this.autoComplete = autocomplete;
        this.id = id;
    }

    public prompt: string;
    public forceError : boolean;
    public errorPrompt: string;
    public value : string;
    public type : string;
    public isReadonly : boolean;
    public vertical : 'fit' | 'fill';
    public isValid : boolean;
    public onChange : (value: string) => void;
    public autoComplete : boolean;
    public id : string;
}

export class InputV2PropsReadonly extends InputV2Props {
    constructor(
        prompt : string, 
        value : string,
        type = 'text',
        vertical : 'fit' | 'fill' = 'fit') {
        super(prompt, false, '', value, true, _ => { }, type, true, vertical, false, '')
    }
}

export function getInputV2HeaderText(type : string, value : string, prompt : string) {
    return type == 'date' || (value && value.length) > 0 ? prompt : '';
}

export function getInputV2ErrorText(isReadonly : boolean, isValid : boolean, errorPrompt : string) {
    return !isReadonly && !isValid ? errorPrompt : '';
}

interface IInputV2State {
    hadChanged : boolean
}

export function InputV2(props : InputV2Props) {
    return React.createElement(InputV2View, props);
}

class InputV2View extends BaseContainer<InputV2Props, IInputV2State> {
    constructor(props : InputV2Props) {
        super(props);
        this.state = {
            hadChanged: props.forceError,
        }
    }

    valueChanged(v : string) {
        this.setState(state => { return { ...state, hadChanged: true } })
        this.props.onChange(v);
    }

    render() {
        let headerText = getInputV2HeaderText(this.props.type, this.props.value, this.props.prompt);
        let errorText = (!this.state.hadChanged && !this.props.forceError) ? '' : getInputV2ErrorText(this.props.isReadonly, this.props.isValid, this.props.errorPrompt);
        
        return <DecoratorContainer>
            <HeaderedComponent text={headerText} verticalAlign={this.props.vertical} />
            <InputV2Base
                id={this.props.id} 
                prompt={this.props.prompt}
                value={this.props.value}
                type={this.props.type}
                isReadonly={this.props.isReadonly}
                autoComplete={this.props.autoComplete}
                onChange={v => this.valueChanged(v)}/>
            <UnderlinedComponent />
            <ErrorComponent text={errorText} />
        </DecoratorContainer>
    }
}