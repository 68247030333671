import BaseReport from "./BaseReport";

import BaseAnalysis from "../BaseAnalysis";

class BaseReportIndexAnalysis extends BaseReport{

    constructor(proto: BaseReport, collection : BaseAnalysis[]){
        super(proto.queryId, proto.userId, proto.departmentId, proto.firstDate, proto.secondDate, proto.dateType, proto.avgValue);
        this.indexAnalysisCollection = collection;
    }

    public indexAnalysisCollection : Array<BaseAnalysis>;
}

export default BaseReportIndexAnalysis;