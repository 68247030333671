import React from 'react'

import { IMyPopupBaseBasic, MyPopupBase } from '../MyPopupBase/MyPopupBase';

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';

import './PopupConfirm.css'

interface IPopUpConfrimBase extends IMyPopupBaseBasic {
    confirmButtonId : string
    cancelButtonId : string
    text : string;
    onConfirm() : void;
}

interface IPopUpConfirmDefault extends IPopUpConfrimBase {
    textProvider : ILanguagePhrase;
}

export function PopUpConfirmDefault(props : IPopUpConfirmDefault) {
    const lang = props.textProvider;
    return PopUpConfirm({ ...props, header: lang.confirmation, confirm: lang.confirm, cancel: lang.cancel });
}

interface IPopUpConfirm extends IPopUpConfrimBase {
    header : string;
    confirm : string;
    cancel : string;
}

export default function PopUpConfirm(props: IPopUpConfirm) {
    function onConfirm() {
        props.closePopup();
        props.onConfirm();
    }

    return <MyPopupBase zIndex={props.zIndex} closePopup={props.closePopup} width={50}>
        <div className="PopupConfirmHeader" >{props.header}</div>
        <p className="PopupConfirmText">{props.text}</p>
        <div className="PopupConfirmButtonsContainer">
            <button id={props.cancelButtonId} className="PopupConfirmCloseButton" onClick={() => props.closePopup()}>{props.cancel}</button>
            <button id={props.confirmButtonId} className="PopupConfirmConfirmButton" onClick={() => onConfirm()}>{props.confirm}</button>
        </div>
    </MyPopupBase>
}