import { Euro } from '../../Helpers/Currencies';
import Currency from '../Currencies/Currency'
import TariffUserAPI from './TariffUserAPI';

export default class TariffUser {
    constructor(proto: TariffUserAPI = new TariffUserAPI(), currency : Currency = Euro) {
        this.tariffId = proto.priceId;
        this.innerName = proto.innerName;
        this.totalPrice = proto.totalPrice;
        this.currency = currency;
        this.postTime = new Date(proto.postTime);
        this.managerCount = proto.managerCount;
        this.employeeCount = proto.employeeCount;
        this.hierarchiesCount = proto.hierarchiesCount;
        this.maxEmployees = proto.maxEmployees;
        this.maxHierarchies = proto.maxHierarchies;
        this.maxManagers = proto.maxManagers;

        this.pricePerEmployee = Math.floor((this.totalPrice / this.employeeCount) * 100) / 100;
    }

    public tariffId : string;

    public innerName : string;

    public pricePerEmployee : number;

    public totalPrice : number;

    public currency : Currency;

    public postTime : Date;

    public managerCount : number;

    public employeeCount : number;

    public hierarchiesCount : number;

    public maxEmployees : number;
    
    public maxHierarchies : number;

    public maxManagers : number;
}