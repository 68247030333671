import React from 'react';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import { TextProvider } from '../../../Helpers/TextProvider/TextProvider';

import PersonImg from "../../../Media/Img/person.png";

import './BaseAdminSettings.css'

export interface IBaseAdminSettings {
    language? : ILanguagePhrase
}

export interface IBaseAdminSettingsState {

}

export abstract class BaseAdminSettings<TProps extends IBaseAdminSettings, TState extends IBaseAdminSettingsState> extends React.Component<TProps, TState> {
    public static readonly InitialState : IBaseAdminSettingsState = {  }

    public readonly _language : ILanguagePhrase;

    constructor(props : TProps) {
        super(props);

        this._language = props.language ?? new TextProvider().GetLang();
    }

    abstract getLink() : string;

    abstract onLinkClicked() : void;

    abstract getName() : string;

    abstract getButtons() : JSX.Element[];

    abstract getMainContent() : JSX.Element;

    render() {
        return <div className="BaseAdminSettings">
            <p className="BaseAdminSettingsLinkP">
                <span onClick={() => this.onLinkClicked()}>{this.getLink()}</span> 
                {'>'} 
                <span>{this.getName()}</span></p>
            <div className="BaseAdminSettingsInner">
                <div className="BaseAdminSettingsLinksContainer">
                    <img className="BaseAdminSettingsAvatar" src={PersonImg}/>
                    {this.getButtons()}
                </div>
                <div className="BaseAdminSettingsInfoContainer">
                    <p className="Header">{this._language.personalInformation}</p>
                    {this.getMainContent()}
                </div>
            </div>
        </div>
    }
}