import RequestSignature from '../RequestProvider/RequestSignature'

export default class BalanceServiceConstants {
    public static readonly _getUserBalance = new RequestSignature('GET', '/api/UserBalance');

    public static readonly _getTrialBalance = new RequestSignature('GET', '/api/TrialUserBalance')

    public static readonly _getIncomeHistory = new RequestSignature('GET', '/api/HistoryPayments');

    public static readonly _getOutcomeHistory = new RequestSignature('GET', '/api/WritesOffBalance');

    public static readonly _getTariff = new RequestSignature('GET', '/api/UserPrice');

    public static readonly _getDefaultTariffs = new RequestSignature('GET', '/api/Prices');
}