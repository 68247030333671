import React from 'react'

import BaseContainer from '../../BaseContainer';
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact';
import ReportInfo from '../../../Components/Report/ReportInfo/ReportInfo'

import Report from '../../../Models/Analysis/Report';

import IMiddlewareActionsOrganization from '../../../ContainersMiddlewares/MiddlewareActionsOrganization/IMiddlewareActionsOrganization'
import MiddlewareActionsOrganization from '../../../ContainersMiddlewares/MiddlewareActionsOrganization/MiddlewareActionsOrganization'
import Pair from '../../../Models/Pair';
import ReportsGroup from '../../../Models/Analysis/Report/ReportsGroup';
import Hierarchy from '../../../Models/Organizations/Hierarchy';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import DateType, { GetValueByDateType } from '../../../Models/Analysis/DateType';
import BaseReportIndexAnalysis from '../../../Models/Analysis/Report/BaseReportIndexAnalysis';
import DatesHelper from '../../../Helpers/DatesHelper';

interface IDailyReportReportManager {
    textProvider : ILanguagePhrase
    hierarchy : Hierarchy
    report : Report;
    dateType : DateType
}

interface IDailyReportReportManagerState {
    report : Report
    data : ReportsGroup
    isLoaded : boolean
}

export default class DailyReportReportManager extends BaseContainer<IDailyReportReportManager, IDailyReportReportManagerState> {
    private readonly _middleware : IMiddlewareActionsOrganization = new MiddlewareActionsOrganization();

    constructor(props : IDailyReportReportManager) {
        super(props);
        
        this.state = {
            report : new Report('','','','',0,false,false,''),
            data : new ReportsGroup([],[],[]),
            isLoaded : false,
        }

        this.loadData(props.report);
    }

    componentDidUpdate(prevProps: Readonly<IDailyReportReportManager>): void {
        if (this.props != prevProps)
            this.loadData(this.props.report)
    }

    loadData(report : Report) {
        this._middleware.GetAllIndexeByReportId(report.reportId).then(group => this.updateData(group));
    }

    editArray(arr : BaseReportIndexAnalysis[], [start,end] : [Date, Date]) {
        return arr.map(item => {
            let copy = Object.assign({},item);
            copy.firstDate = start.toISOString();
            copy.secondDate = end.toISOString();
            copy.dateType = DateType.ByDays;
            copy.indexAnalysisCollection = copy.indexAnalysisCollection.filter(value => {
                let date = new Date(value.postTime);
                return date >= start && date <= end;
            });
            return copy;
        })
    }

    updateData(group : ReportsGroup) {
        const [ start, end ] = GetValueByDateType<() => [Date, Date]>(this.props.dateType,
            () => { throw new Error('Not implemented') },
            () => DatesHelper.getCurrentMonth(),
            () => DatesHelper.getCurrentWeek(),
            () => { throw new Error('Not implemented') })();

        group.anxietyReport = this.editArray(group.anxietyReport, [start, end]);
        group.tensityReport = this.editArray(group.tensityReport, [start, end]);
        group.volumeReport =  this.editArray(group.volumeReport,  [start, end]);

        let report = Object.assign<{}, Report>({}, this.props.report);
        report.dateType = DateType.ByDays;
        report.firstDate = start.toISOString();
        report.secondDate = end.toISOString();

        this.changeValueState(new Pair('report', report), new Pair('data', group), new Pair('isLoaded', true));
    }

    render() {
        if (!this.state.isLoaded)
            return <PreloaderCompactDefault />

        return <ReportInfo 
                    textProvider={this.props.textProvider}
                    reportsGroup={this.state.data}
                    report={this.state.report}
                    hierarchy={this.props.hierarchy}/>
    }
}