import IStateContainerCallback from "../IStateContainerCallback"
import IReportsServices from "../../Services/Reports/IReportsServices"
import IReportsActions from "./IReportsActions";
import AddHierarchyAnalysis from "../../Models/Analysis/Add/AddHierarchyAnalysis";
import Report from "../../Models/Analysis/Report";
import ReportInterval from "../../Models/Reports/ReportInterval";
import ReportsServices from "../../Services/Reports/ReportsServices";

class ReportsActions implements IReportsActions {
    private readonly reportsService : IReportsServices;
    constructor(service : IReportsServices | null = null) {
        this.reportsService = service ?? new ReportsServices();
    }

    public async GetDailyReports() : Promise<Report[]> {
        return await this.reportsService.GetDailyReports();
    }

    public async GetReportsAnalysis(stateContainerCallback : IStateContainerCallback) : Promise<void> {
        let reports = await this.reportsService.GetReportsAnalysis();
        if(reports.length)
            stateContainerCallback(reports);
    }

    public async GetReportDateIntervalResponce() : Promise<Array<Report>> {
        return await this.reportsService.GetReportsAnalysis();
    }

    public async GetReportAnalysisByQueryId(queryId : string, stateContainerCallback : IStateContainerCallback) : Promise<void> {
        let report = await this.reportsService.GetReportAnalysis(queryId);
        stateContainerCallback(report);
    }

    public async DeleteReportByReportId(reportId : string) : Promise<void> {
        await this.reportsService.DeleteReport(reportId);
    }

    public async GetReportDateInterval(stateContainerCallback : IStateContainerCallback) : Promise<void> {
        let interval = await this.reportsService.GetReportDateInterval();
        stateContainerCallback.call(null,interval);
    }

    public async GetReportInterval() : Promise<ReportInterval> {
        return await this.reportsService.GetReportDateInterval();
    }

    public async AddReportsHierarchy(addHierarchyReport : AddHierarchyAnalysis) : Promise<void>{
        await this.reportsService.AddReportsHierarchyAllForm(addHierarchyReport);
    }

}

export default ReportsActions;