import React from 'react'

import {IMyPopupBaseBasic, MyPopupBase} from '../../../Components/MyPopupBase/MyPopupBase'
import {IEmployeeInfoResult, EmployeeInfo} from "../../../Components/Admin/EmployeeInfo/EmployeeInfo"
import { PopUpConfirmDefault } from '../../../Components/PopupConfirm/PopupConfirm';

import BaseContainer from '../../BaseContainer';
import EmployeeBaseLocal from '../../../Models/Employees/EmployeeBaseLocal';
import IEmployeeActions from '../../../Actions/EmployeeActions/IEmployeeActions';
import EmployeeActions from '../../../Actions/EmployeeActions/EmployeeActions';
import EmployeeOrganizationInfo from '../../../Models/Employees/EmployeeOrganizationInfo';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import { IRoutesAgent } from '../../../Helpers/Routes/RoutesAgent';
import PopUpMessage from '../../../Components/PopupMessage/PopupMessage';

import './EmployeeAddPopup.css'
import EmployeeAccess from '../../../Enums/EmployeeAccess';

export const inviteSendButtonId = 'EmployeeAddPopupSendInvite',
             inviteCancelButtonId = 'EmployeeAddPopupCancelInvite';

interface IEmployeeAddPopup extends IMyPopupBaseBasic {
    textProvider : ILanguagePhrase
    routesAgent : IRoutesAgent
}

interface IEmployeeAddPopupState {
    forceError : boolean
    result : IEmployeeInfoResult
    errorMessage : string
    linkNewNeeded : boolean
    employeeId : string
}

export default class EmployeeAddPopup extends BaseContainer<IEmployeeAddPopup, IEmployeeAddPopupState>{
    private readonly _employeeActions : IEmployeeActions = new EmployeeActions();

    constructor(props : IEmployeeAddPopup) {
        super(props);
        this.state = {
            forceError : false,
            result: {isSuccess: false, info: new EmployeeBaseLocal()},
            errorMessage : '',
            linkNewNeeded : false,
            employeeId: '',
        }
    }

    onUpdate(result : IEmployeeInfoResult) {
        this.setState(state => {
            return {
                ...state,
                result: result,
            };
        });
    }

    addEmployee() {
        this.setState(state => { return { ...state, forceError: true }})
        if (!this.state.result.isSuccess)
            return;

        this.removeDuplicateDepartmentInfos(this.state.result.info.departmentsInfo);
        this.state.result.info.languageType = this.props.textProvider.lang;
        this._employeeActions.addAsync(this.state.result.info).then(([isSuccess, message]) => {
            if (isSuccess) {
               
                this.setState(state => { return { ...state, linkNewNeeded: true, employeeId: message}});
                return;
            }
            
            this.setState(state => { return { ...state, errorMessage: message }})
        });
    }

    removeDuplicateDepartmentInfos(infos : EmployeeOrganizationInfo[]) {
        for (let i = 0; i < infos.length; i++) {
            for (let j = 0; j < i; j++) {
                if (infos[i].departmentId === infos[j].departmentId &&
                    infos[i].hierarchyId === infos[j].hierarchyId &&
                    infos[i].organizationId === infos[j].organizationId) {
                    
                    infos.splice(i--, 1);
                }
            }
        }
    }

    sendInvite() {
        this._employeeActions.sendInvitationAsync(this.state.employeeId).then(errorMessage => {
            if (!errorMessage) this.props.routesAgent.reload();

            this.setState(state => { return { ...state, errorMessage : errorMessage } } );
        });
    }

    render() {
        let linkNewPopup;
        if (this.state.linkNewNeeded)
            linkNewPopup = <PopUpConfirmDefault 
                                zIndex={this.props.zIndex * 2} closePopup={() => this.props.routesAgent.reload()}
                                confirmButtonId={inviteSendButtonId} cancelButtonId={inviteCancelButtonId}
                                textProvider={this.props.textProvider}
                                text={this.props.textProvider.employeeInviteQuestion}
                                onConfirm={() => this.sendInvite()}/>;

        let errorMessage;
        if (this.state.errorMessage)
            errorMessage = <PopUpMessage 
                                zIndex={this.props.zIndex * 2} 
                                closePopup={() => this.setState(state => { return { ...state, errorMessage: '' } })}
                                header={this.state.errorMessage === this.props.textProvider.specialTariffMaxEmployees 
                                                                ? this.props.textProvider.attention : this.props.textProvider.error}
                                text={this.state.errorMessage}
                                buttonId='EmployeeAddPopupMessagePopupButtonId'/>

        return <MyPopupBase zIndex={this.props.zIndex} closePopup={this.props.closePopup} width={'default'}>
            <p className="Header">{this.props.textProvider.employeeAdd}</p>
            <EmployeeInfo info={this.state.result.info} 
                          forceError={this.state.forceError} 
                          textProvider={this.props.textProvider} 
                          onUpdate={v => this.onUpdate(v)}
                          isCurrentUser={false}/>
            <div className="EmployeeAddPopupButtonsBlock">
                <div onClick={() => this.props.closePopup()}>{this.props.textProvider.cancel}</div>
                <div className="EmployeeAddPopupButtonAdd" onClick={() => this.addEmployee()}>{this.props.textProvider.employeeAdd}</div>
            </div>
            {linkNewPopup}
            {errorMessage}
        </MyPopupBase>
    }
}