import ChartData from "../../Models/Entintys/ChartData";

class GraphicValueScaler {

    public static GetRangeValue(values : ChartData[]) : number
    {
        let maxValue = -Infinity;

        values.forEach(v => v._values.forEach(vInner => {
                if(Math.abs(vInner) > maxValue && vInner !== NaN)
                    maxValue = Math.abs(vInner);
            })
        );

        return Math.round(maxValue + 0.5);
    }
}

export default GraphicValueScaler;