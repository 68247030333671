import EmployeeDepartmentInfo from './EmployeeDepartmentInfo'
import EmployeeType from '../../Enums/EmployeeType';
import Gender from "../../Enums/Gender"
import EmployeeBasic from './EmployeeBasic';
import EmployeeBaseLocal from './EmployeeBaseLocal';
import Language from '../Users/Language';
import EmployeeAccess from '../../Enums/EmployeeAccess';

class EmployeeBaseAPI extends EmployeeBasic {
    constructor(
        employeeId : string = '',
        firstName : string = '', 
        lastName : string = '', 
        phone : string = '', 
        email : string = '', 
        birthDate : string = '', 
        gender : Gender = -1, 
        profession : string = '', 
        position : EmployeeType = EmployeeType.Contributor, 
        workExperienceMonths : number = 0, 
        employmentDate : string = '',
        departmentsInfo : EmployeeDepartmentInfo[] = [],
        languageType : Language = Language.En,
        employeeTypeAccess : EmployeeAccess = EmployeeAccess.NotManager) {

        super(employeeId, firstName, lastName, phone, email, birthDate, gender, profession, position, workExperienceMonths, employmentDate, languageType, employeeTypeAccess);
        
        this.departmentIds = departmentsInfo.map(info => info.departmentId);
    }

    public departmentIds : string[];

    public static fromLocal(model : EmployeeBaseLocal) {
        return new EmployeeBaseAPI(
            model.employeeId,
            model.firstName,
            model.lastName,
            model.phone,
            model.email,
            model.birthDate ? model.birthDate : '0001-01-01',
            model.gender,
            model.profession,
            model.position,
            model.workExperienceMonths,
            model.employmentDate ? model.employmentDate : '0001-01-01',
            model.departmentsInfo,
            model.languageType,
            model.accessTypeEmployee,
        )
    }
}

export default EmployeeBaseAPI;