import React from 'react'

interface IButtonV2 {
    className : string
    text : string
    action : () => void
}

export default function ButtonV2(props: IButtonV2) {
    return <button 
                className={props.className} 
                onClick={() => props.action()}>{props.text}</button>
}