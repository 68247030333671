import RequestSignature from "../RequestProvider/RequestSignature";

class EmployeeServiceConstants {

    //public static readonly _registrationRequestSignature:RequestSignature=new RequestSignature("POST","/api/v1.0/Account/Registration");

    public static readonly _getEmployeesInfoSignature = new RequestSignature('GET', '/api/v1.0/Employee/GetEmployeesInfo')

    public static readonly _getEmployeeInfoSignature = new RequestSignature('GET', '/api/v1.1/Employee/GetEmployee')

    public static readonly _sendInvitationSignature = new RequestSignature('POST', '/api/v1.0/Employee/SendInvitation');

    public static readonly _updateSignature = new RequestSignature('PUT', '/api/v1.0/Employee/UpdateEmployeeWithLinks');

    public static readonly _deleteSignature = new RequestSignature('DELETE', '/api/v1.0/Employee/DeleteEmployee');

    public static readonly _getOrganizationsInfoByUser = new RequestSignature('GET', '/api/v1.0/Employee/GetOrganizationsInfoByUser');

    public static readonly _addEmployee = new RequestSignature('POST', '/api/v1.0/Employee/AddAndLinkEmployee')
}

export default EmployeeServiceConstants;