import { Euro } from '../../Helpers/Currencies';
import Currency from '../Currencies/Currency';
import BalanceIncomeRecordAPI from './BalanceIncomeRecordAPI';
import BalanceRecord from './BalanceRecord'

export default class BalanceIncomeRecord extends BalanceRecord {
    constructor(proto : BalanceIncomeRecordAPI = new BalanceIncomeRecordAPI(), currency : Currency = Euro) {
        super(proto, currency)

        this.trialBalance = proto.trialBalance;
        this.personalBalance = proto.personalBalance;
    }

    public trialBalance : number;

    public personalBalance : number;
}