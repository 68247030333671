class DepartmentBase {

    constructor(id : string ,name : string, numberDep : number) {
        this.id = id;
        this.name = name;
        this.numberDepartment = numberDep;
    }

    public id : string;

    public name : string;


    public numberDepartment : number;

}

export default DepartmentBase;