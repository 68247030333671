import React from 'react'

import {SelectV2, SelectV2Props} from '../SelectV2/SelectV2'

import Pair from '../../Models/Pair'
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import { ITextProvider, Languages } from '../../Helpers/TextProvider/TextProvider'

import './LanguageChangeComponent.css'

interface ILanguageChangeComponent {
    vertical : 'fit' | 'fill'

    textProvider : ITextProvider
    currentLanguage : ILanguagePhrase
    changeLanguage(iso : string) : void
    id : string
}

export default function LanguageChangeComponent(props : ILanguageChangeComponent) {
    return SelectV2(new SelectV2Props(
        props.currentLanguage.interfaceLanguage,
        false,
        '',
        props.currentLanguage.iso,
        Languages.map(l => new Pair(l.iso, l.languageName)),
        true,
        v => props.changeLanguage(v),
        false, props.vertical, props.id));
}