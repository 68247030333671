import React from 'react'

import BaseContainer from '../../BaseContainer';
import CRMOrganizationSettingsUI from './CRMOrganizationSettingsUI';

import CRMOrganizationInfo from '../../../Models/CRM/CRMOrganizationInfo';
import Entity from '../../../Models/Organizations/Entity';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';
import UserInfo from '../../../Models/Users/UserInfo';

import { CRMActions, ICRMActions } from '../../../Actions/CRMActions';
import ILocalStorageAgent from '../../../LocalStorageWorker/ILocalStorageAgent';
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';
import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import { ITokenParser, TokenParser } from '../../../Helpers/TokenParser';
import { IRoutesAgent, RoutesAgent } from '../../../Helpers/Routes/RoutesAgent';

import IOrganizationAction from '../../../Actions/OrganizationActions/IOrganizationAction';
import IUserAction from '../../../Actions/UserActions/IUserAction';
import OrganizationActions from '../../../Actions/OrganizationActions/OrganizationActions';
import UserActions from '../../../Actions/UserActions/UserActions';
import BaseUser from '../../../Models/Users/BaseUser';
import PaymentInfoBase from '../../../Models/Payment/PaymentInfoBase';

interface ICRMOrganizationSettings {
    tokenParser : ITokenParser | null
    localStorageAgent : ILocalStorageAgent | null
    routesAgent : IRoutesAgent | null
    textProvider : ITextProvider | null

    crmActions : ICRMActions | null
    organizationActions : IOrganizationAction | null
    userActions : IUserAction | null
}

interface ICRMOrganizationSettingsState {
    isLoaded : boolean

    errorMessage : string
    goBackNeeded : boolean

    info : CRMOrganizationInfo
}

export default class CRMOrganizationSettings extends BaseContainer<ICRMOrganizationSettings, ICRMOrganizationSettingsState> {
    private readonly _tokenParser : ITokenParser;
    private readonly _localStorageAgent : ILocalStorageAgent
    private readonly _routesAgent : IRoutesAgent;
    private readonly _language : ILanguagePhrase;
    
    private readonly _crmActions : ICRMActions;
    private readonly _organizationActions : IOrganizationAction;
    private readonly _userActions : IUserAction;

    constructor(props : ICRMOrganizationSettings) {
        super(props);
        
        this._tokenParser = props.tokenParser ?? new TokenParser();
        this._localStorageAgent = props.localStorageAgent ?? new LocalStorageAgent();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._language = (props.textProvider ?? new TextProvider()).GetLang();
        
        this._crmActions = props.crmActions ?? new CRMActions();
        this._organizationActions = props.organizationActions ?? new OrganizationActions();
        this._userActions = props.userActions ?? new UserActions();

        this.state = {
            isLoaded : false,
            
            errorMessage : '',
            goBackNeeded: false,

            info: new CRMOrganizationInfo(),
        }
    }

    componentDidMount() {
        const id = this._routesAgent.getArgFromQuery(RoutesUrl.idKey);
        if (!id) { this._routesAgent.navigateTo(RoutesUrl.CRMOrganizations); return; }

        this._crmActions.getCompanyInfoAsync(id).then(result => {
            this.setState(state => {
                if (typeof result === 'string') return { ...state, isLoaded: true, errorMessage: result, goBackNeeded: true };
                else return { ...state, isLoaded: true, info: result };
            });
        });
    }

    onUserInfoUpdate(info : UserInfo) : Promise<boolean | undefined> {
        const copy = Object.assign<{}, BaseUser>({}, this.state.info.adminInfo);
        BaseUser.edit(copy, info.firstName, info.lastName, info.email, info.phone);

        return this._userActions.UpdateUserInfo(copy).then(result => {
            if (!result) {
                const mainInfo = this.state.info;
                mainInfo.adminInfo = copy;
                this.setState(state => { return { ...state, info: mainInfo } })
                return true;
            }
            
            this.setState(state => { return { ...state, errorMessage: result, goBackNeeded: false } });
            return false;
        })
    }

    onCompanyInfoUpdate(info : Entity) : Promise<boolean | undefined> {
        const copy = Object.assign<{}, Entity>({}, this.state.info.companyInfo);
        Entity.edit(copy, info.phone, info.officialWebsite, info.numberOfEmployees, Entity.getPaymentInfo(info));

        return this._organizationActions.UpdateEntityAsync(copy).then(_ => {
            const mainInfo = this.state.info;
            mainInfo.companyInfo = copy;
            this.setState(state => { return { ...state, info: mainInfo} });
            return true;
        });
    }
    
    onErrorClose() : void {
        if (this.state.goBackNeeded) this._routesAgent.navigateTo(RoutesUrl.CRMOrganizations);

        this.setState(state => { return { ...state, errorMessage: '' } })
    }

    onDelete() {
        this._crmActions.deleteCompanyAsync(this.state.info.companyInfo.organizationId).then(errorMessage => {
            if (!errorMessage) this._routesAgent.navigateTo(RoutesUrl.CRMOrganizations);

            this.setState(state => { return { ...state, goBackNeeded: false, errorMessage: errorMessage } });
        })
    }

    render() {
        return <CRMOrganizationSettingsUI 
                    tokenParser={this._tokenParser}
                    localStorageAgent={this._localStorageAgent}
                    language={this._language}
                    routesAgent={this._routesAgent}
                    isLoaded={this.state.isLoaded}
                    errorMessage={this.state.errorMessage}
                    info={this.state.info}
                    onUserInfoUpdate={info => this.onUserInfoUpdate(info)}
                    onCompanyInfoUpdate={info => this.onCompanyInfoUpdate(info)}
                    onErrorClose={() => this.onErrorClose()}
                    onDelete={() => this.onDelete()}/>
    }
}