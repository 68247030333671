import React, { useState } from 'react'

import { IMyPopupBase, MyPopupBase } from '../../../Components/MyPopupBase/MyPopupBase'
import { SelectV2Base, SelectV2BaseProps } from '../../../Components/SelectV2/SelectV2Base'

import CurrencyProvider, { Euro } from '../../../Helpers/Currencies'
import Entity from '../../../Models/Organizations/Entity'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import PaymentInfo from '../../../Models/Payment/PaymentInfo'

import Validator from '../../../Helpers/Validator/Validator'
import { InputV2, InputV2Props } from '../../../Components/InputV2/InputV2'
import Language, { getValueByLang } from '../../../Models/Users/Language'
import Pair from '../../../Models/Pair'
import PaymentInfoBase from '../../../Models/Payment/PaymentInfoBase'
import BaseContainer from '../../BaseContainer'

import IOrganizationAction from '../../../Actions/OrganizationActions/IOrganizationAction'
import OrganizationActions from '../../../Actions/OrganizationActions/OrganizationActions'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'

import './BalanceUI.css'
import './RechargeBalancePopup.css'

interface IRechargeBalancePopup extends IMyPopupBase {
    textProvider : ILanguagePhrase
    onResult(info : PaymentInfo) : void
}

interface IRechargeBalancePopupView extends IRechargeBalancePopup {
    paymentInfo : PaymentInfoBase
    isLoaded : boolean
}

interface IRechargeBalancePopupState {
    paymentInfo : PaymentInfoBase | undefined
    isLoaded : boolean
}

export default class RechargeBalancePopupState extends BaseContainer<IRechargeBalancePopup, IRechargeBalancePopupState> {
    private readonly _organizationActions : IOrganizationAction = new OrganizationActions();
    private readonly _infoCompanyPromise : Promise<Entity | undefined>;

    constructor(props : IRechargeBalancePopup) {
        super(props);
        this.state = {
            paymentInfo : undefined,
            isLoaded : false,
        }

        this._infoCompanyPromise = this._organizationActions.GetEntitiesAsyncResponce().then(res => res.length === 0 ? undefined : res[0]);
    }

    getInfo(result : Entity | undefined) {
        if (!result) return undefined;

        return new PaymentInfoBase(result.name, result.country, result.address1, result.address2, result.city, result.zipCode, result.nationalTaxId)
    }

    componentDidMount() {
        this._infoCompanyPromise.then(result => {
            this.changeValueState(new Pair('paymentInfo', this.getInfo(result)), new Pair('isLoaded', true))
        })
    }

    render() {
        if (!this.state.isLoaded)
            return <PreloaderCompactDefault />

        return <RechargeBalancePopupView 
                    zIndex={this.props.zIndex}
                    closePopup={() => this.props.closePopup()}
                    width={'default'}
                    textProvider={this.props.textProvider}
                    onResult={info => this.props.onResult(info)}
                    paymentInfo={this.state.paymentInfo ?? new PaymentInfoBase('','','','','','','')}
                    isLoaded={this.state.isLoaded}/>
    }
}

function RechargeBalancePopupView(props : IRechargeBalancePopupView) {
    const [ forceError, forceErrorChange ] = useState(false);
    const [ companyName, companyNameChange ] = useState(props.paymentInfo.companyName)
    const [ country, countryChange ] = useState(props.paymentInfo.country);
    const [ address1, address1Change ] = useState(props.paymentInfo.address1);
    const [ address2, address2Change ] = useState(props.paymentInfo.address2);
    const [ city, cityChange ] = useState(props.paymentInfo.city);
    const [ zipCode, zipCodeChange ] = useState(props.paymentInfo.zipCode);
    const [ taxId, taxIdChange ] = useState(props.paymentInfo.nationalTaxId);
    const [ amountStr, amountStrChange ] = useState('')
    const [ currentCurrencyCode, currentCurrencyCodeChange ] = useState(Euro.code);

    const checkCompanyName = () => !Validator.isNullOrWhitespace(companyName);
    const checkCountry = () => !Validator.isNullOrWhitespace(country);
    const checkAddress1 = () => !Validator.isNullOrWhitespace(address1);
    const checkCity = () => !Validator.isNullOrWhitespace(city);
    const checkZipCode = () => !Validator.isNullOrWhitespace(zipCode);
    const checkAmount = () => !Validator.isNullOrWhitespace(amountStr) && !Number.isNaN(Number(amountStr)) && Number(amountStr) >= 5;
    
    const checkAll = () => 
        checkCompanyName() && 
        checkCountry()     && 
        checkAddress1()    &&
        checkCity()        && 
        checkZipCode()     &&
        checkAmount();

    const onPayClicked = () => {
        forceErrorChange(true);
        if (!checkAll()) return;

        let baseInfo = new PaymentInfoBase(companyName, country, address1, address2, city, zipCode, taxId);
        let amount = Math.floor(Number(amountStr) * 100);
        props.onResult(new PaymentInfo(baseInfo, amount, currentCurrencyCode));
    }

    if (!props.isLoaded) {
        return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={'default'}>
            <PreloaderCompactDefault />
        </MyPopupBase>
    }

    const en = 'en';
    const lang = getValueByLang<string>(props.textProvider.lang, en, 'fr', en);
    const docLink = `https://whyeva.com/${lang}/docs.html`;
    return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={'default'}>
        <div className="RechargeBalancePopupContentContainer">
            <p className="SubHeader">{props.textProvider.balanceRecharge}</p>
            <p className="Prompt">1. {props.textProvider.paymentEnterBillingAddress}</p>
            <div className='SettingsVertical'>
                <div>
                    {InputV2(new InputV2Props(
                        props.textProvider.companyName,
                        forceError,
                        props.textProvider.uncorrectCompanyName,
                        companyName,
                        checkCompanyName(),
                        v => companyNameChange(v),
                        'text', false, 'fit'))}
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentCountry,
                        forceError,
                        props.textProvider.paymentEnterCountry,
                        country,
                        checkCountry(),
                        v => countryChange(v),
                        'text', false, 'fit'))}
                </div>
                <div>
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentAddress1,
                        forceError,
                        props.textProvider.paymentEnterAddress,
                        address1,
                        checkAddress1(),
                        v => address1Change(v),
                        'text', false, 'fit'))}
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentAddress2,
                        forceError,
                        '',
                        address2,
                        true,
                        v => address2Change(v),
                        'text', false, 'fit'))}
                </div>
                <div>
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentCity,
                        forceError,
                        props.textProvider.paymentEnterCity,
                        city,
                        checkCity(),
                        v => cityChange(v),
                        'text', false, 'fit'))}
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentZipCode,
                        forceError,
                        props.textProvider.paymentEnterZipCode,
                        zipCode,
                        checkZipCode(),
                        v => zipCodeChange(v),
                        'text', false, 'fit'))}
                </div>
                <div>
                    {InputV2(new InputV2Props(
                        props.textProvider.paymentTaxIdNumber,
                        forceError,
                        '',
                        taxId,
                        true,
                        v => taxIdChange(v),
                        'text', false, 'fit'))}
                </div>
            </div>
            <p className="Prompt">2. {props.textProvider.paymentEnterPaymentAmount}</p>
            <div className="RechargeBalancePopupRow">
                {InputV2(new InputV2Props(
                    props.textProvider.balancePaymentAmount,
                    forceError,
                    props.textProvider.paymentEnterPaymentAmount,
                    amountStr,
                    checkAmount(),
                    v => amountStrChange(v),
                    'text', false, 'fit'))}
                {SelectV2Base(new SelectV2BaseProps(
                    currentCurrencyCode,
                    CurrencyProvider.Currencies.map(c => new Pair(c.code, c.symbol)),
                    false,
                    v => currentCurrencyCodeChange(v)))}
            </div>
            <div className="RechargeBalancePopupBottomContainer">
                <a className = "RechargeBalancePopupLink Prompt" target = "_blank" href = {docLink}>{props.textProvider.termsOfSale}</a>
                <div className="ButtonsContainer">
                    <button className="SecondaryButton" onClick={() => props.closePopup()}>{props.textProvider.cancel}</button>
                    <button className="PrimaryButton" onClick={() => onPayClicked()}>{props.textProvider.balancePay}</button>
                </div>
            </div>
        </div>
    </MyPopupBase>
}