import IAnxietyAnalysisService from "./IAnxietyAnalysisService";
import BaseService from "../BaseService";
import ReportAnxietyAnalysis from "../../Models/Analysis/Report/ReportAnxietyAnalysis";
import AnxietyAnalysisConstants from "./AnxietyAnalysisConstants";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";
import RequestSettings from "../RequestProvider/RequestSettings";
import ReportEmotionalAnalysis from "../../Models/Analysis/Report/ReportEmotionalAnalysis";
import IRequestProvider from "../RequestProvider/IRequestProvider";

class SentimentAnalysisService extends BaseService implements IAnxietyAnalysisService{
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async GetReportsAnxiety(reportId : string) : Promise<ReportAnxietyAnalysis[]>{
        const requestSettings = new RequestSettings
            (AnxietyAnalysisConstants.GetAnxietyCollectionAnalysis,
            new Dictionary(new Pair("reportId", reportId)), null);
            
        let result : ReportAnxietyAnalysis[] = [];
        await this.RequestProvider.Request(requestSettings, new Dictionary(
            new Pair(200, r => result = r),
            new Pair(204, _ => result = []),
        ));

        return result;
    }
    
    public async GetReportsEmotional(reportId : string) :Promise<ReportEmotionalAnalysis[]>{
        const requestSettings = new RequestSettings(AnxietyAnalysisConstants.GetEmotionalCollectionAnalysis, new Dictionary(new Pair("reportId", reportId)), null);
        
        let result : ReportEmotionalAnalysis[] = [];
        await this.RequestProvider.Request(requestSettings, new Dictionary(
            new Pair(200, r => result = r as ReportEmotionalAnalysis[]),
            new Pair(204, _ => result = [])
        ));
        
        return result;
    }
}

export default SentimentAnalysisService;