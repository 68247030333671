import React, { ChangeEvent } from 'react'

import BaseContainer from '../../Containers/BaseContainer'
import { DecoratorContainer, HeaderedComponent, UnderlinedComponent, ErrorComponent } from '../Decorators/Decorators'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';

import './MonthSelector.css'

interface IMonthSelector {
    prompt : string;
    textProvider : ILanguagePhrase;
    months : number;
    onChange : (months: number) => void;
    isReadonly : Boolean;
    vertical : 'fit' | 'fill';
} 

interface IMonthSelectorState {
    months: number,
    years: number,
}

export default class MonthSelector extends BaseContainer<IMonthSelector, IMonthSelectorState> {
    constructor(props: IMonthSelector) {
        super(props);
        this.state = {
            months: this.getMonthsFromMonths(props.months),
            years: this.getYearsFromMonths(props.months),
        }
    }

    getMonthsFromMonths = (v : number) => Math.floor(v % 12);

    getYearsFromMonths = (v : number) => Math.floor(v / 12);

    toMonths = (months : number, years: number) => months + (years * 12);

    getMonths() {
        let m = [];
        for (let i = 0; i < 12; i++)
            m.push(i);

        return m;
    }

    getYears() {
        let y = [];
        for (let i = 0; i <= 90; i++)
            y.push(i);

        return y;
    }

    onMonthSeleted(v : number) {
        this.setState(state => {
            return {
                ...state,
                months: v
            };
        });

        this.props.onChange(this.toMonths(v, this.state.years))
    }

    onYearsSelected(v : number) {
        this.setState(state => {
            return {
                ...state,
                years: v
            };
        });

        this.props.onChange(this.toMonths(this.state.months, v))
    }

    getSelector(options : number[], selected: number, className : string, onSelected : (v : number) => void) {
        const changeHandler : (e :ChangeEvent<HTMLSelectElement>) => void = e => onSelected(Number(e.currentTarget.children[e.currentTarget.selectedIndex].innerHTML));
        return <select 
                    className={className} 
                    onChange={changeHandler}>{options.map(v => <option selected={v === selected}>{v}</option>)}</select>
    }

    render() {
        let selectorClass = this.props.isReadonly ? "MonthSelectorSelectorReadonly" : "MonthSelectorSelector";

        return <DecoratorContainer>
            <HeaderedComponent text={this.props.prompt} verticalAlign={this.props.vertical}/>
            <div className="MonthSelectorInner">
                {this.getSelector(this.getMonths(), this.state.months, selectorClass, v => this.onMonthSeleted(v))}
                <span className="MonthSelectorSpan">{this.props.textProvider.months}</span>
                {this.getSelector(this.getYears(), this.state.years, selectorClass, v => this.onYearsSelected(v))}
                <span className="MonthSelectorSpan">{this.props.textProvider.years}</span>
            </div>
            <UnderlinedComponent />
            <ErrorComponent text=' '/>
        </DecoratorContainer>
    }
}