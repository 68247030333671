import React from "react";

import "./Footer.css";

interface IFooter{
    text : string
}

function Footer(props : IFooter) {
    return(<div className = "footer">
                {props.text}
           </div>);
}

export default Footer;