import React from "react";

import "./ChartPrompt.css";

interface IProps {
    color : string
    text : string
}

function ChartPrompt(props : IProps) {
    return <div className = "chartPrompt">
                <div style = {{background : props.color}}></div>
                <div>{props.text}</div>
            </div>
}

export default ChartPrompt;