import PaymentInfo from './PaymentInfo'

export default class PaymentInfoAPI {
    constructor(proto : PaymentInfo) {
        this.companyName = proto.companyName;
        this.country = proto.country;
        this.address1 = proto.address1;
        this.address2 = proto.address2;
        this.city = proto.city;
        this.zipCode = proto.zipCode;
        this.nationalTaxId = proto.nationalTaxId;
        this.amount = proto.amount;
        this.currencySymbol = proto.currencyCode;
    }

    public companyName : string;
    
    public country : string;

    public address1 : string;

    public address2 : string;

    public city : string;

    public zipCode : string;

    public nationalTaxId : string;

    public amount : number;

    public currencySymbol : string;
}