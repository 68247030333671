import React, {useState} from 'react'

import { DecoratorContainer, HeaderedComponent, ErrorComponent, ImagedComponent, UnderlinedComponent } from '../Decorators/Decorators'

import InputV2Base from './InputV2Base'
import { InputV2Props, getInputV2HeaderText, getInputV2ErrorText } from './InputV2'
import { IImagedComponent } from '../Decorators/ImagedComponent';

interface IInputV2 {
    inputProps : InputV2Props
    imgProps : IImagedComponent
}

export default function InputV2(props : IInputV2) {
    const [ hadChanged, hadChangedChange ] = useState(false)

    let headerText = getInputV2HeaderText(props.inputProps.type, props.inputProps.value, props.inputProps.prompt);
    let errorText = props.inputProps.forceError || hadChanged ? getInputV2ErrorText(props.inputProps.isReadonly, props.inputProps.isValid, props.inputProps.errorPrompt) : '';
    
    const valueChanged = (v : string) => {
        hadChangedChange(true);
        props.inputProps.onChange(v);
    }

    let propsBase = Object.assign({}, props.inputProps)
    propsBase.onChange = v => valueChanged(v);

    return <DecoratorContainer>
        <HeaderedComponent text={headerText} verticalAlign={props.inputProps.vertical} />
        <ImagedComponent imgPosition={props.imgProps.imgPosition} imgSrc={props.imgProps.imgSrc} imgClicked={props.imgProps.imgClicked}>
            {InputV2Base(propsBase)}
        </ImagedComponent>
        <UnderlinedComponent />
        <ErrorComponent text={errorText} />
    </DecoratorContainer>
}