import CRMOrganization from '../Models/CRM/CRMOrganization'
import CRMOrganizationInfo from '../Models/CRM/CRMOrganizationInfo'

import { ICRMService, CRMService } from '../Services/CRM/CRMService';

export interface ICRMActions {
    /**
     * Gets list of availiable companies
     * @returns @see CRMOrganization array if ok otherwise @see string with error message
     */
     getCompaniesAsync(pageNumber : number) : Promise<CRMOrganization[] | string>;

     /**
      * Get company's info by it's organization id
      * @returns @see CRMOrganizationInfo if ok otherwise @see string with error message
      */
     getCompanyInfoAsync(organizationId : string) : Promise<CRMOrganizationInfo | string>;

     /**
     * Deletes company and all it's info
     * @param organizationId 
     * @returns error message or an empty string if ok
     */
    deleteCompanyAsync(organizationId : string) : Promise<string>; 
}

export class CRMActions implements ICRMActions {
    private readonly _crmService : ICRMService;
    constructor(crmService : ICRMService | null = null) {
        this._crmService = crmService ?? new CRMService();
    }

    public async getCompaniesAsync(pageNumber: number): Promise<string | CRMOrganization[]> {
        return await this._crmService.getCompaniesAsync(pageNumber);
    }

    public async getCompanyInfoAsync(organizationId: string): Promise<string | CRMOrganizationInfo> {
        return await this._crmService.getCompanyInfoAsync(organizationId);
    }

    public async deleteCompanyAsync(organizationId: string): Promise<string> {
        return await this._crmService.deleteCompanyAsync(organizationId);
    }
}