import EntityTypes from "./EntityTypes";
import NumberOfEmployees from "./NumberOfEmployees";
import ProfessionalSectorType from "./ProfessionalSectorType";
import Organization from "./Organization";

import DirectionOfActivityEntity from "./DirectionOfActivityEntity";
import PaymentInfoBase from "../Payment/PaymentInfoBase";

class Entity extends Organization {
    constructor(
        entityType : EntityTypes = EntityTypes.Company,
        causeOfRegistration : string = '',
        howHeardAboutUs: string = '',
        phone : string = '',
        officialWebsite : string = '',
        numberOfEmployees : NumberOfEmployees = NumberOfEmployees.From20To49,
        professionalSectorType : ProfessionalSectorType = ProfessionalSectorType.Private,
        directionOfActivity : DirectionOfActivityEntity = DirectionOfActivityEntity.commercialCompany,
        companyName : string = '',
        country : string = '',
        address1 : string = '',
        address2 : string = '',
        city : string = '',
        zipCode : string = '',
        nationalTaxId : string = '',
        hierarchyIds : string[] = [],
        orgId : string = "",
        dateRegistration : string = '0001-01-01') {
        
        super(orgId, phone, officialWebsite, numberOfEmployees, professionalSectorType, hierarchyIds)

        this.entityType = entityType;
        this.howHeardAboutUs = howHeardAboutUs;
        this.causeOfRegistration = causeOfRegistration;
        this.directionOfActivity = directionOfActivity;
        this.name = companyName;
        this.country = country;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.zipCode = zipCode;
        this.nationalTaxId = nationalTaxId;
        this.dateRegistration = dateRegistration;
    }

    public static edit(item : Entity,
        phone : string,
        officialWebsite : string,
        numberOfEmployeesString : NumberOfEmployees,
        paymentInfo : PaymentInfoBase) {
        
        item.phone = phone;
        item.officialWebsite = officialWebsite;
        item.numberOfEmployees = numberOfEmployeesString;
        item.name = paymentInfo.companyName;
        item.country = paymentInfo.country;
        item.address1 = paymentInfo.address1;
        item.address2 = paymentInfo.address2;
        item.city = paymentInfo.city;
        item.zipCode = paymentInfo.zipCode;
        item.nationalTaxId = paymentInfo.nationalTaxId;
    }

    public static base(id : string) : Entity {
        return new Entity(0,'','','','',0,0,0,'','','','','','','',[],id,'');
    }

    public entityType : EntityTypes;

    public causeOfRegistration : string

    public howHeardAboutUs: string

    public directionOfActivity : DirectionOfActivityEntity

    public convertEmployeesNumber(valueString : string) {
        return NumberOfEmployees[Number(valueString)];
    }
    
    public static getPaymentInfo(item : Entity) {
        return new PaymentInfoBase(item.name, item.country, item.address1, item.address2, item.city, item.zipCode, item.nationalTaxId);
    }

    public name : string;

    public country : string;

    public address1 : string;

    public address2 : string;

    public city : string;

    public zipCode : string;

    public nationalTaxId : string;

    public dateRegistration: string;
}

export default Entity;