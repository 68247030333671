import React, { ChangeEvent } from 'react'

import { DecoratorContainer, ErrorComponent, UnderlinedComponent } from '../../Decorators/Decorators'

import EmployeeOrganizationInfo from '../../../Models/Employees/EmployeeOrganizationInfo'
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase"
import OrganizationInfo from '../../../Models/OrganizationReport/OrganizationInfo'

import './EmployeeInfoDepartmentsPanel.css'

interface IEmployeeInfoDepartmentsPanel {
    textProvider: ILanguagePhrase;
    
    organizationTopologies : OrganizationInfo[];
    employeeOrganizationInfos : EmployeeOrganizationInfo[];

    checkLinked(infos : EmployeeOrganizationInfo[]) : boolean;

    itemEdited(info : EmployeeOrganizationInfo, index : number) : void;
    itemAdded(info : EmployeeOrganizationInfo) : void;
    itemRemoved(index : number) : void;
}

export default function EmployeeInfoDepartmentsPanel(props: IEmployeeInfoDepartmentsPanel) {
    function getItemsTemplate(organizationTopologies : OrganizationInfo[], employeeOrganizationInfos : EmployeeOrganizationInfo[]) {
        let result : JSX.Element[][] = [];

        for (let i = 0; i < employeeOrganizationInfos.length; i++) {
            result.push(getItemTemplate(organizationTopologies, employeeOrganizationInfos[i], i));
        }

        return result;
    }

    function getItemTemplate(organizationTopologies : OrganizationInfo[], employeeOrganizationInfo : EmployeeOrganizationInfo, index : number) {
        let hierarchyOptions : JSX.Element[] = [];
        let departmentOptions : JSX.Element[] = [];

        let organization = organizationTopologies.find(t => t.id === employeeOrganizationInfo.organizationId);
        if (organization) {
            hierarchyOptions = organization.hierarchies.map(h => <option id={h.id} selected={h.id === employeeOrganizationInfo.hierarchyId}>{h.name}</option>);

            let hierarchy = organization.hierarchies.find(h => h.id === employeeOrganizationInfo.hierarchyId);
            if (hierarchy)
                departmentOptions = hierarchy.departments.map(d => <option id={d.id} selected={d.id === employeeOrganizationInfo.departmentId}>{d.name}</option>)
        }

        const SelectClass = 'EmployeeInfoDepartmentsPanelSelect';

        let organizationSelect = <select className={SelectClass} onChange={e => organizationUpdated(e, index)}>
            <option></option>
            {organizationTopologies.map(t => <option id={t.id} selected={t.id === employeeOrganizationInfo.organizationId}>{t.name}</option>)};
        </select>;
        
        let hierarchySelect = <select className={SelectClass} onChange={e => hierarchyUpdated(e, index)}>
            <option></option>
            {hierarchyOptions}
        </select>

        let departmentSelect = <select className={SelectClass} onChange={e => departmentUpdated(e, index)}>
            <option></option>
            {departmentOptions}
        </select>

        let items = [
            organizationSelect,
            hierarchySelect,
            departmentSelect
        ];
        return items.map(i => <DecoratorContainer>{i}<UnderlinedComponent /></DecoratorContainer>);
    }

    function organizationUpdated(e : ChangeEvent<HTMLSelectElement>, index : number) {
        let element = (e.target as HTMLSelectElement)[(e.target as HTMLSelectElement).selectedIndex];
        let item = props.employeeOrganizationInfos[index];
        item.organizationId = element.id;
        item.organizationName = element.innerText;

        fillHierarchyInfo(item);
        props.itemEdited(item, index);
    }

    function hierarchyUpdated(e : ChangeEvent<HTMLSelectElement>, index : number) {
        let element = (e.target as HTMLSelectElement)[(e.target as HTMLSelectElement).selectedIndex];
        let item = props.employeeOrganizationInfos[index];
        item.hierarchyId = element.id;
        item.hierarchyName = element.innerText;

        fillDepartmentInfo(item);
        props.itemEdited(item, index);
    }

    function departmentUpdated(e : ChangeEvent<HTMLSelectElement>, index : number) {
        let element = (e.target as HTMLSelectElement)[(e.target as HTMLSelectElement).selectedIndex];   
        let item = props.employeeOrganizationInfos[index];
        item.departmentId = element.id;
        item.departmentName = element.innerText;

        props.itemEdited(item, index);
    }

    function addItem() {
        let info = new EmployeeOrganizationInfo();
        fillOrganizationInfo(info);

        props.itemAdded(info);
    }

    function fillOrganizationInfo(info : EmployeeOrganizationInfo) {
        if (props.organizationTopologies.length !== 1)
            return;
        
        let organization = props.organizationTopologies[0];
        info.organizationId = organization.id;
        info.organizationName = organization.name;

        fillHierarchyInfo(info);
    }

    function fillHierarchyInfo(info : EmployeeOrganizationInfo) {
        let organization = props.organizationTopologies.find(o => o.id === info.organizationId);
        if (!organization || organization.hierarchies.length !== 1) {
            info.hierarchyId = info.hierarchyName = info.departmentId = info.departmentName = '';
            return;
        }

        let hierarchy = organization.hierarchies[0];
        info.hierarchyId = hierarchy.id;
        info.hierarchyName = hierarchy.name;

        fillDepartmentInfo(info);
    }

    function fillDepartmentInfo(info : EmployeeOrganizationInfo) {
        let organization = props.organizationTopologies.find(o => o.id === info.organizationId);
        if (!organization)
            return;

        let hierarchy = organization.hierarchies.find(h => h.id == info.hierarchyId);
        if (!hierarchy || hierarchy.departments.length !== 1) {
            info.departmentId = info.departmentName = '';
            return;
        }

        let department = hierarchy.departments[0];
        info.departmentId = department.id;
        info.departmentName = department.name;
    }

    function removeItem(index : number) {
        props.itemRemoved(index);
    }

    let templates = getItemsTemplate(props.organizationTopologies, props.employeeOrganizationInfos);

    return <div className="EmployeeInfoDepartmentsPanelContainer">
        <table className="EmployeeInfoDepartmentsPanelTable">
            <tbody>
                <tr>
                    {templates.map(t => <td>{t[0]}</td>)}
                    <td rowSpan={3}><button className='EmployeeInfoDepartmentsPanelAddButton' onClick={() => addItem()}>{props.textProvider.employeeAddDepartment}</button></td>
                </tr>
                <tr>
                    {templates.map(t => <td>{t[1]}</td>)}
                </tr>
                <tr>
                    {templates.map(t => <td>{t[2]}</td>)}
                </tr>
                <tr>
                    {templates.map((_, i) => <td align='right'><button className="EmployeeInfoDepartmentsPanelRemoveButton" onClick={() => removeItem(i)}>{props.textProvider.delete}</button></td>)}
                </tr>
            </tbody>
        </table>
        <ErrorComponent text={!props.checkLinked(props.employeeOrganizationInfos) ? props.textProvider.employeeMustHaveDepartment : ""}/>
    </div>
}