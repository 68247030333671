import IReportsServices from "./IReportsServices";
import BaseService from "../BaseService";
import RequestSettings from "../RequestProvider/RequestSettings";
import ReportsConstants from "./ReportsConstants";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";
import ReportInterval from "../../Models/Reports/ReportInterval";
import AddHierarchyAnalysis from "../../Models/Analysis/Add/AddHierarchyAnalysis";
import Report from "../../Models/Analysis/Report";

import IRequestProvider from "../RequestProvider/IRequestProvider";

class ReportsServices extends BaseService implements IReportsServices {
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async GetDailyReports() : Promise<Report[]> {
        const settings = new RequestSettings(ReportsConstants.CurrentReports, new Dictionary(), null);
        let result : Report[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as Report[]),
            new Pair(204, _ => { })
        ));

        return result;
    }

    public async GetReportsAnalysis() : Promise<Array<Report>> {
        const settings = new RequestSettings(ReportsConstants.GetReports, new Dictionary(), null)
        let result : Report[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as Report[]),
            new Pair(204, _ => { })
        ));

        return result;
    }

    public async GetReportAnalysis(queryId : string) : Promise<Report> {
        const settings = new RequestSettings(ReportsConstants.GetReport, new Dictionary(new Pair("queryId", queryId)), null);
        let report = new Report();

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => report = r as Report),
        ));

        return report;
    }

    public async DeleteReport(reportId :string) : Promise<void> {
        const settings = new RequestSettings(ReportsConstants.DeleteReport, new Dictionary(new Pair("reportId",reportId)), null)

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { })
        ));
    }

    public async GetReportDateInterval() : Promise<ReportInterval> {
        const settings = new RequestSettings(ReportsConstants.GetReportInterval, new Dictionary(), null)
        let result : ReportInterval = new ReportInterval();

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as ReportInterval)
        ));

        return result;
    }

    public async AddReportsHierarchyAllForm(addHierarchyAnalysis : AddHierarchyAnalysis) : Promise<string> {
        const settings = new RequestSettings(ReportsConstants.AddReportsHierarchyAllForm, new Dictionary(), JSON.stringify(addHierarchyAnalysis));
        let result = '';

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as string),
        ));

        return result;
    }

}

export default ReportsServices;