export default class ChartData{

    public _name:string;

    public _values:Array<number>;

    public _avgValue:number;

    constructor(name:string,values:Array<number>,avgValue:number)
    {
        this._name=name;
        this._values=values;
        this._avgValue=avgValue;
    }

    private GetValueInArrByCondition(arr : Array<number>, condition : (first : number, second : number) => boolean) : number {
        return arr.reduce((a, v) => condition(a, v) ? v : a);
    }

    public GetMaxValue() : number{
        if(this._values.length == 0 || this._values == undefined || this._values == null)
            throw new Error("empty or null array");
        let maxValue = this.GetValueInArrByCondition(this._values, (value : number, item : number) => {return (value < item && item != NaN)})
        return maxValue;
    }

    public GetMinValue() : number{
        if(this._values.length == 0 || this._values == undefined || this._values == null)
            throw new Error("empty or null array");
        let minValue = this.GetValueInArrByCondition(this._values, (value : number, item : number) => {return (value > item && item != NaN)})
        return minValue;
    }

}