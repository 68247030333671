import ReportType, { reportTypeSwitch } from "../../Models/Reports/ReportType"

class AvgValueScaler {

    public static ScaleAvgValue(value : number, reportType : ReportType) : number
    {
        return reportTypeSwitch(reportType,
            this.ScaleTensityValue,
            this.ScaleAnxietyValue,
            this.ScaleVolumeValue)(value);
    }

    private static ScaleTensityValue(value : number) : number
    {
        if (value < 1.5)
            return 1;
        if (value < 2.5)
            return 2;
        if (value < 3.5)
            return 3;
        if (value < 4.25)
            return 4;
        if (value <= 5)
            return 5;

        return 1;
    }

    private static ScaleAnxietyValue(value : number) : number
    {
        if (value <= 0.825)
            return 5;
        if (value < 1.65)
            return 4;
        if (value < 2.475)
            return 3;
        if (value < 3.3)
            return 2;
        //if (value <= 5)
            //return 1;
        return 1;
    }

    private static ScaleVolumeValue(value : number) : number
    {
        if (value < 1.25)
            return 1;
        if (value < 1.875)
            return 2;
        if (value < 2.5)
            return 3;
        if (value < 3.75)
            return 4;
        if (value <= 5)
            return 5;
        
        return 1;
    }
}

export default AvgValueScaler;