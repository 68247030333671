import React from "react"
import { IRoutesAgent, RoutesAgent } from '../../Helpers/Routes/RoutesAgent'
import IUserActions from "../../Actions/UserActions/IUserAction";
import SignInUI from "./SignInUI"
import { ITextProvider, TextProvider } from "../../Helpers/TextProvider/TextProvider";
import Preloader from "../../Components/Preloader/Preloader";
import Validator from '../../Helpers/Validator/Validator'
import UserActions from "../../Actions/UserActions/UserActions";
import ILanguagePhrase from "../../Helpers/LanguagePhrase/ILanguagePhrase";
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";

interface ISignInPage {
    userActions : IUserActions | null
    routesAgent : IRoutesAgent | null
    textProvider : ITextProvider | null
    localStorageAgent : ILocalStorageAgent | null;
}

export interface ISignInPageState{
    email:string
    password:string
    emailErrorPrompt:string
    passwordErrorPrompt:string
    isCorrectData:boolean
    isLoad : boolean
    isConfirmedEmail : boolean
}

class SignInPage extends React.Component<ISignInPage,ISignInPageState>{
    private readonly _userActions : IUserActions;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _textProvider : ITextProvider;
    private readonly _language : ILanguagePhrase;
    
    constructor(props : ISignInPage){
        super(props);
        this._userActions = props.userActions ?? new UserActions();
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this._language = (this._textProvider = props.textProvider ?? new TextProvider()).GetLang();

        (props.localStorageAgent ?? new LocalStorageAgent()).token = '';

        this.state={
            email:"",
            password:"",
            emailErrorPrompt:"",
            passwordErrorPrompt:"",
            isCorrectData:true,
            isConfirmedEmail : true,
            isLoad : true
        }
    }

    changeValueStateAction=(name:string,value:string) => {
        this.setState(state => {
            return {
                ...state,
                [name]:value
            }
        })
    }

    changeEmailValueAction=(value:string) => {
        this.changeValueStateAction("emailErrorPrompt", !Validator.IsEmailTemplate(value) ? this._language.uncorrectEmail : '')
        this.changeValueStateAction("email",value)
    }

    changePasswordValueAction=(value:string) => {
        if(value=="")   
            this.changeValueStateAction("passwordErrorPrompt",this._language.emptyArea)
        else{
            this.changeValueStateAction("passwordErrorPrompt","")
        }
        this.changeValueStateAction("password",value)
    }

    isEmptyErrorPrompts = ():boolean => {
        return (this.state.emailErrorPrompt && this.state.passwordErrorPrompt) == ""
    }

    isFullValuesState=():boolean => {
        return (this.state.email && this.state.password) != ""
    }

    isValidValueAreas = ():boolean => {
        let result:boolean=false;
        if(this.isEmptyErrorPrompts())
            if(!this.isFullValuesState())
                this.warnUser();
            else
                result=true;
        return result;
    }

    warnUser=() => {
        if(this.state.email=="")
            this.setState({emailErrorPrompt:this._language.emptyArea})
        if(this.state.password=="")
            this.setState({passwordErrorPrompt:this._language.emptyArea})
    }

    authUser=() => {
        if(this.isValidValueAreas()){
            this.setState({isLoad : false});
            this._userActions.AuthorizeAsync(this.state.email, this.state.password, this.stateContainerCallBack, this.unConfirmedEmail);
        }
    }

    stateContainerCallBack=(obj : unknown) => {
        this.setState({isCorrectData:false, isLoad : true})
    }

    unConfirmedEmail = () => {
        this.setState({isConfirmedEmail : false, isLoad : true});
    }

 
    render(){
        let content = (this.state.isLoad) ? (<SignInUI
                                                routesAgent={this._routesAgent}
                                                state={this.state}
                                                changeEmailAction={this.changeEmailValueAction}
                                                changePasswordAction={this.changePasswordValueAction}
                                                authUser={this.authUser}
                                                textProvider={this._language}
                                                isCorrectDate={this.state.isCorrectData}
                                            />)
                                          : <Preloader/>
        return content;
    }
}



export default SignInPage;