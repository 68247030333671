import React, { useState } from "react"
import CalendarImg from "../../../Media/Img/calendar.png";
import 'react-calendar/dist/Calendar.css';
import DateType, { GetValueByDateType } from "../../../Models/Analysis/DateType";
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";
import DateSelectBlock from './DateSelectBlock'
import { Period } from "../../../Models/Collections/PeriodDictionary";
import DatesHelper from "../../../Helpers/DatesHelper";
import Pair from "../../../Models/Pair";
import DateValidator from "../../../Helpers/Validator/DateValidator";

import DayDisabledDates from "../../../Helpers/Strategys/DisabledDates/DayDisabledDates";
import MonthDisabledDates from "../../../Helpers/Strategys/DisabledDates/MonthDisabledDates";
import WeekDisabledDates from "../../../Helpers/Strategys/DisabledDates/WeekDisabledDates";
import YearDisabledDates from "../../../Helpers/Strategys/DisabledDates/YearDisabledDates";

import "./PeriodBlock.css"
import { SelectV2Base, SelectV2BaseProps } from "../../SelectV2/Selects";

interface IPeriodBlock{
    reportHierarchy(startDate : Date, endDate : Date, dateType : DateType):void
    textProvider : ILanguagePhrase
    minFirstDateGlobal : Date
    maxSecondDateGlobal : Date
}

function PeriodBlock(props : IPeriodBlock){
    const generateDisabledDates = (dateType : DateType) : [ number[], Date[] ] => {
        let disabledDatesGenerator = GetValueByDateType(dateType,
            () => new DayDisabledDates(),
            () => new MonthDisabledDates(),
            () => new WeekDisabledDates(),
            () => new YearDisabledDates()
        )();
        let disabledDates = disabledDatesGenerator.GetDisabledDates(props.minFirstDateGlobal, props.maxSecondDateGlobal);
        let disabledDays = disabledDatesGenerator.GetDisabledDays()
        return [ disabledDays, disabledDates ];
    }

    const onClicked = () => {
        props.reportHierarchy(firstDate, seconDate, dateType);
    }

    const [ firstDate, firstDateChange ] = useState(new Date());
    const [ seconDate, seconDateChange ] = useState(new Date());
    const [ dateType, dateTypeChange ] = useState(DateType.ByDays);

    const [ disabledDays, disabledDates ] = generateDisabledDates(dateType)

    let minDateFirst  = DateValidator.GetMinFirst(seconDate, dateType);
    let maxDateFirst  = DateValidator.GetMaxFirst(seconDate, dateType);
    let minDateSecond = DateValidator.GetMinSecond(firstDate, dateType);
    let maxDateSecond = DateValidator.GetMaxSecond(firstDate, dateType);

    let options = DatesHelper.getConcatedValuesByDatesDiff<Pair<string,string>>(
        props.maxSecondDateGlobal, props.minFirstDateGlobal,
        new Pair(Period.year.toString(), props.textProvider.byYear),
        new Pair(Period.month.toString(), props.textProvider.byMonth),
        new Pair(Period.week.toString(), props.textProvider.byWeek),
        new Pair(Period.day.toString(), props.textProvider.byDay),
        new Pair(Period.day.toString(), props.textProvider.byDay));

    return <div className = "PeriodBlock">
        <div className = "PairPeriods">
            <DateSelectBlock 
                date={firstDate}
                dateChanged={firstDateChange}
                textProvider={props.textProvider}
                minDate={minDateFirst}
                maxDate={maxDateFirst}
                disabledDates={disabledDates}
                disabledDays={disabledDays}/>
            <span className="Prompt">-</span>
            <DateSelectBlock 
                date={seconDate}
                dateChanged={seconDateChange}
                textProvider={props.textProvider}
                minDate={minDateSecond}
                maxDate={maxDateSecond}
                disabledDates={disabledDates}
                disabledDays={disabledDays}/>
            <img src={CalendarImg}/>
        </div>
        <div className="PeriodBlockSelect">
            {SelectV2Base(new SelectV2BaseProps(
                DateType[dateType],
                options,
                false,
                v => dateTypeChange(Number(v)),
            ))}
            <div />
        </div>
        <div className = "PeriodBlockButton">
            <button className="PrimaryButton" onClick={() => onClicked()}>{props.textProvider.createReport}</button>
        </div>
    </div>;
}

export default PeriodBlock;