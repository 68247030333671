import React, { useState } from 'react'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import Validator from '../../../Helpers/Validator/Validator';
import NumberOfEmployees from '../../../Models/Organizations/NumberOfEmployees';
import Pair from '../../../Models/Pair';
import { InputV2, InputV2Props, InputV2Imaged } from '../../InputV2/InputsV2';
import { SelectV2, SelectV2Props } from '../../SelectV2/SelectV2';

import LinkImg from '../../../Media/Img/externalLinkBlack.png'

import './CompanyInfo.css'
import PaymentInfoBase from '../../../Models/Payment/PaymentInfoBase';
import Entity from '../../../Models/Organizations/Entity';
import ButtonInteractive, { ErrorImg, OkImg } from '../../Buttons/ButtonInteractive/ButtonInteractive';

interface ICompanyInfo {
    info : Entity
    textProvider : ILanguagePhrase

    onAction(info : Entity) : Promise<boolean | undefined>
}

export default function CompanyInfo(props : ICompanyInfo) {
    const [ companyName, companyNameChange ] = useState(props.info.name)
    const [ country, countryChange ] = useState(props.info.country);
    const [ address1, address1Change ] = useState(props.info.address1);
    const [ address2, address2Change ] = useState(props.info.address2);
    const [ city, cityChange ] = useState(props.info.city);
    const [ zipCode, zipCodeChange ] = useState(props.info.zipCode);
    const [ taxId, taxIdChange ] = useState(props.info.nationalTaxId);
    const [ employeesNumber, employeesNumberChange] = useState(props.info.numberOfEmployees);
    const [ phone, phoneChange] = useState(props.info.phone);
    const [ website, websiteChange] = useState(props.info.officialWebsite);

    const [ forceError, forceErrorChange ] = useState(false);

    const cancel = () => {
        forceErrorChange(true);
        companyNameChange(props.info.name);
        countryChange(props.info.country);
        address1Change(props.info.address1);
        address2Change(props.info.address2);
        cityChange(props.info.city);
        zipCodeChange(props.info.zipCode);
        taxIdChange(props.info.nationalTaxId);
        employeesNumberChange(props.info.numberOfEmployees);
        phoneChange(props.info.phone);
        websiteChange(props.info.officialWebsite);
    }

    const getNumberOfEmployees = () => {
        return Object.keys(NumberOfEmployees)
                     .filter(k => Number.isInteger(Number(k)))
                     .map(k => new Pair(k, props.textProvider.companyEmployeesNumber(Number(k))));
    }

    const isNameCorrect = !Validator.isNullOrWhitespace(companyName);
    const isPhoneCorrect = Validator.IsOptionalPhoneTemplate(phone);
    const isTaxIdCorrect = !Validator.isNullOrWhitespace(taxId);
    const isAllCorrect = isNameCorrect && isPhoneCorrect && isTaxIdCorrect;

    const imgClicked = () => {
        let url = website;
        if (!url.toLowerCase().startsWith('http'))
            url = 'http://'+url;

        let link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.style.display = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const onAction = () => {

        forceErrorChange(true);
        if (!isAllCorrect) return Promise.resolve(false);

        let item = Object.assign<{}, Entity>({}, props.info);
        Entity.edit(item, phone, website, employeesNumber, new PaymentInfoBase(companyName, country, address1, address2, city, zipCode, taxId))
        return Promise.resolve(props.onAction(item));
    }

    return <div className="CompanyInfoContainer">
        <div className='SettingsTable'>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.companyName,
                    forceError,
                    props.textProvider.uncorrectCompanyName,
                    companyName,
                    isNameCorrect,
                    v => companyNameChange(v),
                    'text', false, 'fit'))}
                {InputV2(new InputV2Props(
                    props.textProvider.paymentCountry,
                    forceError,
                    props.textProvider.paymentEnterCountry,
                    country,
                    true,
                    v => countryChange(v),
                    'text', false, 'fit'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.paymentAddress1,
                    forceError,
                    props.textProvider.paymentEnterAddress,
                    address1,
                    true,
                    v => address1Change(v),
                    'text', false, 'fit'))}
                {InputV2(new InputV2Props(
                    props.textProvider.paymentAddress2,
                    forceError,
                    '',
                    address2,
                    true,
                    v => address2Change(v),
                    'text', false, 'fit'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.paymentCity,
                    forceError,
                    props.textProvider.paymentEnterCity,
                    city,
                    true,
                    v => cityChange(v),
                    'text', false, 'fit'))}
                {InputV2(new InputV2Props(
                    props.textProvider.paymentZipCode,
                    forceError,
                    props.textProvider.paymentEnterZipCode,
                    zipCode,
                    true,
                    v => zipCodeChange(v),
                    'text', false, 'fit'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.paymentTaxIdNumber,
                    forceError,
                    props.textProvider.paymentEnterTaxIdNumber,
                    taxId,
                    isTaxIdCorrect,
                    v => taxIdChange(v),
                    'text', false, 'fit'))}
                {SelectV2(new SelectV2Props(
                    props.textProvider.companyNumberOfEmployees,
                    forceError,
                    props.textProvider.uncorrectNumberOfEmployees,
                    employeesNumber.toString(),
                    getNumberOfEmployees(),
                    true,
                    v => employeesNumberChange(Number(v)),
                    false, 'fill'))}                    
            </div>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.companyPhone,
                    forceError,
                    props.textProvider.uncorrectCompanyPhone,
                    phone,
                    isPhoneCorrect,
                    v => phoneChange(v),
                    'text', false, 'fill'))}
                <InputV2Imaged 
                    inputProps={new InputV2Props(
                        props.textProvider.companyWebsite,
                        forceError,
                        props.textProvider.uncorrectCompanyWebsite,
                        website,
                        true,
                        v => websiteChange(v),
                        'url', false, 'fill')}
                    imgProps={{
                        imgPosition : 'post',
                        imgSrc : LinkImg,
                        imgClicked : () => imgClicked()
                }}/>
            </div>
        </div>
        <div className="ButtonsContainer">
            <button className="SecondaryButton" onClick={() => cancel()}>{props.textProvider.cancel}</button>
            <ButtonInteractive 
                id='CompanyInfoButtonInteractive'
                successImgSrc={OkImg}
                errorImgSrc={ErrorImg}
                buttonContainerClass=''
                buttonStyle='PrimaryButton'
                buttonText={props.textProvider.update}
                action={() => onAction()}/>
        </div>
    </div>
}