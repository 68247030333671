import React from "react"

import Select from "../../../Components/Select/Select";
import ReportGenerator from '../../../Components/Report/ReportGenerator/ReportGenerator'
import ReportInfo from '../../../Components/Report/ReportInfo/ReportInfo'
import ReportsList from "../../../Components/Report/ReportsList/ReportsList";

import DateType from "../../../Models/Analysis/DateType";
import Department from "../../../Models/Organizations/Department/Department";
import { IOrganizationState } from "./OrganizationPage";
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";

import "../../../Components/Report/Report.css"

export interface IOrganizationUI {
    state:IOrganizationState
    arraysDeps : Array<Department>
    textProvider:ILanguagePhrase
    changeNowDepartmentHandler(depId : string) : void
    reportHierarchy(startDate : Date, endDate : Date, dateType : DateType) : void
    changeNowReport(reportId : string) : void
    deleteReport(reportId : string) : void
    checkOtherDepartment(dep : Department) : void
}

export default function OrganizationUI(props:IOrganizationUI){
   const [isGenerateNewReport, useisGenerateNewReport] = React.useState(false);

   const createReport = (startDate : Date, endDate : Date, dateType : DateType) => {
      props.reportHierarchy(startDate, endDate, dateType); 
      useisGenerateNewReport(true); 
      setTimeout(() => useisGenerateNewReport(false), 20000);
   }

   let secondPart : JSX.Element = <div>{props.textProvider.createReportPrompt}</div>
   if(props.state.isExistReports)
      secondPart = <ReportInfo 
                     textProvider={props.textProvider}
                     reportsGroup={props.state.reportsGroup}
                     report={props.state.nowReport}
                     hierarchy={props.state.hierarchy}/>
   
   const reportsToShow = (props.state.allReportsList ?? []).filter(item => item.hierarchyId == props.state.hierarchy.id);
   return(<div className="OrganizationUIContainer">
            <div className = "ReportPage">
               <div className = "coupleHeadingButtons">
                  <div className = "coupleReportNameHierarchy">
                     <h1 className="OrganizationUIHeader">{props.textProvider.reports + ":"} </h1>
                     {(props.state.hierarchys.length > 1) ? <Select 
                                                               name = "nowHierarchy" 
                                                               nowValue = {props.state.hierarchy.id} mainPrompt = {""} 
                                                               values = {props.state.hierarchys.map(item => item.id)}
                                                               prompts = {props.state.hierarchys.map(item => item.name)} 
                                                               changeValue = {() => {}}
                                                            />
                                                          : <h1 className = "OrganizationUIHeader">{props.state.hierarchy.name}</h1>}
                  </div>
                  <div className = "reportCoupleButtons">
                     <ReportsList
                        id="OrganizationUIReportsList"
                        reports={reportsToShow.sort((a,b) => (new Date(a.postTime) < new Date(b.postTime)) ? 1 : -1)}
                        changeReport = {props.changeNowReport}
                        textProvider = {props.textProvider} 
                        deleteReport = {props.deleteReport}/>
                     {(isGenerateNewReport) ? <div className = "notificationNewReport"></div> : null}
                     <ReportGenerator 
                        id='OrganizationUIReportGenerator'
                        textProvider={props.textProvider}
                        createReport={(s,e,d) => createReport(s,e,d)}
                        minFirstDateGlobal={props.state.minFirstDateGlobal}
                        maxSecondDateGlobal={props.state.maxSecondDateGlobal}/>
                  </div>
               </div>
               {secondPart}
            </div>
         </div>)
}