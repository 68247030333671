import React from "react";

import "./ColorBlock.css";

interface IProps {
    background : string
    value : number
    onClick() : void
}

function ColorBlock(props : IProps) {

    return <div onClick = {props.onClick} className = "ColorBlock Prompt" style = {{background : props.background}}>
                {Number.isNaN(props.value) ? '---' : props.value.toString() }
           </div>
}

export default ColorBlock;