import React from 'react'

import BalanceIncomeRecord from '../../../Models/Balance/BalanceIncomeRecord'
import BaseContainer from '../../BaseContainer'
import DatesHelper from '../../../Helpers/DatesHelper'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import PaginatedList from '../../../Components/PaginatedList'
import Pair from '../../../Models/Pair'

import './BalanceUI.css'

interface IIncomesTable {
    textProvider : ILanguagePhrase
    records : BalanceIncomeRecord[]
    dateToString(d : Date) : string
}

interface IIncomesTableState {
    itemsToShow : BalanceIncomeRecord[]
    panelIndex : number
    moreButtonNeeded : boolean
}

export default class IncomesTable extends BaseContainer<IIncomesTable, IIncomesTableState> {
    private readonly _paginatedListPageSize = 15;
    private readonly _paginatedList : PaginatedList<BalanceIncomeRecord>;

    constructor(props : IIncomesTable) {
        super(props);

        this._paginatedList = new PaginatedList<BalanceIncomeRecord>(props.records, this._paginatedListPageSize)

        let items = this._paginatedList.nextPage();
        this.state = {
            itemsToShow : items,
            panelIndex : -1,
            moreButtonNeeded: items.length === this._paginatedListPageSize,
        };
    }

    mapRecords(records : BalanceIncomeRecord[]) {
        let now = new Date();
        return records.map(record => {
            const [_, yearsDiff, monthesDiff, daysDiff]  = DatesHelper.calcDiff(now, record.postTime);
            const diff = ((yearsDiff * 12) + monthesDiff) + (daysDiff / 31)

            return new Pair(record, diff)
        }).sort((f,s) => s.key.postTime.getTime() - f.key.postTime.getTime());
    }

    getInfoPanel(item : BalanceIncomeRecord, text : string, isInBeginning : boolean) {
        let style : React.CSSProperties = {};
        if (isInBeginning) style = { top: '0vh' };
        else style = { bottom: '1vh' }
        return <div className="BalanceUIPaymentInfoPanelContainer" style={style}>
            <div className="BalanceUIPaymentInfoPanel Shadow">
                <p className="SubHeader BalanceUIBold">{text}</p>
                <table className="BalanceUIPaymentInfoTable">
                    <tr>
                        <td><p className="Prompt">{this.props.textProvider.balanceRemainigTrial}</p></td>
                        <td><p className="Prompt">{item.trialBalance.toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                    <tr>
                        <td><p className="Prompt">{this.props.textProvider.balanceRemaining}:</p></td>
                        <td><p className="Prompt">{item.personalBalance.toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                    <tr>
                        <td><p className="Prompt">{this.props.textProvider.balancePaymentAmount}:</p></td>
                        <td><p className="Prompt">{item.amount.toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                    <tr>
                        <td><p className="Prompt">{this.props.textProvider.balanceResult}:</p></td>
                        <td><p className="Prompt">{(item.trialBalance + item.personalBalance + item.amount).toFixed(2)}{item.currency.symbol}</p></td>
                    </tr>
                </table>
            </div>
        </div>
    }

    getItemTemplate(item : BalanceIncomeRecord, index : number) {
        let text = `${this.props.textProvider.balancePayment}, ${this.props.dateToString(item.postTime)}`;
        let panel = null;
        if (index == this.state.panelIndex)
            panel = this.getInfoPanel(item, text, index < 10);
        return <tr  style={{backgroundColor: this.state.panelIndex === index ? '#F3F3F3' : 'white'}}
                    onClick={() => this.changeValueState(new Pair('panelIndex', this.state.panelIndex === index ? -1 : index))}>
            <td>{text}{panel}</td>
            <td>{item.amount.toFixed(2)}{item.currency.symbol}</td>
        </tr>
    }

    moreClicked() {
        let itemsToAdd = this._paginatedList.nextPage();
        this.changeValueState(
            new Pair('itemsToShow', this.state.itemsToShow.concat(itemsToAdd)), 
            new Pair('moreButtonNeeded', itemsToAdd.length === this._paginatedListPageSize)
        );
    }

    render() {
        return <div className="BalanceUITableContainer"> 
            <table className="BalanceUITable">
                <thead>
                    <td>{this.props.textProvider.balancePaymentDetails}</td>
                    <td>{this.props.textProvider.balanceAmount}</td>
                </thead>
                {this.state.itemsToShow.map((item,index) => this.getItemTemplate(item, index))}
                {this.state.moreButtonNeeded && <tr>
                    <td colSpan={2}><button className="WhiteButton" style={{paddingLeft: '0.5vw', paddingRight: '0.5vw'}} onClick={() => this.moreClicked()}>˅</button></td>
                </tr>}
            </table>
        </div>
    }
}