import IRequestProvider from "./RequestProvider/IRequestProvider";
import LocalStorageAgent from "../LocalStorageWorker/LocalStorageAgent";
import RequestProvider from "./RequestProvider/RequestProvider";
import { TextProvider } from "../Helpers/TextProvider/TextProvider";

class BaseService {
    constructor(requestProvider : IRequestProvider | null) {
        this.RequestProvider = requestProvider ?? new RequestProvider(new LocalStorageAgent(), new TextProvider());
    }

    protected readonly RequestProvider : IRequestProvider;
}

export default BaseService;