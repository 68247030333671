import React, { useState } from 'react'

import {InputV2, InputV2Props} from '../../../Components/InputV2/InputsV2'
import {MyPopupBase} from '../../../Components/MyPopupBase/MyPopupBase'

import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import Validator from '../../../Helpers/Validator/Validator'

import './HierarchyEditPopup.css'

interface IHierarchyEditPopup {
    zIndex : number
    closePopup() : void
    
    name : string
    textProvider : ILanguagePhrase
    onEdit(name : string) : void
}

export default function HierarchyEditPopup(props : IHierarchyEditPopup) {
    const [ forceError, forceErrorChange ] = useState(false);
    const [ name, nameChange ] = useState(props.name)

    const onEdit = () => {
        forceErrorChange(true);
        if (!isValid) return;

        props.onEdit(name);
    }

    let isValid = !Validator.isNullOrWhitespace(name);
    return <MyPopupBase zIndex={props.zIndex} closePopup={() => props.closePopup()} width={'default'}>
        <p className="Header">{props.textProvider.structure}</p>
        <div style={{width: '60%'}}>
            {InputV2(new InputV2Props(
                props.textProvider.structureName,
                forceError,
                props.textProvider.structureNameEnter,
                name,
                isValid,
                v => nameChange(v),
                'text', false, 'fit'))}
            <div className="ButtonsContainer">
                <button className="SecondaryButton" onClick={() => props.closePopup()}>{props.textProvider.cancel}</button>
                <button className="PrimaryButton" onClick={() => onEdit()}>{props.textProvider.edit}</button>
            </div>
        </div>
    </MyPopupBase>
}