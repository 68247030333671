import BalanceType from "../../Enums/BalanceType";

export default class BalanceUserAPI {
    public userId : string = '';

    public postTime : string = '';

    public balance : number = 0;

    /**
     * IT IS CODE NOT SYMBOL
     */
    public currencySymbol : string = 'EUR';

    public typeBalance : BalanceType = BalanceType.Inactive;

    public expirationDate : string = '0001-01-01T00:00:00.0000';
}