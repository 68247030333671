import React from "react"

import BaseContainer from "../../../Containers/BaseContainer";

import "./AdminSideMenuItem.css"

interface IAdminSideMenuItem {
    extended : boolean;
    selected : boolean;
    iconSrcDefault : string;
    iconSrcSelected : string;
    text : string;
    selectHandler() : void;
}

interface IAdminSideMenuItemState {
}

class AdminSideMenuItem extends BaseContainer<IAdminSideMenuItem, IAdminSideMenuItemState> {
    constructor(props : IAdminSideMenuItem) {
        super(props);
    }

    render() {
        const containerStyle : React.CSSProperties = {
            width: this.props.extended ? "100%" : "fit-content",
            background: this.props.selected ? "#5F8BD9" : "transparent",
            borderRadius: this.props.extended ? "0 10px 10px 0" : "10px 0 0 10px",
        };

        const imgStyle : React.CSSProperties = {
            marginLeft: this.props.extended ? '6vw' : '',
        };

        let textBlock : JSX.Element | null = null;
        if (this.props.extended)
            textBlock = <p style={{color: this.props.selected ? "#92E3DA" : "black"}} >{this.props.text}</p>

        return <div className="AdminSideMenuItemContainer" style={containerStyle} onClick={this.props.selectHandler}>
            <img src={this.props.selected ? this.props.iconSrcSelected : this.props.iconSrcDefault} style={imgStyle}></img>
            {textBlock}
        </div>
    }
}

export default AdminSideMenuItem;