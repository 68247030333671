import React from 'react'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import { ITextProvider, TextProvider } from '../../Helpers/TextProvider/TextProvider'
import ILocalStorageAgent from '../../LocalStorageWorker/ILocalStorageAgent'
import LocalStorageAgent from '../../LocalStorageWorker/LocalStorageAgent'
import BaseUser from '../../Models/Users/BaseUser'

import './AdminFrame.css'
import AdminFrameInner from './AdminFrameInner/AdminFrameInner'

export interface IAdminFrame {
    children?: React.ReactNode

    localStorageAgent? : ILocalStorageAgent
    textProvider?: ITextProvider
}

export interface IAdminFrameState {
    widthVW : number
    headerHeight : number
}

export default class AdminFrame extends React.Component<IAdminFrame, IAdminFrameState> {
    public static readonly InitialState : IAdminFrameState = { widthVW: 10, headerHeight: 60 }
    protected readonly _textProvider : ITextProvider;
    protected readonly _language : ILanguagePhrase;
    
    private readonly _user : BaseUser;
    constructor(props: IAdminFrame) {
        super(props);
        this._language = (this._textProvider = props.textProvider ?? new TextProvider()).GetLang();

        this.state = {
            ...AdminFrame.InitialState,
        }

        this._user = (props.localStorageAgent ?? new LocalStorageAgent()).user as BaseUser;
    }

    setWidth(v : number) {
        this.setState(state => {
            return {
                ...state,
                widthVW: v,
            };
        });
    }

    setHeight(v : number) {
        this.setState(state => {
            return {
                ...state,
                headerHeight: v,
            };
        });
    }

    render() {
        let props : React.CSSProperties = {
            marginLeft: `${(-(this.state.widthVW - AdminFrameInner.widthDefault))}vw`, 
            zIndex: 5,
            width: `${100 - AdminFrameInner.widthDefault}vw`,
            maxHeight: `${window.innerHeight - this.state.headerHeight}px`
        }

        return <AdminFrameInner 
                user={this._user}
                textProvider={this._language} 
                sideMenuWidthChanged={vw => this.setWidth(vw)} 
                adminHeaderHeightChanged={v => this.setHeight(v)}>
                <div className="BaseAdminContainerContainer" style={props}>
                    {this.props.children}
                </div>
        </AdminFrameInner>
    }
}