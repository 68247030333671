enum ReportType {
    Tensity,
    Anxiety,
    Volume
}

function reportTypeSwitch<T>(
    reportType : ReportType,
    tensity : T,
    anxiety : T,
    volume : T,
) : T {
    switch (reportType) {
        case ReportType.Tensity : return tensity;
        case ReportType.Anxiety : return anxiety;
        case ReportType.Volume : return volume;

        default: throw new Error('Such report type not implemented')
    }
}

export default ReportType;
export {ReportType, reportTypeSwitch};