export default class PaymentInfoBase {
    constructor(
        companyName : string,
        country : string,
        address1 : string,
        address2 : string,
        city : string,
        zipCode : string,
        nationalTaxId : string) {
        this.companyName = companyName;
        this.country = country;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.zipCode = zipCode;
        this.nationalTaxId = nationalTaxId;
    }

    public companyName : string;

    public country : string;

    public address1 : string;

    public address2 : string;

    public city : string;

    public zipCode : string;

    public nationalTaxId : string;
}