import jwt_decode from "jwt-decode";
import Role from "../Models/Role";
import JwtToken from "../Models/JwtToken";

export interface ITokenParser {
    GetRoles(token : string) : Role[];
}

export class TokenParser{
    
    public GetRoles(token : string) : Role[] {
        if (!!token) {
            const decodedToken : JwtToken = jwt_decode(token) as JwtToken;
            const prop = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            if (prop) {
                const roles = Array.isArray(prop) ? prop as string[] : [prop as string];
                
                return roles.map(r => this.MatchRole(r)).filter(r => r != null).map(r => r as Role);
            }
        }

        return [Role.Unconfirmed];
    }

    private MatchRole(str : string) : Role | null {
        let roles = Object.keys(Role).map(key => Number(key)).filter(key => !Number.isNaN(key));
        for (let role in roles) {
            if (str === Role[roles[role]])
                return roles[role];
        }

        return null;
    }
}