import DepartmentBase from "./DepartmentBase";

class Department extends DepartmentBase {
    constructor(
        id : string,
        name : string,
        numberDep : number) {
        super(id , name, numberDep);
        this.superiorDepartment = null;
        this.subDepartments = new Array<Department>();
    }

    public AddSubDepartment(sub : Department) : void {
        this.subDepartments.push(sub);
        sub.superiorDepartment = this;
    }

    public superiorDepartment : Department | null;

    public subDepartments : Array<Department>;

    public static getDepartmentsList(current : Department) : Department[] {
        let result : Department[] = [ current ];
        if (!current.subDepartments)
            return result;

        let subs = current.subDepartments.map(sub => Department.getDepartmentsList(sub)).reduce((ac,curr) => ac.concat(curr), []);
        return result.concat(subs)
    }

    public static findDepartment(current : Department, id : string) : Department | undefined {
        if (current.id === id)
            return current;
        
        for (let key in current.subDepartments) {
            let dep = Department.findDepartment(current.subDepartments[key], id);
            if (dep)
                return dep;
        }

        return undefined;
    }
}

export default Department;