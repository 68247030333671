import CRMOrganizationAPI from "./CRMOrganizationAPI";

export default class CRMOrganization {
    constructor(proto : CRMOrganizationAPI) {
        this.organizationId = proto.organizationId;
        this.companyName = proto.companyName;
        this.phone = proto.phone;
        this.dateRegistration = new Date(proto.dateRegistration);
        this.adminCompanyEmail = proto.adminCompanyEmail;
    }

    public organizationId : string;
    
    public companyName: string;
    
    public phone: string;
    
    public dateRegistration: Date;

    public adminCompanyEmail : string;
}