class BaseAnalysis{

    constructor(id : string ,postTime : string , value : number){
        this.id = id;
        this.postTime = postTime;
        this.value = value;
    }

    public id : string;

    public postTime : string

    public value : number
    
}

export default BaseAnalysis;