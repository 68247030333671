import TariffStatus from "../../Enums/TariffStatus";
import { Euro } from "../../Helpers/Currencies";
import Currency from "../Currencies/Currency";
import TariffAPI from "./TariffAPI";

export default class Tariff {
    constructor(proto : TariffAPI = new TariffAPI(), currency : Currency = Euro) {
        this.id = proto.id;
        this.innerName = proto.innerName;
        this.maxManagers = proto.maxManagers;
        this.maxEmployees = proto.maxEmployees;
        this.maxHierarchies = proto.maxHierarchies;
        this.dailyPayment = proto.dailyPayment;
        this.monthlyPayment = proto.monthlyPayment;
        this.currency = currency;
        this.status = proto.status;
    }

    public id : string;

    public innerName : string;
    
    public maxManagers : number;
    
    public maxEmployees : number;

    public maxHierarchies : number;

    public dailyPayment : number;

    public monthlyPayment : number;

    public currency : Currency;

    public status : TariffStatus;
}