interface IAnimate {
    millisecondsDuration : number
    animationHandler(animationRate : number) : void
}

function Animate(props : IAnimate) : () => void {
    let stopped = false;
    let startTime = new Date().getTime();
    let step = props.millisecondsDuration / 100;

    let animationStopper = () => stopped = true;

    let animationProcessor = () => {
        if (stopped)
            return;

        let now = new Date().getTime()
        let animationRate = (now - startTime) / props.millisecondsDuration;
        let normalizedAnimationRate = animationRate > 1 ? 1 : animationRate;

        props.animationHandler(normalizedAnimationRate);
        if (animationRate < 1)
            setTimeout(animationProcessor, step);
    }

    animationProcessor();
    return animationStopper;
}

export default Animate;