import Currency from '../Models/Currencies/Currency'

export const Euro = new Currency('EUR','€');

export default class CurrencyProvider {
    public static readonly Currencies = [ Euro ];

    public static getCurrency(code : string) : Currency {
        code = code.toLowerCase();
        return this.Currencies.find(c => c.code.toLowerCase() == code) as Currency;
    }
}