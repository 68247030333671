import ChartOption from "./ChartOption";

class ArrayChartOption{

    public chartOptions : Array<ChartOption>;

    public get GetGhartOptions(){
        return this.chartOptions;
    }

    constructor(chartOptions : Array<ChartOption>){
        this.chartOptions = chartOptions;
    }

    private aggregateInternal(compFunc : (ac:number, curr:number) => boolean, seed : number) {
        return this.chartOptions.reduce((acArray,currArray) => {
            let item = currArray.data._values.reduce((ac,curr) => compFunc(ac, curr) ? curr : ac, seed);
            return compFunc(acArray, item) ? item : acArray;
        }, seed);
    } 

    public GetMaxDataValue() : number {
        return this.aggregateInternal((ac,curr) => curr > ac, this.chartOptions[0].data._values[0]);
    }

    public GetMinDataValue() : number {
        return this.aggregateInternal((ac,curr) => curr < ac, this.chartOptions[0].data._values[0]);
    }

}

export default ArrayChartOption;