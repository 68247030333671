import IStateContainerCallback from "../IStateContainerCallback";
import ITensenessAnalysisActions from "./ITensenessAnalysisActions";
import ITensenessAnalysisService from "../../Services/TensenessAnalysis/ITensenessAnalysisService"
import BaseReportIndexAnalysis from "../../Models/Analysis/Report/BaseReportIndexAnalysis";
import TensenessAnalysisService from "../../Services/TensenessAnalysis/TensenessAnalysisService";

class TensenessAnalysisActions implements ITensenessAnalysisActions {
    private readonly tensenessAnalysisService : ITensenessAnalysisService;
    constructor(service : ITensenessAnalysisService | null = null) {
        this.tensenessAnalysisService = service ?? new TensenessAnalysisService();
    }

    public async GetReportsWellbeingByReportIdResponce(reportId : string) : Promise<Array<BaseReportIndexAnalysis>> {
        let reports = await this.tensenessAnalysisService.GetReportsWellbeingByReportId(reportId);
        return reports.map(item => new BaseReportIndexAnalysis(item, item.wellbeingAnalysisCollection));
    }
}

export default TensenessAnalysisActions;