import React from 'react'

import DepartmentEditPopup from '../DepartmentEditPopup/DepartmentEditPopup'
import HierarchyAddPopup from '../HierarchyAddPopup/HierarchyAddPopup'
import HierarchyEditPopup from '../HierarchyEditPopup/HierarchyEditPopup'
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact'
import StructureComponent from '../../../Components/Admin/StructureComponent/StructureComponent'
import Pair from '../../../Models/Pair'

import Department from '../../../Models/Organizations/Department/Department'
import DepartmentView from '../../../Models/Organizations/Department/DepartmentView'

import './StructuresUI.css'
import PopUpConfirm from '../../../Components/PopupConfirm/PopupConfirm'
import Hierarchy from '../../../Models/Organizations/Hierarchy'
import GuidGenerator from '../../../Helpers/GuidGenetator'
import InteractiveHintsManager from '../../../Components/InteractiveHints/InteractiveHintsManager'
import { IInteractiveHintActions } from '../../../Actions/InteractiveHintActions'
import * as InteractiveHintsKeys from '../../../Helpers/InteractiveHintsKeys'
import PopUpMessage from '../../../Components/PopupMessage/PopupMessage'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'
import { TextProvider } from '../../../Helpers/TextProvider/TextProvider'

const deleteConfirmId = 'StructuresUIDeleteConfirm',
      deleteCancelId = 'StructuresUIDeleteCancel',
      deleteStructureConfirmId = 'StructuresUIDeleteStructureConfirm',
      deleteStructureCancelId = 'StructuresUIDeleteStructureCancel',
      structuresContainerId = 'StructuresUIContainer';

export { deleteConfirmId, deleteCancelId, deleteStructureConfirmId, deleteStructureCancelId }

interface IStructuresUI {
    language? : ILanguagePhrase

    interactiveHintActions : IInteractiveHintActions

    isLoaded : boolean
    structures : Pair<Hierarchy, DepartmentView[]>[]
    addStructure(name : string, mainDepartmentName : string) : void
    extendChanged(hierarchyId : string, struct : DepartmentView[]) : void

    deleteDepartment(departmentId : string) : void
    editDepartment(structure : Pair<Hierarchy, DepartmentView[]>, department : DepartmentView) : void
    addDepartment(superiorId : string, name : string, structureId : string) : void
    deleteStructure(structureId : string) : void
    editStructure(hierarchy : Hierarchy) : void

    errorMessage : string
    onCloseError() : void
}

interface IStructuresUIState {
    deletePopupRequested : boolean
    editPopupRequested : boolean
    addPopupRequested : boolean
    deleteStructureRequested : boolean
    editHierarchyRequested : boolean
    structureAddRequested : boolean

    currentStructure : Pair<Hierarchy, DepartmentView[]>
    currentView : DepartmentView
    superiorView : DepartmentView
}

export default class StructuresUI extends React.Component<IStructuresUI, IStructuresUIState> {
    private readonly _language : ILanguagePhrase;

    constructor(props : IStructuresUI) {
        super(props);
        this.state = {
            deletePopupRequested : false,
            editPopupRequested : false,
            addPopupRequested : false,
            deleteStructureRequested : false,
            editHierarchyRequested : false,
            structureAddRequested : false,

            currentStructure : new Pair(new Hierarchy(''), []),
            currentView : new DepartmentView(new Department('','',0)),
            superiorView : new DepartmentView(new Department('','',0)),
        }

        this._language = props.language ?? new TextProvider().GetLang();
    }

    onEditDepartment(newName : string, superiorId : string) {
        let current = this.state.currentView;
        current.name = newName;
        current.superiorDepartment = Department.findDepartment(this.state.currentStructure.value[0], superiorId) ?? new DepartmentView(new Department(GuidGenerator.GenerateZeroGuid(), '', 0));
        this.props.editDepartment(this.state.currentStructure, current);
    }

    structureEditRequested(hierarchy : Hierarchy) {
        this.setState(state => { return { ...state, currentStructure: new Pair(hierarchy, [] as DepartmentView[]), editHierarchyRequested: true } });
    }

    onStructureEdit(name : string) {
        let struct = this.state.currentStructure.key;
        struct.name = name;
        this.props.editStructure(struct);
    }

    getPopups() {
        let items : JSX.Element[] = [];

        if (!!this.props.errorMessage)
            items.push(<PopUpMessage 
                            zIndex={200}
                            closePopup={() => this.props.onCloseError()}
                            header={this._language.attention}
                            text={this.props.errorMessage}
                            buttonId='StructuresUISpecialTariffError'/>)

        if (this.state.deletePopupRequested)
            items.push(<PopUpConfirm 
                            zIndex={100} closePopup={() => this.setState(state => { return { ...state, deletePopupRequested: false } })}
                            confirmButtonId={deleteConfirmId} cancelButtonId={deleteCancelId}
                            header={this._language.confirmation}
                            text={this._language.departmentDeleteConfirmation(this.state.currentView.name, this.state.superiorView.name)}
                            confirm={this._language.confirm}
                            cancel={this._language.cancel}
                            onConfirm={() => this.props.deleteDepartment(this.state.currentView.id)}/>);

        if (this.state.editPopupRequested)
            items.push(<DepartmentEditPopup 
                            zIndex={100}
                            closePopup={() => this.setState(state => { return { ...state, editPopupRequested: false } })}
                            width='default'
                            textProvider={this._language}
                            structure={this.state.currentStructure.value}
                            superior={this.state.superiorView}
                            current={this.state.currentView}
                            actionText={this._language.edit}
                            onAdd={(name, superiorId) => this.onEditDepartment(name, superiorId)}/>)

        if (this.state.addPopupRequested)
            items.push(<DepartmentEditPopup 
                            zIndex={100}
                            closePopup={() => this.setState(state => { return { ...state, addPopupRequested: false } })}
                            width='default'
                            textProvider={this._language}
                            structure={this.state.currentStructure.value}
                            superior={this.state.superiorView}
                            current={new DepartmentView(new Department('','',0))}
                            actionText={this._language.addDepartment}
                            onAdd={(name, superiorId) => this.props.addDepartment(superiorId, name, this.state.currentStructure.key.id)}/>);

        if (this.state.deleteStructureRequested)
            items.push(<PopUpConfirm 
                            zIndex={100} closePopup={() => this.setState(state => { return { ...state, deleteStructureRequested: false } })}
                            confirmButtonId={deleteStructureConfirmId} cancelButtonId={deleteStructureCancelId}
                            header={this._language.confirmation}
                            text={this._language.structureDeleteConfirmation}
                            confirm={this._language.confirm}
                            cancel={this._language.cancel}
                            onConfirm={() => this.props.deleteStructure(this.state.currentStructure.key.id)}/>)

        if (this.state.editHierarchyRequested)
             items.push(<HierarchyEditPopup 
                            zIndex={100}
                            closePopup={() => this.setState(state => { return { ...state, editHierarchyRequested: false } })}
                            name={this.state.currentStructure.key.name}
                            textProvider={this._language}
                            onEdit={name => this.onStructureEdit(name)}/>)

        if (this.state.structureAddRequested)
            items.push(<HierarchyAddPopup 
                            zIndex={100} 
                            closePopup={() => this.setState(state => { return { ...state, structureAddRequested: false } })}
                            width='default'
                            textProvider={this._language}
                            addStructure={(name,mainDepName) => this.props.addStructure(name,mainDepName)}/>)

        return items;
    }    

    callPopup(
        currentStructure : Pair<Hierarchy, DepartmentView[]>,
        currentView : DepartmentView,
        superiorView : DepartmentView,
        statePropName : string) {
            this.setState(state => { return {
                ...state,
                currentStructure: currentStructure,
                currentView: currentView,
                superiorView: superiorView,
                [statePropName]: true
             }})
    }

    structureDeleteRequested(id : string) {
        let struct = new Hierarchy('');
        struct.id = id;
        this.callPopup(new Pair(struct, []), new DepartmentView(new Department('','',0)), new DepartmentView(new Department('','',0)), 'deleteStructureRequested');
    }

    render() {
        let content;
        if (!this.props.isLoaded) {
            content = <PreloaderCompactDefault />;
        } else {
            content = <div className="StructuresUIContentContainer">
                {this.props.structures.map(structure => <StructureComponent 
                                                            textProvider={this._language}
                                                            hierarchy={structure.key}
                                                            isReadonly={false}
                                                            structure={structure.value}
                                                            itemSelected={(view, superior) => this.callPopup(structure, view, superior, 'editPopupRequested')}
                                                            extendChanged={(hierarchyId, struct) => this.props.extendChanged(hierarchyId, struct)}
                                                            itemAddRequested={(view) => this.callPopup(structure, new DepartmentView(new Department('','',0)), view, 'addPopupRequested')}
                                                            itemDeleteRequested={(curr, sup) => this.callPopup(structure, curr, sup, 'deletePopupRequested')}
                                                            structureDeleteRequested={id => this.structureDeleteRequested(id)}
                                                            editHierarchy={hierarchy => this.structureEditRequested(hierarchy)}/>)}
                <InteractiveHintsManager 
                    lang={this._language}
                    interativehintActions={this.props.interactiveHintActions}
                    infos={[
                        {
                            hintKey : InteractiveHintsKeys.structuresHintKey,
                            hint : this._language.interactiveHintStructures,
                            position : {
                                anchorId : structuresContainerId,
                                shiftH : 5,
                                shiftV : 5
                            }
                        }
                    ]}/>
            </div>
        }
        
        return <div id={structuresContainerId} className="StructuresUIContainer">
            {this.getPopups()}
            <p className="StructuresUIHeader">{this._language.structures}</p>
            <p className="StructuresUIPrompt">{this._language.structuresPrompt}</p>
            {content}
            <div className="StructuresUIAddButton" onClick={() => this.setState(state => { return { ...state, structureAddRequested: true } })}>+ {this._language.structureAdd}</div>
        </div>
    }
}