import React from "react"

export interface IAppState{
    isDemoVersion : boolean
    isLoad : boolean
   
}

class StartState implements IAppState{
    isDemoVersion = false
    isLoad = false
  
}


const context:React.Context<IAppState>=React.createContext(new StartState());



export default context;