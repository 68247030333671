import RequestSettings from "../RequestProvider/RequestSettings";
import RequestSignature from "../RequestProvider/RequestSignature";

class ReportsServiceConstants {

    public static readonly CurrentReports = new RequestSignature('GET', '/api/v1.0/Reports/CurrentReports')

    public static readonly GetReports : RequestSignature = new RequestSignature("GET", "/api/v1.0/Reports/ReportsAnalysis");
    
    public static readonly GetReport : RequestSignature = new RequestSignature("GET", "/api/v1.0/Reports/ReportId");
    
    public static readonly DeleteReport : RequestSignature = new RequestSignature("DELETE", "/api/v1.0/Reports/Report");

    public static readonly GetReportInterval : RequestSignature = new RequestSignature("GET", "/api/v1.1/Reports/ReportDateInterval");

    public static readonly  AddReportsHierarchyAllForm : RequestSignature = new RequestSignature("POST", "/api/v1.0/Reports/ReportsHierarchy");
    
}

export default ReportsServiceConstants;