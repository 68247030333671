import React from "react";
import {ReportType, reportTypeSwitch} from "../../Models/Reports/ReportType";

import "./CircleValue.css";

interface IProps {
    value : number
    reportType: ReportType
    backgroundColor : string
}

function CircleValue(props : IProps) {
    return <div style={{background : getGradient(props.reportType, props.value, props.backgroundColor)}} className = "circleValue">
                <p className="SubHeader">{props.value.toString()}</p>
           </div>
}

function getGradient(reportType : ReportType, value : number, backgroundColor : string) : string {
    const colorEmpty : string = '#F3F3F3';

    let ratio : number = getRatio(reportType, value);

    if (ratio == 1)
        return `${backgroundColor}`

    if (ratio == 0)
        return `${colorEmpty}`

    let transitionPoint : number = ratio;

    let colorGradient : string = `conic-gradient(${backgroundColor} 0turn, 
                                                 ${backgroundColor} ${transitionPoint}turn, 
                                                 ${colorEmpty}      ${transitionPoint}turn)`;

    let emptyGradient : string = `conic-gradient(${colorEmpty} ${transitionPoint}turn,
                                                 ${colorEmpty} 1turn)`;

    return `${colorGradient}, ${emptyGradient}`;
}

//Wellbeing, Emotional, Activity -> 0 to 5
function getRatio(type : ReportType, value : number) : number {
    return reportTypeSwitch(type,
        () => value / 5,
        () => value / 5,
        () => value / 5)();
}

export default CircleValue;