import Department from "../../Models/Organizations/Department/Department";
import BaseReportIndexAnalysis from "../../Models/Analysis/Report/BaseReportIndexAnalysis";
import ChartData from "../../Models/Entintys/ChartData";
import DateType, { GetValueByDateType } from "../../Models/Analysis/DateType";
import BaseAnalysis from "../../Models/Analysis/BaseAnalysis";

export default class OrganizationDataConverterToUI{
    public static GetSubDepartmentsDataUi(
        mainDepartment : Department, 
        reportsAnalysis : BaseReportIndexAnalysis[], 
        period : Date[]){
        
        let departments = [mainDepartment].concat(mainDepartment.subDepartments);
        let uiData = departments.map(item => this.GetDepartmentAnalysisValues(item,reportsAnalysis,period));  

        return uiData;
    }

    public static GetDepartmentAnalysisValues(department : Department, reportsAnalysis : BaseReportIndexAnalysis[], period : Date[]) : ChartData {
        let report = reportsAnalysis.find(valueItem => valueItem.departmentId == department.id);
        if (!report || !report.indexAnalysisCollection)
            return new ChartData(department.name, [], NaN);
        
        let nowReport = report as BaseReportIndexAnalysis;
        let uniqueNowReportIndex : BaseAnalysis[] = [];

        uniqueNowReportIndex = period.map(date => {
            let value = nowReport.indexAnalysisCollection.find(item => 
                this.GetItemToCompare(new Date(item.postTime), nowReport.dateType) == this.GetItemToCompare(date, nowReport.dateType));

            return value ? new BaseAnalysis(value.id, value.postTime, Math.floor(value.value * 1000) / 1000) : new BaseAnalysis('',date.toISOString(),NaN);
        });
        
        return new ChartData(department.name, uniqueNowReportIndex.map(item => item.value), nowReport.avgValue);
    }

   private static GetItemToCompare(date : Date, dateType : DateType) : number {
    const getUtcDate = (d : Date) => new Date(d.getTime());

    return GetValueByDateType(dateType,
        () => date.getDate(),
        () => getUtcDate(date).getMonth(),
        () => date.getDate(),
        () => getUtcDate(date).getFullYear())();
    }
}