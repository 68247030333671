import DateType from "../DateType";

class AddAnalysis{

    constructor(firstDate : Date, secondDate : Date, dateType : DateType){
        this.firstDate = firstDate;
        this.secondDate = secondDate;
        this.dateType = dateType;
    }

    public firstDate : Date;

    public secondDate : Date;

    public dateType : DateType;

}

export default AddAnalysis;