enum Role{
    Administrator = 0,
    HR = 1,
    Consultant = 2,
    Unconfirmed = 3,
    Manager = 4,
    Employee = 5,
    CEO = 6,
    Lead = 7,
    WorkingDoctor = 8,
    CompanyAdministrator = 9,
    Banned = 10,
}

export default Role;