import IDisabledDates from "./IDisabledDates";

class WeekDisabledDates implements IDisabledDates{

    public GetDisabledDays() : Array<number> {
        return [0,2,3,4,5,6];
    }

    public GetDisabledDates(startDate : Date, endDate : Date) : Array<Date> {
        return [];
    }


}

export default WeekDisabledDates;