import PaymentType from "../../Enums/PaymentType";
import Currency from "../Currencies/Currency";
import BalanceRecordAPI from "./BalanceRecordAPI";

export default class BalanceRecord {
    constructor(proto : BalanceRecordAPI, currency : Currency) {
        this.postTime = new Date(proto.postTime);
        this.amount = proto.balance;
        this.currency = currency;
        this.paymentType = proto.typePayment;
    }

    public postTime : Date;

    public amount : number;

    public currency : Currency;

    public paymentType : PaymentType
}