import EmployeeAPI from './EmployeeAPI'
import EmployeeBaseLocal from "./EmployeeBaseLocal";
import EmployeeOrganizationInfo from "./EmployeeOrganizationInfo";
import EmployeeType from '../../Enums/EmployeeType'
import Gender from "../../Enums/Gender"
import OrganizationInfo from '../OrganizationReport/OrganizationInfo'

export default class EmployeeLocal extends EmployeeBaseLocal {
    public static FromAPI(apiModel : EmployeeAPI, infos : OrganizationInfo[]) {
        let departmentsInfo : EmployeeOrganizationInfo[] = [];
        if (apiModel.departmentIds)
            departmentsInfo = apiModel.departmentIds.map(id => this.FindDepartment(id, infos))
                                                    .filter(i => i)
                                                    .map(i => i as EmployeeOrganizationInfo);                                    
        let result : EmployeeLocal = {...apiModel, departmentsInfo: departmentsInfo };
        return result;
    }

    private static FindDepartment(id : string, infos : OrganizationInfo[]) : EmployeeOrganizationInfo | null {
        for (let orgKey in infos) {
            let org = infos[orgKey];
            for (let hierKey in org.hierarchies) {
                let hier = org.hierarchies[hierKey];
                for (let depKey in hier.departments){
                    let dep = hier.departments[depKey];
                    if (dep.id == id)
                        return new EmployeeOrganizationInfo(dep.id, dep.name, hier.id, hier.name, org.id, org.name);
                }
            }
        }

        return null;
    }

    constructor(
        employeeId : string = '',
        firstName : string = '', 
        lastName : string = '', 
        phone : string = '', 
        email : string = '', 
        birthDate : string ='', 
        gender : Gender = -1, 
        profession : string = '', 
        position : EmployeeType = EmployeeType.Contributor, 
        workExperienceMonths : number = 0, 
        employementDate : string = '',
        departmentInfo : EmployeeOrganizationInfo[] = [],
        isInvited : Boolean = false,
        sentInvitationDate : string = '',
        activationDate : string = '',
        deactivationDate : string = '') {
            super(
                employeeId,
                firstName,
                lastName,
                phone,
                email,
                birthDate,
                gender,
                profession,
                position,
                workExperienceMonths,
                employementDate,
                departmentInfo
            )

            this.departmentsInfo = departmentInfo;
            this.isInvited = isInvited;
            this.employeeId = employeeId;
            this.sentInvitationDate = sentInvitationDate;
            this.activationDate = activationDate;
            this.deactivationDate = deactivationDate;
        }

    public isInvited : Boolean;

    public employeeId : string;

    public sentInvitationDate : string;

    public activationDate : string;

    public deactivationDate : string;
}