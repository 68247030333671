type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

class RequestSignature{
    public HttpMethod : HttpMethod;
    public Uri : string;
    public ContentType : string

    constructor(httpMethod:HttpMethod, uri:string, contentType : string = "application/json;charset=utf-8"){
        this.HttpMethod = httpMethod;
        this.Uri = uri;
        this.ContentType = contentType;
    }
    
}

export default RequestSignature;