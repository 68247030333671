import DateType, { GetValueByDateType } from "../../Models/Analysis/DateType";
import DatesHelper from "../DatesHelper";

export default class DateValidator {

    private static readonly MaxRangeMonth :number = 5;

    private static readonly MaxRangeYear = 3;

    private static readonly MaxRangeDay = 2;

    private static readonly MaxRangeWeek = 3;

    public static ChangeFirstDate(firstDate : Date, secondDate : Date, sortType : DateType) : Date {
        const [isFirstAfter, yearsDiff, monthesDiff, daysDiff] = DatesHelper.calcDiff(secondDate, firstDate);

        return GetValueByDateType(sortType,
            () => yearsDiff > 0 || monthesDiff > this.MaxRangeDay ? this.GetMaxFirst(secondDate, sortType) : firstDate,
            () => yearsDiff > 0 || monthesDiff < this.MaxRangeMonth ? this.GetMaxFirst(secondDate, sortType) : firstDate,
            () => this.GetMaxFirst(secondDate, sortType),
            () => yearsDiff < this.MaxRangeYear ? this.GetMaxFirst(secondDate, sortType) : firstDate)();
    }

    public static ChangeSecondDate(firstDate : Date,secondDate : Date, sortType : DateType) : Date {
        const [isFirstAfter, yearsDiff, monthesDiff, daysDiff] = DatesHelper.calcDiff(secondDate, firstDate);

        return GetValueByDateType(sortType,
            () => yearsDiff > 0 || monthesDiff > this.MaxRangeDay ? this.GetMaxSecond(firstDate, sortType) : secondDate,
            () => yearsDiff > 0 || monthesDiff > this.MaxRangeMonth ? this.GetMaxSecond(firstDate, sortType) : secondDate,
            () => yearsDiff > 0 || monthesDiff > this.MaxRangeWeek ? this.GetMaxSecond(firstDate, sortType) : secondDate,
            () => yearsDiff > this.MaxRangeYear ? this.GetMaxSecond(firstDate, sortType) : secondDate)();
    }

    public static GetMaxFirst (secondDate : Date, sortType : DateType) : Date {
        return GetValueByDateType(sortType, 
            () => this.ChangeDate(secondDate, 0, 0, -2),
            () => this.ChangeDate(secondDate, 0, -2, 0),
            () => this.ChangeDate(secondDate, 0, 0, -14),
            () => this.ChangeDate(secondDate, -1, 0, 0))();
    }

    public static GetMinFirst(secondDate : Date, sortType : DateType) : Date{
        return GetValueByDateType(sortType,
            () => this.ChangeDate(secondDate, 0, -this.MaxRangeDay, 0),
            () => this.ChangeDate(secondDate, 0, -this.MaxRangeMonth, 0),
            () => this.ChangeDate(secondDate, 0, -this.MaxRangeWeek, 0),
            () => this.ChangeDate(secondDate, -this.MaxRangeYear, 0, 0))();
    }
    
    public static GetMaxSecond (firstDate : Date, sortType : DateType) : Date {
        return GetValueByDateType(sortType,
            () => this.ChangeDate(firstDate, 0, this.MaxRangeDay, 0),
            () => this.ChangeDate(firstDate, 0, this.MaxRangeMonth, 0),
            () => this.ChangeDate(firstDate, 0, this.MaxRangeWeek, 0),
            () => this.ChangeDate(firstDate, this.MaxRangeYear, 0, 0))();
    }

    public static GetMinSecond (firstDate : Date, sortType : DateType) : Date {
        return GetValueByDateType(sortType,
            () => this.ChangeDate(firstDate, 0, 0, 2),
            () => this.ChangeDate(firstDate, 0, 2, 0),
            () => this.ChangeDate(firstDate, 0, 0, 14),
            () => this.ChangeDate(firstDate, 1, 0, 0))();
    }

    private static ChangeDate(date : Date, yearsDiff : number, monthesDiff : number, daysDiff : number) : Date {
        return new Date(date.getFullYear() + yearsDiff, date.getMonth() + monthesDiff, date.getDate() + daysDiff);
    }
}