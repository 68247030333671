import Colors from "./Colors";
import IColorRecognizer from "./IColorRecognizer";

class VolumeColorRecognizer implements IColorRecognizer{
    
    public GetColorCode(value:number) : string{
        let color = "";
        if(value >= 1 && value < 2)
            color = Colors.lightRed;
        if(value >= 2 && value < 4)
            color = Colors.lightYellow;
        if(value >= 4 && value <= 5)
            color = Colors.lightGreen;
        return color;
    }
}

export default VolumeColorRecognizer;