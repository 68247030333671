class Validator{
    
    public static readonly globalLetter:RegExp=/[A-ZAА-Я-ÂÀÇËÉÊÈÏÎÔÛÙÜŸ]/;
    
    public static readonly numeral:RegExp=/[0-9]/;

    public static readonly nameReg : RegExp = /^[a-zA-Zа-яА-ЯÂâÀàÇçËëÉéÊêÈèÏïÎîÔôÛûÙùÜüŸÿ0-9]*$/
    
    public static readonly emailTemplate:RegExp=/^.+@.+\..+$/;

    public static readonly phoneTemplate:RegExp=/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){9,14}(\s*)?$/;

    public static readonly URLTemplate:RegExp = /^((https?|ftp)\:\/\/)?([a-z0-9]{1})((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z]{2,6})(\/?)$/;
  
    private static CheckRegExp(str:string, exp:RegExp) {
        return (Boolean)(str.match(exp) ?? false);
    }
  
    public static isEmpty(str:string) {
        return str==""
    }

    public static IsPassword(s : string) {
        return Validator.IsPasswordLenght(s) && Validator.IsHaveGlobalLetter(s) && Validator.IsHaveNumeral(s);
    }

    public static IsPasswordLenght(str:string) {
        return !!str && str.length >= 8;
    }
  
    public static IsHaveGlobalLetter(str:string) {
        return !!str && Validator.CheckRegExp(str,Validator.globalLetter);
    }
  
    public static IsHaveNumeral(str:string) {
        return !!str && Validator.CheckRegExp(str,Validator.numeral);
    }
  
    public static IsOptionalEmailTemplate(str:string) {
        return (str !== null && str !== undefined) && (str.length === 0 || Validator.IsEmailTemplate(str))
    }

    public static IsEmailTemplate(str:string) {
        return !!str && Validator.CheckRegExp(str,Validator.emailTemplate);
    }

    public static IsOptionalPhoneTemplate(str:string) {
        return (str !== null && str !== undefined) && (str.length === 0 || Validator.IsPhoneTemplate(str));
    }

    public static IsPhoneTemplate(str:string) {
        return !!str && Validator.CheckRegExp(str,Validator.phoneTemplate);
    }

    public static IsOptionalURLTemplate(str:string) {
        return (str !== null && str !== undefined) && (str.length === 0 || Validator.IsURLTemplate(str));
    }

    public static IsURLTemplate(str : string) {
        return !!str && Validator.CheckRegExp(str,Validator.URLTemplate);
    }
  
    public static isNullOrWhitespace(str : string) {
        return !str || str.trim().length === 0;
    }

    public static isOlderThan(d : Date, age : number) {
        let diff = new Date(new Date().getTime() - d.getTime());
        let years = diff.getFullYear() - 1970;
        if (years >= age)
            return true;
        
        return false;
    }
  }
  
  export default Validator;
  