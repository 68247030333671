enum Language{
    En = 1,
    Fr = 2,
    Ru = 3
}

export function getValueByLang<T>(lang : Language, onEn : T, onFr : T, onRu : T) {
    switch (lang) {
        case Language.En: return onEn;
        case Language.Fr: return onFr;
        case Language.Ru: return onRu;

        default: throw new Error('Such lang not implemented');
    }
}

export default Language;