import React, { FormEvent } from "react";

import "./Select.css";

interface ISelect{
    nowValue : string
    mainPrompt : string
    name : string
    values : Array<string>
    prompts : Array<string>
    changeValue(value : string, name : string) : void
}

function Select(props : ISelect){

    const onChangeValue = (e : FormEvent<HTMLSelectElement>) => {
        let value = e.currentTarget.value;
        props.changeValue(value,props.name);
    }

    let options = props.values.map((item,index) => {
        if(item == props.nowValue)
            return (<option selected value = {item}>{props.prompts[index]}</option>);
        else 
            return (<option value = {item}>{props.prompts[index]}</option>);
    });

    return(<div className = "select">
            <div className = "selectPrompt">{props.mainPrompt}</div>
            <select onChange = {onChangeValue}>
                {options}
            </select>
            <div className = "selectErrorPrompt"></div>
           </div>);
}

export default Select;