import DepartmentBase from "./DepartmentBase";

class DepartmentAdd extends DepartmentBase {

    constructor(id : string,headDepartmentId : string, name : string, numberDepartment : number) {
        super(id,name, numberDepartment);


        this.headDepartmentId = headDepartmentId;
    }

    public headDepartmentId : string;
}

export default DepartmentAdd;