import AuthUser from "./AuthUser";
import Language from "./Language";
import Role from '../Role'
import UserInfo from "./UserInfo";

class BaseUser extends AuthUser{

    constructor(
        id : string = '',
        password : string = '',
        userName : string = '',
        firstName : string = '',
        lastName : string = '',
        email : string = '',
        phone : string = '',
        lastActivity : string = '',
        langKey : Language = Language.Fr,
        isDemo : boolean = true,
        positions : Role[] = [],
        registrationDate: string = '') {
        super(email,password);
        this.id = id;
        this.userName=userName;
        this.firstName=firstName;
        this.lastName=lastName;
        this.phone=phone;
        this.lastActivity = lastActivity;
        this.languageType = langKey;
        this.isDemoVersion = isDemo;
        this.positions = positions;
        this.registrationDate = registrationDate;
    }

    public static edit(user : BaseUser,
        firstName : string,
        lastName : string,
        email : string, 
        phone : string) {
        
        user.firstName = firstName;
        user.lastName = lastName;
        user.email = email;
        user.phone = phone;
    }

    public id : string

    public userName:string

    public firstName:string

    public lastName:string

    public phone:string

    public lastActivity : string

    public languageType : Language

    public isDemoVersion : boolean;

    public positions : Role[];

    public registrationDate : string;
}

export default BaseUser;