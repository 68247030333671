enum EmployeeType
{
    Founder = 0,
    TopManagement = 1,
    FunctionalManagement = 2,
    MiddleManagement = 3,
    LineManagement = 4,
    Contributor = 5
}

export default EmployeeType;