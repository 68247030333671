import DepartmentBase from "./DepartmentBase";

class DepartmentAddAsMain extends DepartmentBase {
    constructor(id :string, name : string, hierarchyId : string, numberDep : number) {
        super(id,name, numberDep);


        this.hierarchyId = hierarchyId;
    }

    public hierarchyId : string;
}

export default DepartmentAddAsMain;