class MainHierarchy{

    public organizationId : string;

    public name : string;

    constructor(orgId : string, name : string){
        this.organizationId = orgId;
        this.name = name;
    }
}

export default MainHierarchy;