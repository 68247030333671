import EmployeeAccess from '../../Enums/EmployeeAccess';
import EmployeeType from '../../Enums/EmployeeType'
import Gender from "../../Enums/Gender"
import Language from '../Users/Language';

abstract class EmployeeBasic {
    constructor(
        employeeId : string,
        firstName : string, 
        lastName : string, 
        phone : string, 
        email : string, 
        birthDate : string, 
        gender : Gender, 
        profession : string, 
        position : EmployeeType, 
        workExperienceMonths : number, 
        employementDate : string,
        languageType : Language,
        employeeAccess : EmployeeAccess) {

        this.employeeId = employeeId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
        this.birthDate = birthDate;
        this.gender = gender;
        this.profession = profession;
        this.position = position;
        this.workExperienceMonths = workExperienceMonths;
        this.employmentDate = employementDate;
        this.languageType = languageType;
        this.accessTypeEmployee = employeeAccess;
    }

    public employeeId : string;

    public firstName : string;
    
    public lastName : string;

    public phone : string;

    public email : string;

    public birthDate : string;

    public gender : Gender;

    public profession : string;

    public position : EmployeeType;

    public workExperienceMonths : number;

    public employmentDate : string;

    public languageType : Language;

    public accessTypeEmployee : EmployeeAccess
}

export default EmployeeBasic;