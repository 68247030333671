import React from "react"
import BaseContainer from "../../BaseContainer"
import OrganizationUI from "./OrganizationUI"
import Hierarchy from "../../../Models/Organizations/Hierarchy";
import Preloader from "../../../Components/Preloader/Preloader";
import { ITextProvider, TextProvider } from "../../../Helpers/TextProvider/TextProvider";
import IMiddlewareActionsOrganization from "../../../ContainersMiddlewares/MiddlewareActionsOrganization/IMiddlewareActionsOrganization";
import context from "../../../AppContext";
import Department from "../../../Models/Organizations/Department/Department";
import Report from "../../../Models/Analysis/Report";
import DateType from "../../../Models/Analysis/DateType";
import RoutesUrl from "../../../Helpers/Routes/RoutesUrl";
import ReportInterval from "../../../Models/Reports/ReportInterval";
import MiddlewareActionsOrganization from "../../../ContainersMiddlewares/MiddlewareActionsOrganization/MiddlewareActionsOrganization";
import ReportsGroup from "../../../Models/Analysis/Report/ReportsGroup";
import ILocalStorageAgent from "../../../LocalStorageWorker/ILocalStorageAgent";
import LocalStorageAgent from "../../../LocalStorageWorker/LocalStorageAgent";
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";


export interface IOrganizationState{
    nowReport : Report

    reportsGroup : ReportsGroup
    allReportsList : Array<Report>,
    hierarchy : Hierarchy
    hierarchys : Array<Hierarchy>
    nowDepartment : Department
    isLoad : boolean
    sortType : DateType

    //reports state
    minFirstDateGlobal : Date
    maxSecondDateGlobal : Date

    isExistReports : boolean
}

class OrganizationPage extends BaseContainer<{},IOrganizationState>{
    private timer :NodeJS.Timeout | null = null;

    private readonly _localStorageAgent : ILocalStorageAgent = new LocalStorageAgent();
    private readonly middlewareAction : IMiddlewareActionsOrganization = new MiddlewareActionsOrganization();
    private readonly _language : ILanguagePhrase = new TextProvider().GetLang();

    constructor(props:any){
        super(props);

        this.middlewareAction.Init(this.endLoadInitHandler, () => {window.location.href = RoutesUrl.NoHierarchy}, 
                                   this.notExistReportsCallback, this.notExistReadyReportCallback);

        this.timer = setInterval(() => {
            this.middlewareAction.GetReports()
                .then((reports : Array<Report>) => {this.setState({allReportsList : reports})})
                .catch(() => {})
        },20000);

        this.state={
            nowReport : Report.getReport(),

            reportsGroup : new ReportsGroup([],[],[]),
            allReportsList : [],
            hierarchy : new Hierarchy(""),
            hierarchys : [],
            nowDepartment : new Department("", "", 0),
            isLoad : false,
            sortType : DateType.ByMonth,

            minFirstDateGlobal : new Date(2020,0),
            maxSecondDateGlobal : new Date(2022,8),

            isExistReports : false
        }
    }

    //life cycle
    componentWillUnmount = () => {
        if(this.timer)
            clearInterval(this.timer);
    }

    changeNowHierarchy = (hierarchyId : string) => {
        this.setState({isLoad : false, hierarchy : this.state.hierarchys.find(item => item.id == hierarchyId) as Hierarchy});
        const nowReport = this.state.allReportsList.filter(item => item.hierarchyId == hierarchyId).find(item => item.isReady || item.almostReady);
        if(nowReport)
            this.setState({nowReport});
        this.setState({isLoad : true});
    }

    changeNowReport = (reportId : string) => {
        this._localStorageAgent.appendDefaultReportIdByHierarchyId(this.state.hierarchy.id, reportId);
        let nowReport = this.state.allReportsList.find(item => item.reportId == reportId) as Report;
        this.setState({isLoad : false, nowReport});
        this.middlewareAction.GetAllIndexeByReportId(reportId).then(group => this.endLoadDateIndexes(group));
    }

    endLoadDateIndexes = (reportsGroup : ReportsGroup) => {
        this.setState({
            reportsGroup: reportsGroup, 
            isLoad : true, 
            isExistReports : true
        });
    }

    notExistReportsCallback = (hierarchy : Hierarchy, hierarchys : Array<Hierarchy>, reportInterval : ReportInterval) => {
        this.setState({hierarchy, hierarchys, 
                        maxSecondDateGlobal : new Date(reportInterval.secondDate), 
                        minFirstDateGlobal : new Date(reportInterval.firstDate),
                        isLoad : true, 
                        isExistReports : false});
        
    }

    notExistReadyReportCallback = (hierarchy : Hierarchy, hierarchys : Array<Hierarchy>, reportInterval : ReportInterval, reports : Array<Report>) => {
        this.notExistReportsCallback(hierarchy, hierarchys, reportInterval);
        this.setState({allReportsList : reports});
        
    }

    endLoadInitHandler = (reportsGroup : ReportsGroup,
                          allReports : Array<Report>,
                          nowReport : Report,
                          hierarchys : Array<Hierarchy>,
                          hierarchy : Hierarchy,
                          reportInterval : ReportInterval) : void => {
        let nowDepartment = hierarchy.mainDepartment as Department;
    
        if(reportsGroup.anxietyReport.length !== 0)
        {
            this.fillInterval(reportInterval, nowReport);

            let nowDateType = reportsGroup.anxietyReport[0].dateType;
            let secondDate = new Date(reportsGroup.anxietyReport[0].secondDate);
            let sub = secondDate.getTimezoneOffset();
            secondDate = new Date(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate(), secondDate.getHours(), secondDate.getMinutes() - sub);
            if(nowDateType == DateType.ByMonth){
                secondDate.setDate(secondDate.getDate() + 1);
            }
            this.setState({
                hierarchy : hierarchy,
                reportsGroup : reportsGroup, 
                allReportsList : allReports, 
                nowDepartment : nowDepartment, 
                isLoad : true, 
                sortType : reportsGroup.anxietyReport[0].dateType,
                hierarchys : hierarchys,
                nowReport : nowReport,
                isExistReports : true
            });
            return;
        }
            
        this.setState({
            hierarchy : hierarchy,
            reportsGroup : reportsGroup, 
            allReportsList : allReports, 
            nowDepartment : nowDepartment, 
            isLoad : true,
            sortType : DateType.ByMonth,
            hierarchys: hierarchys,
            nowReport :nowReport,
            isExistReports : true
        });
    }
         
    fillInterval = (interval : ReportInterval, report : Report) => {
        let minFirstDateGlobal = new Date(interval.firstDate);
        let maxSecondDateGlobal = new Date(interval.secondDate);

        this.setState({minFirstDateGlobal, 
                       maxSecondDateGlobal
                    });
    }

    findNowDepartment = (mainDep : Department, nowDepId : string, nowDep : Department) => {
        if(mainDep.id == nowDepId)
            nowDep = mainDep;
        else {
            mainDep.subDepartments.forEach((item) => {
                nowDep = this.findNowDepartment(item,nowDepId,nowDep);
            });
        }
        return nowDep;
    }

    changeNowDepartmentHandler = (depId : string) => {
        let nowDepartment = this.findNowDepartment(this.state.hierarchy.mainDepartment as Department, depId, new Department("", "", 0));
        this.setState({nowDepartment});
    }

    //reports

    generateNewReport = (startDate : Date, endDate : Date, dateType : DateType) => {
        this.middlewareAction.reportHierarchy(startDate, endDate, dateType, this.state.hierarchy.id)
            .then(() => {
                let newReport = new Report("", new Date().toString(),
                startDate.toString(),
                endDate.toString(),
                dateType,
                false, 
                false, 
                this.state.hierarchy.id);
                if(this.state.allReportsList && this.state.allReportsList.length)
                    this.setState({allReportsList : this.state.allReportsList.concat(newReport)});
                else
                    this.setState({allReportsList : [newReport]});
            });
    }

    deleteReport = (reportId : string) => {
        this.setState({isLoad : false});
        this.middlewareAction.deleteReport(reportId)
            .then(_ => {
                let newReportsList = this.state.allReportsList.filter(item => item.reportId != reportId);
                if(newReportsList.length == 0){
                    this.notExistReportsCallback(this.state.hierarchy, 
                                                 this.state.hierarchys, 
                                                 new ReportInterval(this.state.minFirstDateGlobal.toString(), 
                                                 this.state.maxSecondDateGlobal.toString()));
                }
                else{
                    this.setState({nowReport : newReportsList[0] ,isLoad : true});
                }
                this.setState({allReportsList : newReportsList});
            })

    }
    
    checkOtherDepartment = (dep : Department) => {
        this.setState({nowDepartment : dep});
    }

    render(){
        let content = <Preloader/>
        if(this.state.isLoad)
            content = (<OrganizationUI       
                            arraysDeps = {Hierarchy.getDepartments(this.state.hierarchy)}
                            state={this.state}
                            textProvider = {this._language}
                            changeNowDepartmentHandler = {this.changeNowDepartmentHandler}
                            reportHierarchy = {this.generateNewReport}
                            changeNowReport = {this.changeNowReport}
                            deleteReport = {this.deleteReport}
                            checkOtherDepartment = {this.checkOtherDepartment}
                     />);

        return(content);
    }

}

OrganizationPage.contextType = context;

export default OrganizationPage;