import BalanceType from "../../Enums/BalanceType";
import BalanceUserAPI from "./BalanceUserAPI";
import Currency from "../Currencies/Currency";
import { Euro } from "../../Helpers/Currencies";

export default class BalanceUser {
    constructor(proto : BalanceUserAPI = new BalanceUserAPI(), currency : Currency = Euro) {
        this.userId = proto.userId;
        this.postTime = proto.postTime;
        this.balance = proto.balance;
        this.currency = currency;
        this.balanceType = proto.typeBalance;
        this.expirationDate = new Date(proto.expirationDate);
    }

    public userId : string;

    public postTime : string;

    public balance : number;

    public currency : Currency

    public balanceType : BalanceType

    public expirationDate : Date;
}