import React from 'react'

import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'
import RoutesUrl from '../../Helpers/Routes/RoutesUrl'
import Language, { getValueByLang } from '../../Models/Users/Language'

import './InformationComponent.css'

interface IInformationComponent {
    textProvider : ILanguagePhrase
}

export default function InformationComponent(props : IInformationComponent) {

    const docLink = `https://whyeva.com/${getLangCode(props.textProvider.lang)}/docs.html`
    return <div className = "InformationComponentContainer">
        <p className="Header">{props.textProvider.resourcesInformation}</p>
        <p className="SubHeader">{props.textProvider.programVersion + ": 7.9.2"}</p>
        <a className = "InformationComponentLink Prompt" target = "_blank" href = {docLink}>{props.textProvider.termsOfSale}</a>
        <a className = "InformationComponentLink Prompt" target = "_blank" href = {docLink}>{props.textProvider.termsOfUse}</a>
        <a className = "InformationComponentLink Prompt" target = "_blank" href = {docLink}>{props.textProvider.dataProtection}</a>
        <a target="_blank" className="PrimaryButton InformationComponentEmailLink" href={RoutesUrl.getFAQLink(props.textProvider.lang)}>{props.textProvider.support}</a>
    </div>
}

function getLangCode(lang : Language) {
    const en = 'en';
    return getValueByLang<string>(lang, en, 'fr', en);
}