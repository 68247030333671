import IUserService from "../../Services/User/IUserService"
import BaseUser from "../../Models/Users/BaseUser";
import AuthUser from "../../Models/Users/AuthUser";
import IUserActions from "./IUserAction";
import Token from "../../Models/Token";
import Language from "../../Models/Users/Language";
import RoutesUrl from "../../Helpers/Routes/RoutesUrl";
import UserService from "../../Services/User/UserService";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";
import BadRequestAnswer from "../../Models/Exceptions/BadRequestAnswer";
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import { IRoutesAgent, RoutesAgent } from "../../Helpers/Routes/RoutesAgent";

class UserActions implements IUserActions {
    private readonly _userService : IUserService;
    private readonly _localStorageAgent : ILocalStorageAgent;
    private readonly _routesAgent : IRoutesAgent;
    constructor(
        userService : IUserService | null = null,
        localStorageAgent : ILocalStorageAgent | null = null,
        routesAgent : IRoutesAgent | null = null) {
    
        this._userService = userService ?? new UserService();
        this._localStorageAgent = localStorageAgent ?? new LocalStorageAgent();
        this._routesAgent = routesAgent ?? new RoutesAgent();
    }

    private SuccessAuthRegistrateCallBack(token : Token):void{
        this._localStorageAgent.token = token.authToken;
        this.GetUserProfile().then((user : BaseUser) => {
            this._localStorageAgent.user = user;
            this._routesAgent.navigateTo(RoutesUrl.Root);
        });

    }

    public async AuthorizeAsync(email:string, password:string, errorCallback : (s : string) => void, unConfirmedEmailCallback : () => void) {
        let user:AuthUser = new AuthUser(email,password);
        await this._userService.AuthorizeAsync(user, r => this.SuccessAuthRegistrateCallBack(r), r => errorCallback(r), unConfirmedEmailCallback );
    }

    public async RegistrationInOrganization(
        firstName:string,
        lastName:string,
        email:string,
        phoneNumber:string) : Promise<string>{
        let currentUser = this._localStorageAgent.user as BaseUser;
        let user:BaseUser=new BaseUser("","",firstName,firstName,lastName,email,phoneNumber,"",currentUser.languageType);
        return await this._userService.RegistrationInOrganization(user);
    }

    public async RegistrationAdmin(
        password : string,
        firstName : string,
        lastName : string,
        email : string,
        langKey : Language,
        isDemo : boolean) : Promise<string>{
        const user = new BaseUser("",password,firstName,firstName,lastName,email,'',"",langKey,isDemo);
        return await this._userService.RegistrationAdmin(user);
    }

    public async UpdateUserInfo(user : BaseUser) : Promise<string>{
        return await this._userService.UpdateUserInformation(user);
    }

    public async GetUserProfileById(userId :string) : Promise<BaseUser|string>{
        return await this._userService.GetUserProfileById(userId);
    }

    public async GetUserProfile() : Promise<BaseUser>{
        return await this._userService.GetUserProfile();
    }

    public async DeleteUser(userId : string) : Promise<string> {
        return await this._userService.DeleteUser(userId);
    }

    public async BlockManagerChangeAsync(userId : string, needToBlock : boolean) : Promise<boolean> {
        return await this._userService.BlockManagerChangeAsync(userId, needToBlock);
    }

    public async ResendConfirmationEmail(userId : string) : Promise<string> {
        return await this._userService.ResendConfirmationEmail(userId);
    }

    public async ResetPassword(email : string) : Promise<boolean> {
        return await this._userService.ResetPassword(email);
    }
}

export default UserActions;