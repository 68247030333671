enum NumberOfEmployees { Under20, From20To49, From50To249, Over250 }

export function getValueByNumberOfEmployees<T>(v : NumberOfEmployees, 
    onUnder20 : T,
    on20To49 : T,
    on50To249 : T,
    onOver250 : T) {
    switch (v) {
        case NumberOfEmployees.Under20: return onUnder20;
        case NumberOfEmployees.From20To49: return on20To49;
        case NumberOfEmployees.From50To249: return on50To249;
        case NumberOfEmployees.Over250: return onOver250;

        default: throw new Error('Not implemented');
    }
}

export default NumberOfEmployees;