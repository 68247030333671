import {ChartConfiguration,ChartDataSets, Chart, ChartData} from 'chart.js';

import ChartOptionClass from "../../../Models/ChartModels/ChartOption";
import ArrayChartOption from "../../../Models/ChartModels/ArrayChartOption";


class ChartOption{


     public GetOption(chartsOption : ArrayChartOption, periods:Array<string>,stepSize : number, isShowYValues : boolean ,maxY : number = 0, minY : number = 0 ):ChartConfiguration {
        let datasets=this.GetDatasets(chartsOption.GetGhartOptions);
        const isMobile = document.body.clientWidth < 1024;
        let option : ChartConfiguration = {
            type:"line",
            data:{
                datasets,
                labels:periods
            },

            options: {
                tooltips : {
                    titleFontSize: isMobile ? 36 : 12,
                    bodyFontSize: isMobile ? 30 : 12,
                },
                elements : {
                    line : {
                        borderWidth: isMobile ? 6 : 3,
                    },
                    point : {
                        radius : isMobile ? 10 : 3,
                        borderWidth : isMobile ? 6 : 1,
                        hoverRadius : isMobile ? 20 : 1,
                    },
                },
                plugins : {
                    title : {
                        display : true,
                        text : "ft"
                    },
                },
                animation : {
                    duration : 0,
                },
                
                legend : {
                    display : false,
                },
               
                scales: { 
                    xAxes : [{
                        ticks : {
                            fontSize: isMobile ? 20 : 12,
                        }
                    }],
                    yAxes: [{
                        ticks : {
                            display : isShowYValues,    
                            min : minY,
                            max : maxY,
                            stepSize,
                            fontSize : isMobile ? 30 : 12,
                        },
                    }],
                },
            }
        }

        return option;

     }

 


     private GetDatasets(chartsOption : Array<ChartOptionClass>)
     {
        let dataSets:Array<ChartDataSets> = chartsOption.map(item => {
            return {
                type : item.chartType,
                data : item.data._values,
                label : item.data._name,
                fill : item.isFill,
                pointBackgroundColor : item.data._values.map(val => item.coloringStrategy.GetColorCode(val)),
                backgroundColor : item.color,
                borderColor: 'black',
                lineTension: 0,
            }
        });
        return dataSets;
     }

}

export default ChartOption;

