import Pair from '../Models/Pair'

const dataKey = 'data'

export function GetMessage(data : any) : string {
    let result = FindMessage(data);
    return result.key ? result.value : '';
}

//{
//  ...
//  data: {
//      'PropName': 'message'
//  }
//}

export function FindMessage(data : any) : Pair<boolean, string> {
    if (typeof data === "string") 
        return new Pair(true, data as string);

    const message = data['message'];
    if (message) return new Pair(true, message);

    if (Object.keys(data).some(key => key === dataKey))
        return FindMessage(data[dataKey]);

    for (let key in data) {
        let prop = data[key];
        
        for (let innerKey in prop)
            if (typeof prop[innerKey] === 'string') return new Pair(true, prop[innerKey])
    }

    return new Pair(false, '');
}