import BaseReportIndexAnalysis from './BaseReportIndexAnalysis'

export default class ReportsGroup {
    public static getGroups(
        anxietyReports : BaseReportIndexAnalysis[][], 
        tensityReports : BaseReportIndexAnalysis[][], 
        volumeReports : BaseReportIndexAnalysis[][]) : ReportsGroup[] {
        
        anxietyReports = anxietyReports.filter(r => r.length > 0);
        tensityReports = tensityReports.filter(r => r.length > 0);
        volumeReports = volumeReports.filter(r => r.length > 0);

        let queries : string[] = [];
        this.appendQueriesIds(queries, anxietyReports);
        this.appendQueriesIds(queries, tensityReports);
        this.appendQueriesIds(queries, volumeReports);
        
        return queries.map(q => this.getGroup(q, anxietyReports, tensityReports, volumeReports));
    }

    private static getGroup(queryId : string,
        anxietyReports : BaseReportIndexAnalysis[][], 
        tensityReports : BaseReportIndexAnalysis[][], 
        volumeReports : BaseReportIndexAnalysis[][]) : ReportsGroup {
            return new ReportsGroup(
                anxietyReports.find(r => r[0].queryId === queryId) ?? [],
                tensityReports.find(r => r[0].queryId === queryId) ?? [],
                volumeReports.find(r => r[0].queryId === queryId) ?? []);
        }

    private static appendQueriesIds(queriesIds : string[], reports : BaseReportIndexAnalysis[][]) {
        reports.forEach(r => {
            let curr = r[0].queryId;
            if (!queriesIds.some(q => q === curr))
                queriesIds.push(curr);
        });
    }
    
    constructor(anxietyReport : BaseReportIndexAnalysis[], tensityReport : BaseReportIndexAnalysis[], volumeReport : BaseReportIndexAnalysis[]) {
        this.anxietyReport = anxietyReport;
        this.tensityReport = tensityReport;
        this.volumeReport = volumeReport;
    }

    public get hasEmptyReports() : boolean {
        return this.anxietyReport.length === 0 ||
               this.tensityReport.length === 0 ||
               this.volumeReport.length === 0;
    }

    public anxietyReport : BaseReportIndexAnalysis[];
    public tensityReport : BaseReportIndexAnalysis[];
    public volumeReport : BaseReportIndexAnalysis[];
}