import React from 'react'

import ChartComponent from '../../../Components/Chart/ChartComponent/ChartComponent'

import ArrayChartOption from '../../../Models/ChartModels/ArrayChartOption'
import ChartOption from '../../../Models/ChartModels/ChartOption'
import DateType from '../../../Models/Analysis/DateType'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase'

import AnxietyColorRecognizer from '../../../Helpers/Strategys/ColorStrategy/AnxietyColorRecognizer'
import TensityColorRecognizer from '../../../Helpers/Strategys/ColorStrategy/TensityColorRecognizer'
import VolumeColorRecognizer from '../../../Helpers/Strategys/ColorStrategy/VolumeColorRecognizer'

import ChartData from '../../../Models/Entintys/ChartData'

import '../Report.css'
import './OrganizationCharts.css'

interface IOrganizationCharts {
    textProvider : ILanguagePhrase
    
    period : Date[]
    sortType : DateType

    anxietyRangeValue : number
    anxietyChartData : ChartData
    tensityChartData : ChartData
    subDepTensityChart : ChartOption[]
    volumeChartData  : ChartData
}

export default function OrganizationCharts(props : IOrganizationCharts) {
    let periods = props.period.map(item => item.toLocaleDateString());

    return <div>
        <div className = "chartCouple">
            <ChartComponent
                chartsOption = {new ArrayChartOption([new ChartOption(props.tensityChartData, "black", new TensityColorRecognizer(), "line", false)].concat(props.subDepTensityChart))}
                periods = {periods}
                heading = {props.textProvider.tensionDynamics}
                isShowYLabels = {true}
                prompts = {[]}
                yAxis = {null}
                minYValue = {0}
                maxYValue = {5}/>
            <ChartComponent
                chartsOption = {new ArrayChartOption([new ChartOption(props.volumeChartData, "#92E3DA", new VolumeColorRecognizer(), "bar", false)])}
                    periods = {periods}
                    heading = {props.textProvider.volumeDynamics}
                    isShowYLabels = {true}
                    prompts = {[]}
                    yAxis = {null}
                    minYValue = {0}
                    maxYValue = {props.volumeChartData._values.reduce((ac,curr) => curr > ac ? curr : ac, 0)}/>
        </div>
        <div className = "chartCouple">
            <ChartComponent
                chartsOption = {new ArrayChartOption([new ChartOption(props.anxietyChartData, "#5F8BD9", new AnxietyColorRecognizer(), "bar", false)])}
                    periods = {periods}
                    heading = {props.textProvider.evolutionOfTensionDynamics}
                    isShowYLabels = {true}
                    prompts = {[]}
                    yAxis = {null}
                    minYValue = {props.anxietyRangeValue * (-1)}
                    maxYValue = {props.anxietyRangeValue}/>
            <div className="addReportBlock">
                <div className="OrganizationChartsAddReport">{`+${props.textProvider.addNewReport}`}</div>
                <div className="futureBlock Prompt">{props.textProvider.hereYouCanAdd}</div>
            </div>
        </div>
    </div>
}