import BaseReportIndexAnalysis from "../Models/Analysis/Report/BaseReportIndexAnalysis";

import AnxietyAnalysisService from '../Services/AnxietyAnalysis/AnxietyAnalysisService'
import IAnxietyAnalysisService from "../Services/AnxietyAnalysis/IAnxietyAnalysisService";

export interface IAnxietyAnalysisActions{
    GetReportsAnxietyResponce(reportId : string) : Promise<BaseReportIndexAnalysis[]> 
    
    GetReportsEmotionalResponce(reportId : string) : Promise<BaseReportIndexAnalysis[]>
}

export class AnxietyAnalysisActions implements IAnxietyAnalysisActions {
    private readonly _anxietyAnalysisService : IAnxietyAnalysisService;
    
    constructor(anxietyAnalysisService : IAnxietyAnalysisService | null = null) {
        this._anxietyAnalysisService = anxietyAnalysisService ?? new AnxietyAnalysisService();
    }

    public async GetReportsAnxietyResponce(reportId : string) : Promise<Array<BaseReportIndexAnalysis>> {
        let analysis = await this._anxietyAnalysisService.GetReportsAnxiety(reportId);
        return analysis.map(item => new BaseReportIndexAnalysis(item, item.anxietyAnalysisCollection));
    }

    public async GetReportsEmotionalResponce(reportId : string) : Promise<Array<BaseReportIndexAnalysis>> {
        let reports = await this._anxietyAnalysisService.GetReportsEmotional(reportId);
        return reports.map(item => new BaseReportIndexAnalysis(item, item.emotionalAnalysisCollection));
    }
}