import React from 'react'

import { IBaseAdminSettings, IBaseAdminSettingsState, BaseAdminSettings } from '../BaseSettings/BaseAdminSettings'
import PopUpConfirm, { PopUpConfirmDefault } from '../../../Components/PopupConfirm/PopupConfirm'

import EmployeeLocal from '../../../Models/Employees/EmployeeLocal';
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl'
import { EmployeeInfo } from '../../../Components/Admin/EmployeeInfo/EmployeeInfo';
import { InputV2, InputV2PropsReadonly } from '../../../Components/InputV2/InputV2';

import './EmployeeSettingsUI.css'
import ButtonInteractive, { ErrorImg, OkImg } from '../../../Components/Buttons/ButtonInteractive/ButtonInteractive';
import PopUpMessage from '../../../Components/PopupMessage/PopupMessage';

const inviteConfirmId = 'EmployeeSettingsUIInviteConfirm',
      inviteCancelId = 'EmployeeSettingsUIInviteCancel',
      deleteConfirmId = 'EmployeeSettingsUIDeleteConfirm',
      deleteCancelId = 'EmployeeSettingsUIDeleteCancel';

export { inviteConfirmId, inviteCancelId, deleteConfirmId, deleteCancelId }

interface IEmployeeSettingsUI extends IBaseAdminSettings {
    employee : EmployeeLocal | undefined
    isCorrect : boolean
    isCurrentUser : boolean
    deleteEmployee() : void
    employeeUpdated(isCorrect: Boolean, employee : EmployeeLocal) : void
    clear() : void
    update() : Promise<boolean>
    sendInvite() : void
    errorMessage : string
    errorMessageClose() : void
}

interface IEmployeeSettingsUIState extends IBaseAdminSettingsState {
    forceError : boolean;
    deletePopupRequested : boolean;
    invitationPopupRequested : boolean;
}

export default class EmployeeSettingsUI extends BaseAdminSettings<IEmployeeSettingsUI, IEmployeeSettingsUIState> {
    constructor(props : IEmployeeSettingsUI) {
        super(props);
        this.state = {
            ...BaseAdminSettings.InitialState,
            forceError : false,
            deletePopupRequested: false, 
            invitationPopupRequested: false,
        }
    }

    getLink() : string {
        return this._language.employees;
    }
    
    onLinkClicked() : void {
        window.location.href = RoutesUrl.Users;
    }

    getName() : string {
        let f = this.props.employee?.firstName ?? '';
        let l = this.props.employee?.lastName ?? '';

        return `${f} ${l}`;
    }

    changeDeletePopupState(v : boolean) {
        this.setState(state => {
            return {
                ...state,
                deletePopupRequested: v
            }
        })
    }

    changeInvitationPopupState(v : boolean) {
        this.setState(state => {
            return {
                ...state,
                invitationPopupRequested: v,
            };
        });
    }

    getButtons() : JSX.Element[] {
        return [];
    }

    correctDate(d : string) {
        if (!d || d.startsWith('0001-01-01'))
            return '-';

        return `${d.substring(8, 10)}.${d.substring(5, 7)}.${d.substring(0, 4)}`;
    }

    onUpdate() {
        this.setState(state => { return { ...state, forceError: true } })
        if (!this.props.isCorrect) return Promise.resolve(false);

        return this.props.update();
    }

    getMainContent() : JSX.Element {
        let invitationPopup : JSX.Element = this.state.invitationPopupRequested
                                                ? <PopUpConfirm 
                                                    zIndex={100} closePopup={() => this.changeInvitationPopupState(false)}
                                                    confirmButtonId={inviteConfirmId} cancelButtonId={inviteCancelId}
                                                    header={this._language.confirmation}
                                                    text={this._language.connectInvitationSure}
                                                    confirm={this._language.confirm}
                                                    cancel={this._language.cancel}
                                                    onConfirm={() => this.props.sendInvite()}/>
                                                : <div></div>;

        let deletePopup = null;
        if (this.state.deletePopupRequested)
            deletePopup = <PopUpConfirmDefault
                            zIndex={100} closePopup={() => this.changeDeletePopupState(false)}
                            confirmButtonId={deleteConfirmId} cancelButtonId={deleteCancelId}
                            text={this._language.employeeDeleteConfirm}
                            textProvider={this._language}
                            onConfirm={() => this.props.deleteEmployee()}/>;

        let errorMessage;
        if (this.props.errorMessage)
            errorMessage = <PopUpMessage 
                                zIndex={100} 
                                closePopup={() => this.props.errorMessageClose()} 
                                header={this._language.error} 
                                text={this.props.errorMessage}
                                buttonId='EmployeeSettingsErrorMessagePopupButtonId'/>

        let content;
        let inviteContent;
        if (this.props.employee) {
            content = <EmployeeInfo 
                isCurrentUser={this.props.isCurrentUser}
                info={this.props.employee}
                forceError={this.state.forceError}
                textProvider={this._language}
                onUpdate={r => this.props.employeeUpdated(r.isSuccess, { ...(this.props.employee as EmployeeLocal), ...r.info })}/>

            inviteContent=<div className="EmployeeSettingsUIInvitationContainer">
                <h1>{this._language.connectInvitation}</h1>
                <div>
                    {InputV2(new InputV2PropsReadonly(
                        this._language.connectSendDate,
                        this.correctDate(this.props.employee.sentInvitationDate),
                        'text',
                        'fill'
                    ))}
                    {InputV2(new InputV2PropsReadonly(
                        this._language.connectSubmittedDate,
                        this.correctDate(this.props.employee.activationDate),
                        'text',
                        'fill'
                    ))}
                </div>
                <div>
                    {InputV2(new InputV2PropsReadonly(
                        this._language.connectRevokedDate,
                        this.correctDate(this.props.employee.deactivationDate),
                        'text',
                        'fill'
                    ))}
                </div>
            </div>
        }

        return <div className="EmployeeSettingsUIContainer">
            {content}
            <div className="ButtonsContainer">
                <button className="WhiteButton" onClick={() => this.props.clear()}>{this._language.clear}</button>
                <ButtonInteractive 
                    id='EmployeeSettingsUIButtonInteractive'
                    successImgSrc={OkImg}
                    errorImgSrc={ErrorImg}
                    buttonContainerClass=''
                    buttonStyle='PrimaryButton'
                    buttonText={this._language.update}
                    action={() => this.onUpdate()}/>
            </div>
            {inviteContent}
            <div className="EmployeeSettingsUIInvitateContainer">
                <button className='PrimaryButton' onClick={() => this.changeInvitationPopupState(true)}>{this._language.connectSendInvite}</button>
                {invitationPopup}
            </div>
            <p className="Header">{this._language.accountSettings}</p>
            <div className='EmployeeSettingsUIActionButtonsContainer'>
                <button className="WhiteButton" onClick={() => this.changeDeletePopupState(true)}>{this._language.delete}</button>
            </div>
            {deletePopup}
            {errorMessage}
        </div>
    }
}