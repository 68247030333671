import React from 'react'
import IUserAction from '../../../Actions/UserActions/IUserAction';
import UserActions from '../../../Actions/UserActions/UserActions';
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact';
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import { ITextProvider, Languages, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import Pair from '../../../Models/Pair';
import BaseUser from '../../../Models/Users/BaseUser';
import Language from '../../../Models/Users/Language';
import UserInfo from '../../../Models/Users/UserInfo';

import BaseContainer from '../../BaseContainer';
import ProfileUI from './ProfileUI';
import DirectionOfActivityEntity from '../../../Models/Organizations/DirectionOfActivityEntity';
import EntityTypes from '../../../Models/Organizations/EntityTypes';
import NumberOfEmployees from '../../../Models/Organizations/NumberOfEmployees';
import ProfessionalSectorType from '../../../Models/Organizations/ProfessionalSectorType';
import IOrganizationAction from '../../../Actions/OrganizationActions/IOrganizationAction';
import OrganizationActions from '../../../Actions/OrganizationActions/OrganizationActions';
import Entity from '../../../Models/Organizations/Entity';
import { IInteractiveHintActions, InteractiveHintActions } from '../../../Actions/InteractiveHintActions';
import LocalStorageAgent from '../../../LocalStorageWorker/LocalStorageAgent';
import { IRoutesAgent, RoutesAgent } from '../../../Helpers/Routes/RoutesAgent';

interface IProfile {
    textProvider : ITextProvider | null
    interactiveHintActions : IInteractiveHintActions | null
    routesAgent : IRoutesAgent | null
}

interface IProfileState {
    isLoaded : boolean
    user : BaseUser
    company : Entity
    interactiveHintsAllowed : boolean
    errorMessage : string
}

export default class Profile extends BaseContainer<IProfile, IProfileState> {
    private readonly _userActions : IUserAction = new UserActions();
    private readonly _organizationActions : IOrganizationAction = new OrganizationActions();
    private readonly _textProvider : ITextProvider;
    private readonly _interactiveHintActions : IInteractiveHintActions;
    private readonly _routesAgent : IRoutesAgent;
    private readonly _lang : ILanguagePhrase;

    private readonly _infoPromise : Promise<BaseUser>;
    private readonly _infoCompanyPromise : Promise<Entity[]>;
    private readonly _interactiveHintsShowAllowedPromise : Promise<boolean>;

    constructor(props : IProfile) {
        super(props);
        this._lang = (this._textProvider = (props.textProvider ?? new TextProvider())).GetLang();
        this._interactiveHintActions = props.interactiveHintActions ?? new InteractiveHintActions(new LocalStorageAgent());
        this._routesAgent = props.routesAgent ?? new RoutesAgent();
        this.state = {
            user : new BaseUser('','','','','','','','',Language.Ru,false),
            company : new Entity(EntityTypes.Company,'','','','',
                                      NumberOfEmployees.From20To49,ProfessionalSectorType.Private,DirectionOfActivityEntity.more,'','','','','','','',[],''),
            interactiveHintsAllowed : false,
            isLoaded : false,
            errorMessage : '',
        };
        this._infoPromise = this._userActions.GetUserProfile();
        this._infoCompanyPromise = this._organizationActions.GetEntitiesAsyncResponce();
        this._interactiveHintsShowAllowedPromise = this._interactiveHintActions.IsHintsShowAllowed();
    }

    getName(first : string, last : string) {
        const getPart = (s : string) => !s || s.length === 0 ? '' : s;

        return `${getPart(first)} ${getPart(last)}`;
    }

    componentDidMount() {
        Promise.all([
            this._infoPromise.then(user => {
                user.languageType = this._lang.lang;
                this.changeValueState(new Pair('user', user))}
            ),
            this._infoCompanyPromise.then(companyInfo => {
                this.changeValueState(new Pair('company', companyInfo[0]))
            }),
            this._interactiveHintsShowAllowedPromise.then(allowed => {
                this.setState(state => { return { ...state, interactiveHintsAllowed : allowed}})
            })
        ]).then(() => {
            this.changeValueState(new Pair('isLoaded', true)); 
        });
    }

    updateInfo(info : UserInfo) {
        let user = this.state.user;
        BaseUser.edit(user, info.firstName, info.lastName, info.email, info.phone);
        return this.updateUser(user);
    }

    updateCompanyInfo(info : Entity) {
        return this.updateCompany(info);
    }

    changeLang(iso : string) {
        let lang = Languages.find(l => l.iso === iso) as ILanguagePhrase;
        this._textProvider.SetLang(lang);
        let user = this.state.user;
        user.languageType = lang.lang;
        return this.updateUser(user);
    }

    updateUser(user : BaseUser) {
        return this._userActions.UpdateUserInfo(user).then(errorMessage => {
            if (!errorMessage) return true;

            this.setState(state => { return { ...state, errorMessage: errorMessage }});
            return false;
        });
    }

    updateCompany(company : Entity) {
        return this._organizationActions.UpdateEntityAsync(company).then(_ => true);
    }

    showInteractiveHintsChange(value : boolean) {
        this._interactiveHintActions.HintsShowAllowChange(value).then(_ => this._routesAgent.reload())
    }

    showInteractiveHintsAgain() {
        this._interactiveHintActions.HintsShowAgain().then(_ => this._routesAgent.reload());
    }

    render() {
        if (!this.state.isLoaded)
            return <PreloaderCompactDefault />

        return <ProfileUI 
                    textProvider={this._textProvider}
                    info={this.state.user}
                    companyInfo={this.state.company}
                    showInteractiveHints={this.state.interactiveHintsAllowed}
                    interactiveHintsActions={this._interactiveHintActions}
                    saveInfo={info => this.updateInfo(info)}
                    saveCompanyInfo={companyInfo => this.updateCompanyInfo(companyInfo)}
                    changeLanguage={lang => this.changeLang(lang)}
                    showInteractiveHintsChange={v => this.showInteractiveHintsChange(v)}
                    showInteractiveHintsAgain={() => this.showInteractiveHintsAgain()}
                    errorMessage={this.state.errorMessage}
                    errorMessageClose={() => this.setState(state => { return { ...state, errorMessage: ''} })}/>
    }
}