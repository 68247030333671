import DateType, { GetValueByDateType } from "../Models/Analysis/DateType";

export default class DatesHelper {
    /**
     * Calculates difference between two dates
     * @returns 0 = is first after second, 1 = years diff, 2 = monthes diff, 3 = days diff
     */
    public static calcDiff(laterDate : Date, earlierDate : Date) :
        [boolean, number, number, number]
     {
        let isFirstAfter = laterDate.getTime() - earlierDate.getTime() >= 0;
        let result = new Date(isFirstAfter ? laterDate.getTime() - earlierDate.getTime() : earlierDate.getTime() - laterDate.getTime());
        let yearsDiff = result.getFullYear() - 1970;

        return [ isFirstAfter, yearsDiff, result.getMonth(), result.getDate()];
    }

    /**
     * Gets value by diff between dates. Diff args concates if needed
     */
    public static getConcatedValuesByDatesDiff<T>(laterDate: Date, earlierDate: Date,
        onYearsDiff : T,
        onMonthesDiff : T,
        onWeeksDiff : T,
        onDaysDiff : T,
        onSecondAfterFirst : T) : T[] {
        
        let [isAfter, yearsDiff, monthesDiff, daysDiff] = DatesHelper.calcDiff(laterDate, earlierDate);

        if (!isAfter)
            return [onSecondAfterFirst];
        if (yearsDiff > 0)
            return [onDaysDiff, onWeeksDiff, onMonthesDiff, onYearsDiff];
        if (monthesDiff > 0)
            return [onDaysDiff, onWeeksDiff, onMonthesDiff];
        if (daysDiff >= 14)
            return [onDaysDiff, onWeeksDiff];
        return [onDaysDiff];
    }

    private static readonly _daysOfWeek = [
        new Date(2022, 0, 2), //sunday
        new Date(2022, 0, 3), //monday
        new Date(2022, 0, 4), //tuesday
        new Date(2022, 0, 5), //wednesday
        new Date(2022, 0, 6), //thursday
        new Date(2022, 0, 7), //friday
        new Date(2022, 0, 8), //saturday
    ];

    /**
     * Gets days of week names by current language's bcp code
     */
    public static getLocaleWeekDaysNames(bcp : string) : string[] {
        return this._daysOfWeek.map(d => d.toLocaleString(bcp, { weekday: 'short' }).split('.')[0].toUpperCase());
    }

    /**
     * @returns [ first day, last day ]
     */
    public static getCurrentMonth() : [Date, Date] {
        let now = new Date();
        
        return [ 
            new Date(now.getFullYear(), now.getMonth(), 1), 
            new Date(now.getFullYear(), now.getMonth()+1, 0) 
        ]
    }

    /**
     * @returns [monday, sunday]
     */
    public static getCurrentWeek(now : Date = new Date()) : [Date, Date] {
        if (now.getDay() === 0) 
            now = new Date(now.getFullYear(),now.getMonth(),now.getDate()-1);
        let day = now.getDay()

        return [ 
            new Date(now.getFullYear(), now.getMonth(), now.getDate() - (day - 1)),
            new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - day))
        ]
    }

    public static getReportDates(start : Date, end : Date, type : DateType) : Date[] {
        let stepFunc = GetValueByDateType<(d : Date) => Date>(type,
            d => this.getNextDate(d,0,0,1),
            d => this.getNextDate(d,0,1,0),
            d => this.getNextDate(d,0,0,7),
            d => this.getNextDate(d,1,0,0));

        let dates : Date[] = [];
        let currentDate = start;

        while (currentDate <= end) {
            dates.push(currentDate);
            currentDate = stepFunc(currentDate);
        }

        return dates;
    }

    private static getNextDate(prev : Date, yDiff : number, mDiff : number, dDiff : number) {
        return new Date(prev.getFullYear() + yDiff, prev.getMonth() + mDiff, prev.getDate() + dDiff);
    }
}