import BaseService from '../BaseService';
import IRequestProvider from '../RequestProvider/IRequestProvider';
import RequestSettings from '../RequestProvider/RequestSettings';

import Action from '../../Models/Delegate/Action';
import CRMOrganization from '../../Models/CRM/CRMOrganization'
import CRMOrganizationAPI from '../../Models/CRM/CRMOrganizationAPI'
import CRMOrganizationInfo from '../../Models/CRM/CRMOrganizationInfo'
import Dictionary from '../../Models/Collections/Dictionary';
import Pair from '../../Models/Pair';

import { ErrorMessageResponse } from '../ResponseTypes'
import * as CRMSignatures from './CRMServiceConstants'

import { GetMessage } from '../ObjectsReflector'

export interface ICRMService {
    /**
     * Gets list of availiable companies
     * @returns @see CRMOrganization array if ok otherwise @see string with error message
     */
    getCompaniesAsync(pageNumber : number) : Promise<CRMOrganization[] | string>;

    /**
     * Get company's info by it's organization id
     * @returns @see CRMOrganizationInfo if ok otherwise @see string with error message
     */
    getCompanyInfoAsync(organizationId : string) : Promise<CRMOrganizationInfo | string>;

    /**
     * Deletes company and all it's info
     * @param organizationId 
     * @returns error message or an empty string if ok
     */
    deleteCompanyAsync(organizationId : string) : Promise<string>; 
}

export class CRMService extends BaseService implements ICRMService {
    constructor(requestProvider : IRequestProvider | null = null) {
        super(requestProvider);
    }

    public async getCompaniesAsync(pageNumber : number) : Promise<CRMOrganization[] | string> {
        const settings = new RequestSettings(CRMSignatures.companiesSignature, new Dictionary(new Pair('pageNumber', `${pageNumber}`)), {});
        let result : CRMOrganization[] | string = 'Something gone wrong';

        await this.RequestProvider.Request(settings, new Dictionary<Number, Action<unknown>>(
            new Pair(200, r => result = (r as CRMOrganizationAPI[]).map(item => new CRMOrganization(item))),
            new Pair(204, _ => result = []),
            new Pair(400, o => result = (o as ErrorMessageResponse).message)
        ));

        return result;
    }

    public async getCompanyInfoAsync(organizationId : string) : Promise<CRMOrganizationInfo | string> {
        const settings = new RequestSettings(CRMSignatures.companyInfoSignature, new Dictionary(new Pair('organizationId', organizationId)), {});
        let result : CRMOrganizationInfo | string = 'Something gone wrong';

        await this.RequestProvider.Request(settings, new Dictionary<Number, Action<unknown>>(
            new Pair(200, r => result = r as CRMOrganizationInfo),
            new Pair(204, _ => result = 'No organization found'),
            new Pair(400, _ => result = 'Something gone wrong')
        ));

        if (typeof result === 'string') return result;

        (result as CRMOrganizationInfo).companyInfo.organizationId = organizationId;
        return result;
    }

    public async deleteCompanyAsync(organizationId : string) : Promise<string> {
        const settings = new RequestSettings(CRMSignatures.companyDeleteSignature, new Dictionary(new Pair('organizationId', organizationId)), {}) ;
        let result : string = '';

        await this.RequestProvider.Request(settings, new Dictionary<Number, Action<unknown>>(
            new Pair(200, _ => result = ''),
            new Pair(204, _ => result = ''),
            new Pair(400, d => result = GetMessage(d)),
        ));

        return result;
    }
}