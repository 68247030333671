import React from 'react'

import AdminFrame from './AdminFrame';
import CRMFrame from './CRMFrame';

import RoutesUrl from '../../Helpers/Routes/RoutesUrl';

interface IFramesProvider {
    children?: React.ReactNode
}

export default function FramesProvider(props: IFramesProvider) : JSX.Element {
    const path = window.location.pathname;
    
    if (path.startsWith(RoutesUrl.UserInterface))
        return <AdminFrame>{props.children}</AdminFrame>

    if (path.startsWith(RoutesUrl.CRMInterface))
        return <CRMFrame>{props.children}</CRMFrame>

    return <React.Fragment>{props.children}</React.Fragment>;
}