import Action from '../../Models/Delegate/Action';
import Dictionary from '../../Models/Collections/Dictionary';
import Pair from '../../Models/Pair';

import BaseService from '../BaseService'
import EmployeeAPI from '../../Models/Employees/EmployeeAPI'
import EmployeeBaseAPI from '../../Models/Employees/EmployeeBaseAPI'
import EmployeeServiceConstants from './EmployeeServiceConstants';
import EmployeeAPIWithDepartmentsInfo from '../../Models/Employees/EmployeeAPIWithDepartmentsInfo'
import OrganizationInfo from '../../Models/OrganizationReport/OrganizationInfo'
import RequestSettings from '../RequestProvider/RequestSettings';
import IEmployeeService from './IEmployeeService';

import IRequestProvider from "../RequestProvider/IRequestProvider";
import { GetMessage } from '../ObjectsReflector';
import EmployeeBaseLocal from '../../Models/Employees/EmployeeBaseLocal';
import EmployeeLocal from '../../Models/Employees/EmployeeLocal';
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';
import { ITextProvider, TextProvider } from '../../Helpers/TextProvider/TextProvider';

class EmployeeService extends BaseService implements IEmployeeService {
    private readonly _lang : ILanguagePhrase;

    constructor(requestProvider : IRequestProvider | null = null, textProvider : ITextProvider | null = null){
        super(requestProvider);

        this._lang = (textProvider ?? new TextProvider()).GetLang();
    }

    public async getEmployeesInfoAsync() : Promise<EmployeeLocal[]> {
        const settings = new RequestSettings(EmployeeServiceConstants._getEmployeesInfoSignature, new Dictionary(), null)
        let result : EmployeeLocal[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as EmployeeLocal[]),
            new Pair(204, _ => { })
        ))

        return result;
    }

    public async getEmployeeInfoAsync(id : string) : Promise<EmployeeAPI | string> {
        const settings = new RequestSettings(EmployeeServiceConstants._getEmployeeInfoSignature, new Dictionary<string, string>(new Pair('id', id)), null)
        
        let result : EmployeeAPIWithDepartmentsInfo | string = '';
        await this.RequestProvider.Request(settings, new Dictionary<number,Action<EmployeeAPI|string>>(
            new Pair(200, r => result = r as EmployeeAPIWithDepartmentsInfo),
            new Pair(400, r => result = GetMessage(r)),
        ))

        if (typeof result === 'string') return result;
        (result as EmployeeAPIWithDepartmentsInfo).departmentIds = (result as EmployeeAPIWithDepartmentsInfo).departmentsInfo.map(i => i.departmentId);
        return result;
    }

    public async sendInvitationAsync(employeeId : string) : Promise<string> {
        const settings = new RequestSettings(EmployeeServiceConstants._sendInvitationSignature, new Dictionary<string, string>(new Pair('employeeId', employeeId)), null)

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async updateAsync(employee : EmployeeBaseLocal) : Promise<string> {
        let model = EmployeeBaseAPI.fromLocal(employee);
        const settings = new RequestSettings(EmployeeServiceConstants._updateSignature, new Dictionary(), JSON.stringify(model))

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ))

        return result;
    }

    public async deleteAsync(employeeId : string) : Promise<string> {
        const settings = new RequestSettings(EmployeeServiceConstants._deleteSignature, new Dictionary(new Pair('id', employeeId)), null)

        let result = '';
        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async getOrganizationsInfoByUserAsync() : Promise<OrganizationInfo[]> {
        const settings = new RequestSettings(EmployeeServiceConstants._getOrganizationsInfoByUser, new Dictionary(), null);
        let result : OrganizationInfo[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as OrganizationInfo[]),
            new Pair(204, _ => { })
        ))

        return result;
    }

    public async addEmployeeAsync(employee : EmployeeBaseLocal) : Promise<[ boolean , string  ]> {
        const data = EmployeeBaseAPI.fromLocal(employee);
        const settings = new RequestSettings(EmployeeServiceConstants._addEmployee, new Dictionary(), JSON.stringify(data))
        
        let result : [ boolean , string ] = [ false, 'error' ];
        await this.RequestProvider.Request(settings, new Dictionary<number, Action<string>>(
            new Pair(200, r => result = [ true, r as string ]),
            new Pair(400, r => result = [ false, GetMessage(r) ]),
            new Pair(409, _ => result = [ false, this._lang.specialTariffMaxEmployees ])
        ));

        return result;
    }
}

export default EmployeeService;