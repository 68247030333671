import React, { useState } from "react"

import InjectableComponent from '../../InjectableComponent/InjectableComponent'
import ReportBlock from "../ReportBlock/ReportBlock"

import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase";
import Report from "../../../Models/Analysis/Report"
import RoutesUrl from "../../../Helpers/Routes/RoutesUrl";

import CloseImg from "../../../Media/Img/closeBlack.png";

import "./ReportsList.css"
import "../Report.css"

interface IReportsList {
    id : string
    reports : Report[]
    textProvider : ILanguagePhrase
    deleteReport(reportId : string) : void
    changeReport(reportId : string) : void
}

function ReportsList(props : IReportsList){
    const [ listNeeded, listNeededChange ] = useState(false);

    return <InjectableComponent popupContainerId={props.id} popupContainerClass='reportListContainer' isPopupNeeded={listNeeded}
            template={<button className="WhiteButton DisabledButton" disabled onClick={() => listNeededChange(!listNeeded)}>{props.textProvider.reportLibrary}</button>}
            getPopup={() => <div className="ReportsList">
                <div className = "clossContainerReportList">
                    <img onClick={() => listNeededChange(false)} src={CloseImg}/>
                </div>
                <div className = "reportListBlocks">
                    <button className="WhiteButton ReportsListDailyReportButton" onClick={() => window.location.href = RoutesUrl.DailyReport}>{props.textProvider.reportDaily}</button>
                    {props.reports && props.reports.map(r => 
                        <ReportBlock report={r} changeReport={props.changeReport} textProvider={props.textProvider}  deleteReport={props.deleteReport}/>)}
                </div>
            </div>}/>
}

export default ReportsList;