import RequestSignature from "../RequestProvider/RequestSignature";

class UserServiceConstants{

    public static readonly _registrationRequestSignature:RequestSignature=new RequestSignature("POST","/api/v1.0/Account/Registration");

    public static readonly _authorizationRequestSignature:RequestSignature=new RequestSignature("POST","/api/v1.0/Account/Login");

    public static readonly _getProfileSignature:RequestSignature = new RequestSignature("GET","​/api/v1.0/Profile/Info");

    public static readonly _registrationInOrganization: RequestSignature = new RequestSignature("POST", "/api/v1.0/Account/RegistrationInOrganization");

    public static readonly _adminRegistration : RequestSignature = new RequestSignature("POST","/api/v1.2/Account/AdminRegistration");

    public static readonly updateUserInformation = (id : string) : RequestSignature => 
        new RequestSignature("PUT",`/api/v1.0/Profile/Info${id.length > 0 ? '/'+id : ''}`);

    public static readonly _getUserProfileById : RequestSignature = new RequestSignature("GET","/api/v1.0/Profile/Info");

    public static readonly _getUserProfile : RequestSignature = new RequestSignature("GET","/api/v1.0/Profile/Info");

    public static readonly  _deleteUser : RequestSignature = new RequestSignature("DELETE","/api/DeleteUser");

    public static readonly _blockManager : RequestSignature = new RequestSignature('POST', '/api/BanUser');

    public static readonly _unblockManager : RequestSignature = new RequestSignature('POST', '/api/UnBanUser');

    public static readonly _resendConfirmationEmail : RequestSignature = new RequestSignature('POST', '/api/v1.0/Account/ResendConfirmationEmail')

    public static readonly _requestChangePassword : RequestSignature = new RequestSignature("POST", "/api/v1.0/Account/RequestChangePassword");

    public static readonly _resetPassword : RequestSignature = new RequestSignature("POST","/api/v1.0/Account/Restore");

}

export default UserServiceConstants;