import React from 'react'

import BaseContainer from "../../../Containers/BaseContainer"
import {InputV2, InputV2Props} from "../../InputV2/InputsV2"
import {SelectV2, SelectV2Props} from "../../SelectV2/SelectV2"
import EmployeeInfoDepartmentsPanel from "./EmployeeInfoDepartmentsPanel"
import PreloaderCompactDefault from '../../PreloaderCompact/PreloaderCompact'

import Pair from "../../../Models/Pair"
import EmployeeActions from '../../../Actions/EmployeeActions/EmployeeActions'
import IEmployeeActions from '../../../Actions/EmployeeActions/IEmployeeActions'
import EmployeeBaseLocal from "../../../Models/Employees/EmployeeBaseLocal"
import EmployeeOrganizationInfo from "../../../Models/Employees/EmployeeOrganizationInfo"
import ILanguagePhrase from "../../../Helpers/LanguagePhrase/ILanguagePhrase"
import Validator from "../../../Helpers/Validator/Validator"
import Gender from '../../../Enums/Gender'
import MonthSelector from '../../MonthSelector/MonthSelector'
import OrganizationInfo from '../../../Models/OrganizationReport/OrganizationInfo'


import './EmployeeInfo.css'
import DatesHelper from '../../../Helpers/DatesHelper'
import EmployeeAccess from '../../../Enums/EmployeeAccess'
import { DecoratorContainer, UnderlinedComponent, ErrorComponent } from '../../Decorators/Decorators'

export interface IEmployeeInfoResult {
    isSuccess : Boolean,
    info : EmployeeBaseLocal
}

interface IEmployeeInfo {
    isCurrentUser : boolean
    info : EmployeeBaseLocal
    forceError : boolean
    textProvider: ILanguagePhrase
    onUpdate(r : IEmployeeInfoResult) : void
}

interface IEmployeeInfoState {
    isLoaded : Boolean
    organizationInfos : OrganizationInfo[]
}

export class EmployeeInfo extends BaseContainer<IEmployeeInfo, IEmployeeInfoState> {
    private readonly _employeeActions : IEmployeeActions = new EmployeeActions();

    private readonly _positions : Pair<string, string>[];

    constructor(props: IEmployeeInfo) {
        super(props);
        this.state = {
            isLoaded: false,
            organizationInfos: [],
        }

        this._positions = props.textProvider.positions.map(p => new Pair(p.key.toString(), p.value));
    }

    componentDidMount() {
        this._employeeActions.getOrganizationsInfoByUserAsync().then(r => {
            this.setState(state => {
                return {
                    ...state,
                    isLoaded: true,
                    organizationInfos: r
                };
            });
        })
    }

    checkAll() : Boolean {
        return this.stringCheck(this.props.info.firstName) &&
               this.emailCheck(this.props.info.email) &&
               this.phoneCheck(this.props.info.phone) &&
               this.birthDateCheck(this.props.info.birthDate) &&
               this.hiredDateCheck(this.props.info.employmentDate) &&
               this.props.info.departmentsInfo && this.props.info.departmentsInfo.length > 0 && this.checkLinked(this.props.info.departmentsInfo);
    }

    myUpdate() {
        this.props.onUpdate({
            isSuccess: this.checkAll(),
            info: this.props.info,
        })
    }

    firstNameChanged(value : string) {
        this.props.info.firstName = value;
        this.myUpdate();
    }

    lastNameChanged(value : string) {
        this.props.info.lastName = value;
        this.myUpdate();
    }

    emailChanged(value : string) {
        this.props.info.email = value;
        this.myUpdate();
    }

    emailCheck(v : string) {
        return Validator.IsEmailTemplate(v);
    }

    phoneChanged(value : string) {
        this.props.info.phone = value;
        this.myUpdate();
    }

    phoneCheck(v : string) {
        return Validator.IsOptionalPhoneTemplate(v);
    }

    birthDateChanged(value : string) {
        let date = new Date(value);
        if (isNaN(date.getTime())) return;
        
        this.props.info.birthDate = date.toISOString().substring(0,10);
        this.myUpdate();
    }

    birthDateCheck(v : string) {
        let d = new Date(v);
        return d && d.getFullYear() >= 1900 && Validator.isOlderThan(d, 18);
    }

    genderChanged(key : string) {
        let gender : Gender = Number(key);
        this.props.info.gender = gender;
        this.myUpdate();
    }

    getGender(v : string) {
        for (let key in Gender) {
            if (v === key)
                return key;
        }

        return null;
    }

    professionChanged(value : string) {
        this.props.info.profession = value;
        this.myUpdate();
    }

    positionChanged(value : string) {
        this.props.info.position = Number(value);
        this.myUpdate();
    }

    workExperienceChanged(value : number) {
        this.props.info.workExperienceMonths = value;
        this.myUpdate();
    }

    hiredDateChanged(value : string) {
        let date = new Date(value);
        if(isNaN(date.getTime())) return;

        this.props.info.employmentDate = date.toISOString();
        this.myUpdate();
    }

    hiredDateCheck(v : string) : boolean {
        let d = new Date(v);
        if (!d) return false;
        const [isLater] = DatesHelper.calcDiff(new Date(), d)
        return isLater && d.getFullYear() >= 1950;
    }

    stringCheck(v : string) {
        return !Validator.isNullOrWhitespace(v);
    }   

    changeValue(name : string, value : string) {
        this.setState(state => {
            return {
                ...state,
                [name] : value
            }
        })
    }

    getGenders() {
        return [
            new Pair<string, string>('-1', ''),
            new Pair<string, string>(Gender.Male.toString(), this.props.textProvider.genderMale),
            new Pair<string, string>(Gender.Female.toString(), this.props.textProvider.genderFemale)
        ];
    }

    onChanged(departmentsInOrganizations : EmployeeOrganizationInfo[]) {
        let newInfo : EmployeeBaseLocal = { ...this.props.info, departmentsInfo: departmentsInOrganizations };
        this.setState(state => {
            return {
                ...state,
                info: newInfo,
            };
        });

        this.props.onUpdate({
            isSuccess: this.checkAll(),
            info: newInfo,
        });
    }
    
    departmentInfoEdited(info : EmployeeOrganizationInfo, index : number) {
        this.props.info.departmentsInfo[index] = info;
        this.departmentHandlerBase();
    }

    departmentInfoAdded(info : EmployeeOrganizationInfo) {
        this.props.info.departmentsInfo.push(info);
        this.departmentHandlerBase();
    }

    departmentInfoRemoved(index : number) {
        this.props.info.departmentsInfo.splice(index, 1);
        this.departmentHandlerBase();
    }

    departmentHandlerBase() {
        this.props.onUpdate({
            isSuccess: this.checkAll(),
            info: this.props.info
        });
    }

    normalizeDate(d : string) {
        return d.substring(0, 10);
    }

    checkLinked(infos : EmployeeOrganizationInfo[]) {
        return infos.length > 0 && infos.some(info => info.organizationId != '' && info.hierarchyId != '' && info.departmentId != '');
    }

    permissionChange(v : boolean) {
        if (v) this.props.info.accessTypeEmployee = EmployeeAccess.Manager;
        else this.props.info.accessTypeEmployee = EmployeeAccess.BannedManager;
        this.myUpdate();
    }

    getCheckbox(name : string, prompt: string, checked : boolean, changed : (v : boolean) => void) {
        return <DecoratorContainer>
            <div className='EmployeeInfoCheckboxContainer'>
                <input 
                    type='checkbox' 
                    name={`EmployeeInfo${name}Checkbox`}
                    checked={this.props.isCurrentUser || checked}
                    disabled={this.props.isCurrentUser}
                    onInput={e => changed(!e.currentTarget.checked)}/>
                <label htmlFor={`EmployeeInfo${name}Checkbox`}>{prompt}</label>
            </div>
            <UnderlinedComponent />
            <ErrorComponent text={''}/>
        </DecoratorContainer>
    }

    render() {
        if (!this.state.isLoaded)
            return <PreloaderCompactDefault />

        return <div className="EmployeeInfoTable">
            <div>
                {InputV2(new InputV2Props(
                    this.props.textProvider.firstName,
                    this.props.forceError,
                    this.props.textProvider.uncorrectFirstName,
                    this.props.info.firstName,
                    this.stringCheck(this.props.info.firstName),
                    v => this.firstNameChanged(v),
                    'text', false, 'fit'))}
                {InputV2(new InputV2Props(
                    this.props.textProvider.lastName,
                    this.props.forceError,
                    this.props.textProvider.uncorrectLastName,
                    this.props.info.lastName,
                    true,
                    v => this.lastNameChanged(v),
                    'text', false, 'fill'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    this.props.textProvider.email,
                    this.props.forceError,
                    this.props.textProvider.uncorrectEmail,
                    this.props.info.email,
                    this.emailCheck(this.props.info.email),
                    v => this.emailChanged(v),
                    'email', false, 'fit'))}
                {InputV2(new InputV2Props(
                    this.props.textProvider.phoneNumber,
                    this.props.forceError,
                    this.props.textProvider.uncorrectPhoneNumber,
                    this.props.info.phone,
                    Validator.IsOptionalPhoneTemplate(this.props.info.phone),
                    v => this.phoneChanged(v),
                    'text', false, 'fill'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    this.props.textProvider.dateBirth,
                    this.props.forceError,
                    this.props.textProvider.uncorrectDateBirth,
                    this.normalizeDate(this.props.info.birthDate),
                    this.birthDateCheck(this.props.info.birthDate),
                    v => this.birthDateChanged(v),
                    'date', false, 'fill'))}
                {SelectV2(new SelectV2Props(
                    this.props.textProvider.gender,
                    this.props.forceError,
                    this.props.textProvider.uncorrectGender,
                    this.props.info.gender.toString(),
                    this.getGenders(),
                    true,
                    v => this.genderChanged(v),
                    false, 'fill'))}
            </div>
            <div>
                {SelectV2(new SelectV2Props(
                    this.props.textProvider.position,
                    this.props.forceError,
                    this.props.textProvider.uncorrectPosition,
                    this.props.info.position.toString(),
                    this._positions,
                    true,
                    v => this.positionChanged(v),
                    false, 'fill'))}
                {InputV2(new InputV2Props(
                    this.props.textProvider.profession,
                    this.props.forceError,
                    this.props.textProvider.uncorrectProfession,
                    this.props.info.profession,
                    true,
                    v => this.professionChanged(v),
                    'text', false, 'fill'))}
            </div>
            <div>
                <MonthSelector 
                    prompt={this.props.textProvider.professionalExperience}
                    textProvider={this.props.textProvider}
                    months={this.props.info.workExperienceMonths}
                    onChange={v => this.workExperienceChanged(v)}
                    isReadonly={false}
                    vertical='fill'/>
                {InputV2(new InputV2Props(
                    this.props.textProvider.hiredDate,
                    this.props.forceError,
                    this.props.textProvider.uncorrectHiredDate,
                    this.normalizeDate(this.props.info.employmentDate),
                    this.hiredDateCheck(this.props.info.employmentDate),
                    v => this.hiredDateChanged(v),
                    'date', false, 'fill'))}
            </div>
            <div className='EmployeeInfoCheckboxesContainer'>
                <p style={{color: '#C4C4C4', fontSize: '1vw'}}>{this.props.textProvider.permissions}</p>
                {this.getCheckbox(
                    'manager', 
                    this.props.textProvider.permissionReporting,
                    this.props.info.accessTypeEmployee === EmployeeAccess.Manager,
                    v => this.permissionChange(v))}
            </div>
            <p className="Header" style={{marginTop: '5vh'}} >{this.props.textProvider.companyInformation}</p>
            <div className="EmployeeInfoDepartmentPanelContainer">
                <EmployeeInfoDepartmentsPanel 
                    textProvider={this.props.textProvider}
                    organizationTopologies={this.state.organizationInfos}
                    employeeOrganizationInfos={this.props.info.departmentsInfo}
                    checkLinked={infos => this.checkLinked(infos)}
                    itemEdited={(info, index) => this.departmentInfoEdited(info, index)}
                    itemAdded={(info) => this.departmentInfoAdded(info)}
                    itemRemoved={index => this.departmentInfoRemoved(index)}/>
            </div>
        </div>;
    }
}