import IDepartmentActions from "../DepartmentActions/IDepartmentActions";
import IHierarchyActions from "./IHierarchyActions";
import IHierarchyService from "../../Services/Hierarchies/IHierarchyService";

import Department from "../../Models/Organizations/Department/Department";
import Hierarchy from "../../Models/Organizations/Hierarchy";
import MainHierarchy from "../../Models/Organizations/MainHierarchy";
import DepartmentAddAsMain from "../../Models/Organizations/Department/DepartmentAddAsMain";
import DepartmentActions from "../DepartmentActions/DepartmentActions";
import HierarchyService from "../../Services/Hierarchies/HierarchyService";

class HierarchyActions implements IHierarchyActions {
    private readonly _departmentActions : IDepartmentActions;
    private readonly _hierarchyService : IHierarchyService;
    constructor(
        departmentActions : IDepartmentActions | null = null,
        hierarchyService : IHierarchyService | null = null) {
        this._departmentActions = departmentActions ?? new DepartmentActions();
        this._hierarchyService = hierarchyService ?? new HierarchyService();
    }

    public async AddHierarchyWithMainDepartmentAsync(item : MainHierarchy, mainDepartmentName : string) : Promise<string> {
        const [ isSuccess, message ] = await this.AddHierarchyAsync(item);
        if (!isSuccess) return message;

        await this._departmentActions.AddDepartmentAsMainAsync(new DepartmentAddAsMain('', mainDepartmentName, message, 0));
        return '';
    }

    private async AddHierarchyAsync(item : MainHierarchy) : Promise<[boolean, string]> {
        return await this._hierarchyService.AddHierarchyAsync(item);
    }

    public async UpdateHierarchyAsync(item : Hierarchy) : Promise<void> {
        await this._hierarchyService.UpdateHierarchyAsync(item);
    }

    public async DeleteHierarchyAsync(id : string) : Promise<void> {
        await this._hierarchyService.DeleteHierarchyAsync(id);
    }

    public async GetHierarchyAsyncResponce(id : string) : Promise<Hierarchy> {
        const [ hierarchy, department ] = await Promise.all([
            this._hierarchyService.GetHierarchyAsync(id), 
            this._departmentActions.GetDepartmentsHierarchyAsync(id)
        ]);
        
        (hierarchy as Hierarchy).mainDepartment = department as Department;
        return hierarchy;
    }
}

export default HierarchyActions;