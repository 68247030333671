import BalanceRecord from "../Models/Balance/BalanceRecord";
import BalanceUserInfo from "../Models/Balance/BalanceUserInfo";
import Pair from "../Models/Pair";
import Tariff from "../Models/Tariffs/Tariff";
import TariffUser from "../Models/Tariffs/TariffUser";

import { BalanceService, IBalanceService } from "../Services/Balance/BalanceService";

export interface IBalanceActions {
    getUserBalanceInfo() : Promise<BalanceUserInfo>
    
    getTariffsInfo() : Promise<Pair<TariffUser, Tariff[]>>
}

export class BalanceActions implements IBalanceActions{
    private readonly _balanceService : IBalanceService;
    constructor(balanceService : IBalanceService | null = null) {
        this._balanceService = balanceService ?? new BalanceService();
    }

    public async getUserBalanceInfo() {
        const [balance, trialBalance, incomes, outcomes] = await Promise.all([
            this._balanceService.getUserBalance(),
            this._balanceService.getTrialBalance(),
            this._balanceService.getIncomeHistory(),
            this._balanceService.getOutcomeHistory(),
        ]);

        this.roundAmounts(incomes);
        this.roundAmounts(outcomes);
        return new BalanceUserInfo(balance, trialBalance, incomes, outcomes);
    }

    public async getTariffsInfo() {
        let [current, defaults] = await Promise.all([
            this._balanceService.getCurrentTariff(),
            this._balanceService.getDefaultTariffs()
        ]);

        return new Pair(current, defaults)
    }

    private readonly _roundMultiplier = 100;
    private roundAmounts(array : BalanceRecord[]) {
        array.forEach(item => item.amount = Math.floor(item.amount * this._roundMultiplier) / this._roundMultiplier)
    }
}