import IDepartmentActions from "./IDepartmentActions";
import IDepartmentService from "../../Services/Departments/IDepartmentService";

import Department from "../../Models/Organizations/Department/Department";
import DepartmentAdd from "../../Models/Organizations/Department/DepartmentAdd";
import DepartmentAddAsMain from "../../Models/Organizations/Department/DepartmentAddAsMain";
import DepartmentBase from "../../Models/Organizations/Department/DepartmentBase";
import DepartmentGet from "../../Models/Organizations/Department/DepartmentGet";
import DepartmentService from "../../Services/Departments/DepartmentService";

class DepartmentActions implements IDepartmentActions {
    private readonly _departmentService : IDepartmentService;
    constructor(service : IDepartmentService | null = null) {
        this._departmentService = service ?? new DepartmentService();
    }

    public async AddDepartmentAsync(item : DepartmentAdd) : Promise<void> {
        await this._departmentService.AddDepartmentAsync(item);
    }

    public async AddDepartmentAsMainAsync(item : DepartmentAddAsMain) : Promise<void> {
        await this._departmentService.AddDepartmentAsMainAsync(item);
    }

    public async UpdateDepartmentAsync(item : DepartmentGet) : Promise<void> {
        await this._departmentService.UpdateDepartmentAsync(item);
    }

    public async DeleteDepartmentAsync(id : string) : Promise<void> {
        await this._departmentService.DeleteDepartmentAsync(id);
    }

    public async GetDepartmentAsync(id : string) : Promise<DepartmentBase> {
        return await this._departmentService.GetDepartmentAsync(id);
    }

    public async GetDepartmentsHierarchyAsync(hierarchyId : string) : Promise<Department> {
        let departments = await this._departmentService.GetDepartmentsAsync(hierarchyId);
        let head = this.GetDepartment(this.GetHead(departments));

        return this.SetChildren(head, departments);
    }

    private GetHead(items : DepartmentGet[]) : DepartmentGet {
        for (let key in items) {
            if (items[key].superiorDepartmentId == null) {
                return items[key];
            }
        }

        throw new Error("No head");
    }

    private SetChildren(head : Department, items : DepartmentGet[]) : Department {
        for (let key in items) {
            if (items[key].superiorDepartmentId == head.id) {
                let child = this.SetChildren(this.GetDepartment(items[key]), items);
                head.AddSubDepartment(child);
            }
        }

        return head;
    }

    private GetDepartment(item : DepartmentGet) : Department {
        return new Department(item.id,item.name, item.numberDepartment);

    }
}

export default DepartmentActions;