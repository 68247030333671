import ILocalStorageAgent from "../LocalStorageWorker/ILocalStorageAgent";
import InteractiveHintState from "../Models/InteractiveHints/InteractiveHintsState";
import BaseUser from "../Models/Users/BaseUser";

export interface IInteractiveHintActions {
    /**
     * Sets whether to show hints for current user
     * @param isHintsShowAllowed 
     */
    HintsShowAllowChange(isHintsShowAllowed : boolean) : Promise<void>;

    /**
     * Cleans up info about showing hints for current user and sets show hints flag
     */
    HintsShowAgain() : Promise<void>;

    /**
     * Checks if hint with such key should be shown
     * @param hintKey 
     */
    IsHintShowNeeded(hintKey : string) : Promise<boolean>;

    /**
     * Marks hint as shown
     * @param hintKey 
     */
    MarkHintShowed(hintKey : string) : Promise<void>;

    /**
     * Checks if hint show allowed
     */
    IsHintsShowAllowed() : Promise<boolean>;
}

export class InteractiveHintActions {
    private readonly _localStorageAgent : ILocalStorageAgent;

    constructor(localStorageAgent : ILocalStorageAgent) {
        this._localStorageAgent = localStorageAgent;
    }

    public async HintsShowAllowChange(isHintsShowAllowed : boolean) : Promise<void> {
        const user = this._localStorageAgent.user as BaseUser;
        
        const states = this._localStorageAgent.interactiveHintsStates;
        const state = states.find(s => s.userId === user.id);
        if (!state) {
            states.push(new InteractiveHintState(user.id, isHintsShowAllowed, []))
        } else {
            state.isShowAllowed = isHintsShowAllowed;
        }

        this._localStorageAgent.interactiveHintsStates = states;
        return Promise.resolve();
    }

    public async HintsShowAgain() : Promise<void> {
        const user = this._localStorageAgent.user as BaseUser;

        const states = this._localStorageAgent.interactiveHintsStates;
        const state = states.find(s => s.userId === user.id);
        if (!state) {
            states.push(new InteractiveHintState(user.id, true, []))
        } else {
            state.isShowAllowed = true;
            state.showedHintsKeys = [];
        }

        this._localStorageAgent.interactiveHintsStates = states;
        return Promise.resolve();
    }

    public async IsHintShowNeeded(hintKey : string) : Promise<boolean> {
        const user = this._localStorageAgent.user as BaseUser;

        const states = this._localStorageAgent.interactiveHintsStates;
        const state = states.find(s => s.userId === user.id);
        
        if (!state) {
            states.push(new InteractiveHintState(user.id, true, [ ]))
            this._localStorageAgent.interactiveHintsStates = states;
            return Promise.resolve(true);
        }
        
        if (!state.isShowAllowed)
            return Promise.resolve(false);

        const needToShow = state.showedHintsKeys.every(key => key !== hintKey);
        return Promise.resolve(needToShow);
    }

    public async MarkHintShowed(hintKey : string) : Promise<void> {
        const user = this._localStorageAgent.user as BaseUser;
        const states = this._localStorageAgent.interactiveHintsStates;
        const state = states.find(s => s.userId === user.id);

        if (!state) {
            states.push(new InteractiveHintState(user.id, true, [ hintKey ]));
            this._localStorageAgent.interactiveHintsStates = states;
            return Promise.resolve();
        }

        if (state.showedHintsKeys.every(key => key !== hintKey)) {
            state.showedHintsKeys.push(hintKey);
            this._localStorageAgent.interactiveHintsStates = states;
        }

        return Promise.resolve();
    }

    public async IsHintsShowAllowed() : Promise<boolean> {
        const user = this._localStorageAgent.user as BaseUser;
        const states = this._localStorageAgent.interactiveHintsStates;
        const state = states.find(s => s.userId === user.id);

        if (!state) return Promise.resolve(true);

        return Promise.resolve(state.isShowAllowed);
    }
}