import React, { CSSProperties } from 'react'

import './HeaderedComponent.css'

interface IHeaderedComponent {
    text : string
    verticalAlign : 'fill' | 'fit'
}

export default function HeaderedComponent(props : IHeaderedComponent) {
    let text = props.text;
    if (text === undefined || text === null || text.length === 0)
        text = ' ';

    let style : CSSProperties = {};
    if (props.verticalAlign === 'fill') {
        style.marginTop = '0';
        style.marginBottom = 'auto';
    }

    return <div className="HeaderedComponent" style={style}>{text}</div>;
}