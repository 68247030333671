import React from 'react'
import ReactDOM from 'react-dom'

import BaseContainer from '../../Containers/BaseContainer'

import { IInteractiveHint } from './IInteractiveHint'
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase'

import './InteractiveHintComponent.css'

interface IInteractiveHintComponent {
    hint : IInteractiveHint
    lang : ILanguagePhrase
    isLast : boolean
    onClosed() : void
    onDontShowHints() : Promise<void>
}

export default class InteractiveHintComponent extends BaseContainer<IInteractiveHintComponent, {}> {
    private readonly _id : string;
    constructor(props : IInteractiveHintComponent) {
        super(props);
        this._id = `interactiveHintComponent_${props.hint.hintKey}`;
    }

    componentDidMount() {
        this.onUpdate();
    }

    componentDidUpdate() {
        this.onUpdate();
    }

    onUpdate() {
        if (document.getElementById(this._id)) return;
            
        const container = document.createElement('div');
        container.id = this._id;
        document.body.insertBefore(container, document.body.children[0]);

        const endProps : IInteractiveHintComponent = {
            ...this.props,
            onClosed : () => { close(this._id); this.props.onClosed(); },
            onDontShowHints : async () => { close(this._id); await this.props.onDontShowHints(); }
        }
        ReactDOM.render(React.createElement(InteractiveHintView, endProps), container);
    }
    
    render() {
        return <React.Fragment />
    }
}

function close(id : string) {
    const view = document.getElementById(id);
    if (!view) return;

    document.body.removeChild(view);
}

function InteractiveHintView(props : IInteractiveHintComponent) {
    const anchor = document.getElementById(props.hint.position.anchorId);
    if (!anchor) return <div />
    
    const rect = anchor.getClientRects()[0];
    const containerStyle : React.CSSProperties = {
        top: `${Math.floor(rect.top + (document.body.clientHeight * props.hint.position.shiftV / 100))}px`,
        left: `${Math.floor(rect.left + (document.body.clientWidth * props.hint.position.shiftH / 100))}px`
    };

    const lines = props.hint.hint.text.split('\n');
    return <React.Fragment>
        <div className='InteractiveHintBackground'></div>
        <div className='InterativeHintContainer' style={containerStyle}>
            <p className='SubHeader'>{props.hint.hint.header}</p>
            <p className='Prompt'>{lines.map(line => <span>{getFormattedLine(line)}</span>)}</p>
            <div className='InteractiveHintContainerInner'>
                <button className='WhiteButton' onClick={async () => await props.onDontShowHints()}>{props.lang.interactiveDontShow}</button>
                <button className='PrimaryButton' onClick={() => props.onClosed()}>{props.isLast ? props.lang.interactiveGotIt : props.lang.interactiveNext}</button>
            </div>
        </div>
    </React.Fragment>
}

function getFormattedLine(line : string) : JSX.Element {
    const splitForBold = line.split('/b/');
    return <React.Fragment>{splitForBold.map((item, index) => index % 2 === 0 ? item : <b>{item}</b>)}</React.Fragment>
}