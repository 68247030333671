import React from 'react'

import './ErrorComponent.css'

interface IErrorComponent {
    text : string
}

export default function ErrorComponent(props : IErrorComponent) {
    let text = props.text;
    if (text === undefined || text === null || text.length === 0)
        text = ' ';

    return <div className='ErrorComponent'>{text}</div>
}