import React from 'react'

import InformationComponent from '../../../Components/InformationComponent/InformationComponent';
import LanguageChangeComponent from '../../../Components/LanguageChangeComponent/LanguageChangeComponent';

import { ITextProvider, TextProvider } from '../../../Helpers/TextProvider/TextProvider';
import UserInfoComponent from '../../../Components/UserInfoComponent/UserInfoComponent'
import CompanyInfo from '../../../Components/Admin/CompanyInfo/CompanyInfo'
import UserInfo from '../../../Models/Users/UserInfo';

import './ProfileUI.css'
import Entity from '../../../Models/Organizations/Entity';
import PopUpMessage from '../../../Components/PopupMessage/PopupMessage';
import InteractiveHintsManager from '../../../Components/InteractiveHints/InteractiveHintsManager';
import { IInteractiveHintActions } from '../../../Actions/InteractiveHintActions';
import * as InteractiveHintsKeys from '../../../Helpers/InteractiveHintsKeys'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';

const adminInfoContainerId = 'PaymentUIAdminInfoContainerId',
      paymentinfoContainerId = 'PaymentUIPaymentInfoContainerId';

interface IProfileUI {
    textProvider? : ITextProvider

    info : UserInfo
    companyInfo : Entity,
    showInteractiveHints : boolean,
    interactiveHintsActions : IInteractiveHintActions

    saveInfo(info : UserInfo) : Promise<boolean | undefined>
    saveCompanyInfo(info : Entity) : Promise<boolean>
    changeLanguage(iso : string) : void
    showInteractiveHintsChange(v : boolean) : void
    showInteractiveHintsAgain() : void

    errorMessage : string
    errorMessageClose() : void
}

interface IProfileUIState {
    currentInfo : UserInfo
    companyInfo : Entity
}

export default class ProfileUI extends React.Component<IProfileUI, IProfileUIState> {
    private readonly _textProvider : ITextProvider;
    private readonly _language : ILanguagePhrase;

    constructor(props : IProfileUI) {
        super(props);
        this.state = {
            currentInfo : props.info,
            companyInfo : props.companyInfo
        };

        this._language = (this._textProvider = (props.textProvider ?? new TextProvider())).GetLang();
    }

    cancel() {
        this.setState(state => {
            return {
                ...state,
                currentInfo: this.props.info,
            };
        });
    }

    onSave(result : UserInfo) {
        return this.props.saveInfo(result);
    }

    render() {
        let errorMessage;
        if (this.props.errorMessage)
            errorMessage = <PopUpMessage 
                                zIndex={100}
                                closePopup={() => this.props.errorMessageClose()}
                                header={this._language.error}
                                text={this.props.errorMessage}
                                buttonId='ProfileUIErrorMessagePopupButtonId'/>

        return <div className="MainContainer ProfileUIContainer">
            <InteractiveHintsManager
                interativehintActions={this.props.interactiveHintsActions}
                lang={this._language}
                infos={[
                    {
                        hintKey : InteractiveHintsKeys.adminDataHintKey,
                        hint : this._language.interactiveHintAdminData,
                        position : {
                            anchorId : adminInfoContainerId,
                            shiftH : 5,
                            shiftV : 5
                        }
                    },
                    {
                        hintKey : InteractiveHintsKeys.paymentDataHintKey,
                        hint : this._language.interactiveHintPaymentData,
                        position : {
                            anchorId : paymentinfoContainerId,
                            shiftH : 5,
                            shiftV : 5
                        }
                    },
                    {
                        hintKey : InteractiveHintsKeys.supportHintKey,
                        hint : this._language.interactiveHintHelp,
                        position : {
                            anchorId : adminInfoContainerId,
                            shiftH : 5,
                            shiftV : 5
                        }
                    }
                ]}/>
            <div id={adminInfoContainerId}>
                <p className="Header">{this._language.personalInformation}</p>
                <UserInfoComponent
                    align='table'
                    info={this.state.currentInfo}
                    textProvider={this._language}
                    actionText={this._language.update}
                    action={info => this.onSave(info)}
                    onCancel={() => { }}/>
            </div>
            <div id={paymentinfoContainerId}>
                <p className="Header">{this._language.companyInformation}</p>
                <CompanyInfo
                    info={this.state.companyInfo}
                    textProvider={this._language}
                    onAction={info => this.props.saveCompanyInfo(info)}/>
            </div>
            <div>
                <p className="Header">{this._language.interfaceSettings}</p>
                <div className="ProfileUILangSelectContainer">
                    <LanguageChangeComponent 
                        vertical='fit' 
                        currentLanguage={this._language} 
                        textProvider={this._textProvider}
                        changeLanguage={v => { this.props.changeLanguage(v); window.location.reload() }}
                        id='ProfileUILangaugeChangeComponent'/>
                </div>
            </div>
            <div>
                <p className="Header">{this._language.interactiveHints}</p>
                <div className="ProfileUIInteractiveHintsContainer">
                    <div>
                        <input type="checkbox" 
                                name="showInteractiveHintsInput" 
                                checked={!this.props.showInteractiveHints} 
                                onChange={e => this.props.showInteractiveHintsChange(!e.target.checked)}/>
                        <label htmlFor="showInteractiveHintsInput">{this._language.interactiveDontShow}</label>
                    </div>
                    <button className='WhiteButton' onClick={() => this.props.showInteractiveHintsAgain()}>{this._language.interactiveAgain}</button>
                </div>
            </div>
            <InformationComponent textProvider={this._language}/>
            {errorMessage}
        </div>
    }
}