import BaseService from "../BaseService";
import DepartmentServiceConstants from "./DepartmentServiceConstants";
import IDepartmentService from "./IDepartmentService";
import RequestSettings from "../RequestProvider/RequestSettings";

import DepartmentAdd from "../../Models/Organizations/Department/DepartmentAdd";
import DepartmentAddAsMain from "../../Models/Organizations/Department/DepartmentAddAsMain";
import DepartmentBase from "../../Models/Organizations/Department/DepartmentBase";
import DepartmentGet from "../../Models/Organizations/Department/DepartmentGet";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";

import IRequestProvider from "../RequestProvider/IRequestProvider";

class DepartmentService extends BaseService implements IDepartmentService {
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async AddDepartmentAsync(item : DepartmentAdd) : Promise<void> {
        const settings = new RequestSettings(DepartmentServiceConstants.AddDepartmentSignature, new Dictionary(), JSON.stringify(item));

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => item.id = r as string)
        ))
    }

    public async AddDepartmentAsMainAsync(item : DepartmentAddAsMain) : Promise<void> {
        const settings = new RequestSettings(DepartmentServiceConstants.AddDepartmentAsMainDepartmentSignature, new Dictionary(), JSON.stringify(item));

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => item.id = r as string)
        ))
    }

    public async UpdateDepartmentAsync(item : DepartmentGet) : Promise<void> {
        const settings = new RequestSettings(DepartmentServiceConstants.UpdateDepartmentSignature, new Dictionary<string, string>(), JSON.stringify(item));

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { }),
        ))
    }

    public async DeleteDepartmentAsync(id : string) : Promise<void> {
        const settings = new RequestSettings(DepartmentServiceConstants.DeleteDepartmentSignature, new Dictionary<string, string>(new Pair("id", id)), null);

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { })
        ))
    }

    public async GetDepartmentAsync(id : string) : Promise<DepartmentBase> {
        const settings = new RequestSettings(DepartmentServiceConstants.GetDepartmentSignature, new Dictionary<string, string>(new Pair("id", id)), null)
        let result : DepartmentBase = new DepartmentBase('','',0);

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as DepartmentBase),
        ))

        return result;
    }

    public async GetDepartmentsAsync(hierarchyId : string) : Promise<DepartmentGet[]> {
        const settings = new RequestSettings(DepartmentServiceConstants.GetDepartmentsSignature, new Dictionary(new Pair("hierarchyId", hierarchyId)), null)
        let result : DepartmentGet[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as DepartmentGet[]),
            new Pair(204, _ => { })
        ));

        return result;
    }
}

export default DepartmentService;