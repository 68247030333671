import React, { FormEvent, useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

import './StripePaymentUI.css'
import ILanguagePhrase from '../../../Helpers/LanguagePhrase/ILanguagePhrase';
import RoutesUrl from '../../../Helpers/Routes/RoutesUrl';
import PopUpMessage from '../../../Components/PopupMessage/PopupMessage';
import PreloaderCompactDefault from '../../../Components/PreloaderCompact/PreloaderCompact';
import Validator from '../../../Helpers/Validator/Validator';
import DecoratorContainer from '../../../Components/Decorators/DecoratorContainer';
import ErrorComponent from '../../../Components/Decorators/ErrorComponent';

interface IStripePaymentUI {
    zIndex : number
    privateKey : string
    email: string
    textProvider : ILanguagePhrase
}

export default function StripePaymentUI(props : IStripePaymentUI) {
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState(props.email);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [paymentElementReady, setPaymentElementReady] = useState(false);

    useEffect(() => {
        if (!stripe)
            return;

        if (!props.privateKey)
            return;

        stripe.retrievePaymentIntent(props.privateKey).then(({ paymentIntent }) => {
            if (!paymentIntent) {
                setMessage(props.textProvider.error);
                return;
            }

            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage(props.textProvider.success);
                    break;
                case "processing":
                    setMessage(props.textProvider.balancePaymentProcessing);
                    break;
                case "requires_payment_method":
                    break;
                default:
                    setMessage(props.textProvider.error);
                    break;
            }
        });
      }, [stripe]);
    
    const handleSubmit = async (e : FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        if (!stripe || !elements || !Validator.IsEmailTemplate(email)) {
          return;
        }
    
        setIsLoading(true);
    
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + RoutesUrl.Balance,
            receipt_email: email,
          },
        });
    
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(!error ? props.textProvider.error : error.message as string);
        } else {
          setMessage(props.textProvider.error);
        }
    
        setIsLoading(false);
    };

    let isReady = !isLoading && stripe && elements && paymentElementReady;
    let emailErrorText = Validator.IsEmailTemplate(email) ? ' ' : props.textProvider.uncorrectEmail;

    return <form id="payment-form" onSubmit={async e => await handleSubmit(e)}>
        <PaymentElement id="payment-element" onReady={e => setPaymentElementReady(true)}/>
        <DecoratorContainer>
          <input id="StripepaymentUIEmail" type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={props.textProvider.email} />
          <ErrorComponent text={emailErrorText}/>
        </DecoratorContainer>
        <div className="StripePaymentUIButtonBlock">
            {isReady ? <button id="submit" className="PrimaryButton">{props.textProvider.balancePay}</button> : <PreloaderCompactDefault />}
        </div>
        { /*Show any error or success messages*/ }
        {message && <PopUpMessage zIndex={props.zIndex} closePopup={() => setMessage('')} header='' text={message} buttonId='StripePaymentUIMessagePopupButtonId'/>}
    </form>
}