import EmployeeDepartmentInfo from "./EmployeeDepartmentInfo";

class EmployeeOrganizationInfo extends EmployeeDepartmentInfo {
    constructor(
        departmentId : string = '', 
        departmentName : string = '',
        hierarchyId : string = '',
        hierarchyName : string = '',
        organizationId : string = '',
        organizationName : string = '') {
        
        super(departmentId, departmentName);
        this.hierarchyId = hierarchyId;
        this.hierarchyName = hierarchyName;
        this.organizationId = organizationId;
        this.organizationName = organizationName;
    }

    public hierarchyId : string;

    public hierarchyName : string;

    public organizationId : string;

    public organizationName : string;
}

export default EmployeeOrganizationInfo;