import BaseService from "../BaseService";
import Entity from "../../Models/Organizations/Entity";
import Individual from "../../Models/Organizations/Individual";
import IOrganizationService from "./IOrganizationService";
import RequestSettings from "../RequestProvider/RequestSettings";
import Dictionary from "../../Models/Collections/Dictionary";
import Pair from "../../Models/Pair";
import BaseUser from "../../Models/Users/BaseUser";

import { 
    updateEntitySignature, 
    deleteEntitySignature,
    getEntitiesSignature, 
    getIndividualsSignature,
    getUsersOrganizationByToken 
} from './OrganizationServiceConstants'

import IRequestProvider from "../RequestProvider/IRequestProvider";
import { GetMessage } from "../ObjectsReflector";

class OrganizationService extends BaseService implements IOrganizationService {
    constructor(requestProvider : IRequestProvider | null = null){
        super(requestProvider);
    }

    public async UpdateEntityAsync(item : Entity) : Promise<void> {
        const settings = new RequestSettings(updateEntitySignature, new Dictionary<string, string>(), JSON.stringify(item));

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => { }),
            new Pair(204, _ => { })
        ));
    }

    public async DeleteEntityAsync(id : string) : Promise<string> {
        const settings = new RequestSettings(deleteEntitySignature, new Dictionary(new Pair('id', id)), {});
        let result = '';

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, _ => result = ''),
            new Pair(204, _ => result = ''),
            new Pair(400, r => result = GetMessage(r)),
        ));

        return result;
    }

    public async GetEntitiesAsync() : Promise<Array<Entity>> {
        const settings = new RequestSettings(getEntitiesSignature, new Dictionary(), null)
        let result : Entity[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as Entity[]),
            new Pair(204, _ => { }),
        ));

        return result;
    }

    public async GetIndividualsAsync() : Promise<Array<Individual>> {
        const settings = new RequestSettings(getIndividualsSignature, new Dictionary<string, string>() , null)
        let result : Individual[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as Individual[]),
            new Pair(204, _ => { }),
        ))

        return result;
    }

    public async GetUsersOrganizationsByToken() : Promise<Array<BaseUser>> {
        const settings = new RequestSettings(getUsersOrganizationByToken, new Dictionary(), null)
        let result : BaseUser[] = [];

        await this.RequestProvider.Request(settings, new Dictionary(
            new Pair(200, r => result = r as BaseUser[]),
            new Pair(204, _ => { })
        ));

        return result;
    }
}

export default OrganizationService;