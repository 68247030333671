import React from 'react'

import BaseContainer from '../../../Containers/BaseContainer'

import './ButtonInteractive.css'

import OkImg from '../../../Media/Img/okWhite.png'
import ErrorImg from '../../../Media/Img/errorWhite.png'
export { OkImg, ErrorImg };

interface IButtonInteractive {
    id : string
    successImgSrc : string
    errorImgSrc : string
    buttonContainerClass: string
    buttonStyle : string
    buttonText : string
    /**
     * @returns animation status: true - success animation, false - error animation, undefined - no animation
     */
    action : () => Promise<boolean | undefined>
}

interface IButtonInteractiveState {
    animationStatus : number
    imgSrc : string
}

export default class ButtonInteractive extends BaseContainer<IButtonInteractive, IButtonInteractiveState> {
    private readonly buttonId = 'button'
    private readonly imgId = 'img';

    private readonly animationDuration = 3000;

    constructor(props : IButtonInteractive) {
        super(props);
        this.state = {
            animationStatus : 0,
            imgSrc: props.successImgSrc, //it's hidden
        };
    }

    onClick() {
        this.props.action().then(result => {
            if (result === undefined) return;
            
            if (result === true) {
                this.setState(state => { return { ...state, imgSrc: this.props.successImgSrc }});
                this.initAnimation();
            } else if (result === false) {
                this.setState(state => { return { ...state, imgSrc: this.props.errorImgSrc }});
                this.initAnimation();
            }
        });
    }

    initAnimation() {
        const buttonBlock = document.getElementById(`${this.props.id}_${this.buttonId}`) as HTMLElement;
        const imgId = document.getElementById(`${this.props.id}_${this.imgId}`) as HTMLElement;

        const color = buttonBlock.style.color;
        const zIndex = buttonBlock.style.zIndex;
        buttonBlock.animate([
            { color: color, zIndex: 0, offset: 0 },
            { color: 'transparent', zIndex: 0, offset: .15 },
            { color: 'transparent', zIndex: 0, offset: .85 },
            { color: color, zIndex: 0, offset: 1 },
            { zIndex: zIndex, offset : 1 }
        ], this.animationDuration);
        imgId.animate([
            { opacity: 0, offset: 0    },
            { opacity: 1, offset: 0.15 },
            { opacity: 1, offset: 0.85 },
            { opacity: 0, offset: 1    }
        ], this.animationDuration);
    }

    render() {
        return <div className={`ButtonInteractiveContainer ${this.props.buttonContainerClass}`}>
            <button
                id={`${this.props.id}_${this.buttonId}`}
                className={this.props.buttonStyle + " ButtonInteractiveButton"} 
                onClick={() => this.onClick()}>{this.props.buttonText}</button>
            <div id={`${this.props.id}_${this.imgId}`} className="ButtonInteractiveImgContainer">
                <img className='ButtonInteractiveImg' src={this.state.imgSrc}/>
            </div>
        </div>
    }
}