import React from 'react'

import './DecoratorContainer.css'

const DecoratorContainer : React.FunctionComponent = props => {
    return <div className='DecoratorContainer'>
        {props.children}
    </div>
}

export default DecoratorContainer;