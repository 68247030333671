import React from 'react'

export default class PaginatedList<T> {
    private readonly items : T[];
    private readonly pageSize : number;

    constructor(items : T[], pageSize : number) {
        this.items = ([] as T[]).concat(items);
        this.pageSize = pageSize;
    }

    public nextPage() : T[] {
        let items = this.items.splice(0, this.pageSize)
        return items;
    }
}