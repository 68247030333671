import RequestSignature from "../RequestProvider/RequestSignature";

class DepartmentServiceConstants {

    public static readonly AddDepartmentSignature = new RequestSignature("POST", "/api/v1.0/Department/AddDepartment");

    public static readonly AddDepartmentAsMainDepartmentSignature = new RequestSignature("POST", "/api/v1.0/Department/AddDepartmentAsMainDepartment");

    public static readonly UpdateDepartmentSignature = new RequestSignature("PUT", "/api/v1.0/Department/UpdateDepartment");

    public static readonly DeleteDepartmentSignature = new RequestSignature("DELETE", "/api/v1.0/Department/DeleteDepartment");

    public static readonly GetDepartmentSignature = new RequestSignature("GET", "/api/v1.0/Department/GetDepartment");

    public static readonly GetDepartmentsSignature = new RequestSignature("GET", "/api/v1.0/Department/GetDepartments");
}

export default DepartmentServiceConstants;