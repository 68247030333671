import React, { useState } from 'react'

import ButtonInteractive, { ErrorImg, OkImg } from '../Buttons/ButtonInteractive/ButtonInteractive'
import {InputV2, InputV2Props} from "../InputV2/InputV2"

import UserInfo from "../../Models/Users/UserInfo"
import ILanguagePhrase from '../../Helpers/LanguagePhrase/ILanguagePhrase';
import Validator from "../../Helpers/Validator/Validator"

import './UserInfoComponent.css'

interface IUserInfoComponent {
    align : 'vertical' | 'table'
    info : UserInfo
    textProvider: ILanguagePhrase
    actionText : string
    action : (info : UserInfo) => Promise<boolean | undefined>
    onCancel: () => void
}

export default function UserInfoComponent(props : IUserInfoComponent) {
    const resetInfo = () => {
        forceErrorChange(true);
        firstNameChange(props.info.firstName);
        lastNameChange(props.info.lastName);
        emailChange(props.info.email);
        phoneChange(props.info.phone);
    }

    const [ forceError, forceErrorChange ] = useState(false);
    const [ firstName, firstNameChange ] = useState(props.info.firstName);
    const [ lastName, lastNameChange] = useState(props.info.lastName);
    const [ email, emailChange ] = useState(props.info.email);
    const [ phone, phoneChange ] = useState(props.info.phone);

    const checkAll = () => firstNameCheck(firstName) && lastNameCheck(lastName) && emailCheck(email) && phoneCheck(phone);

    const firstNameCheck = (v : string) => !Validator.isNullOrWhitespace(v);
    const lastNameCheck = (v : string) => !Validator.isNullOrWhitespace(v);
    const emailCheck = (v : string) => Validator.IsEmailTemplate(v);
    const phoneCheck = (v : string) => Validator.IsOptionalPhoneTemplate(v);

    const onAction = () => {
        forceErrorChange(true);
        if (!checkAll()) return Promise.resolve(false);
        
        return Promise.resolve(props.action(new UserInfo(firstName, lastName, email, phone)));
    }

    return <div>
        <div className={props.align === 'table' ? 'SettingsTable' : 'SettingsVertical'}>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.firstName, 
                    forceError,
                    props.textProvider.uncorrectFirstName, 
                    firstName, 
                    firstNameCheck(firstName), 
                    v => firstNameChange(v),
                    'text', false, 'fit'))}
                {InputV2(new InputV2Props(
                    props.textProvider.lastName,
                    forceError,
                    props.textProvider.uncorrectLastName,
                    lastName,
                    lastNameCheck(lastName),
                    v => lastNameChange(v),
                    'text', false, 'fit'))}
            </div>
            <div>
                {InputV2(new InputV2Props(
                    props.textProvider.email,
                    forceError,
                    props.textProvider.uncorrectEmail,
                    email,
                    emailCheck(email),
                    v => emailChange(v),
                    'email', false, 'fit'))}
                {InputV2(new InputV2Props(
                    props.textProvider.phoneNumber,
                    forceError,
                    props.textProvider.uncorrectPhoneNumber,
                    phone,
                    Validator.IsOptionalPhoneTemplate(phone),
                    v => phoneChange(v),
                    'text', false, 'fit'))}
            </div>
        </div>
        <div className="ButtonsContainer">
                <div className="SecondaryButton" onClick={() => {props.onCancel(); resetInfo()}}>{props.textProvider.cancel}</div>
                <ButtonInteractive 
                    id="UserInfoComponentButtonInteractive"
                    successImgSrc={OkImg}
                    errorImgSrc={ErrorImg}
                    buttonContainerClass=''
                    buttonStyle='PrimaryButton'
                    buttonText={props.actionText}
                    action={() => onAction()}/>
            </div>
    </div>
}