import React from 'react'
import ReactDOM from 'react-dom'

interface IInjectableComponent {
    popupContainerId : string
    popupContainerClass : string
    isPopupNeeded : boolean
    template : JSX.Element
    getPopup() : JSX.Element
}

/** 
 * Component which has 
 *  @see template showing on it's place
 *  @see popup which shows when activated and injected in document's body
 */
export default function InjectableComponent(props : IInjectableComponent) {
    const popupContainer = document.getElementById(props.popupContainerId);
    if (props.isPopupNeeded && !popupContainer) {
        const container = document.createElement('div');
        container.id = props.popupContainerId;
        container.className = props.popupContainerClass;
        document.body.appendChild(container);
        ReactDOM.render(props.getPopup(), container);
    } 
    
    if (!props.isPopupNeeded && popupContainer) {
        document.body.removeChild(popupContainer);
    }

    return props.template;
}