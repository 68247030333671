import React from 'react'

import './ImagedComponent.css'

export interface IImagedComponent {
    imgPosition: 'pre' | 'post'
    imgSrc : string
    imgClicked : () => void
}

const ImagedComponent : React.FunctionComponent<IImagedComponent> = props => {
    let imgStyle : React.CSSProperties = { }
    if (props.imgPosition === 'pre')
        imgStyle.marginRight = '0.5vw';
    else
        imgStyle.marginLeft = '0.5vw';

    let img = <img style={imgStyle} onClick={() => props.imgClicked()} className='ImagedComponentImg' src={props.imgSrc}/>;

    return <div className='ImagedComponent'>
        {props.imgPosition === 'pre' && img}
        <div className='ImagedComponentContainer'>
            {props.children}
        </div>
        {props.imgPosition === 'post' && img}
    </div>
}

export default ImagedComponent;