import DateType from "../DateType";
import AddAnalysis from "./AddAnalysis"

class AddHierarchyAnalysis extends AddAnalysis{

    constructor(hierarchyId : string, firstDate : Date, secondDate : Date, dateType : DateType){
        super(firstDate,secondDate,dateType);
        this.hierarchyId = hierarchyId;
    }

    public hierarchyId : string;


}

export default AddHierarchyAnalysis;