import React from "react"
import LinkPart from "../../Models/Link/LinkPart";
import LinkPartBase from "../../Models/Link/LinkPartBase"
import LinkText from "../../Models/Link/LinkText";
import "./LinkArea.css"

interface ILinkArea {
    linkParts: LinkPartBase[];
    isChecked : boolean;
    checked(value : boolean) : void;
    id : string;
}

function getLinkElement(item: LinkPart) : JSX.Element {
    return <a key={item.text} href={item.address} target="_blank">{item.text}</a>
}

function getTextElement(item: LinkText) : JSX.Element {
    return <span key={item.text}>{item.text}</span>
}

function LinkArea(props: ILinkArea) {
    const checked = () => {
        props.checked(!props.isChecked);
    }

    let children : JSX.Element[] = props.linkParts
                                        .map(item => item instanceof LinkPart 
                                            ? getLinkElement(item as LinkPart) 
                                            : getTextElement(item));

    return <div className="LinkAreaContainer">
        <input id={props.id} type="checkbox" onChange={() => checked()} checked={props.isChecked}></input>
        <div className="linkArea">
            {children}
        </div>
    </div>
}

export default LinkArea;