export interface IRoutesAgent {
    navigateTo(url : string) : void;
    getArgFromQuery(key : string) : string | null;
    reload() : void;
    /**
     * Adds event handler for window's scroll event
     * @param handler function that handles event. Argument is scroll from 0 = top to 1 = bottom 
     * @returns event handler for use in @see removeOnScrollEvent
     */
    onScrollEventAdd(handler : (relativeScroll : number) => void) : any;
    onScrollEventRemove(handler : any) : void;
}

export class RoutesAgent implements IRoutesAgent {
    public navigateTo(url: string): void {
        window.location.href = url;
    }

    public getArgFromQuery(key: string): string | null {
        let params = new URLSearchParams(window.location.search);
        return params.get(key);
    }

    public reload() {
        window.location.reload();
    }

    public onScrollEventAdd(handler : (relativeScroll : number) => void) : any {
        const innerHandler = () => handler(window.scrollY / (document.body.clientHeight - window.innerHeight));
        window.addEventListener('scroll', innerHandler);
        return innerHandler;
    }

    public onScrollEventRemove(handler : any) {
        window.removeEventListener('scroll', handler);
    }
}