import IStateContainerCallback from "../../Actions/IStateContainerCallback";
import { IEndInitCallback, ILoadOnlyHierarchy, INotExistReadyReports } from "./OrganizationContainerCallback";

import IMiddlewareActionsOrganization from "./IMiddlewareActionsOrganization";

import { IAnxietyAnalysisActions, AnxietyAnalysisActions} from "../../Actions/AnxietyAnalysisActions";

import IHierarchyActions from "../../Actions/HerarchyActions/IHierarchyActions";
import ILocalStorageAgent from "../../LocalStorageWorker/ILocalStorageAgent";
import IOrganizationAction from "../../Actions/OrganizationActions/IOrganizationAction";
import IReportsActions from "../../Actions/ReportsActions/IReportsActions";
import ITensenessAnalysisActions from "../../Actions/TensenessActions/ITensenessAnalysisActions";
import IVolumeActions from "../../Actions/VolumeActions/IVolumeActions";

import HierarchyActions from "../../Actions/HerarchyActions/HierarchyActions";
import LocalStorageAgent from "../../LocalStorageWorker/LocalStorageAgent";
import OrganizationActions from "../../Actions/OrganizationActions/OrganizationActions";
import ReportsActions from "../../Actions/ReportsActions/ReportsActions";
import TensenessAnalysisActions from "../../Actions/TensenessActions/TensenessAnalysisActions";
import VolumeActions from "../../Actions/VolumeActions/VolumeActions";

import Hierarchy from "../../Models/Organizations/Hierarchy";
import DateType from "../../Models/Analysis/DateType";
import AddHierarchyAnalysis from "../../Models/Analysis/Add/AddHierarchyAnalysis";
import Report from "../../Models/Analysis/Report";
import ReportsGroup from "../../Models/Analysis/Report/ReportsGroup";

export default class MiddlewareActionsOrganization implements IMiddlewareActionsOrganization{
    private readonly _anxietyActions : IAnxietyAnalysisActions = new AnxietyAnalysisActions();
    private readonly _hierarchyActions : IHierarchyActions = new HierarchyActions();
    private readonly _localStorageAgent : ILocalStorageAgent = new LocalStorageAgent();
    private readonly _organizationsActions : IOrganizationAction = new OrganizationActions();
    private readonly _reportsActions : IReportsActions = new ReportsActions();
    private readonly _tensenessActions : ITensenessAnalysisActions = new TensenessAnalysisActions();
    private readonly _volumeActions : IVolumeActions = new VolumeActions();

    public async Init(endLoadCallback : IEndInitCallback, notExistHierarchy : IStateContainerCallback, 
        notExistReports : ILoadOnlyHierarchy, notExistReadyReports : INotExistReadyReports,) : Promise<void> {

        const [ reports, hierarchies, interval ] = await Promise.all([
            this._reportsActions.GetReportDateIntervalResponce(),
            this.GetHierarchys(),
            this._reportsActions.GetReportInterval()
        ]);

        if(hierarchies.length === 0) {
            notExistHierarchy(""); 
            return;
        }

        const nowHierarchy = this.GetCurrentHierarchy(hierarchies);
        if (!reports || !reports.length) {
            notExistReports(nowHierarchy, hierarchies, interval);
            return;
        }

        const hierarchyReports = reports.filter(item => item.hierarchyId == nowHierarchy.id);
        const nowReport = this.GetDefaultReport(nowHierarchy.id, hierarchyReports.filter(r => r.isReady || r.almostReady));
        if (!nowReport) {
            notExistReadyReports(nowHierarchy, hierarchies, interval, hierarchyReports);
            return;
        }

        const group = await this.GetAllIndexeByReportId(nowReport.reportId);
        if(group.tensityReport.length != 0 && group.anxietyReport.length != 0 && group.volumeReport.length != 0)
            endLoadCallback(group, reports, nowReport, hierarchies ,nowHierarchy, interval);
        else
            notExistReadyReports(nowHierarchy, hierarchies, interval, hierarchyReports);
    }

    private GetCurrentHierarchy(hierarchys : Array<Hierarchy>) : Hierarchy {
        let nowHId = this._localStorageAgent.lastHierarchyId;
        if(nowHId){
            const nowH = hierarchys.find(item => item.id == nowHId);
            if(nowH) return nowH;
        }
        return hierarchys[0];
    }

    private GetDefaultReport(hierarchyId : string, reports : Report[]) {
        const defaultReportId = this._localStorageAgent.getDefaultReportIdByHierarchyId(hierarchyId);
        if (!defaultReportId) return reports[0];

        return reports.find(r => r.reportId === defaultReportId) ?? reports[0];
    }

    public async GetAllIndexeByReportId(reportId : string) : Promise<ReportsGroup> {
        const [ tensityReport, anxietyReport, volumeReport ] = await Promise.all([
            this._tensenessActions.GetReportsWellbeingByReportIdResponce(reportId),
            this._anxietyActions.GetReportsEmotionalResponce(reportId),
            this._volumeActions.GetReportActivityByReportId(reportId)
        ]);
        return new ReportsGroup(anxietyReport, tensityReport, volumeReport);
    }

    private async GetHierarchys() : Promise<Array<Hierarchy>> {
        const nowOrg = await this._organizationsActions.GetCurrentOrganization();
        const hierarchies = await Promise.all(nowOrg.hierarchyIds.map(item => this._hierarchyActions.GetHierarchyAsyncResponce(item)))
        return hierarchies;
            
    }

    public async GetReports() : Promise<Array<Report>> {
        return await this._reportsActions.GetReportDateIntervalResponce();
    }
    
    public async reportHierarchy(firstDate : Date, secondDate : Date, dateType : DateType, hierarchyId : string) : Promise<void> {
        let offset = firstDate.getTimezoneOffset();
        let firstDateUTC = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate(), firstDate.getHours(), firstDate.getMinutes() - offset);
        let secondDateUTC = new Date(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate(), secondDate.getHours(), secondDate.getMinutes() - offset);
        let addHierarchyAnalysis = new AddHierarchyAnalysis(hierarchyId, firstDateUTC, secondDateUTC, dateType);
        return this._reportsActions.AddReportsHierarchy(addHierarchyAnalysis);
    }

    public async deleteReport(reportId : string) : Promise<void> {
        return this._reportsActions.DeleteReportByReportId(reportId);
    }
}